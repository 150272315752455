import { DefaultTheme } from 'styled-components';

import { theme as brandTheme } from 'components/layout/brand-theme';

import { DesignTokens, designTokens } from './constants/design-tokens';

export const theme: DefaultTheme = {
  ...brandTheme,
  token(tokenName: keyof DesignTokens) {
    return designTokens[tokenName];
  },
};
