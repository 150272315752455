import { ILoyaltyCard } from 'generated/rbi-graphql';
import { IActiveCardDetails } from 'state/loyalty/types';

export const useLoyaltyActiveCard = () => {
  return {
    refetchCards: () => Promise.resolve(),
    refetchActiveCard: () => Promise.resolve(),
    loading: false,
    loadingSetLoyaltyCardRewardsTier: false,
    error: undefined,
    activeCard: null as ILoyaltyCard | null,
    loyaltyDetails: null as IActiveCardDetails | null,
    loyaltyPointsFraction: '-',
  };
};
