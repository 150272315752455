import locale from 'date-fns/esm/locale/en-US';

import { IDayOfWeekBand, Rule } from '@rbi-ctg/offers';

import { findFirstRuleOfTypeThatApplies } from './rule-of-type-will-apply';
import { RuleType } from './types';

export const isRedeemableToday = (ruleSet: Rule[] | null): boolean => {
  const dayOfWeekBand = findFirstRuleOfTypeThatApplies<IDayOfWeekBand>(
    RuleType.DayOfWeekBand,
    ruleSet
  );

  // If rule is not present return
  if (!dayOfWeekBand) {
    return true;
  }

  const today = new Date();
  const dayInWeek = today.getDay();
  const dayInWeekString = (locale?.localize?.day(dayInWeek) || '').toLowerCase();

  return Boolean(dayOfWeekBand[dayInWeekString]);
};

export default isRedeemableToday;
