import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { LinkButton } from 'components/link-button';
import UserVerificationModal from 'components/user-verification-modal';
import { primitive } from 'styles/constants/primitives';

import { styles } from './verify-email-message.styled';

const Container = styled.div`
  ${styles}
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  border-radius: ${Styles.borderRadius};
  p {
    margin: ${primitive.$spacing2} 0px;
  }
  button {
    font-weight: ${Styles.fontWeight.heavy};
  }
`;

interface IVerifyEmailMessageProps {
  redirectUrl?: string;
  showModalOnLoad?: boolean;
}

const VerifyEmailMessage = ({ redirectUrl, showModalOnLoad = false }: IVerifyEmailMessageProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [showVerificationModal, setShowVerificationModal] = useState(showModalOnLoad);

  const handleModalDismiss = useCallback(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      setShowVerificationModal(false);
    }
  }, [navigate, redirectUrl]);

  return (
    <>
      <Container data-testid="verify-email-banner">
        <p>{formatMessage({ id: 'pleaseVerifyEmail' })}</p>
        <LinkButton data-testid="verify-email-link" onClick={() => setShowVerificationModal(true)}>
          {formatMessage({ id: 'verify' })}
        </LinkButton>
      </Container>
      {showVerificationModal && <UserVerificationModal onDismiss={handleModalDismiss} />}
    </>
  );
};

export default VerifyEmailMessage;
