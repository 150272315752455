import { useRewardsSectionQuery } from 'generated/sanity-graphql';

export const useRewardsSection = (id?: string) => {
  const rewardsSectionId = id ?? '';

  const { data, loading } = useRewardsSectionQuery({
    variables: {
      rewardsSectionId,
    },
  });

  const rewardsSection = data?.RewardsSection;

  return {
    rewardsSection,
    rewardsSectionLoading: loading,
  };
};
