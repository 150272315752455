/* eslint-disable */
import { getCurrentVersion } from 'utils/appflow';
import { isProduction, appVersionCode, getApiKey, RBIBrand, brand, getCountry } from 'utils/environment';

export default callback => {
  const domains = { 'ch': 'mp.burger-king.ch', 'gb': 'mp.burgerking.co.uk'};
  const cnameDomain = domains[getCountry()];
  const isCNAMEEnabled = (brand() === RBIBrand.BK && getCountry() === 'ch');
  const urls = (isCNAMEEnabled?{
    isCNAMEEnabled,
    cnameDomain,
    v1SecureServiceUrl: cnameDomain + '/webevents/v1/JS/',
    v2SecureServiceUrl: cnameDomain + '/webevents/v2/JS/',
    v3SecureServiceUrl: cnameDomain + '/webevents/v3/JS/',
    configUrl: cnameDomain + '/tags/JS/v2/',
    identityUrl: cnameDomain + '/identity/v1/',
    aliasUrl: cnameDomain + '/webevents/v1/identity/',}:{})
  getCurrentVersion().then(data => {
    window.mParticle = {
      config: {
        appVersion: data ? data.binaryVersionCode : appVersionCode(),
        appFlowBuildId: data ? data.buildId : '',
        userClient: window.navigator.userAgent || '',
        isDevelopmentMode: !isProduction,
        logLevel: isProduction ? 'none' : 'warning',
        dataPlan: {
          planId: 'data_plan'
        },
        ...urls,
      }
    };
    init(getApiKey('mParticle'), callback);
  });
};

const init = (t, callback) => {
  window.mParticle = window.mParticle || {};
  window.mParticle.EventType = {
    Unknown: 0,
    Navigation: 1,
    Location: 2,
    Search: 3,
    Transaction: 4,
    UserContent: 5,
    UserPreference: 6,
    Social: 7,
    Other: 8
  };
  window.mParticle.eCommerce = { Cart: {} };
  window.mParticle.Identity = {};
  window.mParticle.ProductActionType = {
    Unknown: 0,
    AddToCart: 1,
    RemoveFromCart: 2,
    Checkout: 3,
    ViewDetail: 6,
    Purchase: 7,
    Refund: 8,
  }
  window.mParticle.config = window.mParticle.config || {};
  window.mParticle.config.rq = [];
  window.mParticle.config.snippetVersion = 2.3;
  window.mParticle.ready = function (t) {
    window.mParticle.config.rq.push(t);
  };
  var e = [
    'endSession',
    'logError',
    'logBaseEvent',
    'logEvent',
    'logForm',
    'logLink',
    'logPageView',
    'setSessionAttribute',
    'setAppName',
    'setAppVersion',
    'setOptOut',
    'setPosition',
    'startNewSession',
    'startTrackingLocation',
    'stopTrackingLocation'
  ];
  var o = ['setCurrencyCode', 'logCheckout', 'logProductAction', 'createProduct'];
  var i = ['identify', 'login', 'logout', 'modify'];
  e.forEach(function (t) {
    window.mParticle[t] = n(t);
  });
  o.forEach(function (t) {
    window.mParticle.eCommerce[t] = n(t, 'eCommerce');
  });
  i.forEach(function (t) {
    window.mParticle.Identity[t] = n(t, 'Identity');
  });

  function n(e, o) {
    return function () {
      if (o) {
        e = o + '.' + e;
      }
      var t = Array.prototype.slice.call(arguments);
      t.unshift(e);
      window.mParticle.config.rq.push(t);
    };
  }
  var dpId,
    dpV,
    config = window.mParticle.config,
    env = config.isDevelopmentMode ? 1 : 0,
    dbUrl = '?env=' + env,
    dataPlan = window.mParticle.config.dataPlan;
    dataPlan &&
      ((dpId = dataPlan.planId),
      (dpV = dataPlan.planVersion),
      dpId &&
        (dpV && (dpV < 1 || dpV > 1e3) && (dpV = null),
        (dbUrl += '&plan_id=' + dpId + (dpV ? '&plan_version=' + dpV : ''))));
  var mp = document.createElement('script');
  mp.type = 'text/javascript';
  mp.async = true;
  if (window.mParticle.config.isCNAMEEnabled) {
    mp.src = `${document.location.protocol}//${window.mParticle.config.cnameDomain}/tags/js/v2/${t}/mparticle.js${dbUrl}`;
  } else {
    mp.src =
      ('https:' == document.location.protocol ? 'https://jssdkcdns' : 'http://jssdkcdn') +
      '.mparticle.com/js/v2/' +
      t +
      '/mparticle.js' +
      dbUrl;
  }
  mp.onload = callback;
  var c = document.getElementsByTagName('script')[0];
  c.parentNode.insertBefore(mp, c);
};
