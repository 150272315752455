import styled from 'styled-components';

import { ModalContent } from 'components/modal';

export const ModalHeader = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.token('text-default')};
  letter-spacing: 0;
  text-align: center;
  font-family: ${Styles.fontFamily.base};
  font-weight: normal;
  text-transform: uppercase;

  ${Styles.desktop`
    line-height: 1;
  `}
`;

export const HeaderPrefix = styled.span`
  display: inline-block;
  margin: 2rem 0 0.75rem;
  font-size: 1rem;
  font-family: ${Styles.fontFamily.body};
  font-weight: bold;
  text-transform: none;
`;

export const GeolocationImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0rem;
  width: 50px;
  height: 80px;
`;

export const ContentContainer = styled(ModalContent)`
  font-size: 1rem;

  & div.modal-inner {
    max-width: none;
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 0 1.5rem;
    ${Styles.desktop`
      padding: 3rem 15% 2rem;
    `}
  }

  & div.modal-scroller {
    padding-top: 3rem;
    ${Styles.desktop`
      padding: 0;
    `}
  }
`;
