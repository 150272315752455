// @ts-ignore
export const checkForUpdate = (() =>
  // @ts-ignore
  Promise.resolve({})) as unknown as typeof window.IonicCordova.deploy.checkForUpdate;
export const downloadUpdate = (() =>
  Promise.resolve() as unknown) as typeof window.IonicCordova.deploy.downloadUpdate;
export const extractUpdate = (() =>
  Promise.resolve() as unknown) as typeof window.IonicCordova.deploy.extractUpdate;
export const getCurrentVersion = (() =>
  Promise.resolve() as unknown) as typeof window.IonicCordova.deploy.getCurrentVersion;
export const reloadApp = (() =>
  Promise.resolve() as unknown) as typeof window.IonicCordova.deploy.reloadApp;
// eslint-disable-next-line
export const updateDeployChannel = ((channel: string) =>
  Promise.resolve() as unknown) as typeof window.IonicCordova.deploy.configure;

export { AppflowAction, AppflowError, isAppflowError } from './appflow-error';
