import React, { FC, createContext, useContext, useMemo } from 'react';

import { useFeatureMenu } from 'hooks/use-feature-menu';

import useActiveDayParts, { IValidDayPart, isValidDayPart } from './hooks/use-active-day-parts';

export interface IDayPartCtx {
  activeDayParts: ReturnType<typeof useActiveDayParts>;
  dayParts: ReadonlyArray<IValidDayPart>;
}

export const DayPartContext = createContext<IDayPartCtx>({ activeDayParts: [], dayParts: [] });

export const useDayPartContext = () => useContext(DayPartContext);

export const DayPartProvider: FC = ({ children }) => {
  const { featureMenu } = useFeatureMenu();
  const dayParts = useMemo(
    () => featureMenu?.dayParts?.filter(isValidDayPart) || [],
    [featureMenu]
  );

  const activeDayParts = useActiveDayParts({ dayParts });

  return (
    <DayPartContext.Provider value={{ activeDayParts, dayParts }}>
      {children}
    </DayPartContext.Provider>
  );
};
