import React, { FC } from 'react';

import ActionButton from 'components/action-button';
import { Container } from 'components/app-header/loyalty/rewards-management/reward-tier-selection-modal/components/reward-tier-selection-button/reward-tier-selection-button.styled';

import { IRewardTierSelectionButton } from './types';

export const RewardTierSelectionButton: FC<IRewardTierSelectionButton> = ({
  buttonText,
  onClick,
}) => {
  return (
    <Container>
      <ActionButton onClick={onClick} fullWidth>
        {buttonText}
      </ActionButton>
    </Container>
  );
};
