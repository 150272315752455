import { useTrendingQuery } from 'generated/sanity-graphql';

export const useTrending = (id?: string) => {
  const trendingId = id ?? '';

  const { data, loading } = useTrendingQuery({
    variables: {
      trendingId,
    },
  });

  const trending = data?.Trending;

  return {
    trending,
    trendingLoading: loading,
  };
};
