import { IAddressPredictions } from 'pages/store-locator/context/types';
import { useStoreContext } from 'state/store';
import { IPlaceData } from 'utils/geolocation';

export const usePlaceIdTomTomDetails = () => {
  const { storeAddresses } = useStoreContext();

  const getTomTomDetails = (placeId?: string): IPlaceData | undefined => {
    if (placeId && storeAddresses) {
      return storeAddresses
        .filter(({ id }) => id === placeId)
        .map(
          ({ position, address }: IAddressPredictions) =>
            ({
              coordinates: {
                lat: position?.lat,
                lng: position?.lng,
              },
              address: {
                addressLine1: address.addressLine1,
                addressLine2: address.addressline2 || '',
                city: address.city,
                state: address.state,
                streetNumber: address.streetNumber || '',
                zip: address.zipCode || '',
                country: address.country,
              },
            } as IPlaceData)
        )
        ?.pop();
    }
    return undefined;
  };

  return {
    getTomTomDetails,
  };
};
