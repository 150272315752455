import { useContext, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import useRoutes from 'hooks/use-routes';
import { AuthContext } from 'state/auth';

interface IProps {
  skip?: boolean;
  keepParams?: boolean;
  searchParamsOverride?: URLSearchParams;
}

export default function useAuthRedirects(
  props: IProps | undefined = { skip: false, keepParams: false }
) {
  const { loading, isAuthenticated, originLocation, setOriginLocation } = useContext(AuthContext);
  const authenticated = isAuthenticated();
  const location = useLocation();
  const { getLocalizedRouteForPath } = useRoutes();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.skip) {
      return;
    }

    if (loading) {
      return;
    }

    if (authenticated && originLocation) {
      const nextLocation = originLocation;
      setOriginLocation(null);
      navigate(nextLocation, { replace: true });
    } else if (
      !authenticated &&
      location.pathname !== getLocalizedRouteForPath('signin') &&
      location.pathname !== getLocalizedRouteForPath('signup')
    ) {
      setOriginLocation(`${location.pathname}${location.search}`);

      const searchParamsOverride = props.searchParamsOverride
        ? `?${props.searchParamsOverride.toString()}`
        : undefined;
      const searchParams = searchParamsOverride ?? location.search;
      const nextSearchParams = props.keepParams ? searchParams : '';
      const next = getLocalizedRouteForPath('signup') + nextSearchParams;
      navigate(next, { replace: true });
    }
  }, [
    authenticated,
    loading,
    location.pathname,
    location.search,
    originLocation,
    setOriginLocation,
    getLocalizedRouteForPath,
    navigate,
    props.skip,
    props.keepParams,
    props.searchParamsOverride,
  ]);
}
