/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

export interface LocaleRoutes {
  acceptGift: string;
  account: string;
  sendGift: string;
  exploreMenu: string;
  signUp: string;
  signIn: string;
  storeLocator: string;
  quiz: string;
  timsForGood: string;
  userPreferencesModal: string;
  timsRewardsFaq: string;
}

export const localeRoutes: Record<string, LocaleRoutes> = {
  ar: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ca: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  cs: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  de: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  en: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  es: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  eu: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  fr: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  gl: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  hak: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  id: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  it: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ja: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  jv: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ko: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ml: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ms: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  nan: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  nl: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  pa: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  pl: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  pt: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ro: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  ta: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  te: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  th: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
  zh: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    quiz: '/quiz',
    timsForGood: '/tims-for-good',
    userPreferencesModal: '/user-preferences-modal',
    timsRewardsFaq: '/tims-rewards-faq',
  },
};
