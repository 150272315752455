import styled, { keyframes } from 'styled-components';

const close = keyframes`
  0%, 10% {
    transform: translate(0%, 0%);
  }
  20%, 100% {
    transform: translate(0%, 8px);
  }
`;

const sinkDown = keyframes`
  0% {
    transform: translateY(0)
  } 50% {
    transform: translateY(3px);
  } 100% {
    transform: translateY(0);
  }
`;

export const LockContainer = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  z-index: ${Styles.zIndex.top};
`;

export const Lock = styled.div`
  background-color: ${Styles.color.white};
  width: 31px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  margin-left: -15px;
  border-radius: 2px;
  animation: ${sinkDown} 0.3s 0.85s forwards;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 4px;
    animation: ${close} 2s 0.45s linear forwards;
  }
  &:before {
    border-top: 4.5px solid ${Styles.color.white};
    border-left: 4.5px solid ${Styles.color.white};
    border-right: 4.5px solid ${Styles.color.white};
    border-radius: 12px 12px 0 0;
    width: 23px;
    height: 17px;
    top: -22px;
    -webkit-transform-origin: 1px 0;
    -ms-transform-origin: 1px 0;
    transform-origin: 1px 0;
  }
  &:after {
    width: 4px;
    background-color: ${Styles.color.white};
    height: 15px;
    top: -11px;
  }
`;
