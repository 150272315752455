import React, { FC } from 'react';

import { CategoryDropdown } from 'components/app-header/loyalty/rewards-management/reward-tier-selection-modal/components/category-dropdown';
import { RewardTierInput } from 'components/app-header/loyalty/rewards-management/reward-tier-selection-modal/components/reward-tier';
import { CategoryTitle } from 'pages/account/tims-rewards/reward-categories/components/styled';
import { RewardsStyleVariant } from 'pages/account/tims-rewards/reward-categories/types';

import { getSelectedTierName } from '../utils';

import { RewardTierOptionContainer } from './reward-category.styled';
import { IRewardCategoryProps } from './types';

export const RewardCategory: FC<IRewardCategoryProps> = ({
  category: { _id: categoryId, name: categoryName, options: tiers },
  onChange,
  optionsVisible,
  radioButtonName,
  selectedRewardTierId,
  selectedCategory,
  toggleOptionsVisible,
}) => {
  return (
    <CategoryDropdown
      focusOptionsWhenNotVisible
      id={categoryId}
      label={categoryName?.locale}
      onFocus={() => !optionsVisible && toggleOptionsVisible()}
      optionsVisible={optionsVisible}
      selectedOptionName={getSelectedTierName({ selectedCategory, tiers, selectedRewardTierId })}
      title={
        <CategoryTitle variant={RewardsStyleVariant.Primary}>{categoryName?.locale}</CategoryTitle>
      }
      toggleOptionsVisible={toggleOptionsVisible}
      bold
    >
      {tiers.map(
        ({ _id: tierId, name: tierName, points }: { _id: string; name: any; points: number }) => (
          <RewardTierOptionContainer key={tierId} onClick={() => onChange(tierId)}>
            <RewardTierInput
              allowTabNavigation={optionsVisible}
              name={tierName?.locale}
              radioButtonName={radioButtonName}
              selected={tierId === selectedRewardTierId}
              tierLabel={points}
              value={tierId}
            />
          </RewardTierOptionContainer>
        )
      )}
    </CategoryDropdown>
  );
};
