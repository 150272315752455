import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { primitive } from 'styles/constants/primitives';

export const ReceiptDetail = styled.div<{ $total?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${p => (p.$total ? primitive.$spacing3 : 0)};
  font: ${brandFont.copyOne};

  p {
    margin: 0;
  }
`;

export const FeeDeliveryItem = styled.p`
  margin: 0;
  text-transform: ${Styles.textTransform.headlines};
`;

export const RewardsDiscountDetail = styled(ReceiptDetail)`
  p {
    color: ${p => p.theme.token('text-default')};
    font-family: ${Styles.fontFamily.brand};
  }
`;
