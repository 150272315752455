import React, { VFC, useCallback, useMemo } from 'react';

import { NumberInput } from '@rbilabs/components-library/build/components/number-input';
import { sumBy } from 'lodash';
import { useIntl } from 'react-intl';

import { IItemOptionModifier } from '@rbi-ctg/menu';
import { ListItemGroup } from 'components/list-item-group';
import { useMParticleContext } from 'state/mParticle';

import { ModifierListItem } from './modifier-list-item';
import { ModifierOptionsContainer } from './modifier.styled';
import { IModifierTypeUIProps } from './types';
import { logProductModifierCustomization } from './utils';

export const ModifierMultipleChoices: VFC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const { formatMessage } = useIntl();
  const remainedSelectionsQuantity = useMemo(
    () => modifier.maxAmount - sumBy(Object.values(selections), sel => sel.quantity),
    [modifier.maxAmount, selections]
  );
  const mParticle = useMParticleContext();
  const handleOptionQuantityChange = useCallback(
    (option: IItemOptionModifier, newQuantity: number) => {
      onSelectionsChange({
        ...selections,
        [option._key]: {
          ...option,
          quantity: newQuantity,
        },
      });
      logProductModifierCustomization(
        option.name?.locale ?? '',
        modifier.name.locale,
        option._key,
        mParticle
      );
    },
    [mParticle, modifier.name.locale, onSelectionsChange, selections]
  );

  return (
    <ModifierOptionsContainer data-testid="modifier-multiple-choices">
      <ListItemGroup
        subHeader={formatMessage(
          { id: 'chooseItemAmount' },
          { amount: modifier.maxAmount, itemName: modifier.name?.locale }
        )}
        items={modifier.options.map(option => {
          const numberInputValue = selections[option._key]?.quantity || 0;
          const numberInputMax = numberInputValue + remainedSelectionsQuantity;
          const numberInput = (
            <NumberInput
              data-testid="modifier-number-input"
              min={0}
              max={numberInputMax}
              value={numberInputValue}
              onChange={(newQuantity: number) => handleOptionQuantityChange(option, newQuantity)}
              aria-label={`Select ${option.name?.locale} quantity`}
              decrement-aria-label={`Decrement ${option.name?.locale} quantity`}
              increment-aria-label={`Increment ${option.name?.locale} quantity`}
            />
          );

          return <ModifierListItem key={option._key} content={option} controls={numberInput} />;
        })}
      />
    </ModifierOptionsContainer>
  );
};
