import { getApiKey } from 'utils/environment';

export const GOOGLE_MAPS_API_BASE_URL = 'https://maps.googleapis.com/maps/api';

export const GET_CURRENT_POSITION_TIMEOUT = 5000;

export const getGoogleApiSrcPath = () => {
  const googleMapsApiKey = getApiKey('googleMapsWeb') || getApiKey('googleMaps');
  return `${GOOGLE_MAPS_API_BASE_URL}/js?key=${googleMapsApiKey}&libraries=places`;
};
