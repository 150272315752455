import { ISanityImage } from '@rbi-ctg/menu';
import { IImageFragment } from 'generated/sanity-graphql';

export interface IAction {
  actionLabel: string;
  actionHandler: () => void;
  dataTestId?: string;
  textOnly?: boolean;
  outline?: boolean;
}
export interface IActionsModalProps {
  actions: IAction[];
  bodyText?: string | React.ReactNode;
  'data-testid'?: string;
  headerText: string;
  headerTextAlign?: TextAlign;
  image?: ISanityImage | IImageFragment | null;
  imageAlt?: string;
  modalAppearanceEventMessage: string;
}

export enum TextAlign {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left',
}
