import React, { FC } from 'react';

import styled from 'styled-components';

import { Row } from './shared';
import { IFooterProps } from './types';
import { useFeatureFooter } from './use-feature-footer';

const FooterContainer = styled.footer<{ display: boolean }>`
  font-family: ${Styles.fontFamily.base};
  display: ${p => (p.display ? 'block' : 'none')};
  background-color: ${Styles.color.black};
  color: ${Styles.color.white};
  padding: 1rem 1rem;
  width: 100%;
  position: relative;
`;

const FooterContent: FC<IFooterProps> = props => {
  const { featureFooter, featureFooterLoading } = useFeatureFooter();

  const display = !!featureFooter?.rows && featureFooter?.rows?.length > 0;

  return featureFooterLoading ? null : (
    <FooterContainer {...props} role="contentinfo" display={display}>
      {featureFooter?.rows?.map((row, index) => row && <Row key={row._key ?? index} {...row} />)}
    </FooterContainer>
  );
};

export default FooterContent;
