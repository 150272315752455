import React, { VFC, useCallback, useMemo } from 'react';

import { Checkbox } from '@rbilabs/components-library/build/components/checkbox';

import { useMParticleContext } from 'state/mParticle';

import { ModifierListItem } from './modifier-list-item';
import { IModifierTypeUIProps } from './types';
import {
  getModifierOptionMultiplier,
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierCheckbox: VFC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const isChecked = getModifierOptionMultiplier(selectedOption) === 1;
  const mParticle = useMParticleContext();
  const handleCheckClick = useCallback(() => {
    const newSelectedOptionMultiplier = isChecked ? 0 : 1;
    const newSelectedOption = modifier.options.find(
      opt => getModifierOptionMultiplier(opt) === newSelectedOptionMultiplier
    );
    if (newSelectedOption) {
      onSelectionsChange(transformSelectedOptionToSelections(newSelectedOption));
      logProductModifierCustomization(
        newSelectedOption.name?.locale ?? '',
        modifier.name.locale,
        newSelectedOption._key,
        mParticle
      );
    }
  }, [isChecked, mParticle, modifier.name.locale, modifier.options, onSelectionsChange]);

  return (
    <ModifierListItem
      isListItemClickable
      content={modifier}
      selectedOption={selectedOption}
      onListItemClick={handleCheckClick}
      controls={
        <Checkbox
          data-testid="modifier-checkbox-control"
          checked={isChecked}
          label=""
          name={`checkbox-${modifier.name?.locale}`}
        />
      }
    />
  );
};
