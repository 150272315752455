import React, { useContext, useEffect } from 'react';

import { Language, Locale } from '@rbilabs/intl';
import { Locale as DateLocale } from 'date-fns';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { IUserCurrentLocaleCache } from 'state/intl/types';
import { isTest } from 'utils/environment';
import { ISOs } from 'utils/form/constants';
import noop from 'utils/noop';

import useIntlHook from './hook';
import { polyfillListFormatLocale } from './polyfill-list-format-locale';

export const defaultLocale = 'en-US';

export interface IIntlProviderContext {
  region: SupportedRegions;
  feCountryCode: ISOs;
  language: SupportedLanguages;
  locale: string;
  findSupportedRegion: Function;
  findSupportedLanguage: Function;
  setCurrentLocale: Function;
  clearRegionSpecificStorage: Function;
  messages: Record<string, string>;
  dateFormat: DateLocale;
  hasShownLocaleSelector: boolean;
  setHasShownLocaleSelector(): void;
  setShowLanguageSelectorModal(bool: boolean): void;
  showLanguageSelectorModal: boolean;
  inferLanguage(): Promise<Language>;
  setUserCurrentLocale(userCurrentLocale: IUserCurrentLocaleCache | undefined): void;
  userCurrentLocale: IUserCurrentLocaleCache | undefined;
}

export const IntlContext = React.createContext<IIntlProviderContext>({} as IIntlProviderContext);

export const useLocale = () => useContext(IntlContext);

// NOTE: cypress-v2 test suite requirement
// Skip react-intl error logging when running in cypress
const skipErrorLogging = Boolean(isTest || window.Cypress);

export function IntlProvider({ children }: { children: React.ReactNode }) {
  const value = useIntlHook();
  useEffect(() => {
    polyfillListFormatLocale(value.locale as Locale);
  }, [value.locale]);
  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProvider
        defaultLocale={defaultLocale}
        locale={value.locale}
        messages={value.messages}
        {...(skipErrorLogging && { onError: noop })}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
}

export default IntlContext.Consumer;
