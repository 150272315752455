import React, { useCallback, useContext, useMemo, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { IRestaurantGroup } from 'generated/sanity-graphql';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

interface INearbyStoreState extends Partial<IRestaurantNode> {
  address1?: string | null;
}

export interface INearbyStoresContext extends Partial<IRestaurantNode> {
  address1?: string | null;
  setStoresNearby: (nearbyStore: INearbyStoreState) => void;
  updateRestaurantGroup: (restaurantGroup?: IRestaurantGroup) => void;
  resetRestaurantGroup: () => void;
  restaurantGroup?: IRestaurantGroup;
  storeIdFromRestaurantGroup?: string;
}

export const NearbyStoresContext = React.createContext<INearbyStoresContext>({
  address1: {},
} as INearbyStoresContext);
export const useNearbyStoresContext = () => useContext(NearbyStoresContext);

export const NearbyStoresProvider = (props: IBaseProps) => {
  const [nearbyStore, setStoresNearby] = useState<INearbyStoreState>();
  const [restaurantGroup, setRestaurantGroup] = useState<IRestaurantGroup>(
    LocalStorage.getItem(StorageKeys.ACCOUNT_SETTINGS)?.restaurantGroup || ({} as IRestaurantGroup)
  );

  const storeIdFromRestaurantGroup = useMemo(
    () =>
      restaurantGroup?.restaurants?.length
        ? restaurantGroup?.restaurants?.[0]?.number || undefined
        : undefined,
    [restaurantGroup]
  );

  const updateRestaurantGroup = useCallback(rg => {
    const accountSettings = LocalStorage.getItem(StorageKeys.ACCOUNT_SETTINGS);
    LocalStorage.setItem(StorageKeys.ACCOUNT_SETTINGS, {
      ...accountSettings,
      restaurantGroup: rg,
    });
    setRestaurantGroup(rg);
  }, []);

  const resetRestaurantGroup = useCallback(() => {
    updateRestaurantGroup(undefined);
  }, [updateRestaurantGroup]);

  const context = useMemo(
    () => ({
      setStoresNearby,
      updateRestaurantGroup,
      storeIdFromRestaurantGroup,
      resetRestaurantGroup,
      restaurantGroup,
      ...nearbyStore,
    }),
    [
      updateRestaurantGroup,
      storeIdFromRestaurantGroup,
      resetRestaurantGroup,
      restaurantGroup,
      nearbyStore,
    ]
  );

  return (
    <NearbyStoresContext.Provider value={context}>{props.children}</NearbyStoresContext.Provider>
  );
};

export default NearbyStoresContext.Consumer;
