import React from 'react';

import { useIntl } from 'react-intl';

import { Action, Layout, Message } from './styled';

interface IDeliveryBannerProps {
  message: string;
  onClick: () => void;
  position?: 'absolute' | 'relative';
}

const DeliveryBanner = ({ message, onClick, position }: IDeliveryBannerProps) => {
  const { formatMessage } = useIntl();

  return (
    <Layout position={position} data-testid="delivery-banner">
      <Message>{message}</Message>
      <Action onClick={onClick}>{formatMessage({ id: 'track' })}</Action>
    </Layout>
  );
};

export default DeliveryBanner;
