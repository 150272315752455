import {
  gql,
  gql as gqlFunc /* do not change this `as` as it makes it so the babel-plugin-graphql-tag transform works properly */,
} from '@apollo/client';
import { getAllLanguages, getLanguages } from '@rbilabs/intl';

import { ISanityTextNode } from '@rbi-ctg/menu';
import { brand, getCountry } from 'utils/environment';

import ImageFragment from './fragments/image';
import ItemFragment from './fragments/item';
import NutritionExplorerWidgetFragment from './fragments/nutrition-explorer-widget';
import VideoFileFragment from './fragments/video-file';

export interface ILocaleBlockTextContent {
  localeRaw: [
    {
      children: [
        {
          [language: string]: {
            _key: string;
            _type: string;
            marks: object[];
            text: string;
          };
        }
      ];
    }
  ];
  markDefs: object[];
}

export interface IWidget {
  _key: string;
  _type: string;
  headerImage?: {
    _type: string;
  };
  headingContent?: {
    _type: string;
    [language: string]: string;
  };
  localeBlockTextContent?: ILocaleBlockTextContent;
}

export interface IStaticPageRoute {
  _id: string;
  _updatedAt?: string | null;
  requiredUserAcceptance?: boolean | null;
  widgets?: any | null;
  pageHtml?: {
    code: string;
  } | null;
  path: {
    current: string | null;
  };
  title: string | null;
  localePath: {
    [language: string]: {
      current: string | null;
    } | null;
  };
  localeTitle: ISanityTextNode | null;
  displayIndex: number | null;
  parent: {
    _id: string;
    _key: string;
  } | null;
  redirectUrl: ISanityTextNode | null;
}

export interface IGetStaticPageRoutesQuery {
  allStaticPage: IStaticPageRoute[];
}
const supportedLanguages = getAllLanguages(brand());
const regionSupportedLanguages = getLanguages(brand(), getCountry().toUpperCase());

// need to do this at runtime not at build time babel transform
export const GetStaticPageRoutesQuery = gqlFunc(`
  query GetStaticPageRoutes($staticPageWhere: StaticPageFilter) {
    allStaticPage (where: $staticPageWhere) {
      _id
      _updatedAt
      path {
        current
      }
      title
      localePath {
        ${(regionSupportedLanguages || supportedLanguages).map(lang => `${lang} { current }`)}
      }
      redirectUrl {
        locale: _LANG_
      }
      localeTitle {
        locale: _LANG_
      }
      displayIndex
      parent {
        _id
        _key
      }
      redirectUrl {
        locale: _LANG_
      }
    }
  }
`);

// @todo
export interface IStaticPage {
  _id: string;
  [key: string]: any;
}

export interface IGetStaticPageQuery {
  StaticPage: IStaticPage;
}

export interface IGetStaticPageVariables {
  id: string;
}

export const GetStaticPageQuery = gql`
  query GetStaticPage($id: ID!) {
    StaticPage(id: $id) {
      _id
      path {
        _key
        _type
        current
      }
      pageVariablesRaw
      widgets {
        ...NutritionExplorerWidgetFragment
        ... on YoutubeWidget {
          _key
          _type
          videoId
          videoAutoplay
        }
        ... on HeaderWidget {
          _key
          _type
          headingContent {
            locale: _LANG_
          }
          taglineContent {
            locale: _LANG_
          }
          headerImage {
            locale: _LANG_ {
              ...ImageFragment
            }
          }
        }
        ... on VideoWidget {
          _key
          _type
          video {
            locale: _LANG_ {
              ...VideoFileFragment
            }
          }
          caption {
            locale: _LANG_
          }
          attributionLink
        }
        ... on AccordionWidget {
          _key
          _type
          accordionTitle {
            locale: _LANG_
          }
          accordionContent {
            collapsedContent {
              locale: _LANG_
            }
            expandedContent {
              locale: _LANG_
            }
          }
        }
        ... on LocaleBlockTextWidget {
          _key
          _type
          blockTextInversion
          localeBlockTextContent {
            localeRaw: _LANG_Raw
          }
        }
        ... on PreviewWidget {
          _key
          _type
          previewContent {
            previewImage {
              locale: _LANG_ {
                ...ImageFragment
              }
            }
            titleText {
              locale: _LANG_
            }
            bodyText {
              locale: _LANG_
            }
            linkText {
              locale: _LANG_
            }
            linkURL
            localizedLinkURL {
              locale: _LANG_
            }
          }
        }
        ... on ImageWidget {
          _key
          _type
          caption {
            locale: _LANG_
          }
          attributionLink
          image {
            locale: _LANG_ {
              ...ImageFragment
            }
          }
        }
        ... on CallToActionWidget {
          _key
          _type
          callToActionContent {
            heading {
              locale: _LANG_
            }
            subheading {
              locale: _LANG_
            }
            body {
              locale: _LANG_
            }
            buttonText {
              locale: _LANG_
            }
            buttonLink
            link {
              locale: en
            }
            image {
              locale: _LANG_ {
                ...ImageFragment
              }
            }
          }
        }
        ... on QuoteWidget {
          _key
          _type
          quoteText {
            locale: _LANG_
          }
          attributionImage {
            ...ImageFragment
          }
          attributionName
          attributionTitle {
            locale: _LANG_
          }
        }
        ... on NutritionInfoWidget {
          _key
          _type
          nutritionalSection {
            name {
              locale: _LANG_
            }
            products {
              ...ItemFragment
              ... on NutritionalSection {
                name {
                  locale: _LANG_
                }
                products {
                  ...ItemFragment
                }
              }
            }
          }
        }
        ... on MultiWidget {
          _key
          _type
          widget
        }
        ... on DoubleImageWidget {
          _key
          _type
          image1 {
            caption {
              locale: _LANG_
            }
            attributionLink
            image {
              locale: _LANG_ {
                ...ImageFragment
              }
            }
          }
          image2 {
            caption {
              locale: _LANG_
            }
            attributionLink
            image {
              locale: _LANG_ {
                ...ImageFragment
              }
            }
          }
        }
        ... on AnchorWidget {
          _key
          _type
          anchorName {
            locale: _LANG_
          }
        }
        ... on RewardsCarouselWidget {
          _key
          _type
          rewardsCarouselTitle {
            locale: _LANG_
          }
          rewardsCarouselDescription {
            locale: _LANG_
          }
          rewardCarouselCategories {
            _id
            label {
              locale: _LANG_
            }
            rewards {
              _id
              name {
                locale: _LANG_
              }
              image {
                locale: _LANG_ {
                  ...ImageFragment
                }
              }
            }
          }
        }
        ... on AnchorLinksWidget {
          _key
          _type
          title {
            locale: _LANG_
          }
        }
        ... on DownloadFileWidget {
          _key
          _type
          url {
            locale: _LANG_
          }
        }
        ... on LoyaltyBannerWidget {
          _key
          _type
          enabled
          unauthenticatedDesktopBackgroundImage {
            locale: _LANG_ {
              ...ImageFragment
            }
          }
          unauthenticatedMobileBackgroundImage {
            locale: _LANG_ {
              ...ImageFragment
            }
          }
        }
        ... on LoyaltyTabSelectorWidget {
          _key
          _type
          tabs {
            _type
            link
            title
          }
        }
        ... on InfoCellsWidget {
          _key
          _type
          title {
            locale: _LANG_
          }
          icon {
            locale: _LANG_ {
              ...ImageFragment
            }
          }
          infoCells {
            loyaltyTiersSignupCellTitle {
              locale: _LANG_
            }
            loyaltyTiersSignupCellDescription {
              locale: _LANG_
            }
            loyaltyTiersSignupCellImage {
              locale: _LANG_ {
                ...ImageFragment
              }
            }
          }
        }
      }
      title

      pageCSS {
        code
      }
      pageHtml {
        code
      }

      vendorId
      vendorRedirectPath {
        locale: _LANG_
      }
    }
  }
  ${ImageFragment}
  ${ItemFragment}
  ${VideoFileFragment}
  ${NutritionExplorerWidgetFragment}
`;

export const GetStaticPageAcceptanceAgreementQuery = gql`
  query GetStaticPageRoutes($staticPageWhere: StaticPageFilter) {
    allStaticPage(where: $staticPageWhere) {
      _id
      _updatedAt
      requiredUserAcceptance
      title
      localeTitle {
        locale: _LANG_
      }
      widgets {
        ... on HeaderWidget {
          _key
          _type
          headingContent {
            locale: _LANG_
          }
        }
        ... on LocaleBlockTextWidget {
          _key
          _type
          localeBlockTextContent {
            localeRaw: _LANG_Raw
          }
        }
      }
      pageHtml {
        code
      }
    }
  }
`;
