import React, { ChangeEvent, useCallback, useEffect } from 'react';

import { LocaleSelect } from '@rbilabs/components-library/build/components/locale-select';
import { useIntl } from 'react-intl';

import { useLocale } from 'state/intl';
import { LOCALE_SELECTED_QUERY_PARAM } from 'state/intl/hook';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { useMParticleContext } from 'state/mParticle';

export const LanguageSelectorV2 = ({
  redirectToCurrentLocation = false,
  localeOptions,
}: {
  redirectToCurrentLocation?: boolean;
  localeOptions: { value: string; label: string }[];
}) => {
  const { formatMessage } = useIntl();
  const { updateUserAttributes } = useMParticleContext();

  const { clearRegionSpecificStorage, language, locale, region, setHasShownLocaleSelector } =
    useLocale();

  /** update the attributes for this user */
  useEffect(() => {
    updateUserAttributes({ language });
  }, [updateUserAttributes, language]);

  const setLanguage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newLang = event.target.value.substring(0, 2);
      const encodedCurrentUrl = encodeURIComponent(window.location.pathname);

      let strParams = `?lang=${newLang}&${LOCALE_SELECTED_QUERY_PARAM}=1`;
      if (redirectToCurrentLocation) {
        strParams += `&${NEXT_URL_QUERY_PARAM}=${encodedCurrentUrl}`;
      }

      const params = new URLSearchParams(strParams);

      const url = window.location.origin;

      // Add the region query param and clear localstorage
      // if we are not going to do a full url change
      if (url === window.location.origin) {
        params.append('region', region);

        clearRegionSpecificStorage();
      }

      setHasShownLocaleSelector();
      window.location.href = `${url}?${params}`;
    },
    [clearRegionSpecificStorage, redirectToCurrentLocation, region, setHasShownLocaleSelector]
  );

  /** If there is at most one valid locale, do not render the localeSelector */
  if (localeOptions.length <= 1) {
    return null;
  }

  return (
    <LocaleSelect
      data-testid={'locale-selector'}
      reversed={true}
      value={locale}
      options={localeOptions}
      onChange={setLanguage}
      aria-label={formatMessage({ id: 'languageAndRegionSelector' })}
    />
  );
};
