import React, { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useFeatureOnboardingQuery } from 'generated/sanity-graphql';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useGeolocation } from 'state/geolocation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { isIOS, isWeb } from 'utils/environment';
import { maybeLocaleImage } from 'utils/graphql';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { getLocaleTextBlock } from 'utils/sanity';

import {
  Channel,
  IFeatureOnboarding,
  IOnboardingContext,
  IOnboardingProps,
  TriggerRule,
} from './types';

export const OnboardingContext = createContext<IOnboardingContext>({} as IOnboardingContext);

export const useOnboardingContext = () => useContext<IOnboardingContext>(OnboardingContext);

export const OnboardingProvider: FC = ({ children }: IOnboardingProps) => {
  const [allFeatureOnboarding, setAllFeatureOnboarding] = useState<IFeatureOnboarding[]>([]);
  const { isPermissionKnown } = useGeolocation();
  const onboardingEnabled = useFlag(LaunchDarklyFlag.ENABLE_FEATURE_ONBOARDING);
  const hasUserOnboardedOnFirstAppLoad = LocalStorage.getItem(
    StorageKeys.HAS_DONE_FIRST_APP_LOAD_ONBOARDING
  );
  const { data, loading } = useFeatureOnboardingQuery({
    skip: !onboardingEnabled,
  });
  const [showOnboardingContent, setShowOnboardingContent] = useState(false);
  const { location } = useLocationContext();

  useEffect(() => {
    if (data?.allFeatureOnboardings?.length) {
      const featureOnboardings = data.allFeatureOnboardings.map(onboarding => {
        const featureOnboardingChannel = onboarding.channel ?? '';
        const featureOnboardingTriggerRule = onboarding.triggerRule ?? '';
        const screens = onboarding.screens;
        const featureOnboardingScreens = screens
          ?.map(screen => {
            switch (screen?.__typename) {
              case 'ImageWithHeaderAndText':
                const buttonUrl = screen.buttonAction?.actionUrl?.locale ?? '';
                if (buttonUrl.startsWith('/OS/') && isPermissionKnown) {
                  return null;
                }
                return {
                  image: maybeLocaleImage(screen.image),
                  imageDescription: screen?.imageDescription?.locale ?? '',
                  textContent: getLocaleTextBlock(screen, 'textContent'),
                  buttonText: screen.buttonAction?.actionText?.locale ?? '',
                  buttonUrl,
                };
              default:
                return null;
            }
          })
          .filter(screen => !!screen);
        return {
          featureOnboardingChannel,
          featureOnboardingTriggerRule,
          featureOnboardingScreens,
        };
      });
      setAllFeatureOnboarding(featureOnboardings);
    }
  }, [data, isPermissionKnown]);

  const firstAppLoadOnboarding = useMemo(() => {
    const channel = isWeb ? Channel.WEB : isIOS() ? Channel.IOS : Channel.ANDROID;
    return (
      allFeatureOnboarding.filter(
        onboarding =>
          onboarding.featureOnboardingChannel === channel &&
          onboarding.featureOnboardingTriggerRule === TriggerRule.ON_FIRST_APP_LOAD
      )[0] || []
    );
  }, [allFeatureOnboarding]);

  useEffect(() => {
    setShowOnboardingContent(
      !hasUserOnboardedOnFirstAppLoad &&
        onboardingEnabled &&
        !!firstAppLoadOnboarding?.featureOnboardingScreens?.length
    );
  }, [firstAppLoadOnboarding, hasUserOnboardedOnFirstAppLoad, onboardingEnabled]);

  useEffectOnUpdates(() => {
    setShowOnboardingContent(false);
    LocalStorage.setItem(StorageKeys.HAS_DONE_FIRST_APP_LOAD_ONBOARDING, true);
  }, [location]);

  return (
    <OnboardingContext.Provider
      value={{
        onboardingEnabled,
        allFeatureOnboarding,
        featureOnboardingLoading: loading,
        showOnboardingContent,
        firstAppLoadOnboarding: firstAppLoadOnboarding?.featureOnboardingScreens ?? [],
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
