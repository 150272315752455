import * as React from 'react';
import { FC } from 'react';

import { Lock, LockContainer } from './reward-lock.styled';

const RewardLock: FC = props => (
  <LockContainer {...props}>
    <Lock />
  </LockContainer>
);

export default RewardLock;
