import { createGlobalStyle } from 'styled-components';

export const WebGlobalStyle = createGlobalStyle`
/* These styles make the body full-height */
html, body { height: 100%; }
/* These styles disable body scrolling if you are using <ScrollView> */
body { overflow: hidden !important; }
/* These styles make the root element full-height */
#root { display:flex; height:100%; }
/* The SkeletonTheme is inserting a div that we cant style. So we need to get that to inherit the #root sizing */
#root > div { flex: 1; width: 100vw; }

[data-mediaquery="mobile"],[data-mediaquery="headerMobile"], [data-mediaquery="desktop"], [data-mediaquery="desktopLarge"]  {
  display: none;
}

${Styles.mobile`
[data-mediaquery="mobile"] {
  display: initial;
}
`}

${Styles.headerMobile`
[data-mediaquery="headerMobile"] {
  display: initial;
}
`}

${Styles.desktop`
[data-mediaquery="desktop"] {
  display: initial;
}
`}


${Styles.desktopLarge`
[data-mediaquery="desktopLarge"] {
  display: initial;
}
`}

`;
