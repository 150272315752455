import React, { VFC, useCallback, useMemo } from 'react';

import { RadioButton } from '@rbilabs/components-library/build/components/radio-button';

import { IItemOptionModifier } from '@rbi-ctg/menu';
import { ListItemGroup } from 'components/list-item-group';
import { useMParticleContext } from 'state/mParticle';

import { ModifierListItem } from './modifier-list-item';
import { ModifierOptionsContainer } from './modifier.styled';
import { IModifierTypeUIProps } from './types';
import {
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierSingleChoice: VFC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const mParticle = useMParticleContext();
  const handleListItemClick = useCallback(
    (option: IItemOptionModifier) => {
      onSelectionsChange(transformSelectedOptionToSelections(option));
      logProductModifierCustomization(
        option.name?.locale ?? '',
        modifier.name.locale,
        option._key,
        mParticle
      );
    },
    [mParticle, modifier.name.locale, onSelectionsChange]
  );
  return (
    <ModifierOptionsContainer data-testid="modifier-single-choice">
      <ListItemGroup
        items={modifier.options.map(option => (
          <ModifierListItem
            key={option._key}
            isListItemClickable
            onListItemClick={() => handleListItemClick(option)}
            content={option}
            controls={
              <RadioButton
                data-testid="modifier-single-choice-radio"
                label=""
                checked={option._key === selectedOption._key}
              />
            }
          />
        ))}
      />
    </ModifierOptionsContainer>
  );
};
