import { IRollUpTheRim } from '@rbi-ctg/menu';

import { QUERY_TYPE } from '../constants';
import { RollUpTheRimError } from '../exceptions';
import rollUpTheRimQuery from '../queries/roll-up-the-rim';

interface IRollUpTheRimResponse {
  allRollUpTheRims: IRollUpTheRim[];
  errors?: Error[];
}

export default () => {
  return {
    queryType: QUERY_TYPE.SanityGraphqlQuery,
    maxRetries: 3,
    query: rollUpTheRimQuery,
    then: (result: IRollUpTheRimResponse) => result.allRollUpTheRims[0],
    catch: (error: Error) => {
      throw new RollUpTheRimError('An error occurred loading roll up the rim content', error);
    },
  };
};
