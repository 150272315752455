import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import LoyaltyRewardsLogoSimple from 'components/icons/loyalty-rewards-logo-simple';

export const StyledPointsIcon = styled(LoyaltyPointsIcon)`
  margin: 0 0.2em;
  display: inline-block;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  path {
    fill: ${Styles.color.loyaltyHighlight};
  }
  height: 1.1rem;
  position: relative;
  width: 1.1rem;
`;

export const LinkWrapper = styled(Link)`
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    ${StyledPointsIcon} {
      transform: scale3d(1.25, 1.25, 1);
      transition: all 0.35s cubic-bezier(0, 1.27, 0.46, 1.65);
    }
  }
`;

export const StyledRewardsLogo = styled(LoyaltyRewardsLogoSimple)`
  ${Styles.desktop`
    width: 6.125rem;
    height: 1.5rem;
    margin: 0 0.4em;
    display: inline-block;
    position: relative;
  `}
`;

export const PointsNumber = styled.p`
  color: ${Styles.color.loyaltyHighlight};
  font-family: ${Styles.fontFamily.brand};
  font-size: 1rem;
  margin: 0;
`;
