import { IPosDataQuery } from 'generated/sanity-graphql';

export default function getRestaurantPosDataPlus(
  posData: IPosDataQuery | undefined,
  isDelivery: boolean
) {
  return isDelivery && posData?.RestaurantPosData?.deliveryPLUs
    ? posData?.RestaurantPosData.deliveryPLUs
    : posData?.RestaurantPosData?.plus;
}
