import styled from 'styled-components';

type ExternalContentProp = { hasExternalContent: boolean };

export const ButtonBack = styled.button`
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  padding-bottom: 1.5rem;
`;

export const HeaderContainer = styled.div<ExternalContentProp>`
  ${ButtonBack} {
    margin-bottom: ${({ hasExternalContent }) => (hasExternalContent ? '0' : '1.5rem')};
  }
`;

export const ModalContainerDetail = styled.div<ExternalContentProp>`
  position: absolute;
  inset: 0;
  flex: 1;
  background: #fff;
  overflow: hidden;
`;

export const Container = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
`;
