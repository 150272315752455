import { ICommitOrderOptions } from 'hooks/commit-order';
import { ICommitOrderMutationDeliveryInput } from 'remote/queries/order';
import { UserDetails } from 'state/auth/hooks/types';
import { ServiceMode } from 'state/order';

export const buildCommitDeliveryInput = (
  order: ICommitOrderOptions['order'],
  user: UserDetails | null
): ICommitOrderMutationDeliveryInput | null => {
  if (order.serviceMode !== ServiceMode.DELIVERY) {
    return null;
  }

  return {
    instructions: order.deliveryInstructions,
    tipCents: order.tipAmount,
    pushNotification: true,
    dropoff: {
      ...order.deliveryAddress,
      phoneNumber: order.orderPhoneNumber || user?.details?.phoneNumber || '',
    },
  };
};
