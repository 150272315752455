import { IFooterProps, LinkObject } from '@rbilabs/components-library/build/components/footer';

import { IFeatureFooterV2Query, useFeatureFooterV2Query } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureFooterV2Value {
  featureFooterV2Loading: boolean;
  featureFooterV2: ProcessedFeatureFooterData;
}

export type ProcessedFeatureFooterData =
  | (Omit<IFooterProps, 'id' | 'onClick' | 'localeSelector'> & {
      _id: string | null;
      __typename: string | null;
    })
  | null;

/** Need to process data to match the types expected from the Component Library component */
function processFeatureFooterData(
  data: IFeatureFooterV2Query['FeatureFooterV2'] | null
): ProcessedFeatureFooterData {
  if (!data || !data._id || !data?.mainLinks?.length || !data?.miscellaneousLinks?.length) {
    return null;
  }
  const mainLinks: LinkObject[] = data?.mainLinks?.map((link, index) => {
    return {
      text: link?.text?.locale || '',
      locale: link?.link?.locale || '',
      _key: link?._key || `${link?.text}-${index}`,
    };
  });
  const miscellaneousLinks: LinkObject[] = data?.miscellaneousLinks?.map((link, index) => {
    return {
      text: link?.text?.locale || '',
      locale: link?.link?.locale || '',
      _key: link?._key || `${link?.text}-${index}`,
    };
  });
  const copyrightText = data?.copyrightText?.locale || '';
  const facebookUrl = data?.socialMediaData?.facebookUrl?.locale || '';
  const instagramUrl = data?.socialMediaData?.instagramUrl?.locale || '';
  const tiktokUrl = data?.socialMediaData?.tiktokUrl?.locale || '';
  const twitterUrl = data?.socialMediaData?.twitterUrl?.locale || '';
  const youtubeUrl = data?.socialMediaData?.youtubeUrl?.locale || '';
  const linkedinUrl = data?.socialMediaData?.linkedinUrl?.locale || '';
  const snapchatUrl = data?.socialMediaData?.snapchatUrl?.locale || '';

  const socialMediaData = {
    ...(facebookUrl && { facebookUrl: { locale: facebookUrl } }),
    ...(instagramUrl && { instagramUrl: { locale: instagramUrl } }),
    ...(tiktokUrl && { tiktokUrl: { locale: tiktokUrl } }),
    ...(twitterUrl && { twitterUrl: { locale: twitterUrl } }),
    ...(youtubeUrl && { youtubeUrl: { locale: youtubeUrl } }),
    ...(linkedinUrl && { linkedinUrl: { locale: linkedinUrl } }),
    ...(snapchatUrl && { snapchatUrl: { locale: snapchatUrl } }),
  };

  return {
    _id: data._id,
    __typename: data.__typename,
    mainLinks,
    miscellaneousLinks,
    copyrightText,
    socialMediaData,
  };
}

export const useFeatureFooterV2 = (): IUseFeatureFooterV2Value => {
  const { featureFooterV2Id = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureFooterV2Query({
    variables: { featureFooterV2Id },
    skip: !featureFooterV2Id,
  });

  const featureFooterV2 = processFeatureFooterData(data?.FeatureFooterV2 ?? null);
  const featureFooterV2Loading = featureIdsLoading || loading;

  return {
    featureFooterV2Loading,
    featureFooterV2,
  };
};
