import React, { Suspense } from 'react';

import { OptionalColorProps } from 'components/language-selector';
import { LazyComponent, lazyWithNullFallback } from 'components/layout/util';

import { LanguageSelectorContainer } from './styled';

const LanguageSelectorImpl: LazyComponent<OptionalColorProps> = lazyWithNullFallback(
  () => import('components/language-selector')
);

export const SubheaderLanguageSelector: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <LanguageSelectorContainer>
        <LanguageSelectorImpl color={Styles.color.grey.two} />
      </LanguageSelectorContainer>
    </Suspense>
  );
};
