import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';

export const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
`;

export const TierLabel = styled.p`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  font: ${brandFont.headerTwo};
  margin: 0;
  line-height: 1px;
  color: ${({ theme }) => theme.token('text-button-secondary')};
  z-index: ${Styles.zIndex.below};
  height: 2rem;
  font-size: 1rem;
`;

export const RewardsWrapper = styled.div`
  position: relative;
  height: 2rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  max-width: 17.5rem;
  ${Styles.mobileTiny`
    max-width: 10rem;
  `}

  margin: 0px;
  flex-wrap: wrap;
  line-height: 1.3rem;
  font: ${brandFont.copyOne};
  color: 'initial';
`;

export const Subtitle = styled(Title)`
  font-size: 0.75rem;
  padding-top: 0;
`;
