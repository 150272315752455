import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IMainMenuView } from '@rbi-ctg/menu';
import { IPricingAndAvailabilityFragment } from 'generated/graphql-gateway';
import { IGetMenuSectionsQuery } from 'generated/sanity-graphql';
import useLocation from 'hooks/location/use-location';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { getMenuDataById } from 'remote/api/menu-data';
import { useDayPartContext } from 'state/day-part';
import { useErrorContext } from 'state/errors';
import { useFavoritesContext } from 'state/favorites';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { isAvailableForActiveDayParts } from 'utils/availability';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { useLocale } from '../intl';
import { useLogger } from '../logger';
import { useNetworkContext } from '../network';
import { useServiceModeContext } from '../service-mode';

import { IUseDayPart } from './types';
import { useDayPartSelector } from './use-day-part-selector';

export type MainMenu = IMainMenuView;
export interface IMainMenuContext extends IUseDayPart {
  data: IGetMenuSectionsQuery | undefined;
  loading: boolean;
  getMainMenu(menuId?: string, region?: string, storeId?: string | null): IState;
  checkIfUserIsOnMainMenuPath: () => boolean;
  getPricingAndAvailability: (id: string) => IPricingAndAvailabilityFragment | null;
  isStaticMenu: boolean;
  setIsOffer: (isOffer: boolean) => void;
  isOffer: boolean;
}

interface IState {
  data: MainMenu | null;
  loading: boolean;
}

export const MainMenuContext = React.createContext<IMainMenuContext>({
  data: undefined,
  loading: false,
  getMainMenu: () => ({ loading: false, data: null }),
  getDayPart: () => ({ currentDayPart: null, dayParts: [] }),
  setDayPart: () => null,
  breakfastInterval: undefined,
  mainMenuInterval: undefined,
  checkIfUserIsOnMainMenuPath: () => false,
  getPricingAndAvailability: () => null,
  isStaticMenu: false,
  setIsOffer: isOffer => isOffer,
  isOffer: false,
});

export const useMainMenuContext = () => useContext(MainMenuContext);

export const MainMenuProvider: FC<IBaseProps> = ({ children }) => {
  const isMenuLoadingRef = useRef(false);
  const { language } = useLocale();
  const logger = useLogger();
  const { query, sanityEndpoints, defaultErrorHandler } = useNetworkContext();
  const { showStaticMenu } = useMenuContext();
  const { setDayPart, getDayPart, breakfastInterval, mainMenuInterval } = useDayPartSelector();
  // TODO
  // there are other parts of this provider querying for the sanity menu with fetch
  // that data should eventually be supplied by useMainMenuQuery
  const { featureMenu } = useFeatureMenu();
  const { cateringMenu, defaultMenu } = featureMenu || {};
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const { noStoreSelected } = useStoreContext();
  const { activeDayParts } = useDayPartContext();
  const { tempFavorite, cancelEditing } = useFavoritesContext();
  const {
    location: { pathname },
  } = useLocation();
  const { setSanityDataRef } = useErrorContext();

  const menuId: string = (isCatering(serviceMode) ? cateringMenu : defaultMenu)?._id || '';

  const [{ loading, data }, setState] = useState<IState>({
    data: null,
    loading: false,
  });
  const [isOffer, setIsOffer] = useState(false);

  useEffect(() => {
    if (pathname === routes.menu && data) {
      setSanityDataRef(data);
    }
  }, [pathname, data, setSanityDataRef]);

  // When we navigate away from the menu we are no longer editing.
  // @TODO: Add a modal or something to verify we want to cancel editing favorite
  useEffect(() => {
    if (!pathname.includes(routes.menu) && tempFavorite) {
      cancelEditing();
    }
  }, [cancelEditing, pathname, tempFavorite]);

  const [unfilteredData, setUnfilteredData] = useState<MainMenu | null>(null);

  const filterMenuOptions = useCallback(
    (menuData: MainMenu | null): MainMenu | null => {
      if (!menuData || !menuData.options) {
        return menuData;
      }
      const menuOptions = menuData.options.filter(option => {
        // if enableStaticMenu, only check if showInStaticMenu is true;
        if (showStaticMenu) {
          return option.showInStaticMenu;
        }
        const isActiveInDaypart =
          !activeDayParts.length ||
          isAvailableForActiveDayParts({ activeDayParts, menuData: option });

        return isActiveInDaypart;
      });

      return {
        ...menuData,
        options: menuOptions,
      };
    },
    [activeDayParts, showStaticMenu]
  );

  useEffect(() => {
    setState({
      data: filterMenuOptions(unfilteredData),
      loading: false,
    });
  }, [filterMenuOptions, unfilteredData]);

  useEffectOnUpdates(() => {
    setState({ data: null, loading: false });
  }, [menuId]);

  const getMainMenu = useCallback(() => {
    if (isMenuLoadingRef.current || data) {
      return { loading, data };
    }

    isMenuLoadingRef.current = true;
    setState(state => ({
      ...state,
      loading: true,
    }));

    query<MainMenu>(getMenuDataById(menuId))
      .then((menuData: MainMenu) => {
        logger.groupCollapsed('queryd\n\n', 'menu', `${menuId}`);
        logger.info(menuData);
        logger.groupEnd();
        isMenuLoadingRef.current = false;
        setUnfilteredData(menuData);
      })
      .catch(defaultErrorHandler);

    return { loading, data };
  }, [data, loading, menuId, sanityEndpoints]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffectOnUpdates(() => {
    query<MainMenu>(getMenuDataById(menuId))
      .then((menuData: MainMenu) => {
        logger.groupCollapsed('queryd\n\n', 'menu', `${menuId}`);
        logger.info(menuData);
        logger.groupEnd();
        setUnfilteredData(menuData);
      })
      .catch(defaultErrorHandler);
  }, [language, sanityEndpoints, menuId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If the catering mode is selected and there is no selected restaurant,
   * when the user reach the main menu, we want to reset the serviceMode and show the static menu
   */
  useEffect(() => {
    const isCateringWithoutStoreSelected = isCatering(serviceMode) && noStoreSelected;
    const reachedMainMenu = pathname === routes.menu;
    if (isCateringWithoutStoreSelected && reachedMainMenu) {
      setServiceMode(null);
    }
  }, [noStoreSelected, pathname, serviceMode, setServiceMode]);

  const checkIfUserIsOnMainMenuPath = useCallback(() => pathname === routes.menu, [pathname]);

  return (
    <MainMenuContext.Provider
      value={{
        getMainMenu,
        setDayPart,
        getDayPart,
        breakfastInterval,
        mainMenuInterval,
        checkIfUserIsOnMainMenuPath,
        data: undefined,
        loading,
        getPricingAndAvailability: () => null,
        isStaticMenu: false,
        isOffer,
        setIsOffer,
      }}
    >
      {children}
    </MainMenuContext.Provider>
  );
};

export default MainMenuContext.Consumer;
