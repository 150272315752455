import React from 'react';

import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';

import theme from '../theme';

import { Scroller } from './scroller';

interface IModalContentProps extends IBaseProps, IInner {
  bgColor?: string;
  disableFooter?: boolean;
}

const ModalWrapper = styled.div<IModalContentProps>`
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${p => p.bgColor};
`;

interface IInner extends IBaseProps {
  expand?: boolean;
  widthFull?: boolean;
}

const Inner = styled.div<IInner>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 710px;
  height: ${p => (p.expand ? '95%' : 'auto')};
  padding: env(safe-area-inset-top) env(safe-area-inset-left) env(safe-area-inset-bottom)
    env(safe-area-inset-right);

  /**
   * Aligns with 'x' in closing button
   * 100% - (2 * button-left) - (2 * button-padding-left)
   */
  width: ${p => (p.widthFull ? '95%' : 'calc(100% - 4.75rem)')};
`;

const ModalContent = ({
  children,
  className,
  bgColor = theme.modalWrapperBackground,
  expand = false,
  widthFull = false,
}: IModalContentProps) => (
  <ModalWrapper aria-modal="true" role="dialog" className={className} bgColor={bgColor}>
    <Scroller className="modal-scroller">
      <Inner className="modal-inner" expand={expand} widthFull={widthFull}>
        {children}
      </Inner>
    </Scroller>
  </ModalWrapper>
);

export default ModalContent;
