import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import ActionButton from 'components/action-button';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import { useOrderDetails } from 'hooks/use-order-details';
import { useOrderContext } from 'state/order';
import { useReorder } from 'state/order/hooks/use-reorder';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 18.75rem;
  padding: 2rem 0 3rem 0;

  ${Styles.desktop`
    max-width: 31.25rem;
  `}
`;

const Heading = styled(ModalHeading)`
  margin: 3rem 0 1rem 0;
`;

const Text = styled.p`
  margin: 0 0 3rem 0;
`;

interface IOrderCancelledModalProps {
  onDismiss: () => void;
  modalAppearanceEventMessage?: string;
}

const OrderCancelledModal: React.FC<IOrderCancelledModalProps> = ({
  onDismiss,
  modalAppearanceEventMessage = 'Order Cancelled',
}) => {
  const {
    serverOrder,
    reorder: { reordering },
  } = useOrderContext();
  const { order } = useOrderDetails(serverOrder.rbiOrderId);
  const { handleReorderClick } = useReorder(order);
  const { formatMessage } = useIntl();

  const modalHeading = formatMessage({ id: 'cancelledOrderTitle' });
  const modalBody = formatMessage({ id: 'cancelledOrderAction' });

  return (
    <Modal
      onDismiss={onDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage,
        modalMessage: modalBody,
      }}
    >
      <ModalContent>
        <Container>
          <Icon icon="sadFace" color="primary" width="9.5rem" height="9.5rem" aria-hidden />
          <Heading id="modal-heading">{modalHeading}</Heading>
          <Text>{modalBody}</Text>
          <ActionButton onClick={handleReorderClick} isLoading={reordering}>
            {formatMessage({ id: 'reorder' })}
          </ActionButton>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default OrderCancelledModal;
