import delv from 'dlv';
import { flatten } from 'lodash';

export {
  computeCurrentCalories,
  getFormattedCalorieDefault,
  getFormattedCalorieRange,
  getItemCalories,
} from './calories';
export { modifiersForItem } from './modifiers';
export * from './quick-configs';

// currently some sanity types have the _type field aliased to type, so check both
export const getType = menuData => {
  let type = delv(menuData, '_type', delv(menuData, 'type'));

  return type;
};

export function transformPlus(plus) {
  return Object.entries(plus).reduce((mappedPlus, [key, value]) => {
    mappedPlus[key.replace('plu_', '')] = value;
    return mappedPlus;
  }, {});
}

//resolves picker selections to a single option
export function computeSelectedOption(pickerSelections, item) {
  if (item._type === 'combo' || item._type === 'item') {
    return item;
  }
  const { options } = item;
  const pickerValues = Object.values(pickerSelections);
  if (!pickerSelections || pickerValues.length < 1) {
    return options && options[0] ? options[0].option : false;
  }

  const resolution = options.find(itemOpt => {
    return itemOpt.pickerItemMappings.every(mapping => {
      return pickerSelections[mapping.pickerAspect._id] === mapping.pickerAspectValueIdentifier;
    });
  });

  return resolution?.option || false;
}

export function defaultPickerAspect(item) {
  if (!item.options || !item.options.length || !item.pickerAspects) {
    return {};
  }

  let resolvedPickerDefaults = {};

  item.pickerAspects.forEach(aspect => {
    let resolvedDefault;
    // flatten the current values for comparison
    const currentResolvedValues = Object.values(resolvedPickerDefaults);
    // if the item has pickerDefaults use them
    if (item.pickerDefaults) {
      // find the default pickerAspectOption
      const defaultValue = item.pickerDefaults.find(
        pickerDefault => pickerDefault.pickerAspect._id === aspect._id
      );
      // if a defaultValue was found and can resolve to a pickerOption
      if (
        defaultValue &&
        pickerAspectOptHasResolution(
          defaultValue.pickerAspectValueIdentifier,
          currentResolvedValues,
          item.options
        )
      ) {
        resolvedDefault = defaultValue.pickerAspectValueIdentifier;
      } else {
        resolvedDefault = (
          aspect.pickerAspectOptions.find(option =>
            pickerAspectOptHasResolution(option.identifier, currentResolvedValues, item.options)
          ) || {}
        ).identifier;
      }

      resolvedPickerDefaults[aspect._id] = resolvedDefault;
      // else use the first option that lead to a resolved pickerOption
    } else {
      resolvedDefault = (
        aspect.pickerAspectOptions.find(option =>
          pickerAspectOptHasResolution(option.identifier, currentResolvedValues, item.options)
        ) || {}
      ).identifier;
    }

    resolvedPickerDefaults[aspect._id] = resolvedDefault;
  });

  return resolvedPickerDefaults;
}

export function pickerAspectOptHasResolution(
  selectionCandidate,
  previousSelections,
  possibleResolutions
) {
  return possibleResolutions.some(option => {
    const optMappings = option.pickerItemMappings.map(opt => opt.pickerAspectValueIdentifier);
    return [selectionCandidate, ...previousSelections].every(
      value => optMappings.indexOf(value) > -1
    );
  });
}

/*returns comboSlot selection with metadata describing how many of the selected items are
to be included in the combo and what is to be charged as a separate item*/
export function assignInclusionComboSlotSelections(selections, maxAmount) {
  //defensive promgramming incase any null selections slip in
  if (!selections) {
    return [];
  }

  let counter = 0;

  return selections.map(sel => {
    counter += sel.quantity;
    if (counter > maxAmount) {
      if (counter - sel.quantity < maxAmount) {
        return { ...sel, notIncluded: counter - maxAmount };
      } else {
        return { ...sel, notIncluded: sel.quantity };
      }
    } else {
      return { ...sel, notIncluded: 0 };
    }
  });
}

export function collectComboSlotSelections(comboSlotSelections) {
  return flatten(Object.values(comboSlotSelections).map(slot => slot.selections));
}

function partitionComboSlots(comboSlots, comboSlotSelections) {
  return comboSlots.map(comboSlot => {
    return {
      id: comboSlot._id,
      data: comboSlotSelections[comboSlot._id].data,
      selections:
        assignInclusionComboSlotSelections(
          comboSlotSelections[comboSlot._id].selections,
          comboSlot.maxAmount
        ) || [],
    };
  });
}

// go through the comboSlots that have been selected and pull put extras,
export function partitionModalState(selectedOption, comboSlotSelections, modifierSelections = []) {
  const includedComboSlotSelections = {};
  const prunedItems = [];

  const partitionedComboSlots = Object.keys(comboSlotSelections).length
    ? partitionComboSlots(selectedOption.options, comboSlotSelections)
    : [];

  partitionedComboSlots.forEach(comboSlot => {
    // modifiers can only be added to an option of max quantity 0 or 1, so all modifier selections for a combo slot must apply to a single selection.
    const modifierSelectionsForComboSlot = modifierSelections.filter(
      ({ comboSlotId }) => comboSlotId === comboSlot.id
    );

    comboSlot.selections.forEach(selection => {
      // all selections included
      const current = includedComboSlotSelections[comboSlot.id] || {
        data: comboSlot.data,
        selections: [],
      };

      if (selection.notIncluded === 0) {
        const updatedSelections = current.selections.concat(selection);
        delete selection.notIncluded;
        includedComboSlotSelections[comboSlot.id] = {
          ...current,
          selections: updatedSelections,
        };
        // if some selections included
      } else if (selection.notIncluded > 0 && selection.notIncluded < selection.quantity) {
        // add non included items to pruned array
        prunedItems.push({
          item: {
            ...selection.option.option,
            quantity: selection.notIncluded,
          },
          modifierSelections: modifierSelectionsForComboSlot,
        });
        // update comboSlot to proper included quantity
        const includedQuantity = selection.quantity - selection.notIncluded;

        const updatedSelections = current.selections.concat({
          ...selection,
          quantity: includedQuantity,
        });

        includedComboSlotSelections[comboSlot.id] = {
          ...current,
          selections: updatedSelections,
        };

        // if no selections are included
      } else {
        prunedItems.push({
          item: {
            ...selection.option.option,
            quantity: selection.notIncluded,
          },
          modifierSelections: modifierSelectionsForComboSlot,
        });
      }
    });
  });
  return { includedComboSlotSelections, prunedItems };
}

export const filterExcludedItemOptions = excludes => options =>
  options.filter(opt => !excludes.find(({ value }) => opt._key === value));

export { computeCurrentPrice } from './price';
export * from './get-default-picker-selection';
export * from './is-menu-type';
export * from './picker-calorie-ranges';
export * from './cart-entries-to-menu-object-id';
export * from './option-to-url';
