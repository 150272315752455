import styled from 'styled-components';

interface INavigationContainer {
  multipleItems?: boolean;
}

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: 2fr 20fr 3fr;
  height: 100%;
  max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
`;

export const NavigationContainer = styled.div<INavigationContainer>`
  display: grid;
  grid-template-areas: 'previous stepper next';
  grid-template-columns: 1fr 3.5fr 1fr;
  align-items: center;
  justify-items: center;
  padding: 0 1.5rem;
`;

export const RightButtonContainer = styled.div`
  display: grid;
  grid-area: next;
`;

export const LeftButtonContainer = styled.div`
  display: grid;
  grid-area: previous;
`;

export const SkipContainer = styled.div<INavigationContainer>`
  display: flex;
  align-items: center;
  justify-items: end;
  padding: 0 1.5rem;
  justify-content: flex-end;
`;

export const Stepper = styled.div`
  position: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-area: stepper;
`;
