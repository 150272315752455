import isString from 'lodash/isString';

import { IOffer } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { IOffersFragment } from 'generated/graphql-gateway';
import { isOfferDiscount } from 'utils/cart/helper';
import { isCombo, isPicker } from 'utils/menu';

import { LoyaltyAppliedOffer, LoyaltyOffer, isLoyaltyOffer } from './types';

export const isDiscountLoyaltyOffer = (offer: LoyaltyOffer) =>
  offer?.incentives?.every(benefit => benefit?._type === MenuObjectTypes.OFFER_DISCOUNT);

export const isDiscountCBAOffer = (offer: IOffer) =>
  offer?.option?._type === MenuObjectTypes.OFFER_DISCOUNT;

export const isDiscountOffer = (offer: IOffer | LoyaltyOffer) =>
  isLoyaltyOffer(offer) ? isDiscountLoyaltyOffer(offer) : isDiscountCBAOffer(offer);

export const isSupportOffer = (offer: IOffersFragment) =>
  Boolean(
    offer?.metadata?.some(
      entry =>
        entry?.key?.toLocaleLowerCase() === 'source' &&
        isString(entry?.value) &&
        entry.value.toUpperCase() === 'SUPPORT'
    )
  );

export const isAppliedOfferSwap = (offer: LoyaltyAppliedOffer): boolean => !!offer.swap;

export const getCanStackOfferCheck =
  <T>(getOffer: (element: T) => LoyaltyOffer | LoyaltyAppliedOffer | null) =>
  (offer: LoyaltyOffer, list: T[], standardOffersLimit?: number): boolean => {
    return (
      list.length === 0 ||
      list.every((element: T) => {
        const offerItem = getOffer(element);

        // If offer explicitly set to not be stackable return
        if (offer.isStackable === false) {
          return false;
        }

        // the stackable offer check is for offers only
        return offerItem
          ? !!(offerItem as LoyaltyAppliedOffer)?.swap || standardOffersLimit! > list.length
          : true;
      })
    );
  };

export const getCmsOffersMapByLoyaltyId = (
  offers: LoyaltyOffer[] | null
): { [key: string]: LoyaltyOffer } => {
  return (offers || []).reduce((acc, offer) => {
    if (offer?.loyaltyEngineId) {
      acc[offer.loyaltyEngineId] = offer;
    }

    return acc;
  }, {});
};

export const getCmsOffersMapByCmsId = (
  offers: LoyaltyOffer[] | null
): { [key: string]: LoyaltyOffer } => {
  return (offers || []).reduce((acc, offer) => {
    if (offer?._id) {
      acc[offer._id] = offer;
    }

    return acc;
  }, {});
};

export const incentiveIsOfferBenefit = (offer: LoyaltyOffer): boolean => {
  return !!offer.incentives?.every(incentive => {
    return (
      isOfferDiscount(incentive) ||
      ((isPicker(incentive) || isCombo(incentive)) && incentive.isOfferBenefit)
    );
  });
};

export const filterRedeemableUpsellOffers = (offer: LoyaltyOffer, userOffers: LoyaltyOffer[]) => {
  const { upsellOptions } = offer;
  const userOfferIds = userOffers.map(offer => offer.loyaltyEngineId);

  if (!upsellOptions) {
    return [];
  }

  return upsellOptions.filter(option => {
    return userOfferIds.includes(option?.loyaltyEngineId ?? '');
  }) as LoyaltyOffer[];
};
