import { showServiceModeHeader } from 'components/layout/show-service-mode-header';
import useRoutes from 'hooks/use-routes';
import { useLocationContext } from 'state/location';
import { ServiceMode } from 'state/service-mode';
import { routesRelevantToServiceModeHeaderDisplay } from 'utils/routing';

// determine whether service mode header is shown based on current route and service mode
export default function useLocalizedRoutesForServiceModeHeaderDisplay(
  serviceMode: ServiceMode | null
) {
  const { getLocalizedRouteForPath } = useRoutes();
  const { location } = useLocationContext();
  const serviceModeHeaderAncillaryRoutes = {
    default: getLocalizedRouteForPath(routesRelevantToServiceModeHeaderDisplay.default),
    plk: getLocalizedRouteForPath(routesRelevantToServiceModeHeaderDisplay.plk),
    bk: getLocalizedRouteForPath(routesRelevantToServiceModeHeaderDisplay.bk),
    th: getLocalizedRouteForPath(routesRelevantToServiceModeHeaderDisplay.th),
    fhs: getLocalizedRouteForPath(routesRelevantToServiceModeHeaderDisplay.fhs),
  };
  return showServiceModeHeader(location.pathname, serviceMode, serviceModeHeaderAncillaryRoutes);
}
