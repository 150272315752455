import { useIntl } from 'react-intl';

import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

const useHideIconOnRoute = () => {
  const { formatMessage } = useIntl();
  const {
    location: { pathname },
  } = useLocationContext();

  const notAllowedRoutes = [
    routes.account,
    routes.communication,
    routes.support,
    formatMessage({ id: 'routes.rewardsHistory' }),
  ];

  const hideOnRoute = notAllowedRoutes.find(route => pathname.match(route));

  return !!hideOnRoute;
};

export default useHideIconOnRoute;
