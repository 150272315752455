import React, { useEffect, useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useGatewayFlags } from 'state/launchdarkly';
import { useLogger } from 'state/logger';
import { useMParticleContext } from 'state/mParticle';

import { getClient } from './client';
import { withI18nContext } from './links/with-i18n-context';
import { withLogger } from './links/with-logger';
import { withMParticle } from './links/with-mparticle';
import { withSessionId } from './links/with-session-id';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  const { language, region } = useLocale();
  const gatewayFlags = useGatewayFlags();
  const alternativeGqlUrl = useFlag(LaunchDarklyFlag.ENABLE_GRAPHQL_URL_OVERRIDE);
  const client = useMemo(() => getClient(language, region, alternativeGqlUrl), [gatewayFlags]); // eslint-disable-line react-hooks/exhaustive-deps
  const logger = useLogger();
  const mParticle = useMParticleContext();

  // update stateful links
  useEffect(() => {
    withI18nContext.setLocale(language, region);
  }, [client, language, region]);

  useEffect(() => {
    withSessionId.setSessionId(mParticle.sessionId);
  }, [mParticle.sessionId]);

  useEffect(() => {
    withMParticle.setMParticle(mParticle);
  }, [mParticle]);

  useEffect(() => {
    withLogger.setLogger(logger);
  }, [logger]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { useLazySanityQuery, useSanityQuery } from './hooks/use-sanity-query';
