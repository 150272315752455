import styled from 'styled-components';

const NavContainer = styled.nav<{ reversed: boolean }>`
  background: ${p =>
    p.reversed
      ? p.theme.token('background-hero-reversed')
      : p.theme.token('background-hero-light')};
  color: #1f1f1f;
  height: ${Styles.layout.navHeight.desktop};
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
`;

export default NavContainer;
