/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { IsoCountryCode2 } from '@rbilabs/intl';

import { IPoints } from '@rbi-ctg/frontend';

const mapDefaults: Record<IsoCountryCode2 | 'default', IPoints> = {
  /**
   * (CA)
   */
  [IsoCountryCode2.CA]: {
    lat: 49.76773,
    lng: -96.8097,
    zoom: 4,
  },
  /**
   * (DE)
   */
  [IsoCountryCode2.DE]: {
    lat: 51.1633733,
    lng: 10.44768691,
    zoom: 4,
  },
  /**
   * (NZ)
   */
  [IsoCountryCode2.NZ]: {
    lat: -36.848461,
    lng: 174.763336,
    zoom: 4,
  },
  /**
   * (ZA)
   */
  [IsoCountryCode2.ZA]: {
    lat: 28.99346,
    lng: 25.07909,
    zoom: 4,
  },
  /**
   * (CH)
   */
  [IsoCountryCode2.CH]: {
    lat: 46.801111,
    lng: 8.226667,
    zoom: 4,
  },
  /**
   * (GB)
   */
  [IsoCountryCode2.GB]: {
    lat: 54.00366,
    lng: -2.547855,
    zoom: 4,
  },
  /**
   * (US)
   */
  [IsoCountryCode2.US]: {
    lat: 39.8283459,
    lng: -98.5794797,
    zoom: 4,
  },
  /**
   * (NL)
   */
  [IsoCountryCode2.NL]: {
    lat: 52.35,
    lng: 4.916667,
    zoom: 4,
  },
  /**
   * (AQ)
   */
  [IsoCountryCode2.AQ]: {
    lat: -75.250973,
    lng: -0.071389,
    zoom: 4,
  },
  /**
   * (KR)
   */
  [IsoCountryCode2.KR]: {
    lat: 37.5326,
    lng: 127.024612,
    zoom: 4,
  },
  /**
   * (ES)
   */
  [IsoCountryCode2.ES]: {
    lat: 40.4,
    lng: -3.683333,
    zoom: 4,
  },
  /**
   * (FR)
   */
  [IsoCountryCode2.FR]: {
    lat: 48.8566,
    lng: 2.3522,
    zoom: 4,
  },
  /**
   * (PT)
   */
  [IsoCountryCode2.PT]: {
    lat: 38.7223,
    lng: 9.1393,
    zoom: 4,
  },
  /**
   * (AT)
   */
  [IsoCountryCode2.AT]: {
    lat: 48.2,
    lng: 16.366667,
    zoom: 4,
  },
  /**
   * (CZ)
   */
  [IsoCountryCode2.CZ]: {
    lat: 50.083333333333336,
    lng: 14.466667,
    zoom: 4,
  },
  /**
   * (PL)
   */
  [IsoCountryCode2.PL]: {
    lat: 52.25,
    lng: 21,
    zoom: 4,
  },
  /**
   * (RO)
   */
  [IsoCountryCode2.RO]: {
    lat: 44.43333333333333,
    lng: 26.1,
    zoom: 4,
  },
  /**
   * (SG)
   */
  [IsoCountryCode2.SG]: {
    lat: 1.2833333333333332,
    lng: 103.85,
    zoom: 4,
  },
  /**
   * (SG)
   */
  [IsoCountryCode2.SA]: {
    lat: 23.885942,
    lng: 45.079162,
    zoom: 4,
  },
  /**
   * (TW)
   */
  [IsoCountryCode2.TW]: {
    lat: 25.033333333333335,
    lng: 121.516667,
    zoom: 4,
  },
  default: {
    lat: 44.967243,
    lng: -103.771556,
    zoom: 4,
  },
};

export default mapDefaults;
