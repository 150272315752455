import { ISanityImage } from '@rbi-ctg/menu';
import { IImageFragment } from 'generated/sanity-graphql';
import { BuildImageUrlType } from 'state/ui';

interface IDevice {
  dpr: number;
  width: number;
}

export interface ISizeMediaQuery {
  bp: string | 'default';
  vw: number;
}

export type ImageFormat = 'jpg' | 'pjpg' | 'png' | 'webp';

export declare type AutoMode = 'format';

/**
 * We should shorten this list to get more cache hits
 * & spend less compute time on permutations.
 */
export const supportedDevices: Array<IDevice> = [
  // small image on desktops or slow connections
  { width: 320, dpr: 1 },
  // phones 2x (iPhone 6,7,8)
  { width: 375, dpr: 2 },
  // phones 2.6x (iPhone 6,7,8 Plus)
  { width: 414, dpr: 2.6 },
  // phones 3x (iPhone X)
  { width: 375, dpr: 3 },
  // iPhone 3x, phones 3x
  { width: 1280, dpr: 3 },
  // desktops 1x,
  { width: 1280, dpr: 1 },
  // desktops 1x
  { width: 1440, dpr: 1 },
  // 13" macbook pro
  { width: 1440, dpr: 2 },
  // desktops 3x
  { width: 1440, dpr: 3 },
  // desktops 1x
  { width: 1600, dpr: 1 },
  // desktops 2x
  { width: 1600, dpr: 2 },
  // desktops 4x
  { width: 1600, dpr: 4 },
  // desktops 2x
  { width: 2420, dpr: 2 },
  // desktops 2x
  { width: 2644, dpr: 2 },
];

/**
 * Order matters here for where you want the browser to
 * set priority on format.
 */
export const supportedImageFormats: Array<ImageFormat> = ['webp', 'png', 'jpg'];

/**
 * This is an optimistic guess for what percentages of the viewport
 * an image will take up.
 */
const defaultWidths: Array<number> = [100];

export interface IGenerateSrc {
  buildImageUrl: BuildImageUrlType;
  image: ISanityImage | IImageFragment;
  device: IDevice;
  format?: ImageFormat;
  viewportWidth?: number;
  quality?: number;
  resolutionMultiplier?: number;
  auto?: AutoMode;
}

/**
 * This function creates a url to send to sanity. Try not to pump anything in here that's too
 * dynamic as we want to try to maximize cache hits instead of breaking them.
 *
 * This drastically minimizes the power of sanity's image pipleline, but is intended to simplify image use.
 * Cropping and image formatting should be done on the client with `object-fit` & `object-position` css styles
 * to minimize complexity. This function is purely for generating a url for a downscaled image.
 *
 * @param {ISanityImage} params.image - Sanity image object.
 * @param {IDevice} params.device - object representing device width & dpr
 * @param {number} params.device.width - width of device in css px.
 * @param {number} params.device.dpr - pixel density of a device. Typically ranging between 1-3
 * @param {ImageFormat} params.format -  'jpg' | 'pjpg' | 'png' | 'webp'
 * @param {number} params.[viewportWidth] - optional percent of viewport image will take after styles
 * @param {number} params.[resolutionMultiplier] - optional number a multiplier to be used when requesting the image. default is 1.
 * @param {number} [params.auto] - Should the image format be automatically generated based on the client requesting. Sanity does this on their servers. default is 'format'.
 *
 * @returns {string} - url for sanity with params to optimize.
 */
export const generateSrc = ({
  buildImageUrl,
  image,
  device,
  viewportWidth = 100,
  quality = 40,
  resolutionMultiplier = 1,
  format,
  auto,
}: IGenerateSrc) => {
  const devicePixelWidth = device.width * device.dpr;
  const normalizedViewportWidth = viewportWidth / 100;

  const width = Math.ceil(devicePixelWidth * normalizedViewportWidth * resolutionMultiplier);
  return (
    buildImageUrl(image, {
      fit: 'max',
      quality,
      width,
      format,
      auto,
    }) || ''
  );
};

export interface IGenerateSrcSet {
  buildImageUrl: BuildImageUrlType;
  image: ISanityImage | IImageFragment;
  format?: ImageFormat;
  viewportWidths?: Array<number>;
  quality?: number;
  resolutionMultiplier?: number;
  auto?: AutoMode;
}

/**
 * Given a sanity image, this function generates the srcset
 * for all devices at all formats and given viewport widths.
 *
 * @param {ISanityImage} params.image - Sanity image to generate array for.
 * @param {IImageFormat} params.format - 'jpg' | 'pjpg' | 'png' | 'webp'
 * @param {Array<number>} [params.viewportWidths] - Widths of the viewport this image will appear at. Doesn't have to be exact, just rough estimate i.e [33, 50, 100]
 * @param {number} [params.quality] - number between 1-100. default is 40.
 * @param {number} [params.resolutionMultiplier] - number a multiplier to be used when requesting the image. default is 1.
 * @param {number} [params.auto] - Should the image format be automatically generated based on the client requesting. Sanity does this on their servers. default is 'format'.
 *
 * @returns {string} comma separated list of possible src resolutions.
 */
export const generateSrcSet = ({
  buildImageUrl,
  image,
  format,
  viewportWidths = defaultWidths,
  quality = 40,
  resolutionMultiplier = 1,
  auto = 'format',
}: IGenerateSrcSet) => {
  return viewportWidths
    .reduce<Array<string>>((srcSet, viewportWidth) => {
      supportedDevices.forEach(device => {
        const pixelWidth = device.width * device.dpr * (viewportWidth / 100);
        const srcSetUrl = generateSrc({
          buildImageUrl,
          image,
          device,
          viewportWidth,
          quality,
          resolutionMultiplier,
          format,
          auto,
        });
        if (pixelWidth && srcSetUrl) {
          srcSet.push(`${srcSetUrl} ${Math.floor(pixelWidth)}w`);
        }
      });
      return srcSet;
    }, [])
    .join(', ');
};

/**
 * Some browsers have a bug with the picture component where they preemptively
 * request the fallback for a picture before selecting the src (currently a bug in safari):
 * https://www.smashingmagazine.com/2013/05/how-to-avoid-duplicate-downloads-in-responsive-images/
 *
 * This allows us to detect if a browser even needs a fallback and only renders the fallback
 * if it's needed so greedy safari doesn't fetch the fallback & the correct srcset.
 */
export const needsLegacyImgSupport = () => {
  if (typeof navigator === 'undefined' || !navigator.userAgent) {
    return false;
  }
  return navigator.userAgent.match(/Trident/); // IE-only
};
