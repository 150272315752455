import styled from 'styled-components';

export const LanguageSelectorContainer = styled.div`
  display: none;

  ${Styles.desktopLarge`
    display: block;
    position: absolute;
    right: 2rem;
  `}
`;
