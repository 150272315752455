import { format } from 'date-fns';

import { IOffer } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { CartOfferState, IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';
import { LoyaltyOffer } from 'state/loyalty/types';
import { ILockedOffer } from 'state/offers/hooks/use-locked-offers';

import { USE_TOKEN_ID_AS_OFFER_ID } from './constants';

export enum OfferActivationStatus {
  CAN_ACTIVATE = 'CAN_ACTIVATE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  ACTIVATED = 'ACTIVATED',
}

export const activationStatusFromOfferState = (offerState: CartOfferState | null) => {
  switch (offerState) {
    case CartOfferState.ACTIVE:
      return OfferActivationStatus.ACTIVATED;
    default:
      return OfferActivationStatus.CAN_ACTIVATE;
  }
};

export const reduceOffersFeedback = (
  offersFeedback: ReadonlyArray<IOfferFeedbackEntryFragment>
): Record<string, IOfferFeedbackEntryFragment> =>
  offersFeedback.reduce(
    (acc, entry) => ({
      ...acc,
      [entry.tokenId || entry.couponId]: entry,
    }),
    {}
  );

export const makeUrlForOffer = (offer: IOffer) => {
  if (!offer.option || !offer.vendorConfigs) {
    return null;
  }
  const {
    option: { _id, _type },
  } = offer;

  if (_type === MenuObjectTypes.OFFER_DISCOUNT) {
    return null;
  }

  return `/menu/${_type}-${_id}`;
};

export const isMoreInfoDisclaimerAvailable = (offer: IOffer | LoyaltyOffer): boolean => {
  const moreInfoText = offer?.moreInfo?.localeRaw?.[0]?.children?.[0]?.text;
  return !!moreInfoText;
};

export const redeemedOnTime = (): string => format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

/**
 * NOTE: the following functions assist in getting / using offers based on a uniq offer id
 *       the value used for a uniq offer is not always consistent across brands. sometimes
 *       we use the offer _id for uniqueness and sometimes we use the offer tokenId. These
 *       utils give us a consistent way of determining what uniq id to use for an offer.
 * For example, Tim's allows a single
 *  offer to be assigned to a user multiple times. In this case, we
 *  would have a repeated offer _id, but a uniq offer tokenId in the
 *  offer list
 */

interface IGetUniqIdForOfferProps {
  id: string;
  token?: string | null | undefined;
}

/**
 * @name getUniqIdForOffer
 * @description get the uniq id based on a constant and the data available on the offer
 */
export const getUniqIdForOffer = ({ id, token }: IGetUniqIdForOfferProps): string => {
  if (USE_TOKEN_ID_AS_OFFER_ID && token) {
    return token;
  }
  return id;
};

/**
 * @name isCurrentlySelectedOffer
 * @description determine if an offer is already the selected offer
 */
export const isCurrentlySelectedOffer = (
  uniqOfferId: string,
  selectedOffer: IOffer | ILockedOffer
): boolean => selectedOffer.tokenId === uniqOfferId || selectedOffer._id === uniqOfferId;

export const findOfferByUniqId = (
  id: string,
  offers: IOffer[] | ILockedOffer[] = []
): IOffer | ILockedOffer | undefined =>
  offers.find(offer => offer.tokenId === id || offer._id === id);

export const findOfferIndexByUniqId = (
  id: string,
  offers: IOffer[] | ILockedOffer[] = []
): number => offers.findIndex(offer => offer.tokenId === id || offer._id === id);

/**
 * END utils for uniq offer id
 */

// ruleset utils
export * from './rule-sets';

export * from './sort-offers-for-service-mode';
