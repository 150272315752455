import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDFlagChangeset } from 'launchdarkly-js-client-sdk';
import { isEqual, isNumber, mergeWith } from 'lodash';

import { loadLanguage } from 'state/intl/load-language';
import { loadRegion } from 'state/intl/load-region';
import { getCurrentVersion } from 'utils/appflow';
import { hasAcceptedCookies, isRecentCookieTimestamp } from 'utils/cookies';
import { init as initDataDogLogs, initDatadogRum } from 'utils/datadog';
import {
  appVersionCode,
  getApiKey,
  platform,
  releaseTimestamp,
  sanityDataset,
} from 'utils/environment';
import { getMobileAppVersion } from 'utils/get-mobile-app-version';
import { getMobileOS } from 'utils/get-mobile-os';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import logger from 'utils/logger';

import { FlagType, LaunchDarklyFlag } from './flags';

export * from './flags';

export type LDUser = LDClient.LDUser;
export type LDFlagSet = LDClient.LDFlagSet;

const createAnonymousUserAttributes = async (deviceId?: string) => {
  const mobileOS = await getMobileOS();

  const anon = {
    anonymous: true,
    custom: {
      host: window.location.host,
      platform: platform(),
      mobileOS,
      device_id: deviceId || '',
      userClient: window.navigator.userAgent || '',
      language: loadLanguage(),
      sanityDataset: `${sanityDataset}_${loadRegion().toLowerCase()}`,
    },
    country: loadRegion(),
  };

  return normalizeUserAttributes(anon);
};

// When merging anonymous user attributes with the values from local storage in
// initUserAttributes the anonymous values are always overwritten, to keep the
// version up to date we need to handle it separately
const createVersionAttributes = async (): Promise<LDClient.LDUser> => {
  // Get details in parallel since they are independent to improve load time
  const [version, appShellVersion] = await Promise.all([
    getCurrentVersion(),
    getMobileAppVersion(),
  ]);

  return {
    custom: {
      appVersion: version ? version.binaryVersionCode : appVersionCode(),
      appShellVersion,
      appFlowBuildId: version ? version.buildId : '',
      releaseTimstamp: Number(releaseTimestamp()) || 0,
    },
  };
};

// Helper function to create user and version attributes in one place
const createDefaultAttributes = (deviceId?: string) =>
  Promise.all([createAnonymousUserAttributes(deviceId), createVersionAttributes()]);

const mergeFn = (objValue: unknown, srcValue: unknown) => {
  /*
  appFlowBuildId is a number.
  If the build id comes empty from local storage, an empty string value
  will override a real number. We need to take care of this, and then
  fall back to the usual merging mechanism
  */
  if (isNumber(objValue) && !srcValue) {
    return objValue;
  }
  return undefined; // not really needed, but just to signal that returning undefined will continue with the standard merging behaviour
};

// Merge any number of attribute sets
const mergeAttributes = (...args: LDClient.LDUser[]) => mergeWith({}, ...args, mergeFn);

export function getExtraContext() {
  const { custom } = LaunchDarklyHelper.getInstance().userAttributes;

  if (!custom) {
    return {};
  }

  const mobileContext = {
    mobileOS: custom.mobileOS,
    appFlowBuildId: custom.appFlowBuildId,
    appShellVersion: custom.appShellVersion,
  };

  for (let key in mobileContext) {
    if (!mobileContext[key]) {
      delete mobileContext[key];
    }
  }
  return mobileContext;
}

export const initLaunchDarkly = async (): Promise<Record<string, unknown>> => {
  const ldClient = await LaunchDarklyHelper.init();

  return new Promise<Record<string, unknown>>((resolve, reject) => {
    ldClient.on('initialized', () => {
      const allFlags = ldClient.allFlags();
      const flattenedFlags = LaunchDarklyHelper.flattenFlags(allFlags);
      const ddSampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE];
      const ddSampleRateMparticleEvents =
        flattenedFlags[LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE_MPARTICLE];
      const cookieBannerFlag = flattenedFlags[LaunchDarklyFlag.ENABLE_COOKIE_BANNER];
      const isCookieVersioningEnabled = flattenedFlags[LaunchDarklyFlag.ENABLE_COOKIE_VERSIONING];
      const cookiesAccepted = isCookieVersioningEnabled
        ? hasAcceptedCookies()
        : isRecentCookieTimestamp();

      const extraContext = getExtraContext();

      initDataDogLogs(extraContext, ddSampleRate, ddSampleRateMparticleEvents);

      if (!cookieBannerFlag || cookiesAccepted) {
        const sampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE] || 0;
        const replaySampleRate =
          flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_REPLAY_SAMPLE_RATE] || 0;
        initDatadogRum(extraContext, sampleRate, replaySampleRate);
      }

      resolve(flattenedFlags);
    });

    ldClient.on('failed', () => reject(new Error('LD Init failed')));
    ldClient.on('error', error => reject(error));
  }).catch(error => {
    logger.error(error);
    return {};
  });
};

export type LaunchDarklyFlagsObject = { [F in LaunchDarklyFlag]?: FlagType<F> };

export type UserAttributeUpdates = LDClient.LDUser;

export type AnonymousUserAttributes = LDClient.LDUser & { anonymous: true };

export type UserAttributes = AnonymousUserAttributes & UserAttributeUpdates;

export default class LaunchDarklyHelper {
  private static instance: LaunchDarklyHelper;
  public launchDarkly: LDClient.LDClient;
  private _userAttributes: LDClient.LDUser;

  public get userAttributes() {
    return this._userAttributes;
  }

  public async initUserAttributes(hydratedUserAttributes: LDClient.LDUser = {}) {
    const [userAttributes, versionAttributes] = await createDefaultAttributes();

    this._userAttributes = mergeAttributes(
      userAttributes,
      hydratedUserAttributes,
      versionAttributes
    );

    return this.userAttributes;
  }

  public async updateCurrentUser(changes: UserAttributeUpdates) {
    const normalized = normalizeUserAttributes(changes);
    const originalAttributes = this._userAttributes;
    const mergedAttributes = mergeAttributes(originalAttributes, normalized);

    // Only update LD user if attributes are different via a deep comparison check
    let newFlags: LDClient.LDFlagSet | undefined;
    if (!isEqual(originalAttributes, mergedAttributes)) {
      this._userAttributes = mergedAttributes;
      const flags = await this.launchDarkly.identify(mergedAttributes);
      newFlags = LaunchDarklyHelper.flattenFlags(flags);
      LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, this.launchDarkly.getUser());
    }

    return { newFlags, userAttributes: mergedAttributes };
  }

  public clearCurrentUser = async () => {
    const originalAttributes = this._userAttributes;

    // Persist device id across user sessions
    const deviceId = originalAttributes?.custom?.device_id as string | undefined;
    const newAttributes = await createDefaultAttributes(deviceId);
    const mergedAttributes = mergeAttributes(...newAttributes);

    const flags = await this.launchDarkly.identify(mergedAttributes);
    const newFlags = LaunchDarklyHelper.flattenFlags(flags);
    this._userAttributes = mergedAttributes;
    LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, this.launchDarkly.getUser());
    return { newFlags, userAttributes: mergedAttributes };
  };

  public static getInstance(): LaunchDarklyHelper {
    if (!LaunchDarklyHelper.instance) {
      LaunchDarklyHelper.instance = new LaunchDarklyHelper();
    }
    return LaunchDarklyHelper.instance;
  }

  public static flattenFlags(allFlags: LDFlagSet): LaunchDarklyFlagsObject {
    const flattened: LaunchDarklyFlagsObject = {};
    for (const key in allFlags) {
      flattened[key] = allFlags[key];
    }

    return flattened;
  }

  // Making this async because the rn varation is also async
  public evaluateFlagVariants = async () => {
    await this.launchDarkly.waitUntilReady();
    let updatedFlags = { ...this.launchDarkly.allFlags() };

    for (const flagName in updatedFlags) {
      if (typeof updatedFlags[flagName] === 'object') {
        const variationDetail = this.launchDarkly.variationDetail(flagName);

        if (variationDetail && typeof variationDetail.variationIndex === 'number') {
          updatedFlags[flagName] = `Variation ${variationDetail.variationIndex + 1}`;
        }
      }
    }

    return updatedFlags;
  };

  public addChangeListener = (callback: (flags: LaunchDarklyFlagsObject) => void) => {
    const changeCallback = (changes: LDFlagChangeset) => {
      const flattened = {};
      for (const key in changes) {
        flattened[key] = changes[key].current;
      }

      callback(flattened);
    };
    this.launchDarkly.on('change', changeCallback);

    return () => {
      this.launchDarkly.off('change', changeCallback);
    };
  };
  public static async init() {
    const helperInstance = this.getInstance();
    let config: LDClient.LDOptions = {
      privateAttributeNames: [
        'email',
        'firstName',
        'lastName',
        'name',
        'dateOfBirth',
        'phoneNumber',
        'ip',
      ],
      evaluationReasons: true,
    };

    // NOTE: cypress-v2 test suite requirement
    if (window.Cypress) {
      // Allow us to set initial values for launchDarkly.
      config.bootstrap = window._initial_cypress_feature_flags;
      // Avoid launchDarkly flag stream updates via window.EventSource.
      config.streaming = false;
      // Avoid launchDarkly event requests.
      config.sendEvents = false;
      // Opt out of diagnostic data
      config.diagnosticOptOut = true;
    }
    const cachedUser = LocalStorage.getItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES) || {};
    const initialUserAttributes = await helperInstance.initUserAttributes(cachedUser);

    const apiKey = getApiKey('launchDarkly');
    const ldClient = LDClient.initialize(apiKey, initialUserAttributes, config);
    helperInstance.launchDarkly = ldClient;
    return ldClient;
  }
}

const trimStringKeys = <T extends {}>(attributes: T): T =>
  Object.entries(attributes).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      return { ...acc, [key]: value && trimStringKeys(value) };
    }

    if (typeof value === 'string') {
      return { ...acc, [key]: value.trim() };
    }

    return { ...acc, [key]: value };
  }, {} as T);

const emailAttributeLowerCase = ({ email, ...attributes }: UserAttributeUpdates) => ({
  ...attributes,
  ...(email ? { email: email.toLowerCase() } : null),
});

export const normalizeUserAttributes = (
  userAttributeUpdates: UserAttributeUpdates
): LDClient.LDUser => emailAttributeLowerCase(trimStringKeys(userAttributeUpdates));
