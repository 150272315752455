import { Language } from '@rbilabs/intl';

const NATIVE_LANGUAGE_NAMES: Record<Language, string> = {
  ar: 'العربية',
  ca: 'Català',
  cs: 'Čeština',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  eu: 'Euskara',
  fr: 'Français',
  gl: 'Galego',
  ha: 'Hausa',
  it: 'Italiano',
  ko: '한국어',
  ms: 'Melayu',
  na: 'Nauru',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ta: 'தமிழ்',
  zh: '中文 (Zhōngwén), 汉语, 漢語',
};

/**
 * Gets a language's native name.
 *
 * @param code ISO-639-2 language code
 * @returns string
 */
export const getNativeLanguageName = (code: Language): string => {
  return NATIVE_LANGUAGE_NAMES[code] || code;
};
