import styled from 'styled-components';

import Picture from 'components/picture';

export const StyledPicture = styled(Picture)`
  height: 25rem;
`;

export const TextContentContainer = styled.div`
  margin: 1.125rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;
