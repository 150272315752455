import { useCallback } from 'react';

import { addSeconds, isBefore, subMinutes } from 'date-fns';

import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { POSVendor } from 'state/store/hooks/enums';

interface IUseOrderTimedFire {
  serverOrder: IServerOrder | IHistoricalOrder | null;
  cartEntriesActiveDayParts?: IDayPartBoundary[];
}

// Stop accepting orders 10 minutes before daypart ends
const DAY_PART_END_AHEAD_MINUTES = 10;

export const useOrderTimedFire = ({
  serverOrder,
  cartEntriesActiveDayParts,
}: IUseOrderTimedFire) => {
  let enableOrderTimedFire = useFlag(LaunchDarklyFlag.ENABLE_ORDER_TIMED_FIRE);
  const enableDeliveryOrderTimedFire = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ORDER_TIMED_FIRE);
  const enableDriveThruOrderTimedFire = useFlag(LaunchDarklyFlag.ENABLE_DRIVE_THRU_TIMED_FIRE);
  const enableOrderTimedFireCarrols = useFlag(LaunchDarklyFlag.ENABLE_ORDER_TIMED_FIRE_CARROLS);
  const enableOrderTimedFireNCR = useFlag(LaunchDarklyFlag.ENABLE_ORDER_TIMED_FIRE_NCR);
  const enableOrderTimedFireSICOM = useFlag(LaunchDarklyFlag.ENABLE_ORDER_TIMED_FIRE_SICOM);
  const enableOrderTimedFireQST = useFlag(LaunchDarklyFlag.ENABLE_ORDER_TIMED_FIRE_QST);
  const enableOrderTimedFireRPOS = useFlag(LaunchDarklyFlag.ENABLE_ORDER_TIMED_FIRE_RPOS);
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);

  const isPickupTimeAvailable = useCallback(
    date => {
      if (!cartEntriesActiveDayParts) {
        return false;
      }
      return !cartEntriesActiveDayParts.some(cartEntryMenuItemDayPart => {
        const dayPartLastCall = subMinutes(
          new Date(cartEntryMenuItemDayPart.endTime),
          DAY_PART_END_AHEAD_MINUTES
        );
        return isBefore(dayPartLastCall, date);
      });
    },
    [cartEntriesActiveDayParts]
  );

  /**
   * Defines the pickup time based on the order created date, fireOrderIn and fireOrderAhead values
   * fireOrderIn - the number of seconds after the order is placed when it should be released to kitchen
   * fireOrderAhead - the number of seconds before pickup time when the order is released to kitchen (configured with LD flag per brand)
   */
  let pickupDate = new Date();
  if (serverOrder?.createdAt) {
    pickupDate = new Date(serverOrder?.createdAt);

    if (serverOrder?.fireOrderIn) {
      pickupDate = addSeconds(pickupDate, (serverOrder.fireOrderIn || 0) + (fireOrderAhead ?? 0));
    }
  }

  /**
   * If all flags are enabled then return true
   */
  if (
    enableOrderTimedFire &&
    enableOrderTimedFireCarrols &&
    enableOrderTimedFireNCR &&
    enableOrderTimedFireSICOM &&
    enableOrderTimedFireQST &&
    enableOrderTimedFireRPOS
  ) {
    return {
      enableOrderTimedFire: true,
      enableDeliveryOrderTimedFire,
      enableDriveThruOrderTimedFire,
      pickupDate,
    };
  }

  /**
   * Override enableOrderTimedFire with vendor-specific flags
   */
  switch (serverOrder?.cart?.posVendor) {
    case POSVendor.CARROLS:
      if (typeof enableOrderTimedFireCarrols !== 'undefined') {
        enableOrderTimedFire = enableOrderTimedFireCarrols;
      }
      break;
    case POSVendor.NCR:
      if (typeof enableOrderTimedFireNCR !== 'undefined') {
        enableOrderTimedFire = enableOrderTimedFireNCR;
      }
      break;
    case POSVendor.SICOM:
      if (typeof enableOrderTimedFireSICOM !== 'undefined') {
        enableOrderTimedFire = enableOrderTimedFireSICOM;
      }
      break;
    case POSVendor.QST:
      if (typeof enableOrderTimedFireQST !== 'undefined') {
        enableOrderTimedFire = enableOrderTimedFireQST;
      }
      break;
    case POSVendor.RPOS:
      if (typeof enableOrderTimedFireRPOS !== 'undefined') {
        enableOrderTimedFire = enableOrderTimedFireRPOS;
      }
      break;
    default:
      enableOrderTimedFire = false;
      break;
  }

  return {
    enableDeliveryOrderTimedFire,
    enableDriveThruOrderTimedFire,
    enableOrderTimedFire,
    pickupDate,
    isPickupTimeAvailable,
  };
};
