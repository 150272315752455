import React from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import useMediaQuery from 'hooks/use-media-query';
import { useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useReversedUIContext } from 'state/reversed-ui';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { StyledLoyaltyButton, StyledLoyaltyPointsIcon } from './loyalty-points-button.styled';

const LoyaltyPointsButton = () => {
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const { navigate } = useLocationContext();
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();
  const isMobile = useMediaQuery('mobile');

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const showLoyaltyCta =
    enableLoyaltyPointsInHeader && !loyaltyLoading && loyaltyEnabled && loyaltyUser;

  const handleClick = () => {
    navigate(formatMessage({ id: 'routes.loyaltyDashboard' }));
  };

  return showLoyaltyCta ? (
    <Box margin={isMobile ? '0' : '0.75rem'} minWidth="4rem">
      <StyledLoyaltyButton
        variant={ActionButtonVariants.OUTLINE}
        size={ActionButtonSizes.SMALL}
        data-testid="app-header-loyalty-cta"
        onClick={handleClick}
        reversed={reversedUI}
      >
        <StyledLoyaltyPointsIcon reversed={reversedUI} /> {loyaltyUser?.points}
      </StyledLoyaltyButton>
    </Box>
  ) : null;
};
export default LoyaltyPointsButton;
