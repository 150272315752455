import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { primitive } from 'styles/constants/primitives';

export const PointsEarnedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
`;

export const PointsEarnedLabel = styled.h4`
  display: flex;
  align-items: center;
  margin: 0;
  font: ${brandFont.headerThree};
  color: ${p => p.theme.token('text-default')};
  line-height: 1.1;

  svg {
    width: 1.7rem;
    margin-right: ${primitive.$spacing1};
  }
`;
