import React, { FC } from 'react';

import { ActionButtonSizes, ActionButtonVariants, ActionLink } from 'components/action-button';
import { TypographyBlock } from 'components/features/components/typography-block';
import { IFeatureOnboardingScreen } from 'state/onboarding/types';

import { StyledPicture, TextContentContainer } from './styles';

export const ImageWithHeaderAndText: FC<IFeatureOnboardingScreen> = ({
  image,
  imageDescription,
  textContent,
  buttonText = '',
  buttonUrl = '',
}) => {
  const showButton = !!buttonText && !!buttonUrl;
  return (
    <div>
      <StyledPicture alt={imageDescription} image={image} />
      <TextContentContainer>
        <TypographyBlock content={textContent} />
        {showButton && (
          <ActionLink
            to={buttonUrl}
            variant={ActionButtonVariants.PRIMARY}
            size={ActionButtonSizes.LARGE}
            fullWidth
          >
            {buttonText}
          </ActionLink>
        )}
      </TextContentContainer>
    </div>
  );
};
