export enum EventName {
  APP_FLOW_DOWNLOAD_START = 'app_flow_download_start',
  APP_FLOW_DOWNLOAD_END = 'app_flow_download_end',
  JAVASCRIPT_START = 'javascript_start',
  MARKETING_IMAGE_LOADED = 'marketing_image_loaded',
  MAIN_HERO_IMAGE_LOADED = 'main_hero_image_loaded',
  OFFERS_CAROUSEL_RENDER = 'offers_carousel_render',
  REACT_START = 'react_start',
  MENU_TILE_IMAGE_LOADED = 'menu_tile_image_loaded',
  MENU_ITEM_CALORIES_LOADED = 'menu_item_calories_loaded',
  MENU_TILE_CLICKED = 'menu_tile_clicked',
  RESTAURANT_SELECTED_ON_ORDER = 'restaurant_selected_on_order',
  BOTTOM_TAB_PRESSED = 'bottom_tab_pressed',
  SCREEN_RENDER = 'screen_render',
  DEEPLINK = 'deep_link',
}

export interface DeeplinkEventArgs {
  route: string;
}

export interface BottomTabEventArgs {
  // TODO React Native Navigation: this should be an enum
  // TODO make this required
  screenId?: string;
  route?: string;
}

export type ScreenId =
  | 'home'
  | 'recentItems'
  | 'menu'
  | 'loyaltyOffers'
  | 'loyaltyRewards'
  | 'signupHome'
  | 'inRestaurantRedemption'
  | 'scan';

export interface ScreenEventArgs {
  // TODO React Native Navigation: this should be an enum
  screenId: ScreenId;
}
