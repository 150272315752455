import { Auth } from '@aws-amplify/auth';

import LocalStorage, { StorageKeys } from 'utils/local-storage';

export { getCurrentSession } from './get-current-session';

// This method should get us information about a user being signed-in
// without validating the session with the cognito server. If this does
// not do that we should just move to keep track of a user being signed-in
// or not via localstorage
export const userHasSignedIn = (): Promise<boolean> => {
  return Auth.currentAuthenticatedUser()
    .then(credentials => !!credentials)
    .catch(() => false);
};

// Return if there is an unexpected sign outs tracking
export const checkForUnexpectedSignOut = (): Promise<Error> =>
  Auth.currentSession()
    .then(() => {
      // Add a marker when a user is successfully sign in to track unexpected sign outs
      LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      return null;
    })
    .catch(error => {
      // First check if the user has been logged in successfully
      const userSignedInSuccessfully = LocalStorage.getItem(
        StorageKeys.USER_SIGNED_IN_SUCCESSFULLY
      );
      if (userSignedInSuccessfully) {
        // Remove the marker and return that there has been an unexpected sign out
        LocalStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);
        return error;
      }
      return null;
    });
