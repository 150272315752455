import { gql } from '@apollo/client';

import ImageFragment from './image';
import ImagesFragment from './images';

export const GetAllLoyaltyContentQuery = gql`
  query GetLoyaltyContent {
    allLoyaltyContents(limit: 1) {
      name
      mainHeroLoyaltyImage {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      mainHeroLoyaltyText {
        locale: _LANG_
      }
      mainHeroLoyaltySubtext {
        locale: _LANG_
      }
      timsRewardsImage {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      detailsTabName {
        locale: _LANG_
      }
      detailsPageHeroImageWeb {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      detailsPageHeroImageMobile {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      detailsSetupPageText {
        locale: _LANG_
      }
      detailsSetupPageSubtext {
        locale: _LANG_
      }
      detailsInstructionsOne {
        locale: _LANG_
      }
      detailsInstructionsTwo {
        locale: _LANG_
      }
      detailsInstructionsThree {
        locale: _LANG_
      }
      detailsInstructionsFour {
        locale: _LANG_
      }
      detailsIconOne {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      detailsIconTwo {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      detailsIconThree {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      detailsIconFour {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      loyaltyCardSectionTitle {
        locale: _LANG_
      }
      loyaltyCardSectionText {
        locale: _LANG_
      }
      registerCardSectionTitle {
        locale: _LANG_
      }
      registerCardSectionBlockText {
        localeRaw: _LANG_Raw
      }
      registerCardImage {
        locale: _LANG_ {
          ...ImageFragment
        }
      }
      lostCardSectionTitle {
        locale: _LANG_
      }
      lostCardSectionBlockText {
        localeRaw: _LANG_Raw
      }
      rewardSetupTabName {
        locale: _LANG_
      }
      rewardSetupPageText {
        locale: _LANG_
      }
      rewardSetupPageBlockText {
        localeRaw: _LANG_Raw
      }
      historyTabName {
        locale: _LANG_
      }
      historyPageText {
        locale: _LANG_
      }
      historyPageSubtext {
        locale: _LANG_
      }
      pointsUpdateText {
        locale: _LANG_
      }
      birthdayLegalText {
        locale: _LANG_
      }
      rewardsUnavailableBlockText {
        localeRaw: _LANG_Raw
      }
      onboardingTimsRewardHeaderImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      onboardingTextBlock {
        localeRaw: _LANG_Raw
      }
      onboardingImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      onboardingBackgroundImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      onboardingContentCardBackgroundImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      onboardingButtonText {
        locale: _LANG_
      }
      onboardingButtonLink
      addCardHeaderText {
        locale: _LANG_
      }
      addCardTextBlock {
        localeRaw: _LANG_Raw
      }
      addCardImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      addCardSkipText {
        locale: _LANG_
      }
      tierHeaderText {
        locale: _LANG_
      }
      tierCardTextBlock {
        localeRaw: _LANG_Raw
      }
      seeMoreOptionsText {
        locale: _LANG_
      }
      newPointsImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      newPointsTextBlockTop {
        localeRaw: _LANG_Raw
      }
      newPointsTextBlockBottom {
        localeRaw: _LANG_Raw
      }
      newPointsDisclaimerTextBlock {
        localeRaw: _LANG_Raw
      }
      timsRewardsLevelImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
    }
  }
  ${ImageFragment}
  ${ImagesFragment}
`;
