import React from 'react';

import { OnClick } from '@rbilabs/components-library/build/components/button';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ActionButton, { ActionButtonSizes } from 'components/action-button';
import defaultConfig from 'components/app-header/styled';
import LoyaltyRewardsLogoSimple from 'components/icons/loyalty-rewards-logo-simple';
import { LoyaltyPointsButton } from 'components/loyalty-points-button';
import Picture from 'components/picture';
import { UserAccountButton } from 'components/user-account-button';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import { LoyaltyAppHeader } from 'pages/loyalty/loyalty-app-header';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { designTokens } from 'styles/constants/design-tokens.plk';
import { RBIBrand, brand } from 'utils/environment';
import { routes } from 'utils/routing';

import RecentOrdersLink from '../recent-orders-link';

import { SHOW_LOYALTY_REWARDS_LOGO } from './constants';

interface IRightNav {
  onMyAccountClick: OnClick;
  onReFocus: OnClick;
}

const LoyaltyRewardsLogo = styled(LoyaltyRewardsLogoSimple)`
  margin-right: 1rem;
  position: relative;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;

  &:hover {
    transform: scale3d(1.2, 1.2, 1);
    transition: all 0.35s cubic-bezier(0, 1.27, 0.46, 1.65);
  }
`;
const LoyaltyRewardsLogoPLK = styled.div`
  min-width: 7rem;
  margin-right: 1rem;
  position: relative;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  &:hover {
    transform: scale3d(1.2, 1.2, 1);
    transition: all 0.35s cubic-bezier(0, 1.27, 0.46, 1.65);
  }
`;

const RightNav: React.FC<IRightNav> = ({ onMyAccountClick, onReFocus }) => {
  const { isAuthenticated } = useAuthContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { formatMessage } = useIntl();
  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) ? false : enableSignUpFlag;

  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) ? false : enableOrderingFlag;
  const { featureLoyaltyUi } = useFeatureLoyaltyUi();

  let customStyle = {};

  if (brand() === RBIBrand.PLK) {
    customStyle = {
      ...customStyle,
      backgroundColor: designTokens['background-color'] || '',
      color: designTokens['background-text-color'] || '',
      borderRadius: designTokens['background-border-radius'] || '',
      border: designTokens['background-border'] || '',
    };
  }

  if (!enableSignUp) {
    return null;
  }

  if (isAuthenticated()) {
    return (
      <>
        {loyaltyEnabled && <LoyaltyAppHeader />}
        {enableOrdering && (
          <RecentOrdersLink
            to={routes.orders}
            data-testid="recent-orders-link"
            title={formatMessage({ id: 'recentOrders' })}
          />
        )}
        <UserAccountButton onClick={onMyAccountClick} />
        <LoyaltyPointsButton />
      </>
    );
  }

  return (
    <>
      {loyaltyEnabled && SHOW_LOYALTY_REWARDS_LOGO && (
        <Link to={formatMessage({ id: 'routes.loyaltyDashboard' })}>
          {brand() === RBIBrand.PLK && featureLoyaltyUi ? (
            <LoyaltyRewardsLogoPLK data-testid="lyl-reward-logo">
              <Picture
                image={featureLoyaltyUi?.rewardsLogo?.locale}
                alt={formatMessage({ id: 'rewards' })}
                objectFitContain
              />
            </LoyaltyRewardsLogoPLK>
          ) : (
            <LoyaltyRewardsLogo data-testid="lyl-reward-logo" />
          )}
          <VisuallyHidden>{formatMessage({ id: 'rewards' })}</VisuallyHidden>
        </Link>
      )}
      <Box margin="0 1rem 0 0">
        <ActionButton
          size={ActionButtonSizes.SMALL}
          variant={defaultConfig.buttonVariant}
          to={routes.signUp}
          onClick={onReFocus}
          data-testid="desktop-signup-link"
          style={customStyle}
        >
          {formatMessage({ id: 'signUp' })}
        </ActionButton>
      </Box>
    </>
  );
};

export default RightNav;
