import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.475rem;
  height: 4.125rem;
  background: ${Styles.color.contrastBackground};
  cursor: pointer;
  box-shadow: ${p => p.theme.token('bsm-drop-shadow')};
`;

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: ${Styles.layout.sectionMaxWidth};
  color: ${Styles.color.white};
  width: 100%;
`;

export const IconContainer = styled.div`
  margin-left: 0.5rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Heading = styled.div`
  font-family: ${Styles.fontFamily.base};
  font-size: 1rem;
  color: ${Styles.color.white};
`;

export const Details = styled.div`
  color: ${Styles.color.white};
  font-size: 0.75rem;
`;

export const Close = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const ActionContainer = styled.div``;
