import React from 'react';

import { useMatch } from 'react-router-dom';

import { StyledLink } from 'components/app-header/styled';
import { useReversedUIContext } from 'state/reversed-ui';

interface INavLink {
  matchRoute?: string;
  [key: string]: any;
}

const NavLink: React.FC<INavLink> = ({ matchRoute, ...props }) => {
  const routeToMatch = matchRoute || props.to;
  const match = useMatch(routeToMatch);
  const { reversedUI } = useReversedUIContext();
  return <StyledLink className={match ? 'selected' : ''} {...props} reversed={reversedUI} />;
};

export default NavLink;
