import { asLanguage, getDefaultLanguage } from '@rbilabs/intl';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { brand } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { getNavigatorLanguage } from './get-navigator-language';
import { loadRegion } from './load-region';

export function loadLanguage(): SupportedLanguages {
  const navigatorLanguage = getNavigatorLanguage();
  const inferredUrlParamLanguage = inferLanguageFromUrlParams();

  const lang =
    inferredUrlParamLanguage ||
    LocalStorage.getItem<SupportedLanguages>(StorageKeys.LANGUAGE) ||
    navigatorLanguage.split(/-.*/)[0];

  return findSupportedLanguage(lang);
}

function inferLanguageFromUrlParams(): string | null {
  const params = new URL(window.location.href).searchParams;

  const uriLang = params.get('lang');

  return uriLang;
}

function findSupportedLanguage(requestedLanguage: string): SupportedLanguages {
  return asLanguage(requestedLanguage) || getDefaultLanguage(brand(), loadRegion());
}
