import styled from 'styled-components';

import { DESKTOP_DELIVERY_BANNER_BACKGROUND } from './delivery/constants';

export const DesktopSignupBanner = styled.div<{ $isDelivery?: boolean }>`
  display: none;

  ${props => Styles.desktopLarge`
    ${
      props.$isDelivery
        ? `display: block;`
        : `display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 auto;`
    }

    background: ${DESKTOP_DELIVERY_BANNER_BACKGROUND};
    text-align: center;
    padding: 4rem 1rem;
    max-width: 500px;
    overflow-y: scroll;
  `}
`;
