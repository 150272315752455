/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { StorageKeys } from 'utils/local-storage';

export const LOCALE_SELECTED_QUERY_PARAM = 'locale-selected';

export const regionSpecificLocalStorageKeys = [
  StorageKeys.STORE,
  StorageKeys.AUTH_REDIRECT,
  StorageKeys.ORDER,
  StorageKeys.SERVICE_MODE,
];
