import React, { createContext, useCallback, useContext, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ILoyaltyCustomizableContent, IRollUpTheRim } from '@rbi-ctg/menu';
import { getLoyaltyCustomizableContent } from 'remote/api/customizable-content';
import getRollUpTheRimContent from 'remote/api/roll-up-the-rim';
import noop from 'utils/noop';

import { useLocale } from '../intl';
import { useNetworkContext } from '../network';

interface IRollUpTheRimState {
  rollUpTheRim: IRollUpTheRim;
  isLoadingRollUpTheRim: boolean;
}

interface ILoyaltyCustomizableContentState {
  loyaltyContent: ILoyaltyCustomizableContent;
  isLoadingLoyalty: boolean;
}

interface IGetLoyaltyRequest {
  requestLoyaltyCustomizableContent: () => void;
}

interface IGetRollUpTheRimRequest {
  requestRollUpTheRimContent: () => void;
}

export type TCustomizableContentContext = ILoyaltyCustomizableContentState &
  IRollUpTheRimState &
  IGetLoyaltyRequest &
  IGetRollUpTheRimRequest;

export const CustomizableContentContext = createContext<TCustomizableContentContext>({
  loyaltyContent: {} as ILoyaltyCustomizableContent,
  isLoadingLoyalty: true,
  rollUpTheRim: {} as IRollUpTheRim,
  isLoadingRollUpTheRim: true,
  requestLoyaltyCustomizableContent: noop,
  requestRollUpTheRimContent: noop,
});

export const useCustomizableContent = () => useContext(CustomizableContentContext);

export function CustomizableContentProvider({ children }: IBaseProps) {
  const { query, defaultErrorHandler } = useNetworkContext();
  const { locale } = useLocale();

  const [rollUpTheRimContent, setRollUpTheRimContent] = useState<IRollUpTheRimState>({
    rollUpTheRim: {} as IRollUpTheRim,
    isLoadingRollUpTheRim: true,
  });

  const [loyaltyCustomizableContent, setLoyaltyCustomizableContent] =
    useState<ILoyaltyCustomizableContentState>({
      loyaltyContent: {} as ILoyaltyCustomizableContent,
      isLoadingLoyalty: true,
    });

  const requestLoyaltyCustomizableContent = useCallback(() => {
    query(getLoyaltyCustomizableContent())
      .then(loyaltyContent => {
        setLoyaltyCustomizableContent({
          loyaltyContent,
          isLoadingLoyalty: false,
        });
      })
      .catch(error => {
        defaultErrorHandler(error);
        setLoyaltyCustomizableContent(currentContent => ({
          ...currentContent,
          isLoadingLoyalty: false,
        }));
      });
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const requestRollUpTheRimContent = useCallback(() => {
    query(getRollUpTheRimContent())
      .then(rollUpTheRim => {
        setRollUpTheRimContent({ rollUpTheRim, isLoadingRollUpTheRim: false });
      })
      .catch(error => {
        defaultErrorHandler(error);
        setRollUpTheRimContent(currentData => ({ ...currentData, isLoadingRollUpTheRim: false }));
      });
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomizableContentContext.Provider
      value={{
        ...loyaltyCustomizableContent,
        ...rollUpTheRimContent,
        requestLoyaltyCustomizableContent,
        requestRollUpTheRimContent,
      }}
    >
      {children}
    </CustomizableContentContext.Provider>
  );
}

export default CustomizableContentContext.Consumer;
