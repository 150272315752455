import styled from 'styled-components';

export const DeliveryCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: ${Styles.color.grey.five};
  text-align: left;

  ${Styles.desktop`
    background: none;
  `}
`;

export const Card = styled.div`
  font-family: ${Styles.fontFamily.body};
  padding: 1.375rem;
  margin: 0 0 1rem 0;
  width: 100%;
  background: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};
  box-shadow: ${Styles.boxShadow};

  ${Styles.desktop`
    box-shadow: none;
  `}
`;

export const ETA = styled.p`
  font-size: 0.875rem;
  padding: 0.75rem 0;
  margin: -0.75rem 0 0.75rem 0;
  border-bottom: 1px solid ${Styles.color.grey.four};
`;

export const CardHeading = styled.h4`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.base};
  font-size: 1.125rem;
  line-height: 1.3;
  padding: 0;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
`;

export const TextGroup = styled.div`
  border-bottom: 1px solid ${Styles.color.grey.four};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

export const CardText = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;

export const FeeDeliveryItem = styled.p`
  font-size: 0.875rem;
  margin: 0;
  text-transform: ${Styles.textTransform.headlines};
`;

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.75;
`;

export const FixedMapWindow = styled.div<{ isDeliveryDesktop?: boolean }>`
  height: ${p => (p.isDeliveryDesktop ? '100%' : '52vh')};
  width: 100%;
`;

export const StyledOrderIssues = styled.div`
  background: ${Styles.color.white};
  padding: 1.375rem;
  margin: auto auto 1rem auto;
  width: 90%;
  border-radius: ${Styles.borderRadius};
  box-shadow: ${Styles.boxShadow};
  display: flex;

  ${Styles.desktop`
    max-width: 400px;
  `}
`;

export const DeliveryConfirmationRoot = styled.div`
  width: 100%;
`;

export const ScrollContainer = styled.div``;
