import React, { useCallback } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ActionButton from 'components/action-button';
import Modal, { ModalContent } from 'components/modal';
import { useWelcome } from 'hooks/use-welcome';
import { useLogger } from 'state/logger';
import { useUIContext } from 'state/ui';
import { routes } from 'utils/routing';

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  :first-of-type button {
    margin-bottom: 1rem;
  }
  min-width: 268px;
`;

const MessageText = styled.p`
  font-size: 1.375rem;
  font-family: ${Styles.fontFamily.base};
  text-align: center;
`;

const MessageHeading = styled.h1`
  font-family: ${Styles.fontFamily.base};
  text-align: center;
`;

const WelcomeMessage = () => {
  const logger = useLogger();
  const { formatMessage } = useIntl();
  const { isShowingWelcomeMessage, setUserEmail, hideWelcomeMessage } = useUIContext();
  const { legacyEmail, setEmailRead } = useWelcome({ logger });
  const navigate = useNavigate();

  const handleSignBackIn = useCallback(() => {
    if (legacyEmail) {
      setUserEmail(legacyEmail);
      navigate(routes.signIn);
    }
    hideWelcomeMessage();
    setEmailRead();
  }, [legacyEmail, hideWelcomeMessage, setEmailRead, setUserEmail, navigate]);

  const handleDismiss = useCallback(() => {
    hideWelcomeMessage();
    setEmailRead();
  }, [hideWelcomeMessage, setEmailRead]);
  if (!isShowingWelcomeMessage) {
    return null;
  }

  const modalHeading = formatMessage({ id: 'legacyImportWelcomeMessageHeader' });
  const modalBody = formatMessage({ id: 'legacyImportWelcomeMessageText' });

  return (
    <Modal
      onDismiss={handleDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Welcome Message',
        modalMessage: modalBody,
      }}
    >
      <ModalContent>
        <MessageHeading id="modal-heading" className="modal-heading">
          {modalHeading}
        </MessageHeading>
        <MessageText>{modalBody}</MessageText>
        <ActionButtonContainer>
          <Box margin="0.5rem">
            <ActionButton
              disabled={false}
              data-testid="welcome-message-sign-in"
              onClick={handleSignBackIn}
            >
              {formatMessage({ id: 'signIn' })}
            </ActionButton>
          </Box>
        </ActionButtonContainer>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeMessage;
