export interface TruncateOptions {
  separator?: string;
  maxLetters?: number;
  defaultValue?: string;
  position?: number;
}

const defaultOptions = {
  separator: ' ',
  maxLetters: 20,
  defaultValue: '',
  position: 0,
};

export const truncateTextWithEllipsis = (text: string | undefined, options?: TruncateOptions) => {
  const { separator, maxLetters, defaultValue, position } = { ...defaultOptions, ...options };

  if (!text || position < 0 || maxLetters < 0) {
    return defaultValue;
  }
  const subText = text.split(separator)[position];

  return subText.length > maxLetters ? `${subText.substr(0, maxLetters)}...` : subText;
};
