import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import {
  IAllFeatureIdsQuery,
  useAllFeatureIdsQuery,
  useFeatureNutritionLazyQuery,
} from 'generated/sanity-graphql';
import { usePrevious } from 'hooks/use-previous';

interface IFeatureIds {
  featureAccountId?: string;
  featureAccountDeletePageId?: string;
  featureAccountFormId?: string;
  featureAccountRequestInfoPageId?: string;
  featureDisclaimersId?: string;
  featureExternalLinkId?: string;
  featureFooterId?: string;
  featureGeolocationMapMarkersId?: string;
  featureGeolocationModalId?: string;
  featureGiftId?: string;
  featureHomePageId?: string;
  featureLayoutId?: string;
  featureLinkPhysicalCardUIId?: string;
  featureMenuId?: string;
  featureNavigationId?: string;
  featureOffersId?: string;
  featureSupportDataId?: string;
  featureStaticMenuId?: string;
  featureCateringPageId?: string;
  featureLoyaltyUIId?: string;
  featureClaimPointsUIId?: string;
  featureNutritionId?: string;
  featureLoyaltyRewardListCategoriesId?: string;
  featureLockersId?: string;
  featureBeeperId?: string;
  featureFooterV2Id?: string;
  featurePaybackId?: string;
  featureDonationId?: string;
  featureInvitationCodeId?: string;
  featureIFrameId?: string;
  featureQRCodeRefillDrinksId?: string;
  featureAggregatorRedirectButtonsId?: string;
  // add new feature ids here
}

export interface IFeaturesContext extends IFeatureIds {
  featureIdsLoading: boolean;
}

export const FeaturesContext = createContext<IFeaturesContext>({
  featureIdsLoading: true,
});

export const useFeaturesContext = () => useContext(FeaturesContext);

const processAllFeatureIds = (data: IAllFeatureIdsQuery | undefined): IFeatureIds => {
  if (!data) {
    return {};
  }

  const getSingletonId = (id: keyof Omit<IAllFeatureIdsQuery, '__typename'>) => {
    return data?.[id]?.[0]?._id;
  };

  return {
    featureAccountId: getSingletonId('allFeatureAccounts'),
    featureAccountDeletePageId: getSingletonId('allFeatureAccountDeletePages'),
    featureAccountFormId: getSingletonId('allFeatureAccountForms'),
    featureAccountRequestInfoPageId: getSingletonId('allFeatureAccountRequestInfoPages'),
    featureDisclaimersId: getSingletonId('allFeatureDisclaimers'),
    featureExternalLinkId: getSingletonId('allFeatureExternalLinkSections'),
    featureFooterId: getSingletonId('allFeatureFooters'),
    featureFooterV2Id: getSingletonId('allFeatureFooterV2S'),
    featureGeolocationMapMarkersId: getSingletonId('allFeatureGeolocationMapMarkers'),
    featureGeolocationModalId: getSingletonId('allFeatureGeolocationModals'),
    featureGiftId: getSingletonId('allFeatureGifts'),
    featureHomePageId: 'feature-home-page-singleton',
    featureLoyaltyUIId: getSingletonId('allLoyaltyUis'),
    featureLinkPhysicalCardUIId: getSingletonId('allLinkPhysicalCardUis'),
    featureClaimPointsUIId: getSingletonId('allClaimPointsUis'),
    featureLayoutId: getSingletonId('allFeatureLayouts'),
    featureMenuId: getSingletonId('allFeatureMenus'),
    featureNavigationId: getSingletonId('allFeatureNavigations'),
    featureNutritionId: getSingletonId('allFeatureNutritions'),
    featureOffersId: getSingletonId('allFeatureOffers'),
    featureSupportDataId: getSingletonId('allSupportData'),
    featureStaticMenuId: getSingletonId('allFeatureStaticMenus'),
    featureCateringPageId: getSingletonId('allFeatureCateringPages'),
    featureLoyaltyRewardListCategoriesId: getSingletonId('allRewardLists'),
    featureLockersId: getSingletonId('allFeatureLockers'),
    featureBeeperId: getSingletonId('allFeatureBeeperModals'),
    featurePaybackId: getSingletonId('allFeaturePaybacks'),
    featureDonationId: getSingletonId('allFeatureDonations'),
    featureInvitationCodeId: getSingletonId('allFeatureInvitationCodes'),
    featureIFrameId: getSingletonId('allFeatureIFrames'),
    featureQRCodeRefillDrinksId: getSingletonId('allFeatureQRCodeRefillDrinks'),
    featureAggregatorRedirectButtonsId: getSingletonId('allFeatureAggregatorRedirectButtons'),
  };
};

export const FeaturesProvider: React.FC<IBaseProps> = ({ children }) => {
  const { data: allFeatureIds, loading: featureIdsLoading } = useAllFeatureIdsQuery();
  const featureIds = useMemo(() => processAllFeatureIds(allFeatureIds), [allFeatureIds]);

  const [dispatchFeatureNutritionQuery] = useFeatureNutritionLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { featureNutritionId } = featureIds;
  const previousFeatureNutritionId = usePrevious(featureNutritionId);

  useEffect(() => {
    // pre-fetch critical data after feature nutrition id changes
    if (previousFeatureNutritionId !== featureNutritionId && featureNutritionId) {
      const prefetch = async () => {
        dispatchFeatureNutritionQuery({
          variables: { featureNutritionId },
        });
      };
      prefetch();
    }
  }, [
    dispatchFeatureNutritionQuery,
    featureIdsLoading,
    featureNutritionId,
    previousFeatureNutritionId,
  ]);

  const contextValue = useMemo(() => {
    return { ...featureIds, featureIdsLoading };
  }, [featureIdsLoading, featureIds]);

  return <FeaturesContext.Provider value={contextValue}>{children}</FeaturesContext.Provider>;
};
