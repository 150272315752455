import {
  CreateSliceOptions,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  createSlice,
} from '@reduxjs/toolkit';

import { SliceReducers, SliceReturn, ValidateSliceReducers } from './create-app-slice.types';

export const createAppSlice = <T, Reducers extends SliceReducers<T>>({
  name,
  initialState,
  reducers,
  extraReducers,
}: {
  name: CreateSliceOptions['name'];
  initialState: T;
  reducers: ValidateSliceReducers<T, Reducers>;
  extraReducers?: CreateSliceOptions['extraReducers'];
}) => {
  return createSlice({
    name,
    initialState,
    reducers: reducers as ValidateSliceCaseReducers<T, SliceCaseReducers<T>>,
    extraReducers,
  }) as unknown as SliceReturn<T, ValidateSliceReducers<T, Reducers>>;
};
