import styled from 'styled-components';

export const StickyBoxChild = styled.div<{ lastChild: boolean; spaces?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({ spaces }) => spaces || 1};
  margin-right: ${({ lastChild }) => (lastChild ? '0' : '1rem')};

  ${Styles.mobileTiny`
    margin-right: ${({ lastChild }: { lastChild: boolean }) => (lastChild ? '0' : '0.5rem')};
  `}
`;
