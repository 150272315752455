import { KeyboardEvent } from 'react';

export const isButtonLikeKeypress = (e: KeyboardEvent) => e.key === 'Enter' || e.key === ' ';

export const preventMultiTouchDefault = (e: TouchEvent) => {
  if (e.touches.length > 1) {
    e.preventDefault();
  }
};

export const getMaxPageYFromTouches = (e: TouchEvent) => {
  let pageY: number | null = null;

  if (e.touches[0] && e.touches[0].pageY && e.touches[1] && e.touches[1].pageY) {
    pageY = Math.max(e.touches[0].pageY, e.touches[1].pageY);
  } else if (e.touches[0] && e.touches[0].pageY) {
    pageY = e.touches[0].pageY;
  } else if (e.touches[1] && e.touches[1].pageY) {
    pageY = e.touches[1].pageY;
  }

  return pageY;
};
