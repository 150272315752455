import React, { useMemo } from 'react';

import onClickOutside from 'react-onclickoutside';

const Container = onClickOutside(
  class ClickContainer extends React.Component {
    handleClickOutside = () => {
      this.props.onDismiss();
    };

    render() {
      return this.props.children;
    }
  }
);

export default function useOnClickOutside(onDismiss) {
  return useMemo(
    () => props => <Container {...props} onDismiss={onDismiss} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
