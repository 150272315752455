import { ReactNode, createElement } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';

import { AccessibilityProvider } from 'state/accessibility';
import { AuthProvider } from 'state/auth';
import { BranchProvider } from 'state/branch';
import { BrazeProvider } from 'state/braze';
import { CustomizableContentProvider } from 'state/customizable-content';
import { DayPartProvider } from 'state/day-part';
import { DeliveryConfirmationProvider } from 'state/delivery-confirmation';
import { ErrorsProvider } from 'state/errors';
import { FavoritesProvider } from 'state/favorites';
import { FeaturesProvider } from 'state/features';
import { GeolocationProvider } from 'state/geolocation';
import { ConfiguredStore } from 'state/global-state';
import { GoogleAdManagerProvider } from 'state/google-ad-manager';
import { GraphQLProvider } from 'state/graphql';
import { IntlProvider } from 'state/intl';
import { LDProvider } from 'state/launchdarkly';
import { LoadingProvider } from 'state/loading';
import { LocationProvider } from 'state/location';
import { LoggerProvider } from 'state/logger/provider';
import { LoyaltyProvider } from 'state/loyalty';
import { MParticleProvider } from 'state/mParticle';
import { MenuProvider } from 'state/menu';
import { MobileFooterProvider } from 'state/mobile-footer';
import { MobileHeaderNavProvider } from 'state/mobile-header-nav';
import { NearbyStoresProvider } from 'state/nearby-stores';
import { NetworkProvider } from 'state/network';
import { OffersProvider } from 'state/offers';
import { OnboardingProvider } from 'state/onboarding';
import { OrderProvider } from 'state/order';
import { PaymentProvider } from 'state/payment';
import { PypestreamProvider } from 'state/pypestream';
import { ReversedUIProvider } from 'state/reversed-ui';
import { ScrollProvider } from 'state/scroll';
import { ServiceModeProvider } from 'state/service-mode';
import { PageManagerProvider } from 'state/static-page-manager';
import { StoreProvider } from 'state/store';
import { ThRewardsManagementProvider } from 'state/th-rewards-management';
import { UIProvider } from 'state/ui';
import { LaunchDarklyFlagsObject } from 'utils/launchdarkly';

declare interface IProviderProps {
  children: ReactNode;
}
const providers = ({
  ldFlags,
  userHasSignedIn,
  userSession,
  store,
}: {
  ldFlags: LaunchDarklyFlagsObject;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
  store: ConfiguredStore;
}) => {
  const ReduxProviderWithStore = ({ children }: IProviderProps) =>
    createElement(ReduxProvider, { store }, children);
  const LDProviderWithFlags = ({ children }: IProviderProps) => LDProvider({ children, ldFlags });
  const AuthProviderWithUserSession = ({ children }: IProviderProps) =>
    AuthProvider({ children, userHasSignedIn, userSession });

  return [
    ReduxProviderWithStore,
    LoggerProvider,
    LDProviderWithFlags,
    IntlProvider,
    ...(window.Cypress ? [] : [MParticleProvider]),
    LocationProvider,
    BranchProvider,
    GraphQLProvider,
    BrazeProvider,
    LoadingProvider,
    ErrorsProvider,
    NetworkProvider,
    AuthProviderWithUserSession,
    PypestreamProvider,
    CustomizableContentProvider,
    UIProvider,
    ServiceModeProvider,
    StoreProvider,
    FeaturesProvider,
    DayPartProvider,
    NearbyStoresProvider,
    OffersProvider,
    LoyaltyProvider,
    MenuProvider,
    MobileHeaderNavProvider,
    MobileFooterProvider,
    PaymentProvider,
    PageManagerProvider,
    OrderProvider,
    FavoritesProvider,
    GeolocationProvider,
    AccessibilityProvider,
    HelmetProvider,
    DeliveryConfirmationProvider,
    OnboardingProvider,
    ReversedUIProvider,
    GoogleAdManagerProvider,
    ScrollProvider,
    ThRewardsManagementProvider,
  ];
};

export default providers;
