import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { OnClick } from '@rbi-ctg/frontend';
import { IOffer } from '@rbi-ctg/menu';
import BlockRenderer from 'components/sanity-block-renderer';
import { LoyaltyOffer } from 'state/loyalty/types';

const ticketCircleDiameter = 15;

const TicketContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

interface ITicketSquare {
  borderColor?: string;
  fill?: string;
}
const TicketSquare = styled.div<ITicketSquare>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: ${Styles.borderRadius};
  border: 1px solid ${p => p.borderColor};
  background-color: ${p => p.fill};
`;

interface ITicketCircle {
  borderColor?: string;
  bgColor?: string;
  left?: boolean;
  right?: boolean;
}
const TicketCircle = styled.div<ITicketCircle>`
  position: absolute;
  height: ${ticketCircleDiameter}px;
  width: ${ticketCircleDiameter}px;
  border-radius: 9999rem;
  background-color: ${p => p.bgColor};
  border: 1px solid ${p => p.borderColor};
  top: 50%;
  right: ${p => (p.right ? 0 : 'auto')};
  left: ${p => (p.left ? 0 : 'auto')};
  transform: ${p => (p.right ? 'translate(50%, -50%)' : 'translate(-50%, -50%)')};
`;

const OfferContent = styled.div`
  position: relative;
  z-index: ${Styles.zIndex.below};
  padding: 0 ${ticketCircleDiameter / 2}px;
`;

const OfferContainer = styled.div`
  position: relative;
  min-height: ${ticketCircleDiameter * 3}px;
  margin-bottom: 1rem;
`;

const OfferInner = styled.div`
  padding: 0.5rem 0.5rem 0;
`;

const OfferApplied = styled.p`
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0 0 0.4rem 0;
  letter-spacing: 0.04rem;
`;

const OfferTitle = styled.div`
  > div {
    display: inline-block;
  }
  p {
    font-weight: ${Styles.fontWeight.heavy};
    margin: 0;
    line-height: 1;
    font-size: 0.9rem;
  }
`;

const OfferDescription = styled.span`
  p {
    text-transform: lowercase;
  }
  p::first-letter {
    text-transform: uppercase;
  }
`;

const Invalid = styled.p`
  font-size: 0.8rem;
  color: ${Styles.color.error};
  margin: 0.4rem 0 0 0;
`;

const ControlsContainer = styled.div`
  padding: 0;
  padding-bottom: 0.2rem;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  border: 0;
  font-size: 0.9375rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  text-align: left;

  &[disabled] {
    color: ${Styles.color.grey.four};
    cursor: not-allowed;
  }
`;

interface IOfferProps {
  bgColor?: string;
  fill?: string;
  borderColor?: string;
  selectedOffer?: IOffer | LoyaltyOffer | null;
  isValid?: boolean;
  onRemove?: OnClick;
  textRemove?: string;
  textOfferRequirementsNotMet?: React.ReactNode;
}

const Offer: React.FC<IOfferProps> = ({
  bgColor,
  fill,
  borderColor,
  selectedOffer,
  isValid,
  onRemove,
  textRemove,
  textOfferRequirementsNotMet,
}) => {
  const { formatMessage } = useIntl();
  return (
    <OfferContainer>
      <TicketContainer>
        <TicketSquare fill={fill} borderColor={borderColor} />
        <TicketCircle left bgColor={bgColor} borderColor={borderColor} />
        <TicketCircle right bgColor={bgColor} borderColor={borderColor} />
      </TicketContainer>
      <OfferContent>
        <OfferInner>
          <OfferApplied data-testid="cart-offer-applied">
            {formatMessage({ id: isValid ? 'offerApplied' : 'offerNotApplied' })}
          </OfferApplied>
          <OfferTitle data-testid="cart-offer-title">
            <BlockRenderer content={selectedOffer?.name?.localeRaw} />{' '}
            <OfferDescription>
              <BlockRenderer content={selectedOffer?.description?.localeRaw} />
            </OfferDescription>
          </OfferTitle>
          {!isValid && <Invalid>{textOfferRequirementsNotMet}</Invalid>}
        </OfferInner>
        <ControlsContainer>
          <RemoveButton
            data-testid="cart-offer-remove-button"
            aria-label={`${textRemove} ${selectedOffer?.name} `}
            onClick={onRemove}
          >
            {textRemove}
          </RemoveButton>
        </ControlsContainer>
      </OfferContent>
    </OfferContainer>
  );
};

export default Offer;
