import { IPaymentMethod } from './types';
// @TODO remove after merging cba-797

// In favor of RELOAD_AMOUNTS label/value pair
export const PREPAID_RELOAD_AMOUNTS = [500, 1000, 2500, 5000];

export const DEFAULT_RELOAD_AMOUNT = 500;

export const RELOAD_AMOUNTS = [
  {
    label: '$5',
    value: 500,
  },
  {
    label: '$10',
    value: 1000,
  },
  {
    label: '$25',
    value: 2500,
  },
  {
    label: '$50',
    value: 5000,
  },
];

export const CASH_ACCOUNT_IDENTIFIER = 'CASH';
export const FREE_ORDER_ACCOUNT_IDENTIFIER = 'FREE_ORDER';
export const PAYPAL_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  paypal: true,
  fdAccountId: 'PAYPAL',
  accountIdentifier: 'PAYPAL',
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
  ideal: null,
  sodexo: null,
  chequeGourmet: null,
  transient: true,
};

export const BLIK_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  paypal: null,
  fdAccountId: 'BLIK',
  accountIdentifier: 'BLIK',
  paymentMethodBrand: 'BLIK',
  blik: true,
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
  ideal: null,
  sodexo: null,
  chequeGourmet: null,
};

export const SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  sodexo: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'SODEXO_VOUCHER',
  paymentMethodBrand: 'SODEXO_VOUCHER',
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
  ideal: null,
  paypal: false,
  chequeGourmet: null,
};

export const CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  chequeGourmet: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'CHEQUE_GOURMET_VOUCHER',
  paymentMethodBrand: 'CHEQUE_GOURMET_VOUCHER',
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
  ideal: null,
  paypal: false,
  sodexo: null,
};

export const SODEXO_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  sodexo: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'SODEXO',
  paymentMethodBrand: 'SODEXO',
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
  ideal: null,
  paypal: false,
  chequeGourmet: null,
  transient: true,
};

export const CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  chequeGourmet: true,
  fdAccountId: 'CASH',
  accountIdentifier: 'CHEQUE_GOURMET',
  paymentMethodBrand: 'CHEQUE_GOURMET',
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
  ideal: null,
  paypal: false,
  sodexo: null,
  transient: true,
};
