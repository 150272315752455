import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import LanguageSelectorModal from 'components/language-selector-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

export const LanguageSelectorPage = () => {
  const { formatMessage } = useIntl();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const navigate = useNavigate();

  const showLanguageSelector = !isLocalizationDisabled;

  if (!showLanguageSelector) {
    navigate(routes.base, { replace: true });
  }

  const onDismiss = useCallback(() => {
    navigate(routes.base, { replace: true });
  }, [navigate]);

  return (
    <div>
      <Helmet title={formatMessage({ id: 'languageAndRegion' })}>
        <meta name="robots" content="noindex" />
      </Helmet>
      <LanguageSelectorModal onModalDismiss={onDismiss} />
    </div>
  );
};
