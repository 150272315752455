import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { addMinutes, differenceInMinutes } from 'date-fns';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES } from 'components/bottom-service-mode/hooks/use-bottom-service-details';
import { postOrderTimeout } from 'components/bottom-service-mode/utils';
import { Fab } from 'components/fab';
import { DeliveryStatus } from 'generated/rbi-graphql';
import { useUserOrders } from 'hooks/use-user-orders';
import { DetailsModal } from 'pages/account/account-orders/details-modal';
import { MODAL_TYPE } from 'pages/account/account-orders/types';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { ClickEventComponentNames } from 'state/mParticle/constants';
import { useOrderContext } from 'state/order';
import { isMobile } from 'utils/environment';
import { routes } from 'utils/routing';

const MAX_COMPLETED_TIME_IN_MINUTES = 10;
const MAX_IN_RESTAURANT_REDEEM_TIME_IN_MINUTES = 60;

const Container = styled.div`
  position: relative;
  height: 0px;
`;

const LayoutFabButton = ({ ...rest }) => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuthContext();
  const { numCartPreviewEntries, curbsidePickupOrderId, curbsidePickupOrderTimePlaced } =
    useOrderContext();
  const { inRestaurantRedemptionCart, inRestaurantRedemptionLastModificationDate } =
    useInRestaurantRedemptionContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const enableMainFabButton = useFlag(LaunchDarklyFlag.ENABLE_MAIN_FAB_BUTTON);
  const [detailsModal, setDetailsModal] = useState('');
  const [shouldShowMyCode, setShouldShowMyCode] = useState(false);
  const { loading, orders, refetch } = useUserOrders({ variables: { limit: 1 } });
  const handleDisplayDetailsModal = useCallback(orderId => setDetailsModal(orderId), []);
  const handleOnModalDismiss = useCallback(() => setDetailsModal(''), []);

  // @TODO: Currently we have duplicated logic for recentOrders between FAB Button, Top service Mode and Service Notification
  // We must find a way to avoid this.
  const isCurrentDeliveryOrder = useMemo(() => {
    const deliveryOrder = orders?.[0];
    const orderStatus = deliveryOrder?.delivery?.status;
    const failedOrderStatus = [
      DeliveryStatus.ORDER_CANCELLED,
      DeliveryStatus.ORDER_ABANDONED,
      DeliveryStatus.ORDER_DROPPED_OFF,
    ];

    if (!deliveryOrder || !orderStatus || failedOrderStatus.includes(orderStatus)) {
      return null;
    }
    return deliveryOrder;
  }, [orders]);

  const mParticle = useMParticleContext();
  const handleViewCart = () => {
    mParticle.trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: 'View Cart',
      },
    });
  };
  const handleViewOrder = useCallback(() => {
    if (!loading && isCurrentDeliveryOrder) {
      return navigate(`${routes.orderConfirmation}/${isCurrentDeliveryOrder.rbiOrderId}`);
    }
    if (!loading && orders[0]) {
      handleDisplayDetailsModal(orders[0].rbiOrderId);
    }
  }, [handleDisplayDetailsModal, isCurrentDeliveryOrder, loading, navigate, orders]);
  const isRecentOrder = useCallback((currentOrderTime: string): boolean => {
    const difference = differenceInMinutes(new Date(), new Date(currentOrderTime));
    return difference >= 0 && difference < MAX_COMPLETED_TIME_IN_MINUTES;
  }, []);

  /* We need to add this bc we need to know instantly if we need to show the viewOrder button*/
  useEffect(() => {
    if (pathname === routes.base) {
      refetch();
    }
  }, [pathname, refetch]);

  useEffect(() => {
    const checkMyCodeTimeRange = (lastModDate: Date): boolean => {
      const difference = differenceInMinutes(new Date(), new Date(lastModDate));
      return difference >= 0 && difference < MAX_IN_RESTAURANT_REDEEM_TIME_IN_MINUTES;
    };

    const interval = setInterval(() => {
      const lastModDate =
        inRestaurantRedemptionLastModificationDate ||
        addMinutes(new Date(), -MAX_IN_RESTAURANT_REDEEM_TIME_IN_MINUTES);
      setShouldShowMyCode(checkMyCodeTimeRange(lastModDate));
    }, 2000);
    return () => clearInterval(interval);
  }, [inRestaurantRedemptionLastModificationDate, shouldShowMyCode]);

  const isComboOrPickerPage = pathname.includes('picker') || pathname.includes('combo');
  const isMenuPage = pathname.includes(routes.menu);
  const isItemMenu = pathname.search(/\item\b/) >= 0;

  const shouldHideFabButton =
    !isMobile() ||
    !enableMainFabButton ||
    (isMenuPage && (isComboOrPickerPage || isItemMenu)) ||
    (!isMenuPage && pathname !== routes.base);

  if (shouldHideFabButton) {
    return null;
  }
  const shouldShowViewOrderButton = isRecentOrder((orders[0] as any)?.updatedAt);

  let to;
  let onClick;
  let fabCaption;
  let badgeCount;

  const isPreConfirmCurbside = () =>
    curbsidePickupOrderId &&
    postOrderTimeout(
      curbsidePickupOrderTimePlaced,
      PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
    );

  if (numCartPreviewEntries > 0) {
    fabCaption = formatMessage({ id: 'viewCart' });
    to = routes.cart;
    badgeCount = numCartPreviewEntries;
    onClick = handleViewCart;
    if (pathname !== routes.base && !pathname.includes(routes.menu)) {
      return null;
    }
  } else if (inRestaurantRedemptionCart.length && shouldShowMyCode) {
    fabCaption = formatMessage({ id: 'myCode' });
    to = routes.redeem;
    badgeCount =
      inRestaurantRedemptionCart.reduce((acc, currentValue) => acc + currentValue.quantity, 0) ||
      undefined;
  } else if (isPreConfirmCurbside() && isAuthenticated()) {
    fabCaption = formatMessage({ id: 'iHaveArrived' });
    onClick = () =>
      navigate(`${routes.orderConfirmation}/${curbsidePickupOrderId}`, {
        state: { showToast: true },
      });
  } else if (numCartPreviewEntries < 1 && !shouldShowViewOrderButton) {
    fabCaption = formatMessage({ id: 'startOrder' });
    to = routes.menu;
    if (pathname !== routes.base) {
      return null;
    }
  } else if (isAuthenticated() && shouldShowViewOrderButton) {
    fabCaption = formatMessage({ id: 'viewOrder' });
    onClick = handleViewOrder;
    if (pathname !== routes.base) {
      return null;
    }
  }

  return (
    <Container>
      <Fab caption={fabCaption} badge={badgeCount} to={to} onClick={onClick} {...rest} />
      {detailsModal && (
        <DetailsModal
          rbiOrderId={detailsModal}
          onModalDismiss={handleOnModalDismiss}
          type={MODAL_TYPE.SHOW_DETAILS}
          setDetailsModalId={handleDisplayDetailsModal}
        />
      )}
    </Container>
  );
};

export default LayoutFabButton;
