import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IOffer } from '@rbi-ctg/menu';
import { useUIContext } from 'state/ui';
import { isOfferDiscount } from 'utils/cart/helper';

const MutedText = styled.div`
  text-align: center;
  margin: 0 auto;
  color: ${Styles.color.error};
  font-size: 0.8rem;
  max-width: 15rem;
`;

interface IMinOrderMessage {
  selectedOffer?: IOffer | null;
}

export const MinOrderMessage: React.FC<IMinOrderMessage> = ({
  selectedOffer,
}: IMinOrderMessage) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const discountValue =
    (isOfferDiscount(selectedOffer?.option) && selectedOffer?.option.discountValue) || 0;
  const selectedOfferPrice = discountValue * 100;
  return (
    <MutedText>
      {formatMessage(
        { id: 'minOrderMessage' },
        {
          offerValue: formatCurrencyForLocale(selectedOfferPrice),
        }
      )}
    </MutedText>
  );
};
