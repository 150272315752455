import styled from 'styled-components';

import { StyledDialog as StyledDialogBase } from './actions-modal.styled.default';

export * from './actions-modal.styled.default';

export const StyledDialog = styled(StyledDialogBase)`
  &[data-reach-dialog-content] {
    border-radius: 1.25rem;
    padding-top: 0.5rem;
    text-align: left;
  }

  button {
    width: 100%;
  }
`;
