import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ActionButtonVariants } from 'components/action-button';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import {
  FulfillmentPickupType,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/rbi-graphql';
import { useFeatureLockers } from 'hooks/use-feature-lockers';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';
import { routes } from 'utils/routing';

import {
  ButtonContainer,
  Description,
  LockerCode,
  LockerCodeContainer,
  ModalContent,
  ModalHead,
  ModalInner,
  ModalPicture,
  StyledModal,
  ViewYourOrderButton,
} from './styled';

const POLLING_DURATION_IN_MS = 10000;

const ModalLockersPickup: React.FC = () => {
  const { featureLockersLoading, featureLockers } = useFeatureLockers();
  const [showLockerModal, setShowLockerModal] = useState(false);
  const [hasModalBeenOpened, setHasModalBeenOpened] = useState<boolean | undefined>(undefined);
  const { isAuthenticated } = useAuthContext();
  const { serverOrder } = useOrderContext();
  const navigate = useNavigate();

  const isAuth = isAuthenticated();
  const { data, startPolling, loading, stopPolling } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [RbiOrderStatus.INSERT_SUCCESSFUL],
    },
    skip: !isAuth || featureLockersLoading,
  });

  // We only check the latest order
  const orderCount = data?.userOrders?.count;
  const recentOrder = data?.userOrders?.orders?.[0];
  const { pickupType, pickupReady } = recentOrder?.fulfillmentDetails || {};
  const isReadyToPickup = pickupType === FulfillmentPickupType.LOCKER && !!pickupReady;

  useEffect(() => {
    if (loading || !recentOrder) {
      return;
    }

    startPolling(POLLING_DURATION_IN_MS);

    // This ensures the modal is only shown when the order mutates from not ready to ready
    setHasModalBeenOpened(isReadyToPickup);
  }, [isReadyToPickup, loading, recentOrder, serverOrder, startPolling]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (recentOrder && pickupType === FulfillmentPickupType.LOCKER) {
      if (hasModalBeenOpened === false && isReadyToPickup) {
        setShowLockerModal(true);
      }

      setHasModalBeenOpened(isReadyToPickup);
    }
  }, [hasModalBeenOpened, isReadyToPickup, loading, pickupType, recentOrder, data, stopPolling]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!orderCount || isReadyToPickup || recentOrder?.cart?.serviceMode !== ServiceMode.TAKEOUT) {
      stopPolling();
    }
  }, [data, isReadyToPickup, loading, orderCount, recentOrder, stopPolling]);

  const lockerCode = recentOrder?.fulfillmentDetails?.pickupPin;

  const onModalDismiss = () => {
    setShowLockerModal(false);
    setHasModalBeenOpened(true);
  };

  const onViewOrderDetails = () => {
    if (!recentOrder?.rbiOrderId) {
      return;
    }
    onModalDismiss();
    navigate(`${routes.orderConfirmation}/${recentOrder.rbiOrderId}`);
  };

  if (featureLockersLoading || !showLockerModal) {
    return null;
  }

  return featureLockersLoading ? null : (
    <StyledModal
      onDismiss={onModalDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Lockers Pickup',
      }}
    >
      <ModalInner>
        <ModalHead>
          <ModalPicture image={featureLockers?.lockersModalImage?.locale} alt="" objectFitContain />
          <h2>{featureLockers?.lockersTitle?.locale}</h2>
        </ModalHead>
        <ModalContent>
          <Description>
            <SanityBlockRenderer content={featureLockers?.lockersDescription?.locale} />
          </Description>
          <LockerCodeContainer>
            <LockerCode data-testid="locker-code">{lockerCode}</LockerCode>
            <SanityBlockRenderer content={featureLockers?.lockersCodeTitle?.locale} />
          </LockerCodeContainer>
          <ButtonContainer>
            <ViewYourOrderButton
              variant={ActionButtonVariants.PRIMARY}
              aria-label={featureLockers?.lockersViewOrderButtonText?.locale || ''}
              data-testid="view-your-order-button"
              perceptible
              disabled={!recentOrder?.rbiOrderId}
              onClick={onViewOrderDetails}
            >
              {featureLockers?.lockersViewOrderButtonText?.locale}
            </ViewYourOrderButton>
          </ButtonContainer>
        </ModalContent>
      </ModalInner>
    </StyledModal>
  );
};

const ModalLockersWrapper: React.FC = () => {
  const enableLockersPickup = useFlag(LaunchDarklyFlag.ENABLE_ORDER_READY_LOCKER_CODE_MODAL);
  return enableLockersPickup ? <ModalLockersPickup /> : null;
};

export default ModalLockersWrapper;
