import React, { ReactType, useEffect, useState } from 'react';

/**
 * Delays unmounting of component in order to animate state change
 *
 * @param delayTime time to run animation prior to unmounting.
 * @param Component component to delay unmount.
 * @param isVisible boolean controlling mounting of component.
 *
 * ## Usage
 *
 * Wrap component:
 *
  const DelayedComponent = delayUnmountHOC(Component)

  Pass delayTime and isVisible prop to wrapped component:
 *
  <DelayedComponent onDismiss={hideDrawer} delayTime={500} isVisible={isShowingDrawer}>
 *
 To control animation style, access isVisible prop in styled component
 */

interface IDelayUnmountHOC {
  delayTime: number;
  isVisible: boolean;
  [key: string]: any;
}

export const delayUnmountHOC = (Component: ReactType) => {
  return ({ delayTime, isVisible, ...props }: IDelayUnmountHOC) => {
    const [shouldRender, setShouldRender] = useState(isVisible);

    useEffect(() => {
      if (shouldRender && !isVisible) {
        setTimeout(() => setShouldRender(false), delayTime);
      }
      if (isVisible && !shouldRender) {
        setShouldRender(true);
      }
    }, [delayTime, shouldRender, isVisible]);

    return shouldRender ? <Component isVisible={isVisible} {...props} /> : null;
  };
};
