import React from 'react';

import styled from 'styled-components';

import theme from './theme';
import { IAccountLayout } from './types';

const Container = styled.div<IAccountLayout>`
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: ${({ mobileContainerPadding }) =>
    mobileContainerPadding ? mobileContainerPadding : '1rem 5% 6.25rem'};

  ${Styles.desktop`
    max-width: ${(props: IAccountLayout) => (props.fullWidth ? '100%' : '485px')};
    padding: ${(props: IAccountLayout) =>
      props.desktopContainerPadding ? props.desktopContainerPadding : '75px 0 100px 0'};
  `}
`;

const HeadingContainer = styled.div`
  margin: 1rem 0 2rem;
`;

const Header = styled.h1`
  text-align: center;
  font-family: ${Styles.fontFamily.base};
  text-transform: ${Styles.textTransform.headlines};
  margin: 0;
  line-height: ${theme.headerLineHeight};
`;

const LayoutRoot: React.FC<IAccountLayout> = ({
  children,
  header,
  subHeader = '',
  fullWidth = false,
  mobileContainerPadding,
  className,
  desktopContainerPadding,
}) => (
  <Container
    fullWidth={fullWidth}
    mobileContainerPadding={mobileContainerPadding}
    header={header}
    className={className}
    desktopContainerPadding={desktopContainerPadding}
  >
    {header && (
      <HeadingContainer>
        <Header>{header}</Header>
        {subHeader}
      </HeadingContainer>
    )}
    {children}
  </Container>
);

type Layout = typeof LayoutRoot & { Header: typeof Header };
const Layout = LayoutRoot as Layout;
Layout.Header = Header;

export default Layout;
