import styled from 'styled-components';

import ActionButton from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';

export const StyledLoyaltyPointsIcon = styled(LoyaltyPointsIcon)<{ reversed: boolean }>`
  margin: 0 0.25rem;
  path {
    fill: ${p => p.reversed && p.theme.token('icon-reversed')};
  }
  && {
    height: 1.1rem;
    width: 1.1rem;
    vertical-align: bottom;
  }
`;

export const StyledLoyaltyButton = styled(ActionButton)<{ reversed: boolean }>`
  width: 100%;
  color: ${p => (p.reversed ? p.theme.token('text-reversed') : Styles.color.contrastBackground)};
  border-color: ${p =>
    p.reversed ? p.theme.token('icon-reversed') : Styles.color.contrastBackground};
  background: ${p => p.reversed && 'transparent'};
  padding: 0.375rem 0.725rem;
`;
