import { useGoogleAdSlotsQuery } from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export const useGoogleAdSlots = (ids?: string[]) => {
  const googleAdsEnabled = useFlag(LaunchDarklyFlag.ENABLE_TH_MEDIA_SERVICES);
  const googleAdSlotIds = ids ?? [];
  const { data, loading } = useGoogleAdSlotsQuery({
    variables: {
      where: {
        _id_in: googleAdSlotIds,
      },
    },
    skip: !googleAdSlotIds?.length || !googleAdsEnabled,
  });

  const googleAdSlots = data?.allGoogleAdSlots;

  return {
    googleAdSlots,
    googleAdSlotsLoading: loading,
  };
};
