import isNumber from 'lodash/isNumber';

import {
  MAX_IN_RESTAURANT_CART_QUANTITY,
  MAX_REWARDS_PER_CART,
  MIN_IN_RESTAURANT_CART_QUANTITY,
} from './constants';
import type { ICart, ICartEntry } from './incentive-cart';
import { ICartEntryType } from './incentive-cart';

export const isStackableOffer = (cartEntry: ICartEntry): boolean =>
  cartEntry.type === ICartEntryType.OFFER && !!cartEntry.details.offer.isStackable;

export const isCartEntryQuantityValid = (quantity: unknown) =>
  isNumber(quantity) &&
  quantity <= MAX_IN_RESTAURANT_CART_QUANTITY &&
  quantity >= MIN_IN_RESTAURANT_CART_QUANTITY;

export const canStackReward = (
  cart: ICart,
  rewardLimitePerOrder?: number | null | undefined
): boolean => {
  const maxRewardsPerOrder = rewardLimitePerOrder || MAX_REWARDS_PER_CART;

  // If no max is defined, rewards can be stacked
  if (!maxRewardsPerOrder) {
    return true;
  }

  return cart.filter(entry => entry.type === ICartEntryType.REWARD).length < maxRewardsPerOrder;
};
