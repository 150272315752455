import { Auth } from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { CognitoUserPool, CognitoUserSession, ICognitoStorage } from 'amazon-cognito-identity-js';

import { getCurrentSession } from 'remote/auth';

import { getConfigValue } from '../environment';

import storageSync from './storage';

export const initCognitoSession = async (): Promise<CognitoUserSession | null> => {
  Cognito.configure();
  return getCurrentSession();
};

/**
 * @class
 * The cognito class provides helper methods for configuring and interacting
 * with cognito services
 */
export class Cognito {
  public static storage: ICognitoStorage = storageSync as ICognitoStorage;

  private static userPoolEndpoint() {
    const region = getConfigValue('aws').region;
    return `https://cognito-idp.${region}.amazonaws.com/`;
  }

  public static userPool() {
    const { userPoolClientId, userPoolId } = getConfigValue('aws');

    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: userPoolClientId,
      endpoint: Cognito.userPoolEndpoint(),
      Storage: storageSync as ICognitoStorage,
    });
  }

  public static configure() {
    const { userPoolClientId, userPoolId } = getConfigValue('aws');
    Auth.configure({
      userPoolId,
      userPoolWebClientId: userPoolClientId,
      endpoint: Cognito.userPoolEndpoint(),
      storage: storageSync,
    });

    Amplify.configure({ disabled: true });
  }
}

export default Cognito;
