import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import { usePrevious } from 'hooks/use-previous';
import { useAuthContext } from 'state/auth';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { brand as brandShort, env, fullBrandName, getConfigValue } from 'utils/environment';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { routes } from 'utils/routing';

declare global {
  interface Window {
    Pypestream?: any;
  }
}

export const PYPESTREAM_SCRIPT_ID = 'pypestream-script';
export const PYPESTREAM_INTERFACE_ID = 'pypestream-interface';
const stage = env();

const defaultContext = {
  show: () => {},
  hide: () => {},
  declareContainerReady: () => {},
  injectScript: () => {},
  visible: false,
  loading: true,
  loadingMessage: '',
};

const PypestreamContext = createContext(defaultContext);

export const usePypestreamContext = () => useContext(PypestreamContext);

const { scriptUrl, appId } = getConfigValue('pypestream');

const brand = fullBrandName();
const shortBrand = brandShort();

export const PypestreamProvider = ({ children }: IBaseProps) => {
  const { location } = useLocationContext();

  const [visible, setVisible] = useState(false);
  const [containerReady, setContainerReady] = useState(false);
  const [scriptReady, setScriptReady] = useState(false);
  const [hasBootedOnce, setHasBootedOnce] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { formatMessage } = useIntl();

  const { user, loading: authLoading } = useAuthContext();
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const authReady = !authLoading;
  const cognitoId = user?.cognitoId ?? '';
  const previousCognitoId = usePrevious(cognitoId);
  const cognitoIdHasChanged = cognitoId !== previousCognitoId;
  const forterDeviceId = withForterHeaders?.deviceId ?? '';
  const forterToken = withForterHeaders?.ttiForterToken ?? '';
  const loading = location.pathname?.startsWith(routes.support) && !mounted;

  const name = user?.details?.name ?? '';

  const welcomeMessage = formatMessage(
    { id: `pypestreamWelcome${name ? 'User' : 'Anon'}` },
    { brand, name }
  );
  const loadingMessage = formatMessage({ id: `${shortBrand}.pypestreamLoadingMessage` });

  const passthru = [
    { attribute: 'stage', label: 'stage', value: stage, visible: 'wpm' },
    { attribute: 'forterDeviceId', label: 'forterDeviceId', value: forterDeviceId, visible: 'wpm' },
    { attribute: 'forterToken', label: 'forterToken', value: forterToken, visible: 'wpm' },
    { attribute: 'userAgent', label: 'userAgent', value: navigator.userAgent, visible: 'wpm' },
  ];
  const declareContainerReady = useCallback(() => setContainerReady(true), []);

  const shouldBoot = authReady && scriptReady && containerReady;

  const show = useCallback(() => {
    setVisible(true);
    window?.Pypestream?.show();
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
    window?.Pypestream?.hide();
  }, []);

  const injectScript = useCallback(() => {
    if (!document.getElementById(PYPESTREAM_SCRIPT_ID) && enablePypestream) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${scriptUrl}?ts=${Date.now()}`;
      script.async = true;
      script.id = PYPESTREAM_SCRIPT_ID;
      script.dataset.testid = PYPESTREAM_SCRIPT_ID;
      script.onload = () => setScriptReady(true);
      document.body.appendChild(script);
    }
  }, [enablePypestream]);

  useEffect(() => {
    if (shouldBoot && !hasBootedOnce) {
      window?.Pypestream?.boot(
        {
          APP_ID: appId,
          disableWidgetSlideInOutAnimation: true,
          initialMessages: {
            isPersistent: true,
            showNextThinkingBubble: true,
            welcomeMessage,
          },
          language: 'en',
          passthrough: JSON.stringify(passthru),
          play_chime: false,
          token: cognitoId,
          withInlineAppLoader: false,
        },
        document.getElementById(PYPESTREAM_INTERFACE_ID)
      );
      setHasBootedOnce(true);
      window?.Pypestream?.onMount(() => {
        setMounted(true);
      });
    }
  }, [cognitoId, hasBootedOnce, passthru, shouldBoot, welcomeMessage]);

  useEffect(() => {
    if (hasBootedOnce && cognitoIdHasChanged) {
      // end the current chat
      window?.Pypestream?.shutdown();
      // then start a new one for the new user
      window?.Pypestream?.boot(
        {
          APP_ID: appId,
          disableWidgetSlideInOutAnimation: true,
          initialMessages: {
            isPersistent: true,
            showNextThinkingBubble: true,
            welcomeMessage,
          },
          language: 'en',
          passthrough: JSON.stringify(passthru),
          play_chime: false,
          token: cognitoId,
          withInlineAppLoader: false,
        },
        document.getElementById(PYPESTREAM_INTERFACE_ID)
      );
    }
  }, [cognitoId, cognitoIdHasChanged, hasBootedOnce, passthru, welcomeMessage]);

  return (
    <PypestreamContext.Provider
      value={{ show, hide, visible, declareContainerReady, injectScript, loading, loadingMessage }}
    >
      {children}
    </PypestreamContext.Provider>
  );
};
