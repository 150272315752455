import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconYoutube = props => (
  <svg viewBox="0 0 16 12" {...props}>
    <title lang="en">Follow us on YouTube</title>
    <path
      d="M15.84 2.495S16 3.827 16 5.158v1.248c0 1.33-.16 2.662-.16 2.662s-.157 1.133-.636 1.632c-.608.655-1.29.658-1.603.697-2.239.167-5.601.172-5.601.172s-4.16-.04-5.44-.166c-.356-.068-1.155-.048-1.765-.703C.316 10.201.16 9.068.16 9.068S0 7.737 0 6.406V5.158c0-1.331.16-2.663.16-2.663S.316 1.362.795.863C1.405.208 2.086.205 2.399.167 4.638 0 7.997 0 7.997 0h.007s3.358 0 5.597.167c.313.038.995.04 1.603.696.48.5.636 1.632.636 1.632zM6.348 7.918l4.322-2.303-4.323-2.319.001 4.622z"
      fillRule="nonzero"
    />
  </svg>
);

export default IconYoutube;
