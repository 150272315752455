import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import noop from 'utils/noop';

import { applePayCardDetails } from './apple-pay-payment-details';

export const applePayStub = {
  requestingPayment: false,
  requestApplePayPayment: noop as any,
  supportsCardNetwork: false,
  applePayCardDetails,
  formatApplePayCartPayload: noop as any,
};

// eslint-disable-next-line
export default function useApplePay(arg?: any) {
  const enableTestApplePayUi = useFlag(LaunchDarklyFlag.ENABLE_TEST_APPLE_PAY_WEB);

  const canMakeApplePayPayments = () => Promise.resolve(enableTestApplePayUi);

  return {
    ...applePayStub,
    canMakeApplePayPayments,
    appleDeviceCanMakePayments: canMakeApplePayPayments,
    canUseApplePay: enableTestApplePayUi,
    supportsCardNetwork: enableTestApplePayUi,
  };
}
