import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import { AllowedEvent, IGlobalAttributes } from '@rbilabs/intl-mparticle-client';
import { chunk, compact, isEmpty, isEqual, isNil, merge } from 'lodash';
import { centsToDollars } from 'utils';
import uuidv4 from 'uuid/v4';

import { IBackendCartEntries, ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IUserOffersFeedbackEntry } from '@rbi-ctg/offers';
import useEffectOnce from 'hooks/use-effect-once';
import { useHasAcceptedCookies } from 'hooks/use-has-accepted-cookies';
import useMediaQuery, { MediaQuery } from 'hooks/use-media-query';
import useReadyQueue from 'hooks/use-ready-queue';
import { useSalesForceInApp } from 'hooks/use-salesforce-in-app';
import { getRoundUpDonations } from 'pages/cart/your-cart/totals/utils';
import { HttpErrorCodes } from 'remote/constants';
import { IStaticPageRoute } from 'remote/queries/static-page';
import useBraze from 'state/braze/hooks/use-braze';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import {
  SignUpFieldsVariations,
  defaultSignUpFieldsVariation,
} from 'state/launchdarkly/variations';
import { useLoggerContext } from 'state/logger/context';
import { LAYER_ATTRIBUTE_DEFAULT_VALUE } from 'state/mParticle/constants';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { getCurrentVersion as getCurrentAppflowVersion } from 'utils/appflow';
import { CartEntryType } from 'utils/cart/types';
import { ENABLE_IDENTITY_SYNC_ON_LOGIN } from 'utils/constants';
import { StatusType, addContext as addLoggerContext, dataDogLogger } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import {
  RBIBrand,
  appVersionCode,
  brand,
  env,
  getCountry,
  isNative,
  isTest,
} from 'utils/environment';
import LaunchDarklyHelper from 'utils/launchdarkly';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import noop from 'utils/noop';
import { isOTPEnabled } from 'utils/otp';
import { getInCodeLocalizedRouteForPath, routes } from 'utils/routing';

import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT,
  ONE_INDEX_OFFSET,
  ProductActionTypes,
  SignInPhases,
  TRACKED_PAGES,
} from './constants';
import { createMparticleIdentity } from './create-mparticle-identity';
import initMParticle from './init';
import { MParticleAdapter } from './mparticle-adapter';
import { updateMParticleATTStatus } from './mparticle-att-plugin';
import { getSessionId } from './session-manager';
import setUserAttributes, {
  setUserUTMAttributes,
  updateLocationPermissionStatus,
} from './set-user-attributes';
import * as I from './types';
import { useFlagsForUniversalAttributes } from './use-flags-for-universal-attributes';
import {
  booleanToString,
  determinePlatformFromNavigator,
  flattenCartEntryItems,
  normalizeBooleans,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
} from './utils';

// Export action from index
export { CustomEventNames, EventTypes } from './constants';
export type { ILogEvent, IMParticleCtx, IAppflowUpdateEventOptions } from './types';

declare global {
  interface Window {
    mParticle: I.IMParticle;
  }
}

type ExcludesNull = <T>(x: T | null) => x is T;

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

interface IAppflowEventAttributes {
  'Displayed Update UI'?: boolean;
  Response: 'Successful' | 'Failure';
  updateVersion?: string;
  totalTimeMs?: number;
}

declare interface ILogPageView {
  pathname: string;
  normalizedAndSanitizedAttrs: any;
  normalizedFlags: any;
}

enum ProductItemType {
  Parent = 'Parent',
  Child = 'Child',
}

// returns window location path name
const getSourcePage = () => {
  const pathName = window.location.pathname;
  // removes menu child routes
  const parsedPathName = pathName.replace(/(menu)\/(.*)/i, '$1/');
  return parsedPathName;
};

const getBrowserType = (): string => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else if (userAgent.match(/android/i)) {
    browserName = 'Android';
  } else if (userAgent.match(/iphone/i)) {
    browserName = 'iPhone';
  } else {
    browserName = 'Unknown';
  }
  return browserName as string;
};

const getBrowserVersion = (): string => {
  const userAgent = navigator.userAgent;
  const browserType = getBrowserType();
  const browserSentence = userAgent.substring(userAgent.indexOf(browserType));
  const containsSpace = /\s/.test(browserSentence);
  const indexFirstNumber = browserSentence.search(/\d/);
  const indexFirstSpace = browserSentence.search(/\s/);

  const isAndroid = userAgent.match(/([A-Za-z0-9]+(\.[A-Za-z0-9]+)+);/);
  const isIoS = userAgent.match(/([A-Za-z0-9]+(_[A-Za-z0-9]+)+)/);
  // returns a Semver of the browser's current version, such as 1.2.3
  if (determinePlatformFromNavigator() === 'iOS') {
    const browserVersion = isIoS && isIoS[0];
    return browserVersion as string;
  } else if (determinePlatformFromNavigator() === 'Android') {
    const browserVersion = isAndroid && isAndroid[0];
    return browserVersion as string;
  } else if (containsSpace) {
    const browserVersion = browserSentence.substring(indexFirstNumber, indexFirstSpace);
    return browserVersion as string;
  } else {
    const browserVersion = browserSentence.substring(indexFirstNumber);
    return browserVersion as string;
  }
};

const getIsMobileWeb = (): string => {
  const userAgent = navigator.userAgent;

  if (determinePlatformFromNavigator() === 'Web') {
    return 'FALSE' as string;
  } else if (
    userAgent.match(/chrome|chromium|crios/i) ||
    userAgent.match(/firefox|fxios/i) ||
    userAgent.match(/safari/i) ||
    userAgent.match(/opr\//i) ||
    userAgent.match(/edg/i)
  ) {
    return 'TRUE' as string;
  }
  return 'FALSE';
};

export const MParticleContext = React.createContext<I.IMParticleCtx>({
  init: noop,
  login: noop,
  logout: noop,
  deviceId: 'fakeid',
  sessionId: 'fakeid',
  signInEvent: noop,
  signOutEvent: noop,
  signUpEvent: noop,
  signUpTermToggleEvent: noop,
  autoSignInEvent: noop,
  updateUniversalAttributes: noop,
  updateStaticRoutes: noop,
  updateUserAttributes: noop,
  updateUserLocationPermissionStatus: noop,
  updateUserIdentities: noop,
  logPageView: noop,
  logCommercePageView: noop,
  addToCart: noop,
  updateItemInCart: noop,
  removeFromCart: noop,
  logOrderLatencyEvent: noop,
  logPurchase: noop,
  logCustomerInitiatedRefund: noop,
  logError: noop,
  logEvent: noop,
  trackEvent: noop,
  logNavigationClick: noop,
  logAddPaymentMethodClick: noop,
  logCashVoucherMethodClick: noop,
  selectServiceMode: noop,
  appflowUpdateEvent: noop,
  logOfferActivatedEvent: noop,
  logCheckoutEvent: noop,
  logUpsellAddedEvent: noop,
  logUpsellRemovedEvent: noop,
  logNavBarClickEvent: noop,
  marketingTileClickEvent: noop,
});

export const useMParticleContext = () => useContext<I.IMParticleCtx>(MParticleContext);

export function MParticleProvider(props: { children: ReactNode }) {
  const logFlagsEvaluatedEvent = useRef(false);
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
  const { logger } = useLoggerContext();
  const { locale, language, region } = useLocale();
  const hasAcceptedCookies = useHasAcceptedCookies();
  const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const enableSignUpInBE = useFlag(LaunchDarklyFlag.ENABLE_COGNITO_SIGNUP_IN_BE);
  const isUpsellSimplified = useFlag(LaunchDarklyFlag.ENABLE_PRODUCT_UPSELL_SIMPLIFIED);
  const flagsForUniversalAttributes = useFlagsForUniversalAttributes();
  const [sessionId, setSessionId] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  const prevFlags = useRef({});
  const { flags: rawLdFlags, updateUserDeviceId } = useLDContext();
  const [isNewSignUp, setIsNewSignUp] = useState(false);
  const { initBraze } = useBraze();
  const signUpFieldsVariations =
    useFlag<SignUpFieldsVariations>(LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS) ||
    defaultSignUpFieldsVariation;
  let additionalSignupFields = {} as SignUpFieldsVariations;

  const { handleDispatchEvent } = useSalesForceInApp();

  if (brand() === RBIBrand.PLK && getCountry()?.toUpperCase() === IsoCountryCode2Char.KR) {
    additionalSignupFields.gender = signUpFieldsVariations.gender;
    additionalSignupFields.ageFourteen = signUpFieldsVariations.ageFourteen;
    additionalSignupFields.additionalSignUpTerms = signUpFieldsVariations.additionalSignUpTerms;
  }

  const isSmallScreen = useMediaQuery(MediaQuery.Mobile);

  // store static routes for logging page views
  const staticRoutes = useRef<string[]>([]);
  // Its possible to attempt to log a page view, before sanity loads the static pages
  // This ref holds these values until static pages has loaded, after which the page view can be logged
  const logPageViewParameters = useRef<ILogPageView>();

  const getUserHasLoyalty = () => (LocalStorage.getItem(Keys.USER)?.loyaltyId ? true : false);
  // store universal attributes for mParticle event/page views without re-rendering for changes in values
  const universalAttributes = useRef<I.IMParticleUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    'Source Page': getSourcePage(),
    browserType: getBrowserType(),
    browserVersion: getBrowserVersion(),
    isMobileWeb: getIsMobileWeb(),
    isLoyaltyUser: getUserHasLoyalty(),
    isSmallScreen,
    currentBuild: '',
    ...flagsForUniversalAttributes,
    ...additionalSignupFields,
    layer: LAYER_ATTRIBUTE_DEFAULT_VALUE,
  });
  // set up a backup unique session id in case ad blockers block mParticle
  const uniqueGuid = useRef<string>(uuidv4());

  const updateUserLocationPermissionStatus = () => {
    if (!window?.mParticle?.Identity?.getCurrentUser) {
      return;
    }
    const user = window.mParticle.Identity.getCurrentUser();
    if (!user) {
      return;
    }
    updateLocationPermissionStatus(user);
  };

  const updateStaticRoutes = (newStaticRoutes: IStaticPageRoute[]) => {
    staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
      const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
      if (staticPath) {
        acc.push(`/${staticPath}`);
      }
      return acc;
    }, []);

    if (staticRoutes.current.length && logPageViewParameters.current) {
      maybeTrackPage({ ...logPageViewParameters.current });
      logPageViewParameters.current = undefined;
    }
  };

  const configureSessionId = enqueueIfNotDrained(() =>
    getSessionId()
      .then(({ sessionId: currentSessionUUID }) => {
        const currentSessionId = currentSessionUUID || uniqueGuid.current;

        setSessionId(currentSessionId);
        addLoggerContext('session', currentSessionId);
      })
      .catch(({ error, message }) => {
        // This happens when the web does not have a sessionId yet
        // for iOS to use. We won't bother making it an error
        // since there is nothing for us to do
        if (message === 'No session ID available') {
          logger.warn(message);

          return;
        }

        dataDogLogger({
          message: `Failed to get MParticle SessionID: ${error || message}`,
          status: StatusType.error,
        });
      })
  );

  const updateDeviceId = enqueueIfNotDrained(async () => {
    const id = await getDeviceId();
    updateUserDeviceId(id);
    setDeviceId(id);
  });

  // Log app initialization, bypassing `logEvent` since we are seeing some potential issues with it
  const logAppInit = enqueueIfNotDrained(() => {
    if (isTest) {
      return;
    }
    const allAttributes = {
      ...universalAttributes.current,
      'Device Time': deviceTime(),
      Locale: locale,
      Platform: determinePlatformFromNavigator(),
      RBIEnv: env(),
    };
    MParticleAdapter.logEvent(CustomEventNames.APP_INITIALIZED, EventTypes.Other, allAttributes);
  });

  // initializes mParticle
  const init = useCallback(() => {
    initMParticle(() => {
      const user = window.mParticle?.Identity?.getCurrentUser();
      setUserUTMAttributes(user, new URLSearchParams(window.location.search));
      logAppInit();
      updateUserLocationPermissionStatus();
      drainQueue();
      initBraze();
    });
  }, [drainQueue, initBraze, logAppInit]);

  useEffectOnce(() => {
    // Enqueue setting the sessionId and deviceId to prevent race condition
    configureSessionId();
    updateMParticleATTStatus();
    updateDeviceId();
    if (!enableCookieBanner || hasAcceptedCookies) {
      init();
    }
  });

  //update a user attribute(consent would be a good place to start)

  //Consent mgmt is it's own thing.
  //https://docs.mparticle.com/developers/sdk/web/consent-management/

  const logError = enqueueIfNotDrained(
    (
      error: { name: string; message?: string; stack?: string },
      attrs?: { [key: string]: string | number | boolean }
    ) => {
      const normalizedAttrs = attrs ? normalizeBooleans(attrs) : {};
      window.mParticle.logError(
        {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
        {
          ...normalizedAttrs,
          RBIEnv: env(),
        }
      );
    }
  );

  const errorIdentityCallback = ({
    identityFn,
    callback,
    result,
    tryAgain = true,
    params,
  }: {
    identityFn: any;
    callback: any;
    result: any;
    tryAgain?: boolean;
    params?: any;
  }) => {
    switch (result.httpCode) {
      case I.HTTPCode.NATIVE_IDENTITY_REQUEST:
        return;
      case I.HTTPCode.NO_HTTP_COVERAGE:
        if (tryAgain) {
          return identityFn(params, { callback, tryAgain });
        }
        break;
      case I.HTTPCode.ACTIVE_IDENTITY_REQUEST:
      case HttpErrorCodes.TooManyRequests:
        if (tryAgain) {
          return identityFn(params, { callback, tryAgain: false });
        }
        break;
      case I.HTTPCode.VALIDATION_ISSUE:
      case 400:
      default:
        logger.error({ error: result.body });
    }
  };

  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<I.IMParticleUniversalAttributes>) =>
      (universalAttributes.current = { ...universalAttributes.current, ...newAttributes }),
    []
  );

  const deviceTime = useCallback(() => new Date().toLocaleTimeString([], { hour12: false }), []);

  const {
    chefRecommendationEngine2,
    chefUpsellItemCount,
    enableCheckoutUpsellItems2,
    enableHomePageRecentItems,
    enableOffersRefresh,
    enableRecentItemsAddToCart,
    enableRecentlyOrderedItems,
    enableServiceModeCartSelection,
    enableStoreConfirmationModal,
    enableUserSavedDeliveryAddressPhone,
    enableFeatureHomePage,
    enableRecentItemsWithModifiers,
  } = flagsForUniversalAttributes;

  // If any of these flag values change
  // updateUniversalAttributes so they are updated
  // for the next mParticle event
  useEffect(() => {
    const getCurrentAppflowVersionAndSetAttributes = async () => {
      const isLoyaltyUser = getUserHasLoyalty();
      const currentBuild =
        (await getCurrentAppflowVersion().then(version => version?.buildId)) || appVersionCode();
      updateUniversalAttributes({
        chefRecommendationEngine2,
        chefUpsellItemCount,
        currentBuild,
        enableCheckoutUpsellItems2,
        enableHomePageRecentItems,
        enableOffersRefresh,
        enableRecentItemsAddToCart,
        enableRecentlyOrderedItems,
        enableServiceModeCartSelection,
        enableStoreConfirmationModal,
        enableUserSavedDeliveryAddressPhone,
        enableFeatureHomePage,
        enableRecentItemsWithModifiers,
        isLoyaltyUser,
        isSmallScreen,
      });
    };

    getCurrentAppflowVersionAndSetAttributes().catch(error => logger.error(error));
  }, [
    chefRecommendationEngine2,
    chefUpsellItemCount,
    enableCheckoutUpsellItems2,
    enableHomePageRecentItems,
    enableOffersRefresh,
    enableRecentItemsAddToCart,
    enableRecentlyOrderedItems,
    enableServiceModeCartSelection,
    enableStoreConfirmationModal,
    enableUserSavedDeliveryAddressPhone,
    enableFeatureHomePage,
    enableRecentItemsWithModifiers,
    updateUniversalAttributes,
    logger,
    isSmallScreen,
  ]);

  const getAllowedAttributes = (event: AllowedEvent) => {
    const allowedAttributes = [
      // from IPageView
      'path',
      'referrer',
      'restaurantId',
      'storeId',
      // from IClickEvent
      'component',
      'text',
      'componentId',
    ];
    if (!event.attributes) {
      return {};
    }
    return Object.fromEntries(
      Object.entries(event.attributes).filter(([key]) => allowedAttributes.includes(key))
    );
  };

  /**
   * Tracks an event to mParticle using the RBI events interface from @rbilabs/intl-mparticle-client.
   */
  const trackEvent: I.ItrackEvent = enqueueIfNotDrained((event: AllowedEvent) => {
    let universalAttrs = sanitizeValues(universalAttributes.current);
    universalAttrs = normalizeBooleans(universalAttrs);

    let customerId = '';
    const user = window.mParticle.Identity.getCurrentUser();
    const loggedInUser = !!LocalStorage.getItem(Keys.USER);
    if (user) {
      const userIdentity = user.getUserIdentities();
      if (userIdentity && Object.keys(userIdentity.userIdentities).length > 0) {
        customerId = userIdentity.userIdentities.customerid;
      }
    }

    const globalAttributes: IGlobalAttributes = {
      currentScreen: getSourcePage(),
      deviceTime: deviceTime(),
      serviceMode: universalAttrs['Service Mode'],
      pickupMode: universalAttrs['Pickup Mode'],
      appBuild: universalAttrs.currentBuild,
      browserType: universalAttrs.browserType,
      browserVersion: universalAttrs.browserVersion,
      isMobileWeb: universalAttrs.isMobileWeb,
    };

    MParticleAdapter.logEvent(
      event.name,
      event.type,
      {
        ...universalAttrs,
        ...globalAttributes,
        ...event.globalAttributes,
        ...event.attributes,
        'Customer ID': customerId,
        'Device Time': deviceTime(),
        'Has User ID': loggedInUser,
        Locale: locale,
        Platform: determinePlatformFromNavigator(),
        RBIEnv: env(),
      },
      event.customFlags
    );

    dataDogLogger({
      message: 'mParticle event',
      status: StatusType.info,
      context: {
        event_type: EventTypes[event.type],
        from_mparticle: 1,
        event_name: event.name,
        event_attributes: getAllowedAttributes(event),
      },
    });
  });

  const logEvent = useCallback(
    enqueueIfNotDrained(
      (eventName: CustomEventNames, eventType: EventTypes, attrs = {}, customFlags = {}) => {
        if (!window?.mParticle) {
          return;
        }

        let customerId = '';
        const user = window.mParticle.Identity.getCurrentUser();
        const loggedInUser = !!LocalStorage.getItem(Keys.USER);
        if (user) {
          const userIdentity = user.getUserIdentities();
          if (userIdentity && Object.keys(userIdentity.userIdentities).length > 0) {
            customerId = userIdentity.userIdentities.customerid;
          }
        }

        const logEventWithAttributes = (eventAttrs = {}) => {
          const attributes = {
            ...universalAttributes.current,
            ...eventAttrs,
            'Customer ID': customerId,
            'Device Time': deviceTime(),
            'Has User ID': loggedInUser,
            Locale: locale,
            Platform: determinePlatformFromNavigator(),
            RBIEnv: env(),
          };

          const sanitizedAttributes = sanitizeValues(attributes);
          const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
          const normalizedFlags = normalizeBooleans(customFlags);

          const allKeys = Object.keys(normalizedAndSanitizedAttrs);
          let attributeBatches = [normalizedAndSanitizedAttrs];
          if (allKeys.length > LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT) {
            // Break the keys/values into chunks e.g.
            // [{'key': 'value'}], [{'key_2': 'value'}];
            const arrayFromObject = Object.entries(normalizedAndSanitizedAttrs).map(
              ([key, value]) => ({ [key]: value })
            );
            const arrayOfObjectArrays = chunk(
              arrayFromObject,
              LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT
            );
            attributeBatches = arrayOfObjectArrays.map(arr =>
              // reduce the arrays to an object e.g.
              // [{'key': 'value', 'key_2': 'value'}];
              arr.reduce((acc, cur) => ({ ...acc, ...cur }), {})
            );
          }

          // LogEvents for each of the attributes
          attributeBatches.forEach(batch => {
            MParticleAdapter.logEvent(eventName, eventType, batch, normalizedFlags);
          });
        };
        logEventWithAttributes({ ...attrs });
      }
    ),
    []
  );

  // logs FLAGS_EVALUATED event
  useEffect(() => {
    // logs event if LD flags are updated
    LaunchDarklyHelper.getInstance()
      .evaluateFlagVariants()
      .then(ldFlags => {
        const hasNotYetLoggedFlags = !logFlagsEvaluatedEvent.current;
        const hasFlags = !isEmpty(ldFlags);
        const hasNewFlags = !isEqual(prevFlags.current, ldFlags);
        const cachedUser = LocalStorage.getItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES) || undefined;
        prevFlags.current = ldFlags;

        // Bail out early if are already logged the flags.
        // Bail out if we don't have flags.
        if (hasFlags && (hasNotYetLoggedFlags || hasNewFlags)) {
          logFlagsEvaluatedEvent.current = true;

          // Trigger Flags Evaluated events no earlier than the following conditions met:
          // 1) LaunchDarkly API has been initiated (launchDarklyAttributes != undefined)
          // 2) LaunchDarkly has evaluated the current user (so, user device_id has been written in launchDarklyAttributes)
          if (cachedUser && cachedUser?.custom?.device_id) {
            logEvent(CustomEventNames.FLAGS_EVALUATED, EventTypes.Other, ldFlags);
          }
        }
      })
      .catch(noop);
  }, [rawLdFlags, logEvent]);

  //USER EVENTS
  //https://docs.mparticle.com/developers/sdk/web/idsync/

  const login = enqueueIfNotDrained(
    ({ email, customerid, ...userAttributes } = {}, { callback = noop, tryAgain = true } = {}) => {
      window.mParticle.Identity.login({ userIdentities: { email, customerid } }, result => {
        const user = result.getUser();

        if (!user.isLoggedIn() && !isNative) {
          return errorIdentityCallback({
            identityFn: login,
            callback,
            result,
            tryAgain,
            params: {
              email,
              customerid,
              ...userAttributes,
            },
          });
        }

        const normalizedAttrs = normalizeBooleans(userAttributes);
        setUserAttributes(user, normalizedAttrs, logEvent);

        if (ENABLE_IDENTITY_SYNC_ON_LOGIN) {
          updateUserIdentities({ email, customerid });
        }

        // fire log sign up flow successful after successful mparticle login
        if (isNewSignUp) {
          trackEvent({
            name: 'Sign Up Flow Successful',
            type: EventTypes.Other,
            attributes: {
              signUpType: 'Email',
            },
          });
        }

        configureSessionId();
        callback(result);
      });
    }
  );

  const updateUserAttributes = enqueueIfNotDrained(
    (userAttributes = {}, { callback = noop } = {}) => {
      const user = window.mParticle.Identity.getCurrentUser();

      const normalizedAttrs = normalizeBooleans(userAttributes);
      setUserAttributes(user, normalizedAttrs, logEvent);

      callback();
    }
  );

  const updateUserIdentities: any = enqueueIfNotDrained(
    ({ email, customerid, ccToken } = {}, { callback = noop, tryAgain = true } = {}) => {
      const user = window.mParticle.Identity.getCurrentUser();
      const currentUser = user.getUserIdentities().userIdentities;
      // We should not set customerid to null ever. Will use existing customer id if data
      // being passed in is null
      const newCustomerId = customerid ? customerid : currentUser.customerid;
      const updatedUserIdentities = merge(
        {},
        currentUser,
        createMparticleIdentity(email, newCustomerId, ccToken)
      );

      // values are unchanged
      if (isEqual(currentUser, updatedUserIdentities)) {
        return;
      }
      return MParticleAdapter.Identity.modify({ userIdentities: updatedUserIdentities }, result => {
        if (result.httpCode !== 200) {
          errorIdentityCallback({
            identityFn: updateUserIdentities,
            callback,
            result,
            tryAgain,
            params: {
              email,
              customerid,
              ccToken,
            },
          });
        }
      });
    }
  );

  // make call to idenitify with empty userIdentities to retrieve anonymous user by device
  // We do this instead of creating a new one on logout so that we don't overstate Monthly users
  const emptyUserIdentityRequest = { userIdentities: {} };

  const logout = enqueueIfNotDrained(({ callback = noop, tryAgain = true } = {}) => {
    window.mParticle.Identity.identify(emptyUserIdentityRequest, (result: any) => {
      if (result.httpCode !== I.HTTPCode.SUCCESS) {
        return errorIdentityCallback({
          identityFn: logout,
          callback,
          tryAgain,
          result,
        });
      }

      callback();
    });
  });

  const appflowUpdateEvent = ({
    isBlockingUpdate,
    phase,
    success,
    updateVersion,
    totalTimeMs,
  }: I.IAppflowUpdateEventOptions) => {
    const event: CustomEventNames =
      {
        started: CustomEventNames.APPFLOW_UPDATE_STARTED,
        downloaded: CustomEventNames.APPFLOW_UPDATE_DOWNLOADED,
        extracted: CustomEventNames.APPFLOW_UPDATE_EXTRACTED,
        applied: CustomEventNames.APPFLOW_UPDATE_APPLIED,
      }[phase] || CustomEventNames.APPFLOW_UPDATE_UNKNOWN;

    const attributes: IAppflowEventAttributes = {
      Response: success ? 'Successful' : 'Failure',
      updateVersion,
      totalTimeMs,
    };

    if (!isNil(isBlockingUpdate)) {
      attributes[APPFLOW_DISPLAYED_BLOCKING_UI] = isBlockingUpdate;
    }

    // the attributes of type IAppflowUpdateEventOptions are already boolean-normalized
    logEvent(event, EventTypes.Other, attributes);
  };

  const signInEvent = ({
    phase,
    success,
    message,
    otpMethod,
    method,
    providerType,
  }: I.ISignInEventOptions) => {
    let event;
    const data = {
      Name: !success ? CustomEventNames.SIGN_IN_FAILED : undefined,
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
      Method: method,
      'Social Service': method === I.SignInMethods.SOCIAL ? providerType : undefined,
      Biometrics: undefined, // To be implemented later
    };

    if (isOTPEnabled(otpMethod)) {
      data['LaunchDarkly Flag Value'] = otpMethod;
    }

    event =
      phase === SignInPhases.START
        ? CustomEventNames.SIGN_IN_SUBMITTED
        : success
        ? CustomEventNames.SIGN_IN_COMPLETE
        : CustomEventNames.ERROR;

    if (phase === SignInPhases.COMPLETE) {
      handleDispatchEvent(success ? CustomEventNames.SIGN_IN_COMPLETE : CustomEventNames.ERROR);
    }

    logEvent(event, EventTypes.Other, data);
  };

  const signOutEvent = (success: boolean, message?: string) => {
    setIsNewSignUp(false);
    logEvent(CustomEventNames.SIGN_OUT, EventTypes.Other, {
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
    });
  };

  const signUpEvent = ({
    success,
    message,
    otpMethod,
    additionalAttrs = {},
  }: I.ISignUpEventOptions) => {
    let event = success ? CustomEventNames.SIGN_UP : CustomEventNames.ERROR;
    const data = {
      Name: !success ? CustomEventNames.SIGN_UP_FAILED : undefined,
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
      Type: enableSignUpInBE ? 'Backend' : 'Cognito',
      ...additionalAttrs,
    };

    if (isOTPEnabled(otpMethod)) {
      data['LaunchDarkly Flag Value'] = otpMethod;
    }

    if (success) {
      setIsNewSignUp(true);
      trackEvent({
        name: 'Sign Up Successful',
        type: EventTypes.Other,
        attributes: {
          signUpType: 'Email',
        },
      });
    }

    logEvent(event, EventTypes.Other, data);
    handleDispatchEvent(CustomEventNames.SIGN_UP);
  };

  const signUpTermToggleEvent = ({
    postToggleValue,
    signUpTermName,
  }: I.ISignUpTermToggleEventOptions) => {
    let event = CustomEventNames.SIGN_UP_TERM_TOGGLE;

    const data = {
      'Sign Up Term': signUpTermName,
      'Post Toggle Value': postToggleValue,
    };

    logEvent(event, EventTypes.Other, data);
  };

  const autoSignInEvent = ({ success, message, phase }: I.IAutoSignInEventOptions) => {
    logEvent(
      phase === SignInPhases.COMPLETE
        ? success
          ? CustomEventNames.SIGN_IN_COMPLETE
          : CustomEventNames.ERROR
        : CustomEventNames.SIGN_IN_SUBMITTED,
      EventTypes.Other,
      {
        Name: !success ? CustomEventNames.SIGN_IN_FAILED : undefined,
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
        Method: CustomEventNames.AUTO_SIGN_IN,
      }
    );
  };

  const createSublevelItems = (cartEntry: ICartEntry): I.IMParticleSublevelItem[] => {
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry).filter(
      item => item._id !== cartEntry._id && item.type === CartEntryType.item
    );

    // Merge sublevel items by item id
    const mergedItemsById = subItems.reduce<{
      [id: string]: I.IMParticleSublevelItem | undefined;
    }>((acc, item) => {
      const curItem = acc[item._id];
      if (curItem) {
        curItem.quantity += item.quantity;
      } else {
        acc[item._id] = { id: item._id, quantity: item.quantity };
      }
      return acc;
    }, {});

    return compact(Object.values(mergedItemsById));
  };

  const createSublevelProducts = (cartEntry: ICartEntry): I.IMParticleProduct[] => {
    const subProducts: I.IMParticleProduct[] = [];
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry);

    // Merge sublevel items by item id
    for (const subItem of subItems) {
      if (
        subItem._id !== cartEntry._id &&
        subItem.type === CartEntryType.item &&
        subItem.productHierarchy
      ) {
        const p = createProduct(subItem);
        if (p) {
          p.Attributes.comboChild = booleanToString(true);
          subProducts.push(p);
        }
      }
    }
    return subProducts;
  };

  const createProduct = (
    cartEntry: ICartEntry | IBackendCartEntries
  ): I.IMParticleProduct | null => {
    if (!window?.mParticle?.eCommerce) {
      return null;
    }

    const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
    const _id = '_id' in cartEntry ? cartEntry._id : cartEntry.sanityId;
    const { name = '', price, quantity, isDonation = false, isExtra = false } = cartEntry;
    const basePrice = price ? centsToDollars(price / quantity) : 0;
    const product = MParticleAdapter.createProduct(name, _id, basePrice, quantity);

    if (!product) {
      return null;
    }

    const productSublevelItems = createSublevelItems(cartEntry as ICartEntry);
    const itemLevel =
      productSublevelItems.length === 0 ? ProductItemType.Child : ProductItemType.Parent;

    product.Attributes = {
      cartId: cartId || _id,
      sublevelItems: JSON.stringify(productSublevelItems),
      isDonation: booleanToString(isDonation),
      isExtra: booleanToString(isExtra),
      'Item Level': itemLevel,
      comboChild: booleanToString(false),
    };

    if (itemLevel === ProductItemType.Child) {
      product.Attributes = {
        ...product.Attributes,
        L1: cartEntry.productHierarchy?.L1 || '',
        L2: cartEntry.productHierarchy?.L2 || '',
        L3: cartEntry.productHierarchy?.L3 || '',
        L4: cartEntry.productHierarchy?.L4 || '',
        L5: cartEntry.productHierarchy?.L5 || '',
      };
    }

    product.SubProducts =
      productSublevelItems.length > 0 ? createSublevelProducts(cartEntry as ICartEntry) : [];
    return product;
  };

  const getCartDataItems = (cartEntries: ICartEntry[]): string => {
    const cartData = JSON.stringify({
      items: cartEntries.map(entry => ({
        items: createProduct(entry),
      })),
    });
    const cartDataItemsRegex = /item_\d{3,}/gi;
    return cartData?.match(cartDataItemsRegex)?.join() || '';
  };

  const addToCart = enqueueIfNotDrained(
    (
      cartEntry: ICartEntry,
      serviceMode: ServiceMode,
      previousCartEntries: ICartEntry[],
      selectionAttrs?: I.IAddToCartSelectionAttributes
    ) => {
      if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
        return;
      }

      const product = createProduct(cartEntry);

      if (!product) {
        return;
      }

      MParticleAdapter.logProductAction(window.mParticle.ProductActionType.AddToCart, [product], {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(false),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
      });
    }
  );

  const updateItemInCart = enqueueIfNotDrained(
    (newCartEntry: ICartEntry, originalCartEntry: ICartEntry, serviceMode: ServiceMode) => {
      if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
        return;
      }

      const oldProduct = createProduct(originalCartEntry);
      const newProduct = createProduct(newCartEntry);

      if (!oldProduct || !newProduct) {
        return;
      }

      MParticleAdapter.logProductAction(ProductActionTypes.RemoveFromCart, [oldProduct], {
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(true),
      });

      MParticleAdapter.logProductAction(ProductActionTypes.AddToCart, [newProduct], {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(true),
      });
    }
  );

  const removeFromCart = enqueueIfNotDrained((cartEntry: ICartEntry) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const product = createProduct(cartEntry);

    if (!product) {
      return;
    }

    MParticleAdapter.logProductAction(ProductActionTypes.RemoveFromCart, [product], {
      'Is Kiosk': booleanToString(false),
      'Device Time': deviceTime(),
      'Source Page': getSourcePage(),
      'Is Update': booleanToString(false),
    });
  });

  const logCustomerInitiatedRefund = (
    event: CustomEventNames,
    items: IBackendCartEntries[] = [],
    amount: number = 0,
    reason: string
  ) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const products = items.reduce<I.IMParticleProduct[]>((acc, item) => {
      const product = createProduct(item);
      if (product) {
        acc.push(product);
      }
      return acc;
    }, []);

    // Converts refunded items ids to a string to be sent in the custom event below.
    const refundedItemsString = items.map(({ lineId }) => lineId).join(', ');

    logEvent(event, EventTypes.Transaction, {
      amount,
      items: refundedItemsString,
      reason,
    });

    MParticleAdapter.logProductAction(ProductActionTypes.Refund, products, {
      amount,
      event,
      reason,
      'Device Time': deviceTime(),
      ...universalAttributes.current,
    });
  };

  const logPurchase = enqueueIfNotDrained(
    (
      cartEntries: ICartEntry[],
      store: StoreProxy,
      serviceMode: ServiceMode,
      serverOrder: IServerOrder,
      attrs = {}
    ) => {
      if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
        return;
      }

      const appliedOffersCmsIds = (serverOrder.cart.appliedOffers || []).map(
        ({ sanityId }) => sanityId
      );

      const couponIDs = (serverOrder.cart.offersFeedback || []).map(
        (feedbackEntry: IUserOffersFeedbackEntry) => {
          return feedbackEntry.couponId;
        }
      );

      // Upsells
      // - hasUpsell
      // - upsellTotal
      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      // Create Chef payload
      const upsellEntry = upsells.find(entry => !!entry.recommendationToken);
      const recommendationToken = upsellEntry?.recommendationToken;
      const recommender = upsellEntry?.recommender;
      const chef =
        hasUpsell && recommendationToken
          ? {
              eventType: 'purchase-complete',
              userInfo: {
                visitorId:
                  window.mParticle?.Identity?.getCurrentUser()?.getUserIdentities()?.userIdentities
                    .customerid,
              },
              eventDetail: {
                recommendationToken,
              },
              productEventDetail: {
                productDetails: cartEntries.map(entry => ({
                  id: entry._id,
                  quantity: entry.quantity,
                  displayPrice: entry.price,
                  currencyCode: attrs?.currencyCode || 'USD',
                })),
                purchaseTransaction: {
                  id: serverOrder.rbiOrderId,
                  revenue: centsToDollars(serverOrder.cart.subTotalCents),
                  currencyCode: attrs?.currencyCode || 'USD',
                },
              },
            }
          : null;

      const couponIdsArray = couponIDs.length ? couponIDs : appliedOffersCmsIds;
      const couponIDString = couponIdsArray.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        Id: serverOrder.rbiOrderId,
        Revenue: centsToDollars(serverOrder.cart.subTotalCents),
        Tax: centsToDollars(serverOrder.cart.taxCents),
      };

      const roundUpDonation = getRoundUpDonations(serverOrder);

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: I.IMParticlePurchaseEventAttributes = {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        branch_service_mode: serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(couponIdsArray.length > 0),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.Tax,
        'Total Amount': transactionAttributes.Revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude,
        'Restaurant Longitude': store.longitude,
        'Restaurant Status': store.status,
        'Restaurant Drink Station Type': store.drinkStationType,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId,
        'Restaurant Franchise Group Name': store.franchiseGroupName,
        'Restaurant Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Breakfast': store.hasBreakfast,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast,
        'Restaurant Has Curbside': store.hasCurbside,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Catering': store.hasCatering,
        'Restaurant Has Dine In': store.hasDineIn,
        'Restaurant Has Drive Thru': store.hasDriveThru,
        'Restaurant Has Table Service': store.hasTableService,
        'Restaurant Has Home Delivery': store.hasDelivery,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering,
        'Restaurant Has Parking': store.hasParking,
        'Restaurant Has Playground': store.hasPlayground,
        'Restaurant Has Take Out': store.hasTakeOut,
        'Restaurant Has Wifi': store.hasWifi,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType
        ),
        'Restaurant Parking Type': store.parkingType,
        'Restaurant Playground Type': store.playgroundType,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Restaurant POS Version': store.pos?.version ?? null,
        'Is Kiosk': false,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': upsellTotal,
        'Recommender Provider': recommender || '',
        Chef: chef ? JSON.stringify(chef) : null,
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Is Loyalty': !!serverOrder.loyaltyTransaction,
        roundUpAmount: roundUpDonation?.totalCents ?? 0,
        'Currency Code': attrs.currencyCode || 'USD',
        'Upsell Simplified Enabled': isUpsellSimplified,
      };

      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.deliveryFeeAmount = centsToDollars(attrs.deliveryFeeCents);
        additionalAttrs.deliveryDiscountAmount = centsToDollars(attrs.deliveryFeeDiscountCents);
        additionalAttrs.deliveryGeographicalFeeAmount = centsToDollars(
          attrs.deliveryGeographicalFeeCents
        );
        additionalAttrs.deliveryServiceFeeAmount = centsToDollars(attrs.deliveryServiceFeeCents);
        additionalAttrs.deliverySmallCartFeeAmount = centsToDollars(
          attrs.deliverySmallCartFeeCents
        );
        additionalAttrs.totalDeliveryOrderFeeAmount = centsToDollars(
          attrs.totalDeliveryOrderFeesCents
        );
        additionalAttrs.deliverySurchargeFeeAmount = centsToDollars(
          attrs.deliverySurchargeFeeCents
        );
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs.baseDeliveryFeeAmount = centsToDollars(attrs.baseDeliveryFeeCents);
        additionalAttrs['Address Type'] = attrs.addressType;
        additionalAttrs.hasSavedDeliveryAddress = attrs.hasSavedDeliveryAddress;
        additionalAttrs.hasSelectedRecentAddress = attrs.hasSelectedRecentAddress;
        additionalAttrs.hasRecentAddress = attrs.hasRecentAddress;
      }

      if (transactionAttributes.Revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.Revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);

      const sanitizedAdditionAttrs = sanitizeValues(additionalAttrs);
      const normalizedAdditionalAttrs = normalizeBooleans(sanitizedAdditionAttrs);

      const products = cartEntries.reduce((accumulator, cartEntry) => {
        const eCommerceProduct = createProduct(cartEntry);

        if (!eCommerceProduct) {
          return accumulator;
        }

        const rewardApplied = serverOrder.cart.rewardsApplied?.find(
          reward => reward.cartId === eCommerceProduct.Attributes.cartId
        );

        accumulator.push({
          ...eCommerceProduct,
          Attributes: {
            ...eCommerceProduct.Attributes,
            rewardItem: booleanToString(!!rewardApplied),
          },
        });

        //now we expand the product list with the SubProducts listed on each product, for ecommerce purchases only
        if (eCommerceProduct.SubProducts.length > 0) {
          accumulator = accumulator.concat(eCommerceProduct.SubProducts);
        }

        return accumulator;
      }, [] as I.IMParticleProduct[]);

      try {
        // for docs, refer https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle.eCommerce.html
        MParticleAdapter.logProductAction(
          ProductActionTypes.Purchase,
          products,
          normalizedAdditionalAttrs,
          null,
          normalizedTransactionAttrs
        );
      } catch (error) {
        logger.error({ error, message: 'mParticle > logPurchase error' });
      }

      try {
        // Also send a custom event for the Purchase allowing the brand to
        // create custom queries in mParticle
        if (brand() === RBIBrand.TH) {
          logEvent(CustomEventNames.PURCHASE, EventTypes.Transaction, normalizedAdditionalAttrs);
        }
      } catch (error) {
        logger.error({ error, message: 'mParticle > purchase custom event error' });
      }

      // log rbi purchase events
      if (serializedServiceMode === 'Pickup' || serializedServiceMode === 'Delivery') {
        const eventName =
          serializedServiceMode === 'Pickup' ? 'Purchase Pick Up' : 'Purchase Delivery';
        trackEvent({
          name: eventName,
          type: EventTypes.Other,
        });
      }
    }
  );

  const logPageView = enqueueIfNotDrained(
    (pathname, attrs = {}, customFlags = {}, force = false) => {
      const allAttributes = {
        ...universalAttributes.current,
        ...attrs,
        'Device Time': deviceTime(),
      };
      const sanitizedAttributes = sanitizeValues(allAttributes);
      const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
      const normalizedFlags = normalizeBooleans(customFlags);
      if (force) {
        return window.mParticle.logPageView(pathname, normalizedAndSanitizedAttrs, normalizedFlags);
      }
      if (pathname.startsWith(routes.menu)) {
        const onMainMenu = new RegExp(routes.menu.concat('$')).test(pathname);
        if (onMainMenu) {
          return window.mParticle.logPageView(
            pathname,
            normalizedAndSanitizedAttrs,
            normalizedFlags
          );
        }
      }
      // fixes issue with home page not being captured
      if (pathname === '/') {
        window.mParticle.logPageView(pathname, normalizedAndSanitizedAttrs, normalizedFlags);
      } else {
        const trackParameters = {
          pathname,
          normalizedAndSanitizedAttrs,
          normalizedFlags,
        };
        // Checking if path is local path
        const isLocalRoute = Object.values(routes).some(route => {
          const localizedRoute = getInCodeLocalizedRouteForPath(route, locale, region) || route;
          return route !== '/' && pathname.startsWith(localizedRoute);
        });
        // If staticRoutes have not loaded yet
        // Store them for later when they are available
        if (!isLocalRoute && !staticRoutes.current.length) {
          return maybeTrackWhenStaticRoutesAvailable(trackParameters);
        }
        maybeTrackPage({ ...trackParameters });
      }
    }
  );

  const logCommercePageView = (
    menuData: { id: string; name: string; menuType: string },
    attrs = {}
  ) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const { name, id, menuType } = menuData;
    const product = MParticleAdapter.createProduct(name, id, 0, 1) as I.IMParticleProduct;

    MParticleAdapter.logProductAction(
      ProductActionTypes.ViewDetail,
      [product],
      { menuType, ...attrs },
      null,
      {}
    );
  };

  const maybeTrackPage = enqueueIfNotDrained(
    ({ pathname, normalizedAndSanitizedAttrs, normalizedFlags }: ILogPageView) => {
      const trackedPages = TRACKED_PAGES.concat(staticRoutes.current);
      const matchedPages = trackedPages.filter(page => pathname.startsWith(page));
      if (matchedPages.length) {
        // Find the longest match by setting it to be the first element
        const matchedPage = matchedPages.sort((a, b) => b.length - a.length)[0];
        window.mParticle.logPageView(matchedPage, normalizedAndSanitizedAttrs, normalizedFlags);
      }
    }
  );

  const maybeTrackWhenStaticRoutesAvailable = ({
    pathname,
    normalizedAndSanitizedAttrs,
    normalizedFlags,
  }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      normalizedAndSanitizedAttrs,
      normalizedFlags,
    };
  };

  const logNavigationClick = (eventName: CustomEventNames, attrs = {}, customFlags = {}) => {
    logEvent(
      CustomEventNames.BUTTON_CLICK,
      EventTypes.Navigation,
      {
        Name: eventName,
        ...attrs,
      },
      customFlags
    );
  };

  const logAddPaymentMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_ADD_PAYMENT_METHOD);
  };

  const logCashVoucherMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_CASH_OR_VOUCHER);
  };

  const selectServiceMode = enqueueIfNotDrained(mode => {
    trackEvent({
      name: 'Select Service Mode',
      type: EventTypes.Other,
      attributes: null,
    });
    if (brand() === RBIBrand.TH) {
      MParticleAdapter.logEvent(CustomEventNames.SELECT_SERVICE_MODE, EventTypes.Other, {
        'Device Time': deviceTime(),
        'Service Mode': serializeServiceMode(mode),
        'Pickup Mode': serializePickupMode(mode),
        'Source Page': getSourcePage(),
        RBIEnv: env(),
      });
    }
  });

  const logOfferActivatedEvent = (sanityId: string, offerName: string, tokenId?: string | null) => {
    logEvent(CustomEventNames.OFFER_ACTIVATED, EventTypes.Other, {
      'Sanity ID': sanityId,
      'Offer Name': offerName,
      external_offer_id: tokenId,
    });
  };

  /**
   * Logs an event to mparticle with the duration in MS an order has taken
   * to transition from either PRICE_REQUESTED or INSERT_REQUESTED to
   * *_ERROR/SUCCESSFUL status
   */
  const logOrderLatencyEvent = enqueueIfNotDrained(
    (order: IServerOrder | undefined, actionType: 'commit' | 'price', duration: number) => {
      const orderStatus = order?.status;
      const storeId = order?.cart?.storeDetails?.storeNumber;
      const orderId = order?.rbiOrderId;
      const serviceMode = order?.cart?.serviceMode ?? null;

      const eventName =
        actionType === 'price'
          ? CustomEventNames.ORDER_LATENCY_PRICING
          : CustomEventNames.ORDER_LATENCY_COMMIT;

      MParticleAdapter.logEvent(eventName, EventTypes.Other, {
        Duration: Math.floor(duration),
        Status: orderStatus,
        'Store ID': storeId,
        'Order ID': orderId,
        'Service Mode': serializeServiceMode(serviceMode),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
      });
    }
  );

  const logUpsellAddedEvent = enqueueIfNotDrained((item: ICartEntry, itemPosition?: number) => {
    if (!item.isUpsell) {
      return;
    }
    const { name, price, recommender, recommendationToken } = item;
    if (brand() === RBIBrand.TH) {
      MParticleAdapter.logEvent(CustomEventNames.UPSELL_ADDED, EventTypes.Other, {
        Id: item._id,
        Name: name,
        Price: price && price / 100,
        Recommender: recommender,
        RecommendationToken: recommendationToken,
        'Upsell Item Position': itemPosition || '',
        'Source Page': getSourcePage(),
      });
    }
    trackEvent({
      name: 'Upsell Added',
      type: EventTypes.Other,
      attributes: {
        name,
        sanityId: item._id,
        price: price && price / 100,
        upsellItemPosition: itemPosition,
      },
    });
  });

  const logUpsellRemovedEvent = enqueueIfNotDrained((item: ICartEntry) => {
    if (!item.isUpsell) {
      return;
    }
    const { recommender, recommendationToken, price, name } = item;
    MParticleAdapter.logEvent(CustomEventNames.UPSELL_REMOVED, EventTypes.Other, {
      Id: item._id,
      Name: name,
      Price: price && price / 100,
      Recommender: recommender,
      RecommendationToken: recommendationToken,
      'Source Page': getSourcePage(),
    });
  });

  const logCheckoutEvent = (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const products = cartEntries.map(createProduct).filter(Boolean as any as ExcludesNull);
    const pickUpMode = serializePickupMode(serviceMode);
    const customAttributes = {
      'Pickup Mode': pickUpMode,
      'Cart Data': getCartDataItems(cartEntries),
    };

    if (brand() === RBIBrand.TH) {
      logEvent(CustomEventNames.CHECKOUT, EventTypes.Navigation, customAttributes);
    }

    MParticleAdapter.logProductAction(ProductActionTypes.Checkout, products, customAttributes);
  };

  const marketingTileClickEvent = (title: string, position: number, cardId: string) => {
    trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.MARKETING_TILE,
        text: title,
        position: `Tile ${position + ONE_INDEX_OFFSET}`,
        componentId: cardId,
      },
    });
  };

  const logNavBarClickEvent = (text: string, componentKey: string) => {
    trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.NAV_BAR,
        text,
        componentId: componentKey,
      },
    });
  };

  return (
    <MParticleContext.Provider
      value={{
        // auth events
        init,
        login,
        logout,
        signInEvent,
        deviceId,
        signOutEvent,
        signUpEvent,
        signUpTermToggleEvent,
        autoSignInEvent,
        updateStaticRoutes,
        updateUserAttributes,
        updateUserIdentities,
        updateUserLocationPermissionStatus,

        // pageView data
        logPageView,
        logCommercePageView,

        // eCommerce events
        addToCart,
        updateItemInCart,
        removeFromCart,
        logOrderLatencyEvent,
        logPurchase,
        logCustomerInitiatedRefund,

        // error tracking
        logError,

        // custom events
        logEvent,
        trackEvent,
        logNavigationClick,
        logAddPaymentMethodClick,
        logCashVoucherMethodClick,
        selectServiceMode,
        appflowUpdateEvent,
        logOfferActivatedEvent,
        logCheckoutEvent,
        logUpsellAddedEvent,
        logUpsellRemovedEvent,
        logNavBarClickEvent,
        marketingTileClickEvent,

        // initialized sessionId from mParticle
        sessionId: sessionId || uniqueGuid.current,

        // Allows for universal attrs
        updateUniversalAttributes,
      }}
    >
      {props.children}
    </MParticleContext.Provider>
  );
}

export default MParticleContext.Consumer;
