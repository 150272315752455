import React from 'react';

import { useIntl } from 'react-intl';

import { HamburgerButton } from 'components/app-header/mobile-header/hamburger-button';
import {
  Header,
  LinkNoDecoration,
  MenuButtonContainer,
  MobileHeaderContainer,
} from 'components/app-header/mobile-header/styled';
import StyledLoadingIndicator from 'components/app-header/styled-loading-indicator';
import { AppSubheader } from 'components/app-subheader';
import { DeliveryBannerContainer } from 'components/delivery-banner';
import Logo from 'components/logo';
import SkipToContent from 'components/skip-content';
import { TopServiceMode } from 'components/top-service-mode';
import { useNativeStatusBar } from 'hooks/use-native-status-bar';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoadingIndicator } from 'state/loading';
import { useLocationContext } from 'state/location';
import { useMobileHeaderBackButton, useMobileHeaderComponent } from 'state/mobile-header-nav';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import SignupMobileHeader from '../signup-mobile-header';
import { StyledCenterSpacer, StyledRightSpacer } from '../styled/base';
import { StyledLogo } from '../styled/mobile-header';
import { TopServiceModeContainer } from '../styled/mobile-header.bk';

import { MEDIA_QUERY_HEADER } from './constants';
import MobileHeaderCta from './mobile-header-cta';
import { IMobileHeaderProps } from './types';

// The types for react native do not support `dataSet` yet, but
// it is the required way to pass data-* attributes to the node.
// And we are using this mechanism to ensure these nodes are rendered
// during the pre-render, but only visible for the correct screen size.
const onlyRenderInMobile = () =>
  ({
    dataSet: { mediaquery: MEDIA_QUERY_HEADER },
  } as any);

const StandardMobileHeader = ({
  serviceMode,
  userAddress,
  cateringPickupDateTime,
}: IMobileHeaderProps) => {
  const BackButtonComponent = useMobileHeaderBackButton();
  const {
    location: { pathname },
  } = useLocationContext();

  const LoadingIndicator = useLoadingIndicator();
  const { formatMessage } = useIntl();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableTopServiceMode = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const { nativeStatusBarExist } = useNativeStatusBar();
  const { reversedUI } = useReversedUIContext();

  const isHamburgerMenuEnabled = useFlag(LaunchDarklyFlag.ENABLE_HAMBURGER_MENU_ON_MOBILE);
  const shouldShowBackButtonComponent =
    pathname.includes(formatMessage({ id: 'routes.account' })) ||
    pathname.includes(`${routes.menu}/`) ||
    pathname.includes(`${routes.rewardsList}/`) ||
    pathname.includes(`${routes.rewardsOffers}/`) ||
    pathname.includes(formatMessage({ id: 'routes.linkPhysicalCard' }));

  const shouldShowSignupMobileHeader =
    pathname === routes.signUp || pathname === routes.signIn || pathname === routes.confirmOtp;

  if (shouldShowSignupMobileHeader) {
    return <SignupMobileHeader />;
  }

  const LeftSideComponent =
    (shouldShowBackButtonComponent && BackButtonComponent) ||
    (isHamburgerMenuEnabled ? HamburgerButton : () => null);

  return (
    <MobileHeaderContainer
      {...onlyRenderInMobile()}
      aria-label={formatMessage({ id: 'primaryNavLinks' })}
      testID="mobile-header"
      reversed={reversedUI}
    >
      <Header nativeStatusBarExist={nativeStatusBarExist}>
        <MenuButtonContainer>
          <LeftSideComponent />
        </MenuButtonContainer>
        <SkipToContent />
        <StyledCenterSpacer>
          <LinkNoDecoration to={routes.base}>
            <StyledLogo data-testid="mobile-header-logo">
              <Logo />
            </StyledLogo>
          </LinkNoDecoration>
        </StyledCenterSpacer>
        <StyledRightSpacer className="last">
          <MobileHeaderCta />
        </StyledRightSpacer>
        <StyledLoadingIndicator as={LoadingIndicator} hasStoreSelection={isStoreOpenAndAvailable} />
      </Header>

      {enableTopServiceMode && !enableBottomServiceMode && (
        <TopServiceModeContainer>
          <TopServiceMode />
        </TopServiceModeContainer>
      )}

      {!enableBottomServiceMode && !enableTopServiceMode && <DeliveryBannerContainer />}
      <AppSubheader
        serviceMode={serviceMode!}
        userAddress={userAddress}
        cateringPickupDateTime={cateringPickupDateTime}
      />
    </MobileHeaderContainer>
  );
};

export default function MobileHeader(props: IMobileHeaderProps) {
  const HeaderComponent = useMobileHeaderComponent();

  return HeaderComponent ? <HeaderComponent /> : <StandardMobileHeader {...props} />;
}
