import { ApolloError } from '@apollo/client';

import { GraphQLPaymentErrorType } from './types';

interface IGraphQLPaymentErrorAction {
  paymentMethodType: GraphQLPaymentErrorType;
}

export class ThreeDSMethodError extends Error {
  constructor() {
    super('Method flow activated');
  }
}

export class ThreeDSChallengeError extends Error {
  constructor() {
    super('Challenge flow activated');
  }
}

export const getGraphQLPaymentErrorType = (err: ApolloError): GraphQLPaymentErrorType =>
  (err?.graphQLErrors[0].extensions?.action as IGraphQLPaymentErrorAction)?.paymentMethodType;
