import { useEffect, useMemo, useState } from 'react';

import { noop } from 'lodash';

import { IBringgUuids, ILocation } from '@rbi-ctg/frontend';
import { IServerOrder } from '@rbi-ctg/menu';
import { useFeatureGeolocationMapMarkers } from 'hooks/use-feature-geolocation-map-markers';
import useMap from 'hooks/use-map';
import { StoreProxy } from 'state/store';
import { useUIContext } from 'state/ui';
import { getApiKey, getConfigValue } from 'utils/environment';
import { ICoords } from 'utils/geolocation/types';

interface IUseOrderConfirmationMap {
  bringgUuids: IBringgUuids;
  enableDriverLocation: boolean;
  serverOrder: IServerOrder;
  store: StoreProxy;
}

export default function useDriverLocation({
  bringgUuids,
  enableDriverLocation,
  serverOrder,
  store,
}: IUseOrderConfirmationMap) {
  const [markerCoords, setMarkerCoords] = useState<Array<ILocation>>([]);
  const isDeliveryOrder = !!serverOrder.delivery;
  const destinationLatitude = serverOrder?.delivery?.dropoff?.latitude ?? 0;
  const destinationLongitude = serverOrder?.delivery?.dropoff?.longitude ?? 0;
  const pickupLatitude = serverOrder?.cart?.storeDetails?.latitude ?? 0;
  const pickupLongitude = serverOrder?.cart?.storeDetails?.longitude ?? 0;

  const mapCenter = useMemo(
    () =>
      isDeliveryOrder
        ? {
            lat: destinationLatitude,
            lng: destinationLongitude,
          }
        : { lat: store.latitude ?? 0, lng: store.longitude ?? 0 },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { map, createMarker, MarkerTypes, fitAndCenterFromCoords } = useMap({
    disableControls: false,
    position: mapCenter,
  });

  // Load Map Marker icons.
  const { buildImageUrl } = useUIContext();
  const { featureGeolocationMapMarkers, featureGeolocationMapMarkersLoading } =
    useFeatureGeolocationMapMarkers();
  const driverIconImage = featureGeolocationMapMarkers?.driverIcon?.locale;
  const driverIcon = driverIconImage?.asset ? buildImageUrl(driverIconImage, { w: 50 }) : '';

  /**
   * This sets up connection to the bringg sdk and adds
   * a handler to listen to location change of the driver.
   *
   * Documentation Here:
   * https://www.npmjs.com/package/@bringg/customer-sdk#dont-forget-to-set-your-own-keys-and-params
   *
   * But probably more thorough documentation is in the example here:
   * https://github.com/bringg/customer-js-sdk/blob/master/demo/customer-sdk-demo.js
   */
  const { share_uuid, order_uuid } = bringgUuids;

  useEffect(() => {
    if (!isDeliveryOrder || featureGeolocationMapMarkersLoading) {
      return;
    }
    let disconnectBringg: () => void;
    if (enableDriverLocation && order_uuid && share_uuid) {
      import('utils/bringg-sdk').then((module: any) => {
        const BringgSDK = module.default;
        const handleLocationUpdate = (location: ICoords) => {
          // For some reason this callback occasionally get called
          // where `lat` & `lng` don't exist as methods ¯\_(ツ)_/¯
          const driverLocation = {
            lat: location.lat && location.lat(),
            lng: location.lng && location.lng(),
          };

          if (driverLocation.lat && driverLocation.lng) {
            createMarker({
              type: MarkerTypes.Driver,
              location: driverLocation,
              iconOverrideUrl: driverIcon,
              source: 'orderConfirmation',
            });
            fitAndCenterFromCoords([...markerCoords, driverLocation]);
          }
        };

        const region = getConfigValue('bringg').region;
        BringgSDK.setUpConfigByLocationUrl(`&e=${region}`);
        BringgSDK.setLocationUpdateCb(handleLocationUpdate);

        const apiKey = getApiKey('bringg');

        BringgSDK.connect(
          apiKey,
          () => {
            disconnectBringg = BringgSDK.disconnect;
            BringgSDK.watchOrder(
              {
                order_uuid,
                share_uuid,
              },
              /**
               * @TODO Add behavior for successfully watching the order.
               *
               * We currently don't need to do anything here because we've set
               * the location update listener to listen safetly to new values.
               */
              noop
            );
          },
          noop // onFailure method
        );
      });
    }

    return () => {
      if (disconnectBringg) {
        disconnectBringg();
      }
    };

    // Destructured `order_uuid` & `share_uuid` to primitives so this hook reruns less
    // frequently and doesn't disconnect and reconnect to bringg on every update.
  }, [
    createMarker,
    enableDriverLocation,
    fitAndCenterFromCoords,
    isDeliveryOrder,
    markerCoords,
    MarkerTypes.Driver,
    order_uuid,
    share_uuid,
    featureGeolocationMapMarkersLoading,
    driverIcon,
  ]);

  // Creates store & destination markers on map & updates map view to fit them.
  useEffect(() => {
    const mapCoords = [];

    if (pickupLatitude && pickupLongitude) {
      const pickupCoords = { lat: pickupLatitude, lng: pickupLongitude };
      createMarker({
        type: MarkerTypes.Store,
        location: pickupCoords,
        iconOverrideUrl: driverIcon,
        source: 'orderConfirmation',
      });

      mapCoords.push(pickupCoords);
    }

    if (destinationLatitude && destinationLongitude) {
      const destinationCoord = { lat: destinationLatitude, lng: destinationLongitude };
      createMarker({
        type: MarkerTypes.Destination,
        location: destinationCoord,
        source: 'orderConfirmation',
      });
      mapCoords.push(destinationCoord);
    }

    fitAndCenterFromCoords(mapCoords);
    setMarkerCoords(mapCoords);
  }, [
    createMarker,
    destinationLatitude,
    destinationLongitude,
    fitAndCenterFromCoords,
    mapCenter,
    MarkerTypes.Destination,
    MarkerTypes.Store,
    pickupLatitude,
    pickupLongitude,
    driverIcon,
  ]);

  return map;
}
