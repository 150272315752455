import React, { FC } from 'react';

import { IMainMenuView } from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import {
  IMainMenuContext,
  MainMenuProvider as LegacyMainMenuContextProvider,
  useMainMenuContext as legacyUseMainMenuContext,
} from './legacy-main-menu';
import {
  MainMenuProvider as NewMainMenuContextProvider,
  useMainMenuContext as newUseMainMenuContext,
} from './new-main-menu';

export type MainMenu = IMainMenuView;
export type MainMenuContext = IMainMenuContext;
export const useNewMainMenuContext = newUseMainMenuContext;
export const useMainMenuContext = legacyUseMainMenuContext;

export const MainMenuProvider: FC = ({ children }) => {
  const enableMenuServiceData = useFlag(LaunchDarklyFlag.ENABLE_MENU_SERVICE_DATA);
  const Provider = enableMenuServiceData
    ? NewMainMenuContextProvider
    : LegacyMainMenuContextProvider;

  return <Provider>{children}</Provider>;
};
