import React from 'react';

import { useIntl } from 'react-intl';

import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { LoyaltyAppHeaderView } from './loyalty-app-header.view';

export default () => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();

  // query sanity and user rewards upon page load
  useLoyaltyRewardsList();
  const { formatMessage } = useIntl();

  if (!loyaltyEnabled || !loyaltyUser) {
    return null;
  }

  return (
    <LoyaltyAppHeaderView
      points={loyaltyUser.points}
      to={formatMessage({ id: 'routes.loyaltyDashboard' })}
    />
  );
};
