import React, { FC } from 'react';

import { IsoCountryCode2 } from '@rbilabs/intl';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { ButtonsContainer, CloseButton, Container, CookieNoticeLink, InfoText } from './styles';
import { ICookieBannerProps } from './types';

const CookieBanner: FC<ICookieBannerProps> = ({
  infoText,
  buttonText,
  link,
  handleAccept,
  handleClose,
}) => {
  const { formatMessage } = useIntl();
  const { region } = useLocale();
  const hideCloseButton = useFlag(LaunchDarklyFlag.HIDE_COOKIE_BANNER_CLOSE_BUTTON);

  return (
    <Container>
      <InfoText>
        {infoText} <CookieNoticeLink href={link.href}>{link.text}</CookieNoticeLink>
      </InfoText>
      <ButtonsContainer>
        {!hideCloseButton && (
          <CloseButton data-testid="close-cookies-button" onClick={handleClose}>
            {formatMessage({ id: region === IsoCountryCode2.GB ? 'decline' : 'close' })}
          </CloseButton>
        )}
        <ActionButton
          data-testid="accept-all-cookies-button"
          variant={ActionButtonVariants.INVERSE}
          color="statusValidated"
          onClick={handleAccept}
          fullWidth
        >
          {buttonText}
        </ActionButton>
      </ButtonsContainer>
    </Container>
  );
};

export { CookieBanner };
