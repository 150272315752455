import React, { useEffect, useState } from 'react';

import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';

import { DynamicLeftNav } from './dynamic-left-nav';
import { StaticLeftNav } from './static-left-nav';

export const LeftNavContainer = () => {
  const { featureNavigationDesktop, featureNavigationDesktopWithStore, featureNavigationLoading } =
    useFeatureNavigation();
  const { store } = useStoreContext();

  const sanityNavigationEnabled = useFlag(LaunchDarklyFlag.ENABLE_SANITY_WEB_NAVIGATION);
  const [navLinks, setNavLinks] = useState(featureNavigationDesktop);

  useEffect(() => {
    if (featureNavigationLoading) {
      return;
    }

    if (store._id && featureNavigationDesktopWithStore?.length) {
      setNavLinks(featureNavigationDesktopWithStore);
    } else {
      setNavLinks(featureNavigationDesktop);
    }
  }, [
    featureNavigationDesktop,
    featureNavigationDesktopWithStore,
    featureNavigationLoading,
    store._id,
  ]);

  if (featureNavigationLoading) {
    return null;
  }

  const dynamicNavAvailable = !!featureNavigationDesktop.length && sanityNavigationEnabled;

  return dynamicNavAvailable ? <DynamicLeftNav navLinks={navLinks} /> : <StaticLeftNav />;
};
