import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useIntl } from 'react-intl';

import ModalAgreement from 'components/modal-agreement';
import { ICommunicationPreference, IFavoriteOffer, IFavoriteStore } from 'generated/rbi-graphql';
import useErrorModal from 'hooks/use-error-modal';
import { getCurrentSession } from 'remote/auth';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useLoggerContext } from 'state/logger/context';
import { useNetworkContext } from 'state/network';
import { ISOsToRegions } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';

import {
  ISignIn,
  ISignUpResult,
  ISocialLoginParams,
  useAccountAuthentication,
} from './hooks/use-account-authentication';
import { UserDetails, useCurrentUser } from './hooks/use-current-user';

export enum BooleanAsString {
  True = 'true',
  False = 'false',
}

export type CommunicationPreferences = Array<{
  id: string;
  value: string | BooleanAsString.True | BooleanAsString.False;
}>;

export type FavoriteStore = {
  storeId: string;
  storeNumber?: string;
};

export type RequiredAcceptanceAgreementInfo = {
  id: string;
  updatedAt: string;
};

export type FavoriteStores = Array<FavoriteStore>;

export type FavoriteOffer = {
  id: string;
};

export type FavoriteOffers = Array<FavoriteOffer>;

export type UpdateUserInfo = {
  dob: string;
  name: string;
  phoneNumber: string;
  promotionalEmails: boolean;
  isoCountryCode: string;
  zipcode: string;
  defaultReloadAmt: number;
  defaultCheckoutPaymentMethodId?: string;
  defaultReloadPaymentMethodId?: string;
  autoReloadEnabled: boolean;
  autoReloadThreshold: number;
};

type SignupArgs = {
  email: string;
  name: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
  providerType?: string;
};

export type UpdateAgreementAcceptance = {
  acceptanceFromSanity: Array<IStaticPageRoute>;
  emailMarketing: boolean;
};

export interface AuthInterface {
  // Computed values
  loading: boolean;
  originLocation: null | string;
  user: null | UserDetails;
  currentUserSession: CognitoUserSession | null;
  // Functions
  getCurrentSession(): Promise<null | CognitoUserSession>;
  refreshCurrentUser(): Promise<void>;
  refreshCurrentUserWithNewSession(): Promise<void>;
  isAuthenticated(): boolean;
  setOriginLocation(location: null | string): void;
  signIn(args: ISignIn): Promise<void>;
  signInSocialLogin(userInfos: ISocialLoginParams): void;
  signOut(): Promise<void>;
  signUp(
    args: SignupArgs,
    signInOverride?: (args: ISignIn) => Promise<void>
  ): Promise<ISignUpResult>;
  updateUserInfo(args: UpdateUserInfo, shouldMuteUserInfoErrors?: boolean): Promise<void>;
  updateUserCommPrefs(commPrefs: Array<ICommunicationPreference>): Promise<void>;
  updateUserFavStores(favStores: Array<IFavoriteStore>): Promise<void>;
  updateUserFavOffers(offers: Array<IFavoriteOffer>): Promise<void>;
  updateUserPhoneNumber(phoneNumber: string): Promise<void>;
  updateUserSkillsTestStatus({ hasPassed }: { hasPassed: boolean }): Promise<void>;
  updateUserLastKnownLoyaltyTier(loyaltyTier: string): Promise<void>;
  useUpdateMeMutationLoading: boolean;
  validateLogin(args: { jwt: string; username: string }): Promise<void>;
  validateLoginOtp(args: { otpCode: string }): Promise<void>;
  userHasSignedIn: boolean;
}

export const AuthContext = createContext<AuthInterface>({} as any as AuthInterface);
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  userHasSignedIn,
  userSession,
}: {
  children: ReactNode;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
}) => {
  const { hasNotAuthenticatedError, setHasNotAuthenticatedError } = useNetworkContext();
  const { navigate } = useLocationContext();
  const signOutOnInvalidToken = useFlag(LaunchDarklyFlag.ENABLE_SIGNOUT_ON_INVALID_TOKEN);
  const { decorateLogger, logger } = useLoggerContext();
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const [agreementTerms, setAgreementTerms] = useState<IStaticPageRoute[] | null>(null);
  const [hideAgreementsModal, setHideAgreementsModal] = useState(false);

  const enableAccountRegionVerification = useFlag(
    LaunchDarklyFlag.ENABLE_ACCOUNT_REGION_VERIFICATION
  );

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    onConfirm: () => navigate(routes.signIn, { replace: true }),
    modalAppearanceEventMessage: 'Error: Auth Error',
  });

  const {
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserFavOffers,
    updateUserPhoneNumber,
    updateUserSkillsTestStatus,
    updateUserInfo,
    updateRequiredAcceptanceAgreementInfo,
    updateUserLastKnownLoyaltyTier,
    user,
    userLoading,
    useUpdateMeMutationLoading,
    currentUserSession,
  } = useCurrentUser({
    openErrorDialog,
    hasSignedIn: userHasSignedIn,
    userSession,
  });
  const {
    authLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signInSocialLogin,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
    getAndValidateAcceptanceAgreementFromSanity,
  } = useAccountAuthentication({
    refreshCurrentUser,
    openErrorDialog,
    setCurrentUser,
  });

  // use cognito to determind if user is authed or not. Prevent showing un-auth view to auth user.
  // only use userHasSignedIn while waiting for user response.
  const authenticatedUser = typeof user !== 'undefined' ? !!user : userHasSignedIn;
  const isAuthenticated = useCallback(() => {
    return authenticatedUser;
  }, [authenticatedUser]);

  useEffect(() => {
    // update the user information in the Datadog RUM session, if initialised
    if (!datadogRum.getInitConfiguration()) {
      return;
    }
    if (!user?.cognitoId) {
      datadogRum.removeUser();
      return;
    }
    datadogRum.setUser({
      id: user.cognitoId,
      loyaltyId: user.loyaltyId ?? null,
    });
  }, [user?.cognitoId, user?.loyaltyId]);

  const getCtx = useCallback(() => {
    return {
      // Computed values
      loading: authLoading || userLoading,
      useUpdateMeMutationLoading,
      originLocation,
      user,
      currentUserSession,
      // Functions
      getCurrentSession,
      refreshCurrentUser,
      refreshCurrentUserWithNewSession,
      isAuthenticated,
      signIn,
      signInSocialLogin,
      signOut,
      signUp,
      updateUserInfo,
      updateUserCommPrefs,
      updateUserFavStores,
      updateUserFavOffers,
      updateUserPhoneNumber,
      updateUserSkillsTestStatus,
      updateUserLastKnownLoyaltyTier,
      validateLogin,
      validateLoginOtp,
      userHasSignedIn,
      setOriginLocation: (args: null | string) => setOriginLoc(args),
    };
  }, [
    authLoading,
    userLoading,
    useUpdateMeMutationLoading,
    originLocation,
    user,
    currentUserSession,
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    isAuthenticated,
    signIn,
    signInSocialLogin,
    signOut,
    signUp,
    updateUserInfo,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserFavOffers,
    updateUserPhoneNumber,
    updateUserSkillsTestStatus,
    updateUserLastKnownLoyaltyTier,
    validateLogin,
    validateLoginOtp,
    userHasSignedIn,
    setOriginLoc,
  ]);

  useEffect(() => {
    // save redirect url so user can get redirected correctly after login
    LocalStorage.setItem(StorageKeys.AUTH_REDIRECT, { callbackUrl: originLocation });
  }, [originLocation]);

  useEffect(() => {
    decorateLogger({ is: userSession?.isValid, idToken: userSession?.getIdToken });
  }, [userSession, decorateLogger]);

  useEffect(() => {
    if (hasNotAuthenticatedError) {
      if (signOutOnInvalidToken) {
        signOut();
        logger.warn({
          message: 'User was signed out cause token was invalid',
        });
      }

      setHasNotAuthenticatedError(false);
    }
  }, [
    hasNotAuthenticatedError,
    setHasNotAuthenticatedError,
    logger,
    signOut,
    signOutOnInvalidToken,
  ]);

  useEffect(() => {
    if (enableAccountRegionVerification && user?.details?.isoCountryCode) {
      const userRegion = ISOsToRegions[user.details.isoCountryCode];

      // Make sure we have values for the current region and user region
      if (region && userRegion && region !== userRegion) {
        const sessionEmail = currentUserSession?.getIdToken().payload.email;
        const gqlEmail = user?.details?.email;

        // If the session email does not match the user details returned by the
        // GQL query wait for it to be updated before logging the user out
        if (sessionEmail !== gqlEmail) {
          refreshCurrentUser();
          return;
        }

        // Force sign out
        signOut();

        // Show detailed error message
        openErrorDialog({
          title: formatMessage({ id: 'authRegionErrorTitle' }),
          message: formatMessage({ id: 'authRegionErrorDetails' }),
        });
      }
    }
  }, [
    currentUserSession,
    enableAccountRegionVerification,
    formatMessage,
    openErrorDialog,
    refreshCurrentUser,
    region,
    signOut,
    user,
  ]);

  const clickContinue = useCallback(
    async (updateAgreementAcceptance: UpdateAgreementAcceptance) => {
      const result = await updateRequiredAcceptanceAgreementInfo(updateAgreementAcceptance);

      if (result) {
        setHideAgreementsModal(true);
        setAgreementTerms(null);
      }
    },
    [updateRequiredAcceptanceAgreementInfo]
  );

  const onClickDisagree = useCallback(() => {
    setHideAgreementsModal(true);
    navigate(routes.signOut);
  }, [navigate]);

  const agreementsMparticleData = useMemo(
    () => ({
      modalAppearanceEventMessage: 'Agree or disagree updated terms',
      modalHeader: formatMessage({ id: 'updatedAgreements' }),
      modalMessage: formatMessage({ id: 'reviewAcceptUpdatedAgreement' }),
    }),
    [formatMessage]
  );

  const commPrefs = useMemo(() => user?.details?.communicationPreferences, [user]);

  useEffect(() => {
    if (!user) {
      setAgreementTerms(null);
      setHideAgreementsModal(false);
      return;
    }

    if (!agreementTerms?.length) {
      setHideAgreementsModal(false);
      const requiredTerms = getAndValidateAcceptanceAgreementFromSanity(
        user?.details?.requiredAcceptanceAgreementInfo
      );

      const updatedTerms = requiredTerms?.length ? requiredTerms : null;
      setAgreementTerms(updatedTerms);
    }
  }, [getAndValidateAcceptanceAgreementFromSanity, agreementTerms, user]);

  return (
    <AuthContext.Provider value={getCtx()}>
      {children}
      <ErrorDialog />
      {!!agreementTerms?.length && !hideAgreementsModal && (
        <ModalAgreement
          commPrefs={commPrefs}
          requiredTerms={agreementTerms}
          continueUpdate={clickContinue}
          cancel={onClickDisagree}
          mParticleEventData={agreementsMparticleData}
        />
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext.Consumer;
