import { useBrazeContentCardsQuery } from 'generated/sanity-graphql';

export const useBrazeContentCards = (id?: string) => {
  const brazeContentCardsId = id ?? '';
  const { data, loading } = useBrazeContentCardsQuery({
    variables: {
      brazeContentCardsId,
    },
  });

  const brazeContentCards = data?.BrazeContentCards;

  return {
    brazeContentCards,
    brazeContentCardsLoading: loading,
  };
};
