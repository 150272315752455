import { onError } from '@apollo/client/link/error';
import { getOperationName } from '@apollo/client/utilities';

import {
  DeleteLoyaltyCardDocument,
  GetLoyaltyCardDetailsDocument,
  GetLoyaltyCardDetailsFromBarcodeDocument,
  GetLoyaltyCardTransactionsDocument,
  GetLoyaltyCardsDocument,
  MergeLoyaltyCardsDocument,
} from 'generated/rbi-graphql';
import { CustomEventNames, EventTypes, IMParticleCtx } from 'state/mParticle';

const MPARTICLE_FAILURE_STATUS = 'Failure';

export const withErrorMParticle = onError(({ operation, graphQLErrors }) => {
  const mParticle: IMParticleCtx = operation.getContext().mParticle;

  if (!graphQLErrors || !mParticle) {
    return;
  }

  /**
   * Custom error cases
   */
  switch (operation.operationName) {
    case getOperationName(GetLoyaltyCardTransactionsDocument):
    case getOperationName(DeleteLoyaltyCardDocument):
    case getOperationName(GetLoyaltyCardsDocument):
    case getOperationName(GetLoyaltyCardDetailsDocument):
    case getOperationName(GetLoyaltyCardDetailsFromBarcodeDocument):
    case getOperationName(MergeLoyaltyCardsDocument):
      mParticle.logEvent(CustomEventNames.LOYALTY_LOAD, EventTypes.Other, {
        Status: MPARTICLE_FAILURE_STATUS,
        queryName: operation.operationName,
      });
      break;
    default:
  }
});
