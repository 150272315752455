import React, { MouseEvent, ReactNode } from 'react';

import { IConfirmProps } from 'components/confirm-dialog';
import { IUserOrderFragment } from 'generated/rbi-graphql';
import { TLocalizationKey } from 'types/i18n';

export interface IBottomServiceModeProps {}

export interface IBottomServiceModeDetail {
  heading: string;
  details: string;
  icon: ReactNode;
  buttonContentText: string;
  buttonContentTextLabel?: string;
  buttonContentIcon: ReactNode;
  buttonBadgeContentText?: string | number;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onBottomServiceClick?: () => void;
  buttonDisabled?: boolean;
  variant?: BottomServiceVariants;
  CancellationModal: React.FC;
  ConfirmationModal: React.FC<Pick<IConfirmProps, 'heading' | 'body'>>;
  refetchUserOrder: () => void;
  isLocatorUIRefreshEnabled?: boolean;
}

export interface IOrderInProgress {
  recentOrder: IUserOrderFragment | null | undefined;
  fireOrderAhead: number;
  curbsidePickupOrderId: string;
  curbsidePickupOrderTimePlaced: string;
  isDeliveryOrderCompleted: boolean;
}
export interface IOrderInProgressResult {
  headingId: TLocalizationKey;
  detailsId: TLocalizationKey;
  icon: ReactNode;
  buttonContentTextId: TLocalizationKey;
  buttonContentIcon: ReactNode;
  variant: BottomServiceVariants;
}

export interface IBottomServiceModeViewProps
  extends Omit<
    IBottomServiceModeDetail,
    'CancellationModal' | 'ConfirmationModal' | 'refetchUserOrder'
  > {
  className?: string;
  isLoading?: boolean;
  isLocatorUIRefreshEnabled?: boolean;
}

export enum BottomServiceVariants {
  DEFAULT = 'DEFAULT',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_STORE = 'NO_STORE',
  IN_RESTAURANT_LOYALTY = 'IN_RESTAURANT_LOYALTY',
}
