import { IPriceOrderParams } from 'hooks/price-order/types';
import { IPriceOrderInput } from 'remote/queries/order';

import { buildCartInput } from './build-cart-input';
import { redeemReward } from './redeem-reward';

type BuildPriceOrderInputParams = Omit<IPriceOrderParams, 'redeemReward'>;

export function buildPriceOrderInput(params: BuildPriceOrderInputParams): IPriceOrderInput {
  return {
    ...buildCartInput(params),
    rewardsApplied: params.rewardsApplied,
    cartVersion: params.cartVersion,
    customerLocale: params.customerLocale,
    customerName: params.customerName,
    loyaltyBarcode: params.loyaltyBarcode,
    paymentMethod: params.cardType,
    appliedOffers: params.appliedOffers,
    requestedAmountCents: params.requestedAmountCents,
    redeemReward,
    vatNumber: params.store.vatNumber,
  };
}
