import { useTimsRewardsPointsSectionQuery } from 'generated/sanity-graphql';

export const useTimsRewardPointsSection = (id?: string) => {
  const timsRewardsPointsSectionId = id ?? '';
  const { data, loading } = useTimsRewardsPointsSectionQuery({
    variables: {
      timsRewardsPointsSectionId,
    },
    skip: !timsRewardsPointsSectionId,
  });

  const pointsSection = data?.TimsRewardsPointsSection;

  return {
    pointsSection,
    pointsSectionLoading: loading,
  };
};
