import styled from 'styled-components';

import { isIOS, isNative } from 'utils/environment';

export const StyledLogo = styled.div`
  height: 50px;
  margin: 0 auto ${isNative && !isIOS() ? -3 : 0}px;
  width: 50px;
  ${Styles.headerMobile`
    padding: 6px;
  `}
`;

export const TopServiceModeContainer = styled.div`
  box-shadow: ${Styles.boxShadow};
  z-index: 1;
`;
