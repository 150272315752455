import React, { useRef, useState } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import useOnClickOutside from 'hooks/use-on-click-outside';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useReversedUIContext } from 'state/reversed-ui';

import ListOfLinks from './list-of-links';
import NavMenuItem from './nav-menu-item';
import theme from './theme';

const Grad = styled.div`
  position: sticky;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 3rem;
  pointer-events: none;
  z-index: ${Styles.zIndex.normal};
  &:first-child {
    margin-bottom: -2rem;
    top: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
  &:last-child {
    margin-top: -2rem;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
`;

const DropdownContainer = styled.div`
  opacity: ${p => (p.visible ? 1 : 0)};
  position: absolute;
  top: 78px;
  left: ${p => p.left}px;
  width: 320px;
  display: inline-block;
  box-sizing: border-box;
  background: ${Styles.color.white};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 10%);
  transform: ${p => (p.visible ? 'translate(-50%, 0rem)' : 'translate(-50%, -1rem)')};
  transition: opacity 200ms ease-out, transform 200ms ease-out, visibility 200ms ease-out;
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  z-index: ${Styles.zIndex.top};
  & > div {
    max-height: calc(90vh - 78px);
    padding: 0rem 2rem;
    overflow-y: auto;
  }
  &:after {
    position: absolute;
    display: block;
    content: '';
    border: medium solid ${Styles.color.white};
    border-width: 0px 15px 15px 15px;
    border-color: transparent transparent ${Styles.color.white} transparent;
    top: 1px;
    left: 50%;
    transform: translateY(-100%) translateX(-50%);
  }
`;

function NavItemWithClickOutsideListener(props) {
  const mParticle = useMParticleContext();
  const OutsideClickWrapper = useOnClickOutside(props.onDismiss);

  const createOnStaticPageClick = attr => () => {
    mParticle.trackEvent({
      name: CustomEventNames.BUTTON_CLICK_STATIC_PAGES,
      type: EventTypes.Navigation,
      attributes: attr,
    });
  };

  return (
    <OutsideClickWrapper>
      <DropdownContainer
        id={props.id}
        visible={props.visible}
        aria-expanded={props.visible}
        left={props.left}
      >
        <div>
          {theme.showGradient && <Grad />}
          <ListOfLinks {...props} trackStaticPage={createOnStaticPageClick} />
          {theme.showGradient && <Grad />}
        </div>
      </DropdownContainer>
    </OutsideClickWrapper>
  );
}

export default function Nav(props) {
  const [visible, setVisible] = useState(false);
  const [left, setLeft] = useState(0);
  const ref = useRef();
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();

  const revealDropdown = () => {
    setVisible(v => !v);
    const node = ReactDOM.findDOMNode(ref.current).getBoundingClientRect();
    setLeft((node.right + node.left) / 2);
  };

  return (
    <>
      <NavMenuItem
        data-testid="about-menu-entries"
        onClick={revealDropdown}
        className="ignore-react-onclickoutside"
        aria-expanded={visible}
        aria-controls="aboutMenuEntries"
        ref={ref}
        reversed={reversedUI}
      >
        {props.title}
        <VisuallyHidden>
          {formatMessage({
            id: 'toggleMenu',
          })}
        </VisuallyHidden>
      </NavMenuItem>
      {ReactDOM.createPortal(
        <NavItemWithClickOutsideListener
          id="aboutMenuEntries"
          left={left}
          visible={visible}
          onDismiss={() => {
            setVisible(false);
          }}
          {...props}
        />,
        document.body
      )}
    </>
  );
}
