import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { MobileHeaderContainer } from 'components/app-header/mobile-header/styled';
import useGoBack from 'hooks/use-go-back';
import { useNativeStatusBar } from 'hooks/use-native-status-bar';

import { BackArrowContainer, SignUpHeader } from './signup-mobile-header.styled';

// The types for react native do not support `dataSet` yet, but
// it is the required way to pass data-* attributes to the node.
// And we are using this mechanism to ensure these nodes are rendered
// during the pre-render, but only visible for the correct screen size.
const onlyRenderInMobile = () =>
  ({
    dataSet: { mediaquery: 'mobile' },
  } as any);

const SignupMobileHeader = () => {
  const { formatMessage } = useIntl();
  const { nativeStatusBarExist } = useNativeStatusBar();
  const goBack = useGoBack();

  return (
    <MobileHeaderContainer
      {...onlyRenderInMobile()}
      testID="signup-mobile-header"
      data-testid="signup-mobile-header"
    >
      <SignUpHeader nativeStatusBarExist={nativeStatusBarExist}>
        <BackArrowContainer onPress={goBack}>
          <Icon
            icon="back"
            color="icon-default"
            width="24px"
            title={formatMessage({ id: 'back' })}
          />
        </BackArrowContainer>
      </SignUpHeader>
    </MobileHeaderContainer>
  );
};

export default SignupMobileHeader;
