import styled from 'styled-components';

export const StyledLogo = styled.div`
  margin: 0 auto;
  width: 130px;

  & > svg {
    max-height: inherit;
    width: inherit;
  }
`;

export const TopServiceModeContainer = styled.div``;
