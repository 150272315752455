import { ILoyaltyCustomizableContent } from '@rbi-ctg/menu';
import { QUERY_TYPE } from 'remote/constants';
import { GetAllLoyaltyContentQuery } from 'remote/queries/fragments/loyalty-content';

import { CustomizableContentError } from '../exceptions';

export const getLoyaltyCustomizableContent = () => {
  return {
    queryType: QUERY_TYPE.SanityGraphqlQuery,
    maxRetries: 3,
    query: GetAllLoyaltyContentQuery,
    then: (result: { allLoyaltyContents: [ILoyaltyCustomizableContent] }) =>
      result.allLoyaltyContents[0],
    catch: (error: Error) => {
      throw new CustomizableContentError(
        'An error occurred loading customizable loyalty content',
        error
      );
    },
  };
};
