import { useMemo } from 'react';

import { selectors, useAppSelector } from 'state/global-state';
import { useOffersContext } from 'state/offers';

export const useRewardQuantity = () => {
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const offer = useOffersContext();

  return useMemo(() => {
    const rewardQuantity: number =
      appliedLoyaltyRewardsArray?.reduce(
        (accAppliedReward, appliedReward) => appliedReward.timesApplied + accAppliedReward,
        0
      ) || 0;
    const legacyOfferQuantity: number = offer.selectedOfferCartEntry ? 1 : 0;
    const offerQuantity: number = appliedOffers?.length || 0;

    return offerQuantity + legacyOfferQuantity + rewardQuantity;
  }, [appliedLoyaltyRewardsArray, appliedOffers, offer.selectedOfferCartEntry]);
};
