import { gql } from '@apollo/client';

import { ISanityTextNode } from '@rbi-ctg/menu';

import {
  INutritionExplorerCombo,
  INutritionExplorerItem,
  NutritionExplorerComboFragment,
  NutritionExplorerItemFragment,
} from './nutrition-explorer-menu';

const TastySwapFragment = gql`
  fragment TastySwapFragment on TastySwap {
    swapTitle {
      locale: _LANG_
    }
    selectedProduct {
      ...NutritionExplorerItemFragment
      ...NutritionExplorerComboFragment
    }
    suggestedProduct {
      ...NutritionExplorerItemFragment
      ...NutritionExplorerComboFragment
    }
  }
  ${NutritionExplorerItemFragment}
  ${NutritionExplorerComboFragment}
`;

export interface ISanityTastySwap {
  swapTitle: ISanityTextNode;
  selectedProduct: INutritionExplorerItem | INutritionExplorerCombo;
  suggestedProduct: INutritionExplorerItem | INutritionExplorerCombo;
}

export interface ISanityTastySwaps {
  mainTitle: ISanityTextNode;
  mainSubtitle: ISanityTextNode;
  modalTitle: ISanityTextNode;
  modalSubtitle: ISanityTextNode;
  swaps: ISanityTastySwap[];
}

export default gql`
  fragment TastySwapsFragment on TastySwaps {
    mainTitle {
      locale: _LANG_
    }
    mainSubtitle {
      locale: _LANG_
    }
    modalTitle {
      locale: _LANG_
    }
    modalSubtitle {
      locale: _LANG_
    }
    swaps {
      ...TastySwapFragment
    }
  }
  ${TastySwapFragment}
`;
