import React, { ReactNode } from 'react';

import { StyledLinkExternal } from 'components/app-header/styled';
import { useReversedUIContext } from 'state/reversed-ui';

interface INavLinkExternal {
  reversed: boolean;
  matchRoute?: string | undefined;
  children?: ReactNode;
  isSelected: boolean;
  noBorder: boolean;
}

export const NavLinkExternal: React.FC<INavLinkExternal> = ({ ...props }) => {
  const { reversedUI } = useReversedUIContext();
  return <StyledLinkExternal {...props} reversed={reversedUI} />;
};

export default NavLinkExternal;
