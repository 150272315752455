import styled from 'styled-components';

import Modal, { ModalSize } from 'components/modal';
import Picture from 'components/picture';
import { fadeIn } from 'utils/style/animations';

import theme from './theme';

export const StyledModal = styled(Modal).attrs({ size: ModalSize.SMALL })`
  display: flex;
  align-items: center;
  padding: ${theme.modalPadding};
  height: 100%;
  animation: ${fadeIn} 0.5s forwards;
  && {
    flex-direction: column;
  }
  ${Styles.mobileTiny`
    padding: 2rem 3.75rem 1.5rem;
  `}
  ${Styles.desktop`
    min-height: 600px;
    max-width: 400px;
  `}
`;

export const Header = styled.h1`
  font-family: ${Styles.fontFamily.brand};
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  margin: 0;
  width: 200px;
  letter-spacing: 0.8px;
  ${Styles.mobileTiny`
    font-size: 1.75rem;
  `}
`;

export const ItemText = styled(Header)`
  text-transform: ${theme.itemNameTextTransform};
  font-size: 1.25rem;
  ${Styles.mobileTiny`
    font-size: 1rem;
  `}
`;

export const Body = styled.p`
  text-align: center;
  margin: 0.5rem 0;
`;

export const StyledPicture = styled(Picture)`
  height: 10rem;
  width: 10rem;
  margin: 1.5rem 0;
  ${Styles.mobileTiny`
    margin: 1rem 0;
  `}
`;
