import noop from 'utils/noop';

import { IUseSelectedReward } from './types';

/**
 *
 * TH ONLY -- stub for BK/PLK
 *
 */
export const useSelectedReward = (): IUseSelectedReward => {
  return {
    dismissUpdateError: noop,
    errorUpdatingRewardTier: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChangeRewardTier: (tierId: string | null) => Promise.resolve(),
    selectedRewardTierId: null,
    updatingRewardTier: false,
  };
};
