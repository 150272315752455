import styled from 'styled-components';

import { ButtonContainer as DefaultButtonContainer } from './styled.default';
import theme from './theme';

export * from './styled.default';

export const ButtonContainer = styled(DefaultButtonContainer)`
  ::after {
    border-radius: 10px;
    background: ${theme.badge.color};
`;
