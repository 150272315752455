import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-column: 1 / -1;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid ${Styles.color.grey.four};
`;
