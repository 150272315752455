import { Language, asLanguage, getDefaultLanguage } from '@rbilabs/intl';

import { brand } from 'utils/environment';

import { getNavigatorLanguage } from './get-navigator-language';
import { loadRegion } from './load-region';

export function inferLanguageFromNavigator(): Language {
  const navigatorLanguage = getNavigatorLanguage();
  // If language not set return default language
  return asLanguage(navigatorLanguage.split('-')[0]) || getDefaultLanguage(brand(), loadRegion());
}
