import React, { ReactNode } from 'react';

import dlv from 'dlv';
import { Maybe } from 'graphql/jsutils/Maybe';

import { ICustomizableContent, ISanityBlockContent } from '@rbi-ctg/menu';

export const replaceKeyRegex = (key: string) => new RegExp(`{{${key}}}`, 'g');

const insertValue =
  (value: ReactNode) =>
  (acc: ReactNode, next: ReactNode): ReactNode => {
    return (
      <>
        {acc}
        {value}
        {next}
      </>
    );
  };

const recurseChildren = (output: ReactNode, [key, value]: [string, ReactNode]): ReactNode => {
  return React.Children.map(output, child => {
    if (!child) {
      return '';
    }

    if (typeof child === 'string') {
      const separated = child.split(replaceKeyRegex(key));
      if (separated.length === 1) {
        return separated[0];
      }
      // @ts-ignore by not providing an initial value, the
      // first element in the array is used as the starting value
      // for 'accumulator', which is what we want
      return separated.reduce<ReactNode>(insertValue(value));
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, {}, recurseChildren(child.props.children, [key, value]));
    }

    return child;
  });
};

type TextNode = Maybe<{
  readonly __typename?: 'LocaleString' | undefined;
  readonly locale: Maybe<string>;
}>;

export const getLocalizedValue = (
  content: ICustomizableContent | TextNode,
  path: string,
  values: { [key: string]: ReactNode } = {}
): ReactNode | ISanityBlockContent[] => {
  if (!content) {
    return '';
  }
  const locale: string | ISanityBlockContent[] = dlv(content, path, '');

  // if we are receiving sanity block content attempt to interpolate on children
  // todo: figure out how to interpolate html here
  if (Array.isArray(locale)) {
    return (locale as ISanityBlockContent[]).map(blockContentObject => ({
      ...blockContentObject,
      children: blockContentObject.children.map(spanObject => ({
        ...spanObject,
        text: Object.entries(values).reduce(
          (output, [key, value]) => output.replace(replaceKeyRegex(key), value as string),
          spanObject.text
        ),
      })),
    }));
  }

  return Object.entries(values).reduce<ReactNode>(recurseChildren, locale);
};

export { format, formatRelative } from './format-date';
