import React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';

import {
  ButtonContent,
  ButtonTextContainer,
  PointsIcon,
  RewardLockContainer,
  StyledActionButton,
  StyledIconCirclePlus,
  StyledIconCircleTimes,
  StyledRewardLock,
} from './redeem-reward.styled';
import { RedeemRewardActionButtonProps, RewardActions } from './types';

const RedeemRewardActionButton = ({
  action,
  disabled,
  handleRewardClick,
  points,
  locked,
}: RedeemRewardActionButtonProps) => {
  const { formatMessage } = useIntl();
  const isAdd = action === RewardActions.ADD;

  return (
    <StyledActionButton
      size={ActionButtonSizes.SMALL}
      variant={isAdd ? ActionButtonVariants.PRIMARY : ActionButtonVariants.OUTLINE}
      disabled={disabled}
      onClick={handleRewardClick(action)}
      data-testid={`redeem-${isAdd ? 'add' : 'remove'}`}
    >
      <ButtonContent>
        <PointsIcon />
        <ButtonTextContainer>
          {locked
            ? points
            : formatMessage({ id: isAdd ? 'redeemApply' : 'redeemRemove' }, { points })}
        </ButtonTextContainer>
        {locked ? (
          <RewardLockContainer>
            <StyledRewardLock />
          </RewardLockContainer>
        ) : disabled ? null : isAdd ? (
          <StyledIconCirclePlus width="18" />
        ) : (
          <StyledIconCircleTimes width="18" />
        )}
      </ButtonContent>
    </StyledActionButton>
  );
};

export default RedeemRewardActionButton;
