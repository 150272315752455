import { useEffect, useState } from 'react';

import isEqual from 'react-fast-compare';

import { IOffer } from '@rbi-ctg/menu';
import { IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { UIPattern } from 'state/offers/types';
import { RuleType, findOfferIndexByUniqId } from 'utils/offers';

export interface IUsePromoCodeOfferProps {
  sortedOffers: IOffer[];
  offersFeedback: Record<string, IOfferFeedbackEntryFragment> | undefined;
}

/**
 * @name shouldIncludePromoCodeOffer
 * @description
 *    promo code offers will be marked as isRedeemable = false until the user has unlocked
 *     the offer using a valid promo code
 *    unlike non-promo code offers, we still sometimes want to include these unredeemable offers
 *    -> ex. so that the user can enter the promo code to unlock the offer
 *
 *    WHEN WE WANT TO INCLUDE:
 *    -> ONLY ruleset is for requires-assignment
 *    -> has ruleset(s) other than requires-assignment, AND all all these other rulesets are passing
 * @returns {boolean}
 */
const shouldIncludePromoCodeOffer = (
  offer: IOffer,
  offersFeedback: Record<string, IOfferFeedbackEntryFragment>
) => {
  const offerFeedback = offersFeedback[offer._id]?.redemptionEligibility?.evaluationFeedback;
  if (!offerFeedback) {
    return true;
  }
  const shouldInclude = offerFeedback.reduce((acc, curRule) => {
    if (curRule?.ruleSetType !== RuleType.RequiresAssignment && !curRule?.condition) {
      return false;
    }
    return acc;
  }, true);
  return shouldInclude;
};

/**
 * @name usePromoCodeOffers
 * @description
 *   promo code offers are displayed on the offers page and are separate
 *   from cart promo code offers (ui pattern: CART_PROMO) because they
 *   have a completely separate UX.
 */
export default function usePromoCodeOffers({
  sortedOffers = [],
  offersFeedback = {},
}: IUsePromoCodeOfferProps) {
  const enablePromoCodeOffers = useFlag(LaunchDarklyFlag.ENABLE_PROMO_CODE_OFFERS);
  const [promoCodeOffers, setPromoCodeOffers] = useState<IOffer[]>([]);

  // update promo code offers when sortedOffers changes
  useEffect(() => {
    if (enablePromoCodeOffers) {
      // only if promo code offers are enabled via LD
      // get all promo code offers
      const offers = sortedOffers.reduce((acc, offer: IOffer) => {
        if (offer.uiPattern === UIPattern.PROMO) {
          const isRedeemable = !!offersFeedback[offer._id]?.redemptionEligibility?.isRedeemable;
          const shouldInclude = shouldIncludePromoCodeOffer(offer, offersFeedback);
          if (shouldInclude) {
            acc.push({ ...offer, isRedeemable });
          }
        }
        return acc;
      }, [] as IOffer[]);
      setPromoCodeOffers(previousOffers =>
        isEqual(previousOffers, offers) ? previousOffers : offers
      );
    }
  }, [enablePromoCodeOffers, offersFeedback, sortedOffers]);

  /**
   * enable toggling isRedeemable true / false
   * so that we don't have to re-fetch offers
   * unless absolutely necessary
   */
  const togglePromoCodeOfferRedeemable = (offerId: string) => {
    if (!offerId) {
      return;
    }
    const offerIdx = findOfferIndexByUniqId(offerId, promoCodeOffers);
    if (offerIdx === -1) {
      return;
    }
    const offer = { ...promoCodeOffers[offerIdx] };
    offer.isRedeemable = !offer.isRedeemable;
    const updatedOffers = [
      ...promoCodeOffers.slice(0, offerIdx),
      offer,
      ...promoCodeOffers.slice(offerIdx + 1),
    ];
    setPromoCodeOffers(updatedOffers);
  };

  return {
    promoCodeOffers,
    togglePromoCodeOfferRedeemable,
  };
}
