import { mediaQuery } from '@rbilabs/components-library/build/globalStyles';
import { FlattenSimpleInterpolation, css } from 'styled-components';

import { isNative } from 'utils/environment';

type CustomMediaQueriesType = { [key: string]: string };

const customMediaQueries: CustomMediaQueriesType = {
  headerMobile: 'screen and (max-width: 1199px)',
};

type MediaQueryTaggedTemplate = (
  template: TemplateStringsArray,
  ...args: string[]
) => FlattenSimpleInterpolation;

export type MediaQueries = {
  headerMobile: MediaQueryTaggedTemplate;
  mobile: MediaQueryTaggedTemplate;
  mobileTiny: MediaQueryTaggedTemplate;
  mobileSmall: MediaQueryTaggedTemplate;
  mobileLandscape: MediaQueryTaggedTemplate;
  desktop: MediaQueryTaggedTemplate;
  desktopLarge: MediaQueryTaggedTemplate;
  mobileFullscreen: MediaQueryTaggedTemplate;
};

const mediaQueries: MediaQueries = {
  mobile: (template, ...args) => css`
    @media ${mediaQuery.mobile} {
      ${css(template, ...args)}
    }
  `,
  headerMobile: (template, ...args) => css`
    @media ${customMediaQueries.headerMobile} {
      ${css(template, ...args)}
    }
  `,
  mobileTiny: (template, ...args) => css`
    @media ${mediaQuery.mobileTiny} {
      ${css(template, ...args)}
    }
  `,
  mobileSmall: (template, ...args) => css`
    @media ${mediaQuery.mobileSmall} {
      ${css(template, ...args)}
    }
  `,
  mobileLandscape: (template, ...args) => css`
    @media ${mediaQuery.mobileLandscape} {
      ${css(template, ...args)}
    }
  `,
  desktop: (template, ...args) => css`
    @media ${mediaQuery.desktop} {
      ${css(template, ...args)}
    }
  `,
  desktopLarge: (template, ...args) => css`
    @media ${mediaQuery.desktopLarge} {
      ${css(template, ...args)}
    }
  `,
  mobileFullscreen: (template, ...args) => css`
    @media ${mediaQuery.mobileFullScreen} {
      ${css(template, ...args)}
    }
    ${isNative && css(template, ...args)}/* apply full-screen display logic to capacitor */
  `,
};

export default mediaQueries;
