import { ThemeColorArg, getThemeColor } from '@rbilabs/components-library/build/utils';
import styled, { css } from 'styled-components';

import theme from './theme';
import { BottomServiceVariants } from './types';

//TODO handle colors when background switches
export const mapVariantToColor = (
  variant: BottomServiceVariants,
  isButtonTextColor = false
): ThemeColorArg => {
  switch (variant) {
    case BottomServiceVariants.IN_PROGRESS:
      return 'bsm-in-progress-background-color';
    case BottomServiceVariants.IN_RESTAURANT_LOYALTY:
      return 'background-contrast-loyalty';
    case BottomServiceVariants.DEFAULT:
    default:
      return isButtonTextColor ? 'text-link-default' : 'bsm-default-background-color';
  }
};

export const mapTextToColor = (variant: BottomServiceVariants) => {
  switch (variant) {
    case BottomServiceVariants.IN_RESTAURANT_LOYALTY:
    case BottomServiceVariants.IN_PROGRESS:
      return 'text-reversed';
    case BottomServiceVariants.DEFAULT:
    default:
      return 'text-link-default';
  }
};

export const mapVariantToIconStyles = (variant: BottomServiceVariants) => {
  switch (variant) {
    case BottomServiceVariants.IN_RESTAURANT_LOYALTY:
      return css`
        align-self: flex-start;
        justify-self: center;
        svg {
          height: 1.5rem;
        }
      `;
    default:
      return css`
        align-self: center;
        justify-self: baseline;
      `;
  }
};

export const ButtonContainer = styled.div<{
  $badgeContent: string | number | undefined;
}>`
  grid-area: button;
  align-self: center;
  justify-self: center;
  display: flex;
  position: relative;

  & > :not(:last-child) {
    margin-right: 0.625rem;
  }

  ${({ $badgeContent }) =>
    $badgeContent &&
    css`
      ::after {
        background: ${Styles.color.red};
        color: ${Styles.color.white};
        border-radius: ${Styles.borderRadius};
        content: '${$badgeContent}';
        font-family: ${Styles.fontFamily.base};
        font-size: 0.725rem;
        height: 1.125rem;
        position: absolute;
        right: -0.125rem;
        text-align: center;
        top: -0.5rem;
        width: 1.125rem;
      }
    `}
`;

export const IconContainer = styled.div`
  grid-area: icon;
`;

export const Container = styled.div<{ variant: BottomServiceVariants }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.475rem;
  height: 4.125rem;
  background: ${props => getThemeColor(props.theme, mapVariantToColor(props.variant))};
  cursor: pointer;
  box-shadow: ${p => p.theme.token('bsm-drop-shadow')};

  ${IconContainer}  {
    ${props => mapVariantToIconStyles(props.variant)}
  }
`;

export const LocationOverlayButton = styled.button`
  grid-area: 1 / 1 / span 3 / span 2;
  width: auto;
  min-height: 100%;
  opacity: 0;
  z-index: ${Styles.zIndex.top};
  margin-left: -20%;
  font-family: ${Styles.fontFamily.base};
  ${Styles.desktop`
    margin-left: -5%;
  `}
  cursor: pointer;
`;

export const BottomServiceWrapper = styled.div`
  max-width: ${Styles.layout.sectionMaxWidth};
  color: ${p => p.theme.token(theme.bottomServiceWrapper.color)};
  display: grid;
  grid-template-areas:
    'icon heading button'
    'icon details button';
  grid-template-columns: 2.2rem auto max-content;
  width: 100%;
`;

export const Heading = styled.div<{ variant: BottomServiceVariants }>`
  grid-area: heading;
  font-family: ${Styles.fontFamily.base};
  font-size: 1rem;
  color: ${p => p.theme.token(mapTextToColor(p.variant))};
  padding: 0 0.625rem;
  text-transform: ${theme.textTransform};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0.75rem;
`;

export const Details = styled.div<{ variant: BottomServiceVariants }>`
  grid-area: details;
  color: ${p => p.theme.token(mapTextToColor(p.variant))};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.625rem;
`;

export const AddressStyle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0.75rem;
`;

export const Underline = styled.div`
  background-color: ${p => p.theme.token(theme.underline.color)};
  width: 100%;
  height: 0.125rem;
`;

export const LocationStyle = styled.div`
  display: flex;
  justify-content: space-between;

  ${Styles.desktop`
    height: 1.5rem;
  `}
`;
