import styled from 'styled-components';

export const LanguageSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 1.25rem;
`;

export const Disclaimer = styled.div`
  margin-bottom: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
`;

export const ModalAdditionalContentWrapper = styled.div`
  padding: 0 20px;
`;

export const LanguageSelectionCell = styled.div`
  height: 100%;
  display: flex;
  padding: '12px 24px';
`;
