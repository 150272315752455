import React, { SVGAttributes } from 'react';

export const IconFallbackImage = (props?: SVGAttributes<SVGElement>) => (
  <svg
    width="1333"
    height="1333"
    viewBox="0 0 1333 1333"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="1333" height="1333" fill="black" fillOpacity="0.04" />
    <path
      d="M841 492V842H491V492H841ZM841 442H491C463.5 442 441 464.5 441 492V842C441 869.5 463.5 892 491 892H841C868.5 892 891 869.5 891 842V492C891 464.5 868.5 442 841 442ZM719.5 663.5L644.5 760.25L591 695.5L516 792H816L719.5 663.5Z"
      fill="black"
      fillOpacity="0.3"
    />
  </svg>
);
