import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { routes } from 'utils/routing';

const InvitationCode = () => {
  const mParticle = useMParticleContext();
  const { formatMessage } = useIntl();

  const onInvitationCodeClick = useCallback(() => {
    mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_INVITATION_CODE);
  }, [mParticle]);

  return (
    <ArrowLink
      data-testid="invitation-code"
      to={routes.invitationCode}
      onClick={onInvitationCodeClick}
      prefixIcon="invitationCode"
    >
      {formatMessage({ id: 'invitationCodeLink' })}
    </ArrowLink>
  );
};

export default InvitationCode;
