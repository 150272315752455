import {
  BLIK_PAYMENT_METHOD_PLACEHOLDER,
  CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER,
  CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER,
  PAYPAL_PAYMENT_METHOD_PLACEHOLDER,
  SODEXO_PAYMENT_METHOD_PLACEHOLDER,
  SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER,
} from 'state/payment/constants';
import { IPaymentMethod } from 'state/payment/types';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';

import { IGetAvailablePaymentMethods, ISetupPaymentMethodState } from '../types';

import { getCheckoutPaymentMethodId } from './getCheckoutPaymentMethodId';
import { getPrepaidReloadPaymentMethodId } from './getPrepaidReloadPaymentMethodId';
import { getValidPaymentMethodId } from './getValidPaymentMethodId';
/**
 * Get a valid payment methods list, default, checkout and prepaid payment method ID
 */

const getAvailablePaymentMethods = ({
  accounts,
  applePayCardDetails,
  canUseApplePay,
  canUseGooglePay,
  googlePayCardDetails,
}: IGetAvailablePaymentMethods) => {
  const availablePaymentMethodsWithoutNative = accounts.filter(
    method => !isApplePay(method.fdAccountId) && !isGooglePay(method.fdAccountId)
  );

  // Put Apple/Google Pay on top of the list, after, the prepaid card and finally the CC
  const availablePaymentMethodsWithoutPrepaidOrCash = availablePaymentMethodsWithoutNative.filter(
    method => !method.prepaid && !method.cash
  );

  // Adding Apple Pay if available
  if (canUseApplePay) {
    availablePaymentMethodsWithoutPrepaidOrCash.unshift(applePayCardDetails);
  }
  // Adding Google Pay if available
  if (canUseGooglePay) {
    availablePaymentMethodsWithoutPrepaidOrCash.unshift(googlePayCardDetails);
  }

  return { availablePaymentMethodsWithoutPrepaidOrCash, availablePaymentMethodsWithoutNative };
};

export const getPaymentMethodsState = ({
  paymentMethodId,
  prepaidPaymentMethodId,
  accounts,
  canUseApplePay,
  enableBlik,
  enableCashPayment,
  enablePayPal,
  enableSodexoVoucher,
  enableChequeGourmetVoucher,
  enablePayPalPaycomet,
  enableSodexo,
  enableChequeGourmet,
  enableThDirectPayApplePay,
  enableSaveCard,
  applePayCardDetails,
  canUseGooglePay,
  enableThDirectPayGooglePay,
  googlePayCardDetails,
  paymentMethodHasBeenInit,
}: ISetupPaymentMethodState) => {
  const { availablePaymentMethodsWithoutPrepaidOrCash, availablePaymentMethodsWithoutNative } =
    getAvailablePaymentMethods({
      accounts,
      applePayCardDetails,
      canUseApplePay,
      canUseGooglePay,
      googlePayCardDetails,
    });

  const isDefaultReloadMethodAGiftCard = (paymentMethod?: IPaymentMethod) =>
    !!paymentMethod?.prepaid;

  const isUsersPaymentMethodGiftCard = isDefaultReloadMethodAGiftCard(
    accounts.find(account => account.accountIdentifier === prepaidPaymentMethodId)
  );

  // Get the default prepaid reload card payment method
  const validDefaultReloadPaymentMethodId = getPrepaidReloadPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: prepaidPaymentMethodId,
    canUseApplePay,
    canUseGooglePay,
    applePayCardDetails,
    googlePayCardDetails,
    isUsersPaymentMethodGiftCard,
  });

  // Get the prepaid reload card payment method
  const validReloadPaymentMethodId = getPrepaidReloadPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: prepaidPaymentMethodId,
    canUseApplePay,
    canUseGooglePay,
    applePayCardDetails,
    googlePayCardDetails,
  });

  const prepaidCard = accounts.find(method => !!method.prepaid);
  const cash = accounts.find(method => !!method.cash);

  const availablePaymentMethodList = availablePaymentMethodsWithoutPrepaidOrCash.filter(method => {
    if (!enableSaveCard && method.credit && Object.keys(method.credit).length) {
      return false;
    }

    return true;
  });

  if (prepaidCard) {
    availablePaymentMethodList.unshift(prepaidCard);
  }

  if (enableCashPayment && cash) {
    availablePaymentMethodList.push(cash);
  }

  if (enablePayPal) {
    availablePaymentMethodList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enableSodexoVoucher) {
    availablePaymentMethodList.push(SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enableChequeGourmetVoucher) {
    availablePaymentMethodList.push(CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enableBlik) {
    availablePaymentMethodList.push(BLIK_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enablePayPalPaycomet) {
    availablePaymentMethodList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enableSodexo) {
    availablePaymentMethodList.push(SODEXO_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enableChequeGourmet) {
    availablePaymentMethodList.push(CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER);
  }

  // Get the default payment method
  const validDefaultPaymentMethodId = getValidPaymentMethodId({
    canUseApplePay,
    canUseGooglePay,
    paymentMethodId,
    paymentMethods: availablePaymentMethodList,
    returnFirstValid: true,
  });

  // Get the checkout payment method
  const validCheckoutPaymentMethodId = getCheckoutPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: paymentMethodId,
    canUseApplePay,
    enableThDirectPayApplePay,
    applePayCardDetails,
    canUseGooglePay,
    enableThDirectPayGooglePay,
    googlePayCardDetails,
  });

  return {
    availablePaymentMethodList,
    validDefaultPaymentMethodId,
    validCheckoutPaymentMethodId,
    validReloadPaymentMethodId,
    validDefaultReloadPaymentMethodId,
  };
};
