import styled from 'styled-components';

import { ListItemContent } from 'components/product-list-item/product-list-item.styled';
import { RadioCheckListContainer } from 'components/radio-check-list/radio-check-list.styled';
import { primitive } from 'styles/constants/primitives';

export const ModifierContainer = styled.div<{ isExpanded?: boolean }>`
  ${ListItemContent} {
    background-color: ${props => props.isExpanded && primitive.$blackOpacity4};
  }
`;

export const OptionsContainer = styled.div<{ hasImage: boolean }>`
  ${Styles.mobile`
    button:focus {
      outline: none;
    }
  `}
  ${RadioCheckListContainer} ul {
    ${Styles.desktop`
      padding-left: ${(props: { hasImage: boolean }) => props.hasImage && '5.25rem'}
    `};
  }
`;

export const Container = styled.div`
  background-color: ${Styles.color.cardBackground};
`;

export const StyledControls = styled.div<{ $hasListItemClickListener: boolean }>`
  pointer-events: ${props => props.$hasListItemClickListener && 'none'};
`;

export const ModifierOptionsContainer = styled.div`
  li > *:first-child {
    padding-left: 0;
    margin-left: ${primitive.$spacing4};
    border-bottom: ${p => p.theme.token('border-default')};
  }
  li:last-of-type > *:first-child {
    border-bottom: none;
  }
`;
