import styled, { css } from 'styled-components';

import Link, { LinkExternal } from 'components/link';

const linkStyles = css`
  color: currentColor;
  font-family: ${Styles.fontFamily.base};
  font-size: 1.125rem;
  margin-left: 1.3rem;

  &.active,
  &:hover {
    text-decoration: none;
    color: ${Styles.color.navLinkColor.active};
    font-weight: bold;
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

export const StyledLink = styled(Link)`
  ${linkStyles}
`;

export const StyledLinkExternal = styled(LinkExternal)`
  ${linkStyles}
`;

export const StyledCenterSpacer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  text-align: center;
`;

export const StyledRightSpacer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
