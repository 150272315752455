import styled from 'styled-components';

import { ArrowLink } from 'components/arrow-link';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { brandFont } from 'components/layout/brand-font';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';
import { primitive } from 'styles/constants/primitives';

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: ${primitive.$spacing1};
  align-items: center;
  cursor: pointer;
`;

export const StyledPointsIcon = styled(LoyaltyPointsIcon)`
  margin: 0 ${primitive.$spacing1};
`;

export const StyledRewardsLogo = styled(LoyaltyRewardsLogo)`
  margin-top: ${primitive.$spacing2};
  width: 7.2rem;
  height: 2rem;
`;

export const StyledArrowLink = styled(ArrowLink)`
  &:hover ${StyledPointsIcon} {
    transform: scale3d(1.25, 1.25, 1);
    transition: all 0.35s cubic-bezier(0, 1.27, 0.46, 1.65);
  }
`;

export const Points = styled.p`
  margin: 0 ${primitive.$spacing1} 0 0;
  font: ${brandFont.headerThree};
`;

export const PointsContainer = styled.div<{ $hasLabel?: boolean }>`
  align-items: center;
  color: ${p => p.theme.token('text-default')};
  display: flex;
  font: ${brandFont.headerFour};
  margin: 0 ${primitive.$spacing2} 0 0;
`;
