import React, { useEffect } from 'react';

export type ThreeDsMethodProps = {
  iframeContent: string;
  onComplete: () => void;
};

function executeScripts(html: string) {
  const el = document.createElement('div');
  el.innerHTML = html;
  const scripts = el.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = scripts[i].text;
    document.body.appendChild(script);
  }
}

export const ThreeDsMethod: React.FunctionComponent<ThreeDsMethodProps> = ({
  iframeContent,
  onComplete,
}: ThreeDsMethodProps) => {
  useEffect(() => {
    executeScripts(iframeContent);

    function listener(event: MessageEvent) {
      const origin = new URL(event.origin);
      if (!origin.hostname.endsWith('.rbictg.com')) {
        return;
      }

      if (event.data.type !== '3ds-response') {
        return;
      }

      if (event.data.body.indexOf('cres=') >= 0) {
        return;
      }
      onComplete();
    }

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: iframeContent }} />;
};
