import styled, { keyframes } from 'styled-components';

import { IVisible } from './types';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const Screen = styled.div<IVisible>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  overflow: scroll;
  height: 100vh;
  background-color: ${Styles.color.background};
`;

export const Container = styled.main<IVisible>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  margin: auto;
  animation: ${fadeIn} 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  & iframe {
    height: calc(100% - 100px) !important;
    ${Styles.desktop`height: 100%;`}
  }
  & #pypestream-wrapper {
    top: 50px;
  }
`;

export const LoadingWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  & > svg {
    width: 100%;
    height: 100vh;
    position: absolute;
    inset: 0;
    top: ${Styles.layout.navHeight.mobile};
    ${Styles.desktop`top: ${Styles.layout.navHeight.desktop};`}
  }
`;

export const LoadingWrapperInner = styled.main`
  z-index: ${Styles.zIndex.top};
`;

export const LoadingMessage = styled.h1`
  margin: 0.5rem 0;
  font-size: 1rem;
  text-align: center;
  color: ${Styles.color.white};
`;
