import React, { FC } from 'react';

import Modal from 'components/modal';
import { OnboardingScreensModal } from 'components/onboarding-screens-modal';
import { useLocationContext } from 'state/location';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';

import { IOnboardingContent } from './types';

export const OnboardingContent: FC<IOnboardingContent> = ({ featureOnboardingScreens }) => {
  const { navigate } = useLocationContext();

  const onModalDismiss = () => {
    LocalStorage.setItem(StorageKeys.HAS_DONE_FIRST_APP_LOAD_ONBOARDING, true);
    navigate(routes.base);
  };

  return (
    <Modal
      data-testid={'onboarding-modal'}
      allowsDismiss={false}
      onDismiss={onModalDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Onboarding Triggered',
      }}
    >
      <OnboardingScreensModal
        featureOnboardingScreens={featureOnboardingScreens}
        onModalDismiss={onModalDismiss}
      />
    </Modal>
  );
};
