import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIdCounter } from '@rbilabs/components-library/build/hooks/use-id-counter';
import isUndefined from 'lodash/isUndefined';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ActionButton, { ActionButtonSizes } from 'components/action-button';
import Currency from 'components/currency';
import { MenuObjectTypes } from 'enums/menu';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import Offer from 'pages/cart/your-cart/offer';
import { useAuthContext } from 'state/auth';
import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyRewardsFeedback } from 'state/loyalty/hooks/use-loyalty-rewards-feedback';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { isNative } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';

import CartPreviewEntry from './cart-preview-entry';
import defaultConfig from './config';
import { CHECKOUT_BUTTON_PADDING } from './constants';
import LimitMessage from './limit-message';
import { MinOrderMessage } from './min-order-message';
import MinimumMessage from './minimum-message';
import { ButtonContainer } from './styled/button-container';
import { ActionButtonStyled } from './styled/cart-button';
import { CartPreviewContainer } from './styled/cart-preview-container';
import { EmptyCartMessage } from './styled/empty-cart-message';
import { MessageContainer } from './styled/message-container';
import { TotalsContainer } from './styled/totals-container';
import theme from './theme';

const CartHoverContainer = styled.div`
  box-shadow: ${Styles.boxShadow};
  background: ${Styles.color.white};
  opacity: ${p => (p.visible ? 1 : 0)};
  position: absolute;
  right: 0;
  top: 4.5rem;
  transform: ${p => (p.visible ? 'translateY(0)' : 'translateY(-0.5rem)')};
  transition: all 200ms ease-out;
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};

  ${Styles.desktop`
    border-radius: 10px;
    max-height: calc(100vh - 10rem);
    overflow: hidden;
    z-index: ${Styles.zIndex.normal}
  `}
`;

const CartScrollContainer = styled.div`
  ${Styles.desktop`
    max-height: calc(100vh - 10rem - 203px);
    overflow-y: auto;
    position: relative;
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${Styles.color.black};
  margin-bottom: 0.5rem;

  p {
    font-size: 1rem;
    margin: 0;
  }
`;

export const CartPreviewItem = styled.div`
  border: 1px solid ${Styles.color.grey.four};
  border-radius: ${Styles.borderRadius};
  display: grid;
  grid-template-columns: 2fr 6fr 1fr;
  padding: 0.5rem;
  width: 100%;
  min-height: 102px;

  > div {
    padding: 0 1rem;

    p {
      color: ${theme.cartPreviewItemPColor};
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 16px;
      margin: 0;
    }

    > p:last-of-type {
      margin-top: 14px;
    }
  }
`;

const ButtonContainerShadow = styled.div`
  bottom: 0;
  box-shadow: 0px -4px 4px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  opacity: ${props => (props.boxShadow ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: 0.2s opacity linear;
  right: 0;
`;

class CartPreview extends React.Component {
  state = {
    boxShadow: false,
  };

  constructor(props) {
    super(props);

    this.scrollEl = React.createRef();
  }

  componentDidMount() {
    if (this.scrollEl.current) {
      this.handleBoxShadow(this.scrollEl.current);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartEntries.length !== this.props.cartEntries.length && this.scrollEl.current) {
      this.handleBoxShadow(this.scrollEl.current);
    }
  }

  handleScroll = e => {
    this.handleBoxShadow(e.target);
  };

  handleBoxShadow = scrollEl => {
    const scrollElTop = scrollEl.scrollTop;
    const scrollElHeight = scrollEl.offsetHeight;
    const childHeight = scrollEl.childNodes.length && scrollEl.childNodes[0].offsetHeight;

    if (childHeight && childHeight <= scrollElTop + scrollElHeight) {
      this.setState({ boxShadow: false });
    } else if (!this.state.boxShadow) {
      this.setState({ boxShadow: true });
    }
  };

  handleClickOutside = () => {
    if (this.props.visible) {
      this.props.onDismiss();
    }
  };

  handleCheckout = () => {
    const {
      isStoreOpenAndAvailable,
      openOfferCheckoutConfirmationModal,
      isCartTotalGreaterThanDiscountOfferValue,
    } = this.props;
    if (!isStoreOpenAndAvailable) {
      this.props.navigate(routes.storeLocator);
    } else {
      if (isNative && isCartTotalGreaterThanDiscountOfferValue()) {
        openOfferCheckoutConfirmationModal();
      } else {
        this.props.navigate(routes.cart);
      }
    }
    this.handleClickOutside();
  };

  // Allows users to tab out of the cart preview
  handleOnBlur = event => {
    const childElementIsFocused = event.currentTarget.contains(event.relatedTarget);
    if (!childElementIsFocused) {
      this.props.hideCartPreview();
    }
  };

  render() {
    const {
      calculateCartTotal,
      checkoutPriceLimit,
      canUserCheckout,
      visible,
      isCartEmpty,
      isCartTotalGreaterThanDiscountOfferValue,
      cartPreviewEntries,
      loyaltyEnabled,
      appliedLoyaltyRewards,
      discountOffers,
    } = this.props;

    if (!visible) {
      return null;
    }

    const { boxShadow } = this.state;
    const total = calculateCartTotal();
    return ReactDOM.createPortal(
      <CartHoverContainer
        id={this.props.id}
        visible={visible}
        aria-expanded={visible}
        data-testid="cart-preview"
        onBlur={this.handleOnBlur}
      >
        <CartScrollContainer ref={this.scrollEl} onScroll={this.handleScroll}>
          {(cartPreviewEntries.length > 0 || !!discountOffers?.length) && (
            <CartPreviewContainer>
              {discountOffers?.map(discountOffer => (
                <Offer
                  bgColor="#f4f5f5"
                  fill={Styles.color.grey.five}
                  key={discountOffer._id}
                  borderColor="#b6b6b6"
                  selectedOffer={discountOffer}
                  isValid
                />
              ))}
              {cartPreviewEntries.map(item => {
                return (
                  <CartPreviewEntry
                    closeModalCallback={this.handleClickOutside}
                    key={item.cartId}
                    item={item}
                    rewardApplied={
                      loyaltyEnabled && appliedLoyaltyRewards[item.cartId]?.timesApplied > 0
                    }
                  />
                );
              })}
            </CartPreviewContainer>
          )}
        </CartScrollContainer>
        <ButtonContainer>
          <ButtonContainerShadow boxShadow={boxShadow} />
          <TotalsContainer data-testid="cart-preview-total">
            {isCartEmpty && (
              <EmptyCartMessage>
                <FormattedMessage
                  id="emptyCartMessage"
                  defaultMessage="You don't have anything in your cart yet!"
                  description="Message indicating cart is empty"
                />
              </EmptyCartMessage>
            )}
            <Row>
              <p>
                <b>Total*</b>
              </p>
              <p>
                <b>
                  <Currency amount={total} />
                </b>
              </p>
            </Row>
          </TotalsContainer>
          <MessageContainer>
            <div data-testid="min-order-message">
              {isCartTotalGreaterThanDiscountOfferValue() && <MinOrderMessage />}
            </div>
            <LimitMessage limit={checkoutPriceLimit} totalAmount={total} />
            <MinimumMessage />
          </MessageContainer>
          <Box width="100%" padding={defaultConfig.isSquare ? '0' : CHECKOUT_BUTTON_PADDING}>
            <ActionButton
              fullWidth
              data-testid="checkout-action-button"
              onClick={this.handleCheckout}
              disabled={!canUserCheckout}
              isSquare={defaultConfig.isSquare}
            >
              <FormattedMessage
                id="checkout"
                defaultMessage="Checkout"
                description="Checkout button text"
              />
            </ActionButton>
          </Box>
        </ButtonContainer>
      </CartHoverContainer>,
      document.body
    );
  }
}

const CartPreviewWithClickOutsideListener = onClickOutside(CartPreview);

const WrappedCartPreview = ({ isVisible }) => {
  const { isAuthenticated } = useAuthContext();
  const {
    cartEntries,
    calculateCartTotal,
    checkoutPriceLimit,
    cartPriceLimitExceeded,
    canUserCheckout,
    isCartTotalGreaterThanDiscountOfferValue,
    isCartEmpty,
    cartPreviewEntries,
    numCartPreviewEntries,
  } = useOrderContext();
  useLoyaltyRewardsFeedback(cartEntries);
  const { loyaltyEnabled } = useLoyaltyContext();
  const { selectedOffer: selectedCbaOffer, onCancelRedemption } = useOffersContext();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const cartPreviewID = useIdCounter('cartPreview');
  const [visible, setVisible] = useState(isVisible);
  const { formatCurrencyForLocale } = useUIContext();
  const cartButton = useRef();
  const hide = useCallback(() => setVisible(false), []);
  const { formatMessage } = useIntl();
  const userIsLoggedIn = isAuthenticated();
  const navigate = useNavigate();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const discountAppliedCmsOffer = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);
  const toggleVisible = useCallback(() => setVisible(state => !state), []);
  const prevDiscountAppliedOffers = usePrevious(discountAppliedCmsOffer);
  const isCbaDiscountOffer = selectedCbaOffer?.option?._type === MenuObjectTypes.OFFER_DISCOUNT;
  const { reversedUI } = useReversedUIContext();

  const discountOffers = useMemo(
    () =>
      isCbaDiscountOffer
        ? [selectedCbaOffer]
        : discountAppliedCmsOffer
        ? [discountAppliedCmsOffer]
        : [],
    [discountAppliedCmsOffer, isCbaDiscountOffer, selectedCbaOffer]
  );

  useEffectOnUpdates(() => {
    if (visible || isUndefined(prevDiscountAppliedOffers)) {
      return;
    }
    setVisible(true);

    // Needs setTimeout to work, might be due to conflict
    // w/reach-router's focus management since this is
    // typically triggered at the same time as a route change?
    window.setTimeout(() => {
      cartButton.current?.focus();
    }, 100);
  }, [cartEntries.length, discountOffers?.length]);

  useEffect(() => {
    if (!cartEntries.length) {
      LocalStorage.removeItem(StorageKeys.FREE_MODAL_ITEMS_SHOWED_IN_CART);
    }
  }, [cartEntries]);

  const onConfirmAlert = () => {
    onCancelRedemption();
  };

  const onCancelAlert = () => {
    navigate(routes.menu);
  };

  const [OfferCheckoutConfirmationModal, openOfferCheckoutConfirmationModal] = useDialogModal({
    showCancel: true,
    onConfirm: onConfirmAlert,
    onDismiss: onCancelAlert,
    onCancel: onCancelAlert,
    modalAppearanceEventMessage: 'Valid offers',
  });

  return (
    <>
      <ActionButtonStyled
        variant={defaultConfig.buttonVariant}
        size={ActionButtonSizes.SMALL}
        data-testid="cart-button-desktop"
        onClick={toggleVisible}
        className="ignore-react-onclickoutside"
        aria-expanded={visible}
        aria-controls={cartPreviewID}
        ref={cartButton}
        title="Shopping Cart"
        reversed={reversedUI}
        startIcon={<Icon icon="cart" color="icon-default" aria-hidden />}
        endIcon={false}
      >
        <VisuallyHidden>{formatMessage({ id: 'shoppingCartPreview' })}</VisuallyHidden>
        {defaultConfig.showNumItems
          ? numCartPreviewEntries
          : formatCurrencyForLocale(calculateCartTotal())}
        <VisuallyHidden>
          {defaultConfig.showNumItems
            ? formatMessage({ id: 'items' })
            : formatMessage({ id: 'cartTotal' })}
        </VisuallyHidden>
      </ActionButtonStyled>
      <CartPreviewWithClickOutsideListener
        id={cartPreviewID}
        visible={visible}
        calculateCartTotal={calculateCartTotal}
        checkoutPriceLimit={checkoutPriceLimit}
        discountOffers={discountOffers}
        isStoreOpenAndAvailable={isStoreOpenAndAvailable}
        onDismiss={hide}
        userIsLoggedIn={userIsLoggedIn}
        cartPriceLimitExceeded={cartPriceLimitExceeded}
        openOfferCheckoutConfirmationModal={openOfferCheckoutConfirmationModal}
        canUserCheckout={canUserCheckout}
        isCartTotalGreaterThanDiscountOfferValue={isCartTotalGreaterThanDiscountOfferValue}
        isCartEmpty={isCartEmpty}
        cartPreviewEntries={cartPreviewEntries}
        cartEntries={cartEntries}
        navigate={navigate}
        loyaltyEnabled={loyaltyEnabled}
        appliedLoyaltyRewards={appliedLoyaltyRewards}
        hideCartPreview={hide}
      />
      <OfferCheckoutConfirmationModal
        heading={formatMessage({ id: 'headsUpModalTitle' })}
        body={formatMessage({ id: 'redeemOfferConfirmation' })}
        confirmLabel={formatMessage({ id: 'continueWithoutOffer' })}
        cancelLabel={formatMessage({ id: 'returnToMenu' })}
      />
    </>
  );
};

export default WrappedCartPreview;
