import {
  selectAppliedOffers,
  selectCartEntriesIdsMap,
  selectCmsOffers,
  selectCmsSurpriseOffers,
  selectDiscountAppliedCmsOffer,
  selectExtendedCmsOffersMap,
  selectIncentiveErrorsFilteredByCode,
  selectIncentiveErrorsWithCode,
  selectIncentivesIds,
  selectLoyaltyOffersInCooldown,
  selectOfferFeedbackMap,
  selectOffers,
  selectOffersLoading,
  selectPersonalizedOffers,
  selectSelectedOffer,
  selectSurpriseAvailable,
  selectUpsizeAvailable,
  selectUserOffers,
  selectValidAppliedOffers,
} from './offers/offers.selectors';
import {
  selectAppliedDiscountRewards,
  selectAppliedLoyaltyRewards,
  selectAppliedLoyaltyRewardsArray,
  selectAppliedPoints,
  selectAvailableLoyaltyRewardsMap,
  selectIsPricingRewardApplication,
  selectShouldRefetchRewards,
  selectStagedCartPoints,
  selectTotalTimesRewardApplied,
} from './rewards/rewards.selectors';
import {
  select12DigitSwipeCard,
  selectLoyaltyLoading,
  selectLoyaltyUser,
  selectUser,
} from './user/user.selectors';

export const loyaltySelectors = {
  // Offer actions
  selectAppliedOffers,
  selectCartEntriesIdsMap,
  selectCmsOffers,
  selectCmsSurpriseOffers,
  selectExtendedCmsOffersMap,
  selectIncentiveErrorsFilteredByCode,
  selectIncentiveErrorsWithCode,
  selectIncentivesIds,
  selectOfferFeedbackMap,
  selectOffers,
  selectOffersLoading,
  selectLoyaltyOffersInCooldown,
  selectPersonalizedOffers,
  selectSelectedOffer,
  selectSurpriseAvailable,
  selectUpsizeAvailable,
  selectUserOffers,
  selectValidAppliedOffers,
  selectDiscountAppliedCmsOffer,
  // Rewards actions
  selectAppliedLoyaltyRewards,
  selectAppliedLoyaltyRewardsArray,
  selectAppliedPoints,
  selectAppliedDiscountRewards,
  selectAvailableLoyaltyRewardsMap,
  selectIsPricingRewardApplication,
  selectShouldRefetchRewards,
  selectStagedCartPoints,
  selectTotalTimesRewardApplied,
  // User actions
  select12DigitSwipeCard,
  selectLoyaltyLoading,
  selectUser,
  selectLoyaltyUser,
};
