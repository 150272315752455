import styled, { css } from 'styled-components';

import { ToastType } from './types';

/**
 * ToastView
 */
export const Wrapper = styled.div<{ type: ToastType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 800px;
  min-height: 66px;

  ${({ type }) => {
    return css`
      background: ${Styles.color.alert[type].background};
      color: ${Styles.color.alert[type].text};
    `;
  }}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  ${Styles.desktop`
    max-width: 500px;
  `}
`;
export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: ${Styles.color.white};
`;
export const Message = styled.div`
  white-space: pre-line;
`;
export const Action = styled.button`
  border: none;
  background: none;
  font-family: ${Styles.fontFamily.base};
`;
export const Close = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0.25rem;
  font-family: ${Styles.fontFamily.base};

  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

/**
 * ToastTypeLogo
 */
export const IconContainer = styled.div`
  margin: 0 1rem 0 1rem;
`;
