import { IsoCountryCode } from 'generated/rbi-graphql';
import { getConfigValue } from 'utils/environment';

import { FD_PROXY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFdProxyAndUrl = (country?: IsoCountryCode) => {
  const urlsConfig = getConfigValue('urls');
  const fdUrl = urlsConfig.firstData || urlsConfig.firstDataTh;
  return [FD_PROXY, fdUrl];
};
