import React, { useCallback, useEffect, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import useEffectOnce from 'hooks/use-effect-once';
import useIntl from 'state/intl/hook';
import { getCurrentVersion } from 'utils/appflow';
import { ILoggerOptions, defaultLogger } from 'utils/logger';

import { LoggerContext } from './context';

export const LoggerProvider = ({ children }: IBaseProps) => {
  const [logger, setLogger] = useState(defaultLogger);

  const { region } = useIntl();

  /**
   * decorateLogger allows additional fields to be attached to
   * the logger stored in context and exposed in `useLogger`
   */
  const decorateLogger = useCallback(
    <NewFields extends object>(params: NewFields, options: ILoggerOptions = {}) => {
      setLogger(currentLogger => currentLogger.child(params, options));
    },
    []
  );

  useEffectOnce(() => {
    getCurrentVersion().then(versionData => {
      if (versionData) {
        decorateLogger({
          appflowBuildId: versionData.buildId,
          appVersionCode: versionData.binaryVersionCode,
          appVersionName: versionData.binaryVersionName,
        });
      }
    });
  });

  useEffect(() => {
    decorateLogger({ region });
  }, [decorateLogger, region]);

  return (
    <LoggerContext.Provider value={{ logger, decorateLogger }}>{children}</LoggerContext.Provider>
  );
};
