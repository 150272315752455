import React, { useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IBackendCartEntries, IServerOrder } from '@rbi-ctg/menu';
import ModalRating from 'components/modal-rating';
import OrderCancelledModal from 'components/order-cancelled-modal';
import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';
import { useEtaCounter } from 'hooks/use-eta-counter/';
import useOrderNumber from 'hooks/use-order-number';
import usePolling from 'hooks/use-polling';
import { CartEntry } from 'pages/account/account-orders/details-modal/cart-entry';
import { StyledCartEntries } from 'pages/account/account-orders/details-modal/details-modal.styled';
import getRewardsDiscount from 'pages/account/account-orders/details-modal/receipt-details/get-rewards-discount';
import { getFeesByGroup, getStandardizedName } from 'pages/cart/your-cart/totals/utils';
import fetchDeliveryStatus from 'remote/api/delivery-status';
import { useDeliveryConfirmationContext } from 'state/delivery-confirmation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { DeliveryStatus, OrderStatus } from 'state/order/types';
import { CASH_ACCOUNT_IDENTIFIER } from 'state/payment/constants';
import { ServiceMode } from 'state/service-mode/types';
import { useUIContext } from 'state/ui';
import { computeDeliveryFee } from 'utils/cart/helper';
import { readableHour } from 'utils/restaurant';
import { routes } from 'utils/routing';

import { CashPaymentReminder } from '../cash-payment-reminder';

import CourierDetail from './courier-detail';
import DeliveryProgress from './delivery-progress';
import {
  Card,
  CardHeading,
  CardText,
  DeliveryCardContainer,
  ETA,
  FeeDeliveryItem,
  TextGroup,
  TotalRow,
} from './styled';

interface IDeliveryDetailsProps {
  serverOrder: IServerOrder;
}

// this list is not exhaustive, more statuses can be added
const failedDeliveryStatuses = [
  DeliveryStatus.ORDER_TIMEOUT,
  DeliveryStatus.ORDER_ERROR,
  DeliveryStatus.ORDER_CANCELLED,
];

const DeliveryDetails: React.FC<IDeliveryDetailsProps> = ({ serverOrder }) => {
  const { delivery, rbiOrderId } = serverOrder;
  const [deliveryStatus, setDeliveryStatus] = useState(delivery!.status);
  const [driver, setDriver] = useState(delivery!.driver || null);
  const [dropoff, setDropoff] = useState(delivery?.dropoff);
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const eta = useEtaCounter(deliveryStatus, dropoff);
  const { setBringgUuids } = useDeliveryConfirmationContext();
  const navigate = useNavigate();
  const isCashPayment = serverOrder.cart.payment.cardType === CASH_ACCOUNT_IDENTIFIER;
  const orderNumber = useOrderNumber(serverOrder);
  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const hideTipAmount = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);
  const enableDiscountsOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_DISCOUNTS_ON_ORDER_RECEIPT);
  const enableFeesOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_FEES_ON_ORDER_RECEIPT);
  const enableOverrideDeliveryTime = useFlag(LaunchDarklyFlag.ENABLE_OVERRIDE_DELIVERY_TIME);
  const geographicalFeeLabelIdMap = { CO: 'geographicalFeeCO' };
  const dropoffState = delivery?.dropoff.state;
  const shouldShowAppRating = serverOrder.status === OrderStatus.INSERT_SUCCESSFUL;

  const rewardsDiscountCents = useMemo(
    () =>
      getRewardsDiscount({
        discounts: serverOrder.cart.discounts || [],
        enableDiscountsOnOrderReceipt,
      }),
    [serverOrder, enableDiscountsOnOrderReceipt]
  );

  const fees = serverOrder.cart.fees ?? [];
  const itemFees = getFeesByGroup(fees);

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const { stopPolling } = usePolling({
    queryOptions: fetchDeliveryStatus(rbiOrderId),
    interval: 5000,
    retry: 3,
    onResponse: data => {
      setDropoff(data?.delivery?.dropoff);
      setDeliveryStatus(data?.delivery?.status);
      setDriver(data?.delivery?.driver);
      // Needed to track bringg delivery driver coords
      const share_uuid = data?.delivery?.shareUuid;
      const order_uuid = data?.delivery?.task?.uuid;
      setBringgUuids({ share_uuid, order_uuid });
    },
    onError: () => {
      openErrDialog({
        message: 'There was an error fetching your delivery status. Please try again later.',
      });
    },
  });

  const [ErrorDialog, openErrDialog] = useErrorModal({
    onConfirm: () => navigate(routes.orders),
    modalAppearanceEventMessage: 'Error: Fetching Delivery Details Failure',
  });

  const [CancellationModal, openCancellationModal] = useDialogModal({
    Component: OrderCancelledModal,
    onOpen: stopPolling,
    onDismiss: () => navigate(routes.base),
  });

  useEffect(() => {
    if (deliveryStatus === DeliveryStatus.ORDER_CANCELLED) {
      openCancellationModal();
    }
  }, [deliveryStatus, openCancellationModal]);

  if (!delivery) {
    return null;
  }

  const cardType = serverOrder?.cart?.payment?.cardType || null;
  const ccLast4 = serverOrder?.cart?.payment?.ccLast4 || null;

  const feeCents = delivery?.feeCents || 0;
  const feeDiscountCents = delivery?.feeDiscountCents || 0;
  const geographicalFeeCents = delivery?.geographicalFeeCents || 0;
  const serviceFeeCents = delivery.serviceFeeCents || 0;
  const smallCartFeeCents = delivery?.smallCartFeeCents || 0;
  const deliveryFeeCents = computeDeliveryFee({
    feeCents,
    feeDiscountCents,
    geographicalFeeCents,
    serviceFeeCents,
    smallCartFeeCents,
  });
  const isFreeDelivery = deliveryFeeCents === 0;
  const DeliveryFee = () =>
    isFreeDelivery ? (
      <FeeDeliveryItem tabIndex={0}>
        {formatMessage({ id: 'free' })}
        &nbsp;<s>{formatCurrencyForLocale(feeDiscountCents)}</s>
      </FeeDeliveryItem>
    ) : (
      <CardText tabIndex={0}>{formatCurrencyForLocale(deliveryFeeCents)}</CardText>
    );

  const getDeliveryEtaText = (status: DeliveryStatus): string => {
    switch (status) {
      case DeliveryStatus.DRIVER_AT_CUSTOMER:
        return formatMessage({ id: 'deliveryDriverArrived' });
      case DeliveryStatus.ORDER_DROPPED_OFF:
        return formatMessage({ id: 'deliveryDelivered' });
      default:
        return enableOverrideDeliveryTime ? '' : eta || '...';
    }
  };

  const showCashPaymentMessage = isCashPayment && !failedDeliveryStatuses.includes(deliveryStatus);

  // TODO: check for cancelled orders and orders that have already been delivered
  return (
    <>
      {showCashPaymentMessage && <CashPaymentReminder serviceMode={ServiceMode.DELIVERY} />}
      <DeliveryCardContainer>
        <Card>
          <ETA tabIndex={0}>
            {enableOverrideDeliveryTime
              ? formatMessage({ id: 'etaFixed' })
              : formatMessage({ id: 'eta' }) + ': '}
            <strong data-testid="order-preparation-confirmation-eta">
              {getDeliveryEtaText(deliveryStatus)}
            </strong>
          </ETA>

          <CardHeading
            tabIndex={0}
            data-testid="order-preparation-confirmation"
            data-private
            data-dd-privacy="mask"
          >
            {formatMessage({ id: 'deliveringToYourDoorAt' })}
            <br />
            {delivery.dropoff.addressLine1}
          </CardHeading>
          <DeliveryProgress deliveryStatus={deliveryStatus} rbiOrderId={serverOrder.rbiOrderId} />
          {driver && <CourierDetail driver={driver} />}
        </Card>

        <Card>
          <CardHeading tabIndex={0}>{formatMessage({ id: 'orderDetailsHeading' })}</CardHeading>
          <TextGroup>
            {serverOrder.cart?.ticketNumber ? (
              <CardText tabIndex={0}>
                {formatMessage({ id: 'orderNumber' })}: {orderNumber}
              </CardText>
            ) : null}
            {cardType && ccLast4 && (
              <CardText tabIndex={0}>
                {formatMessage(
                  { id: 'paymentXEndingInY' },
                  {
                    cardType,
                    ccLast4,
                  }
                )}
              </CardText>
            )}
            <CardText tabIndex={0}>
              {formatMessage({ id: 'orderTime' })}: {readableHour(serverOrder.createdAt, true)}
            </CardText>
          </TextGroup>

          <CardHeading tabIndex={0}>{formatMessage({ id: 'deliveryInfo' })}</CardHeading>
          <TextGroup>
            <CardText data-private data-dd-privacy="mask" tabIndex={0}>
              <strong>{serverOrder.cart.customerName}</strong>
            </CardText>
            <CardText data-private data-dd-privacy="mask" tabIndex={0}>
              {delivery.dropoff.addressLine1}
              <br />
              {delivery.dropoff.addressLine2 && (
                <>
                  {formatMessage(
                    { id: 'unitNumber' },
                    { unitNumber: delivery.dropoff.addressLine2 }
                  )}
                  <br />
                </>
              )}
              {delivery.dropoff.city}, {delivery.dropoff.state} {delivery.dropoff.zip}
            </CardText>
            {delivery.dropoff.phoneNumber && (
              <CardText tabIndex={0} data-private data-dd-privacy="mask">
                {delivery.dropoff.phoneNumber}
              </CardText>
            )}
            {delivery.instructions && (
              <>
                <br />
                <CardText data-private data-dd-privacy="mask" tabIndex={0}>
                  {formatMessage({ id: 'instructions' })}: {delivery.instructions}
                </CardText>
              </>
            )}
          </TextGroup>

          <CardHeading tabIndex={0}>{formatMessage({ id: 'yourOrder' })}</CardHeading>
          <TextGroup>
            <StyledCartEntries>
              {serverOrder.cart.cartEntries.map((cartEntry: IBackendCartEntries, idx: number) => (
                <CartEntry entry={cartEntry} key={cartEntry.name! + idx} index={idx} />
              ))}
            </StyledCartEntries>
          </TextGroup>

          {enableFeesOnOrderReceipt &&
            !!itemFees &&
            Object.entries(itemFees).map(([name, entryCents]: [string, number], index) => {
              const feeName = getStandardizedName({ name });
              if (!feeName) {
                return null;
              }

              return (
                <TotalRow key={index}>
                  <CardText>
                    {formatMessage({ id: `${getStandardizedName({ name })}Fee` })}
                  </CardText>

                  <CardText>{formatCurrencyForLocale(entryCents)}</CardText>
                </TotalRow>
              );
            })}

          {!!rewardsDiscountCents && (
            <TotalRow>
              <CardText>
                {formatMessage({
                  id: 'rewardSavings',
                })}
              </CardText>
              <CardText>{formatCurrencyForLocale(-rewardsDiscountCents)}</CardText>
            </TotalRow>
          )}

          <TotalRow>
            <CardText tabIndex={0}>{formatMessage({ id: 'subtotal' })}</CardText>
            <CardText tabIndex={0}>
              {formatCurrencyForLocale(serverOrder.cart.subTotalCents)}
            </CardText>
          </TotalRow>
          {!enableHideTaxLine && (
            <TotalRow>
              <CardText tabIndex={0}>{formatMessage({ id: 'tax' })}</CardText>
              <CardText tabIndex={0}>{formatCurrencyForLocale(serverOrder.cart.taxCents)}</CardText>
            </TotalRow>
          )}
          <TotalRow>
            <CardText tabIndex={0}>{formatMessage({ id: 'deliveryFee' })}</CardText>
            <DeliveryFee />
          </TotalRow>
          {geographicalFeeCents > 0 && (
            <TotalRow>
              <CardText tabIndex={0}>{formatMessage({ id: geographicalFeeLabelId })}</CardText>
              <CardText tabIndex={0}>{formatCurrencyForLocale(geographicalFeeCents)}</CardText>
            </TotalRow>
          )}
          {serviceFeeCents > 0 && (
            <TotalRow>
              <CardText tabIndex={0}>{formatMessage({ id: 'serviceFee' })}</CardText>
              <CardText tabIndex={0}>{formatCurrencyForLocale(serviceFeeCents)}</CardText>
            </TotalRow>
          )}
          {smallCartFeeCents > 0 && (
            <TotalRow>
              <CardText tabIndex={0}>{formatMessage({ id: 'smallCartFee' })}</CardText>
              <CardText tabIndex={0}>{formatCurrencyForLocale(smallCartFeeCents)}</CardText>
            </TotalRow>
          )}
          {!hideTipAmount && (
            <TotalRow data-testid="tipCents">
              <CardText tabIndex={0}>{formatMessage({ id: 'tip' })}</CardText>
              <CardText tabIndex={0}>{formatCurrencyForLocale(delivery.tipCents)}</CardText>
            </TotalRow>
          )}
          <br />
          <TotalRow>
            <CardText tabIndex={0}>
              <strong>{formatMessage({ id: 'total' })}</strong>
            </CardText>
            <CardText tabIndex={0}>
              <strong>{formatCurrencyForLocale(serverOrder.cart.totalCents)}</strong>
            </CardText>
          </TotalRow>
        </Card>
        {shouldShowAppRating && <ModalRating />}
      </DeliveryCardContainer>
      <ErrorDialog />
      <CancellationModal />
    </>
  );
};

export default DeliveryDetails;
