import { ILocation } from '@rbi-ctg/frontend';

import { getApiKey } from '../environment';

export type DeviceType = 'Web' | 'Android' | 'iOS';

const RADAR_SDK_VERSION = '3.0.1';

export function radarTrackOnce(
  location: ILocation,
  userId: string,
  deviceId: string,
  deviceType: DeviceType
) {
  const key = getApiKey('radar');
  if (!key) {
    return;
  }

  const radarDeviceId = deviceId || `device-${deviceType}-${userId}`;

  if (!location.accuracy || location.accuracy < 0) {
    location.accuracy = 0;
  }

  const radarUrl = 'https://api.radar.io/v1/track';
  const headers = {
    authorization: key,
    'content-type': 'application/json',
    'x-radar-device-type': deviceType,
    'x-radar-sdk-version': RADAR_SDK_VERSION,
  };

  const method = 'POST';
  const mode = 'cors';

  const body = JSON.stringify({
    accuracy: location.accuracy,
    description: null,
    deviceId: radarDeviceId,
    deviceType,
    foreground: true,
    installId: deviceId,
    latitude: location.lat,
    longitude: location.lng,
    metadata: null,
    sdkVersion: RADAR_SDK_VERSION,
    stopped: true,
    userId,
  });

  return fetch(radarUrl, { headers, body, method, mode });
}
