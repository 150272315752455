import type * as BrazeNS from '@braze/web-sdk';
import { IAppboy } from 'appboy-web-sdk';

type BrazeSDK = typeof BrazeNS;

declare global {
  interface Window {
    braze: BrazeSDK;
    appboy: IAppboy;
  }
}

export const getSdk = (): IAppboy | BrazeSDK => window.appboy ?? window.braze;

// Because the Braze web SDK update is done in mParticle UI we need to support both 3.x and 4.x versions
// When all markets are using 4.x we should get rid of all window.appboy references
// TODO: Remove support for Braze web SDK 3.x https://rbictg.atlassian.net/browse/ICFE-207
export const enableSDK = () => {
  if (window.appboy) {
    return window.appboy.resumeWebTracking();
  } else {
    return window.braze.enableSDK();
  }
};

export const disableSDK = () => {
  if (window.appboy) {
    return window.appboy.stopWebTracking();
  } else {
    return window.braze.disableSDK();
  }
};

export const toggleContentCards = (parentNode: Element) => {
  if (window.appboy) {
    return window.appboy.display.toggleContentCards(parentNode);
  } else {
    return window.braze.toggleContentCards(parentNode);
  }
};

export const automaticallyShowInAppMessages = () => {
  if (window.appboy) {
    return window.appboy.display.automaticallyShowNewInAppMessages();
  } else {
    return window.braze.automaticallyShowInAppMessages();
  }
};

export const initialize = () => {
  if (window.appboy) {
    return window.appboy.initialize();
  } else {
    // This call should be safe, as the braze sdk is initialized by mparticle and
    // we're only using this call to verify that the initialization was successful
    try {
      return window.braze.initialize('', { baseUrl: '' });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Cannot initialize Braze:', e);

      return;
    }
  }
};

export const openSession = () => getSdk().openSession();

export const changeUser = (userId: string) => getSdk().changeUser(userId);

export const requestContentCardsRefresh = () => getSdk().requestContentCardsRefresh();

export const getCachedContentCards = () => getSdk().getCachedContentCards();

export const requestImmediateDataFlush = () => getSdk().requestImmediateDataFlush();

export const getUser = () => getSdk().getUser();
