import { useEffect, useRef, useState } from 'react';

import { mapValues } from 'lodash';
import { useIntl } from 'react-intl';

import { IOffer, ISanityImage, ISanityLocaleBlockContent, ISanityTextNode } from '@rbi-ctg/menu';
import { CartOfferState, IOfferFeedbackEntryFragment, IOfferVariable } from 'generated/rbi-graphql';
import { useLogger } from 'state/logger';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { UIPattern } from 'state/offers/types';
import { FormatCurrencyForType, useUIContext } from 'state/ui';
import { ILogger } from 'utils/logger';
import { RuleType, findOfferByUniqId, getUniqIdForOffer } from 'utils/offers';
import { parseSanityObject } from 'utils/sanity';
import { toast } from 'utils/toast';

export enum LockedOfferType {
  FREQUENCY = 'frequency',
  CROSS_SELL = 'crossSell',
  DAY_PART = 'dayPart',
  ERROR = 'error',
}

export interface IUseLockedOfferProps {
  isLoading?: boolean;
  hasError?: boolean;
  sortedOffers: IOffer[];
  onRetryOnError?: () => void;
  onReload?: () => void;
  offersFeedback: Record<string, IOfferFeedbackEntryFragment> | undefined;
  logger?: ILogger;
  currencyFormatter?: FormatCurrencyForType;
}

interface IProductCategory {
  sanityId?: string;
  name?: ISanityTextNode | null;
  image?: ISanityImage | null;
}

export interface ILockedOfferStep extends IProductCategory {
  isComplete: boolean;
}

export interface ILockedOffer extends IOffer {
  offerState: CartOfferState | null;
  type: LockedOfferType;
  steps: ILockedOfferStep[];
  stepsTotal: number;
  stepsCompleted: number;
  expiresOnDatetime: Date | null;
  hasError: boolean;
  completedChallengeHeader?: ISanityLocaleBlockContent | null;
  completedChallengeDescription?: ISanityLocaleBlockContent | null;
  allGoalsCompleted: boolean;
}

export interface ILockedOfferFrequency extends ILockedOffer {
  type: LockedOfferType.FREQUENCY;
}

export interface ILockedOfferCrossSell extends ILockedOffer {
  type: LockedOfferType.CROSS_SELL;
}

export interface ILockedOfferDayPart extends ILockedOffer {
  type: LockedOfferType.DAY_PART;
}

export interface ILockedOfferError extends ILockedOffer {
  type: LockedOfferType.ERROR;
}

export type LockedOffer =
  | ILockedOfferFrequency
  | ILockedOfferCrossSell
  | ILockedOfferDayPart
  | ILockedOfferError;

export type KeyValuePairObj = { [key: string]: string | number | Date };

const FALLBACK_TIME = 1000000;

export const LOCALE_BLOCK_KEYS = [
  'description',
  'howToRedeem',
  'moreInfo',
  'name',
  'lockedOffersPanel.completedChallengeHeader',
  'lockedOffersPanel.completedChallengeDescription',
];

export function templateKeyValuePairToObject({
  variables,
  currencyFormatter,
}: {
  variables?: IOfferVariable[] | null;
  currencyFormatter?: FormatCurrencyForType;
}): KeyValuePairObj {
  if (!variables?.length) {
    return {};
  }
  const obj: KeyValuePairObj = {};
  variables.forEach(v => {
    let attr: string | Date | number = '';
    switch (v.type) {
      case 'string':
        attr = v.value;
        break;
      case 'number':
        attr = parseFloat(v.value);
        break;
      case 'int':
        attr = parseInt(v.value);
        break;
      case 'datetime':
        attr = new Date(v.value);
        break;
      case 'currency':
        // using parsInt instead of parseFloat, because the value must be in cents
        const num = parseInt(v.value);
        if (num && !isNaN(num)) {
          attr = currencyFormatter ? currencyFormatter(parseFloat(v.value)) : '';
        }
        break;
      default:
        attr = v.value;
        break;
    }

    obj[v.key] = attr;
  });
  return obj;
}

export function filterSortedLockedOffer({
  sortedOffers = [],
  offersFeedback = {},
  logger,
  currencyFormatter,
}: IUseLockedOfferProps): LockedOffer[] {
  // filter offers for lockedOffers
  const lockedOffers: LockedOffer[] = Object.entries(offersFeedback).reduce<LockedOffer[]>(
    (acc, curr) => {
      const [, evaluatedOffer] = curr;
      let offerType = LockedOfferType.FREQUENCY;
      let hasError = false;
      let stepsCompleted = 0;
      let stepsTotal = 0;
      const uniqOfferId = getUniqIdForOffer({
        id: evaluatedOffer.couponId,
        token: evaluatedOffer.tokenId,
      });
      const offer = findOfferByUniqId(uniqOfferId, sortedOffers);
      // Get the Sanity Offer by ID

      // error state for locked offers
      if (
        !evaluatedOffer.redemptionEligibility.isValid &&
        evaluatedOffer.redemptionEligibility.validationErrors?.length
      ) {
        if (logger) {
          logger.error({
            error: evaluatedOffer.redemptionEligibility.validationErrors,
            message: `Failed to retrieve formation offer ${evaluatedOffer.couponId}`,
          });
        }

        hasError = true;
        offerType = LockedOfferType.ERROR;
        const uiPattern = UIPattern.ERROR;
        acc.push({
          ...(offer as IOffer),
          hasError,
          offerState: evaluatedOffer?.offerState,
          type: offerType,
          stepsCompleted,
          stepsTotal,
          steps: [],
          completedChallengeHeader: null,
          completedChallengeDescription: null,
          allGoalsCompleted: false,
          expiresOnDatetime: null,
          uiPattern,
        });

        return acc;
      }

      if (evaluatedOffer.redemptionEligibility.evaluationFeedback.length > 0) {
        // No offer
        if (!offer || !offer.uiPattern) {
          return acc;
        }

        // Not one of the locked offer uiPatterns
        const uiPattern = offer.uiPattern as UIPattern;
        if (![UIPattern.LIST, UIPattern.STEPPER].includes(uiPattern)) {
          return acc;
        }

        const evaluationFeedback = evaluatedOffer.redemptionEligibility.evaluationFeedback || [];

        // checks for redemption limit violations
        const hasRedemptionLimitViolation = evaluationFeedback.some(
          feedback =>
            feedback?.code === 'REDEMPTION_LIMIT_VIOLATION' && feedback?.condition === false
        );

        // exculdes offer if there are any limit redemption violations
        if (hasRedemptionLimitViolation) {
          return acc;
        }

        type ElementType = typeof evaluationFeedback extends readonly (infer T)[] ? T : never;

        stepsTotal = evaluationFeedback.filter(
          (feedback: ElementType) => feedback?.ruleSetType === RuleType.OrderHistory
        ).length;

        // Parse Template
        const offerData = evaluatedOffer?.offerDetails
          ? JSON.parse(evaluatedOffer.offerDetails)
          : {};

        const timeRuleSet = offerData.ruleSet.find(
          (ruleset: any) => ruleset._type === RuleType.OrderHistory
        );

        const variables = templateKeyValuePairToObject({
          variables: Object.assign(evaluatedOffer?.offerVariables || {}),
          currencyFormatter,
        });

        // Use the endDate variable if there is one present
        // otherwise default to the ruleSet endDate
        let endDate;
        if (!variables?.endDate) {
          endDate = timeRuleSet ? new Date(timeRuleSet?.to) : new Date(Date.now() + FALLBACK_TIME);
        } else {
          endDate = new Date(variables?.endDate);
        }

        // Turn all values into string
        // TODO what should the Datetimes look like?
        const variablesStringValues = mapValues(variables, (val: any) =>
          val ? val.toString() : ''
        );
        const updatedObj = parseSanityObject({
          doc: offer,
          localeBlockKeys: LOCALE_BLOCK_KEYS,
          variables: variablesStringValues,
        });

        // Technically -- Cross Sell is the only time that we care about the option array
        // Iterate over the evaluationFeedback to mark the status of challenge completion
        const steps: ILockedOfferStep[] = [];

        evaluationFeedback.forEach(feedback => {
          if (feedback?.ruleSetType === RuleType.OrderHistory) {
            if (feedback?.condition) {
              stepsCompleted++;
            }

            const sanityId = feedback?.sanityId || undefined;
            // This is a bit brittle, would be nice to use the Sanity Doc type,
            // e.g. CrossSell used Product Category
            if (sanityId && uiPattern === UIPattern.LIST) {
              offerType = LockedOfferType.CROSS_SELL;
            }
            steps.push({
              // ...found,
              sanityId,
              isComplete: feedback?.condition || false,
            });
          }

          // Check for Errors
        });

        const allGoalsCompleted =
          evaluatedOffer.redemptionEligibility.isRedeemable &&
          !evaluatedOffer.redemptionEligibility.validationErrors.length;

        const offerState = evaluatedOffer?.offerState;
        acc.push({
          ...updatedObj,
          hasError,
          offerState,
          type: offerType,
          stepsCompleted,
          stepsTotal,
          steps,
          completedChallengeHeader: updatedObj?.lockedOffersPanel?.completedChallengeHeader,
          completedChallengeDescription:
            updatedObj?.lockedOffersPanel?.completedChallengeDescription,
          allGoalsCompleted,
          expiresOnDatetime: endDate,
        });
      }

      return acc;
    },
    []
  );
  return lockedOffers;
}

export default function useLockedOffers({
  sortedOffers = [],
  offersFeedback,
}: IUseLockedOfferProps) {
  const [lockedOffers, setLockedOffers] = useState<ILockedOffer[]>();
  const { formatCurrencyForLocale } = useUIContext();
  const logger = useLogger();
  const mParticle = useMParticleContext();
  const { formatMessage } = useIntl();

  const [redeemableOffers, setRedeemableOffers] = useState<ILockedOffer[]>();
  const previousRedeemable = useRef<ILockedOffer[]>();

  useEffect(() => {
    const offers = filterSortedLockedOffer({
      sortedOffers,
      offersFeedback,
      logger,
      currencyFormatter: formatCurrencyForLocale,
    });
    setLockedOffers(offers);

    const offersToRedeem = offers.filter(item => item.allGoalsCompleted);
    setRedeemableOffers(offersToRedeem);
  }, [offersFeedback, sortedOffers, logger, formatCurrencyForLocale]);

  // Fire toast when a locked offer becomes redeemable
  useEffect(() => {
    if (!lockedOffers?.length || redeemableOffers === undefined) {
      return;
    }
    // Set initial values
    if (redeemableOffers !== undefined && previousRedeemable.current === undefined) {
      previousRedeemable.current = redeemableOffers;
      return;
    }
    // redeemable offers unlocked, fire toast along with mparticle report
    if (
      previousRedeemable.current !== undefined &&
      redeemableOffers.length > previousRedeemable.current.length
    ) {
      // Finding the newly unlocked offer
      redeemableOffers.forEach(item => {
        const isNewOffer = !previousRedeemable.current?.find(
          prevItem =>
            getUniqIdForOffer({ id: item._id, token: item.tokenId }) ===
            getUniqIdForOffer({ id: prevItem._id, token: prevItem.tokenId })
        );
        if (isNewOffer) {
          mParticle.logEvent(CustomEventNames.CHALLENGE_COMPLETE, EventTypes.Other, {
            'Sanity ID': item._id,
            'Offer Name': item.name?.localeRaw[0].children[0].text,
            'Offer Type': item.type,
          });
          toast.success(formatMessage({ id: 'rewardUnlocked' }));
        }
      });
      previousRedeemable.current = redeemableOffers;
    }
  }, [redeemableOffers, formatMessage, mParticle, lockedOffers]);

  return {
    lockedOffers,
    redeemableOffers,
  };
}
