import styled from 'styled-components';

import { ClickableContainer } from 'components/clickable-container';
import { brandFont } from 'components/layout/brand-font';
import { LinkButton } from 'components/link-button';
import { primitive } from 'styles/constants/primitives';
import { screenReaderOnly } from 'utils/style/screen-reader-only';

import { ICategoryDropdownContainer, OptionsContainerProps } from './types';

export const CategoryDropdownContainer = styled.div<ICategoryDropdownContainer>`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.token('background-pattern')};
  border-bottom: 1px solid ${({ theme }) => theme.token('border-color-default')};
`;

export const TitleContainer = styled.div`
  padding: ${primitive.$spacing2} ${primitive.$spacing4} 0;
  width: 100%;
`;

export const Title = styled(ClickableContainer)<{
  bold: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ bold }) => bold && Styles.fontWeight.heavy};
  width: 100%;
  padding-bottom: ${primitive.$spacing2};

  & > {
    height: 3rem;
  }
`;

export const OptionsContainer = styled.div<OptionsContainerProps>`
  padding: 0 ${primitive.$spacing4};
  z-index: ${Styles.zIndex.normal};
  ${({ focusOptionsWhenNotVisible, optionsVisible }) =>
    !optionsVisible && (focusOptionsWhenNotVisible ? screenReaderOnly : 'display: none;')};
`;

export const SeeMoreOptionsCTA = styled(LinkButton)`
  width: 100%;
  padding: ${primitive.$spacing6} 0 ${primitive.$spacing7};
  text-align: left;
  font: ${brandFont.copyOne};
  color: ${({ theme }) => theme.token('text-link-default')};
`;
