import styled from 'styled-components';

export const Scroller = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding-top: 52px;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${Styles.mobileLandscape`
    padding-top: 0;
  `}

  ${Styles.desktop`
    padding-top: 70px;
  `}
`;
