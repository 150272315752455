import { negate } from 'lodash';

import { pathMatchesAllowList } from './path';
import type { IAllowListPath } from './path';

import { getAllLocalizedVersionsOfRoute, routes } from './index';

/*
 * This list contains:
 * all the routes we WANT bots to index and follow.
 *
 */
const allowNonLocalizedBotIndexFollowList: Array<IAllowListPath> = [
  routes.signIn,
  routes.signUp,
  routes.base,
  routes.menu,
  '/about-popeyes',
  '/contact-us', // main support page & contact us
  routes.storeLocator,
  routes.offers,
  '/company',
  '/our-story',
  '/legal-page',
  '/nutritional-information',
  '/terms-of-service',
  '/accessibility-statement',
  '/sitemap',
  '/corporate-responsibility',
  '/animal-welfare-statement',
  routes.foundation,
  '/privacy-policy',
  '/franchise-international',
  '/franchise',
  /^\/menu\/section-/,
  /^\/menu\/picker-/,
  /^\/menu\/combo-/,
  /^\/menu\/item-/,
  /^\/rewards\/offers\/?/,
];

const allowLocalizedBotIndexFollowList = [...getAllLocalizedVersionsOfRoute('exploreMenu')];

const allowBotIndexFollowList = allowNonLocalizedBotIndexFollowList.concat(
  allowLocalizedBotIndexFollowList
);

/**
 * Determines whether the page referenced by a URL path should include the
 * robots meta tag or not.
 *
 * @param pathname current URL path.
 * @returns boolean
 */
export const shouldUseRobotsMetaTag = negate(pathMatchesAllowList(allowBotIndexFollowList));
