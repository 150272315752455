import React from 'react';

import styled from 'styled-components';

import NavLink from 'components/app-header/nav-link';

import { IRecentOrdersLinkProps } from './types';

const RecentOrdersContainer = styled.div`
  margin-right: 1rem;
`;

const RecentOrdersLink = (props: IRecentOrdersLinkProps) => {
  return (
    <RecentOrdersContainer>
      <NavLink {...props}>{props.title}</NavLink>
    </RecentOrdersContainer>
  );
};

export default RecentOrdersLink;
