import { getDefaultLanguage } from '@rbilabs/intl';

import { brand, country } from 'utils/environment';
import logger from 'utils/logger';

const defaultLanguage = getDefaultLanguage(brand(), country);

export async function getMessagesForLanguage(language: string, region: string) {
  try {
    return await import(`state/translations/${language}_${region}.json`);
  } catch (e) {
    logger.warn(e);
  }

  if (defaultLanguage !== language) {
    try {
      return await import(`state/translations/${defaultLanguage}_${region}.json`);
    } catch (e) {
      logger.warn(e);
    }
  }

  return await import(`state/translations/en.json`);
}
