import { useRefundEligibility } from 'hooks/refund-eligibility';
import { useSupportData } from 'hooks/use-support-data';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

export const useHelpLinkUrlGenerator = (rbiOrderId: string) => {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);

  const enableGuestAndMultiStepSupportForm = useFlag(
    LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
  );

  const { isOrderEligibleForRefund } = useRefundEligibility({ orderId: rbiOrderId });

  const { orderingIssuesCategoryGroup } = useSupportData();

  const orderingIssuesCategoryGroupId = orderingIssuesCategoryGroup?._id;

  //  CIR or the generic support form
  const supportV1Link = isOrderEligibleForRefund
    ? `${routes.reportOrderIssue}/${rbiOrderId}`
    : routes.support;

  // also known as the guest & multi-step form.
  // refund eligibility is determined later in this flow, so there's only one option here.
  const supportV2Link = `${routes.supportCategories}/${
    orderingIssuesCategoryGroupId ?? ''
  }?orderId=${rbiOrderId}-recentOrder`;

  const needHelpLinkUrl = enableGuestAndMultiStepSupportForm ? supportV2Link : supportV1Link;

  const pypestreamUrl = !!rbiOrderId ? `${routes.support}?orderId=${rbiOrderId}` : routes.support;

  return enablePypestream ? pypestreamUrl : needHelpLinkUrl;
};
