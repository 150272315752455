import { useEffect, useState } from 'react';

import isEqual from 'react-fast-compare';

import { IOffer } from '@rbi-ctg/menu';
import { IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { UIPattern } from 'state/offers/types';
import { RuleType } from 'utils/offers';

export interface IUseCartPromoCodeOfferProps {
  sortedOffers: IOffer[];
  offersFeedback: Record<string, IOfferFeedbackEntryFragment> | undefined;
}

/**
 * @name shouldIncludeCartPromoCodeOffer
 * @description
 *    cart promo code offers will be marked as isRedeemable = false until the user has unlocked
 *     the offer using a valid promo code
 *    unlike non-cart promo code offers, we still sometimes want to include these unredeemable offers
 *    -> ex. so that the user can enter the promo code to unlock the offer
 *
 *    WHEN WE WANT TO INCLUDE:
 *    -> ONLY ruleset is for requires-assignment
 *    -> has ruleset(s) other than requires-assignment, AND all all these other rulesets are passing
 * @returns {boolean}
 */
const shouldIncludeCartPromoCodeOffer = (
  offer: IOffer,
  offersFeedback: Record<string, IOfferFeedbackEntryFragment>
) => {
  const offerFeedback = offersFeedback[offer._id]?.redemptionEligibility?.evaluationFeedback;
  if (!offerFeedback) {
    return true;
  }
  const shouldInclude = offerFeedback.reduce((acc, curRule) => {
    if (curRule?.ruleSetType !== RuleType.RequiresAssignment && !curRule?.condition) {
      return false;
    }
    return acc;
  }, true);
  return shouldInclude;
};

/**
 * @name useCartPromoCodeOffers
 * @description
 *   cart promo code offers are not displayed on the offers page and are separate from
 *   promo code offers (ui pattern: PROMO) because they are not displayed on the offers
 *   page and have a completely separate UX.
 */
export default function useCartPromoCodeOffers({
  sortedOffers = [],
  offersFeedback = {},
}: IUseCartPromoCodeOfferProps) {
  const enableCartPromoCodeOffers = useFlag(LaunchDarklyFlag.ENABLE_PROMO_CODE_AT_CHECKOUT);
  const [cartPromoCodeOffers, setCartPromoCodeOffers] = useState<IOffer[]>([]);

  // update cart promo code offers when sortedOffers changes
  useEffect(() => {
    if (enableCartPromoCodeOffers) {
      // only if cart promo code offers are enabled via LD
      // get all cart promo code offers and their unlocked status for this user
      const offers = sortedOffers.reduce((acc, offer: IOffer) => {
        if (offer.uiPattern === UIPattern.CART_PROMO) {
          // ensure isRedeemable is properly set based on offersFeedback
          const isRedeemable = !!offersFeedback[offer._id]?.redemptionEligibility?.isRedeemable;
          const shouldInclude = shouldIncludeCartPromoCodeOffer(offer, offersFeedback);
          if (shouldInclude) {
            acc.push({ ...offer, isRedeemable });
          }
        }
        return acc;
      }, [] as IOffer[]);
      setCartPromoCodeOffers(previousoffers =>
        isEqual(previousoffers, offers) ? previousoffers : offers
      );
    }
  }, [enableCartPromoCodeOffers, offersFeedback, sortedOffers]);

  return {
    cartPromoCodeOffers,
  };
}
