import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

import { LinkNoDecoration } from 'components/app-header/mobile-header/styled';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useReversedUIContext } from 'state/reversed-ui';

export const HamburgerButton: React.FC<{}> = () => {
  const { featureNavigationMobileHeaderButton } = useFeatureNavigation();
  const { reversedUI } = useReversedUIContext();

  const iconColor = reversedUI ? 'icon-reversed' : 'icon-header-contrast';
  const hasNavigationLink = !!featureNavigationMobileHeaderButton?.link?.locale;

  return hasNavigationLink ? (
    <LinkNoDecoration to={featureNavigationMobileHeaderButton?.link?.locale ?? ''}>
      <Icon icon="menu" color={iconColor} width="1.5rem" aria-hidden />
    </LinkNoDecoration>
  ) : null;
};
