import noop from 'utils/noop';

/**
 * stub out unused loyalty features on plk and bk
 */
export const useDeprecatedLoyalty: (...args: any) => any = () => {
  return {
    loyaltyBarcodeDetails: {},
    setLoyaltyBarcodeDetails: noop,
  };
};
