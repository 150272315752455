import { useEffect, useRef } from 'react';

import delv from 'dlv';

import useLocation from './use-location';

function useLocationChange(handleLocationChange = () => {}) {
  const location = useLocation();
  const prevLocationRef = useRef();

  useEffect(() => {
    const prevPathName = delv(prevLocationRef, 'current.location.pathname', '');
    const locationPathName = delv(location, 'location.pathname', '');

    if (prevPathName !== locationPathName) {
      handleLocationChange(prevLocationRef.current, location);
      prevLocationRef.current = location;
    }
  }, [handleLocationChange, location]);

  return location;
}

export default useLocationChange;
