import { getDefaultLanguage } from '@rbilabs/intl';
import dlv from 'dlv';

import { IStaticPageRoute } from 'remote/queries/static-page';
import { brand } from 'utils/environment';

import { LocaleRoutes, localeRoutes } from './generated/locale-routes';

export { localeRoutes } from './generated/locale-routes';
export type { LocaleRoutes } from './generated/locale-routes';

export function getAllLocalizedVersionsOfRoute(routeKey: keyof LocaleRoutes) {
  const routes = Object.values(localeRoutes).map(routeMaps => routeMaps[routeKey]);
  return routes.filter(Boolean);
}

export const getStaticPagesLocalizedRouteForPath = (
  path: string,
  staticRoutes: IStaticPageRoute[],
  language: string
) => {
  if (!staticRoutes || !Array.isArray(staticRoutes)) {
    return null;
  }

  let localizedRelativePath = null;

  staticRoutes.forEach((route: any) => {
    if (dlv(route, 'path.current') === path) {
      const localizedPathWithFallback =
        dlv(route, `localePath.${language}.current`) ||
        dlv(route, `redirectUrl.locale`) ||
        route.path.current;
      localizedRelativePath = `/${localizedPathWithFallback}`;
    }
  });

  return localizedRelativePath;
};

export const getInCodeLocalizedRouteForPath = (
  relativePath: string,
  language: string,
  region: string
) => {
  const defaultLanguageLocaleRoutes = localeRoutes[getDefaultLanguage(brand(), region)];

  const defaultLanguageRouteKey = Object.keys(defaultLanguageLocaleRoutes).find(
    key => defaultLanguageLocaleRoutes[key] === relativePath
  );

  if (defaultLanguageRouteKey) {
    return (
      dlv(localeRoutes, `${language}.${defaultLanguageRouteKey}`) ||
      defaultLanguageLocaleRoutes[defaultLanguageRouteKey]
    );
  }

  return null;
};
