import React from 'react';

import { Link } from 'utils/navigation/router';

import { SanityBlockContentLinkProps } from './types';

/**
 * This is a useful override for removing unwanted component hierarchy.
 * E.g. serializers.marks.em by default adds an <em /> wrapper around text content.
 * If you want to only render the container component and text, override
 * the serializers where serializers.marks.em = PassthroughComponent
 */
export const PassthroughComponent: React.FC = props => <>{props.children}</>;

/**
 * This function return a <Link /> (internal link) or a <a /> (external link) depending on the type of href added in sanity
 */
export const formatSanityLink = ({ children, mark, ...rest }: SanityBlockContentLinkProps) => {
  const href = mark?.href;
  const isInternalLink = href?.startsWith('/');
  return isInternalLink ? (
    <Link to={href} data-testid="sanity-link-to" {...rest}>
      {children}
    </Link>
  ) : (
    <a
      href={href}
      target={mark?.blank ? '_blank' : undefined}
      rel={mark?.blank ? 'noreferrer' : undefined}
      data-testid="sanity-a-href"
      {...rest}
    >
      {children}
    </a>
  );
};
