import React from 'react';

import { createGlobalStyle } from 'styled-components';

// change the footer div style when branch-banner-is-active
const BranchBannerCustomStyle = createGlobalStyle`
body.branch-banner-is-active div[data-testid="footer"] {
  transform: translateY(-76px);
}
`;

export const BranchJourneysBannerCssOverride = () => <BranchBannerCustomStyle />;
