import React from 'react';

import { View } from 'react-native';

import useMediaQuery from 'hooks/use-media-query';
import { useLocationContext } from 'state/location';
import { useOrderContext } from 'state/order';
import { getAllLocalizedVersionsOfRoute, routes } from 'utils/routing';

import DesktopHeader from './desktop-header';
import MobileHeader from './mobile-header';

export const routesWithoutHeader = [
  routes.cart,
  ...getAllLocalizedVersionsOfRoute('signIn'),
  ...getAllLocalizedVersionsOfRoute('signUp'),
  routes.orderConfirmation,
];

export const routesWithoutMobileHeader = [...getAllLocalizedVersionsOfRoute('storeLocator')];

const isPathInRouteList = (routeList: string[], pathname: string) =>
  routeList.some(route => pathname.startsWith(route));

const AppHeader: React.FC = () => {
  const { serviceMode, cateringPickupDateTime, deliveryAddress } = useOrderContext();
  const isMobile = useMediaQuery('headerMobile');

  const {
    location: { pathname },
  } = useLocationContext();

  if (
    (isMobile && isPathInRouteList(routesWithoutMobileHeader, pathname)) ||
    (!isMobile && isPathInRouteList(routesWithoutHeader, pathname))
  ) {
    return null;
  }

  return (
    <View testID="app-header">
      {isMobile && (
        <MobileHeader
          serviceMode={serviceMode}
          userAddress={deliveryAddress?.addressLine1}
          cateringPickupDateTime={cateringPickupDateTime}
        />
      )}
      {!isMobile && (
        <DesktopHeader
          serviceMode={serviceMode}
          userAddress={deliveryAddress?.addressLine1}
          cateringPickupDateTime={cateringPickupDateTime}
        />
      )}
    </View>
  );
};

export default AppHeader;
export { default as LogoHeader } from './logo-header';
export { default as MenuHeader, HEADER_HEIGHT } from './menu-header';
