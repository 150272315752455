import { useEffect, useMemo } from 'react';

import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { isNil } from 'lodash';

import { useGetOrderLazyQuery } from 'generated/graphql-gateway';
import { DeliveryStatus } from 'generated/rbi-graphql';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import {
  GetOrderQuery,
  IGetOrderQueryResponse,
  IGetOrderQueryVariables,
} from 'remote/queries/order';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus } from 'state/order';
import { orderPollFailure, orderPollSuccessful } from 'state/order/order-state-utils';
import noop from 'utils/noop';

import { handleOrderStatusChange } from './on-order-status-change';
import { IUseOrderStatusParams } from './types';

// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1500;

export const useOrderStatus = ({
  failureStatuses,
  logOrderLatency,
  logger,
  onSuccess = noop,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
  rbiOrderId,
  successStatuses,
  skip,
}: IUseOrderStatusParams) => {
  const queryParams: QueryHookOptions<IGetOrderQueryResponse, IGetOrderQueryVariables> = {
    pollInterval: orderStatusPollInterval,
  };
  const isFulfillmentServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_ORDER
  );
  const skipQuery = skip || isNil(rbiOrderId) || isFulfillmentServiceEnabled;
  const skipFulfillmentQuery = skip || isNil(rbiOrderId) || !isFulfillmentServiceEnabled;

  if (rbiOrderId) {
    queryParams.variables = { rbiOrderId };
  }

  const [getOrderFulfillment, lazyQueryResultFulfillment] = useGetOrderLazyQuery({
    variables: queryParams.variables,
    pollInterval: orderStatusPollInterval,
  });
  const [getOrder, lazyQueryResult] = useLazyQuery<IGetOrderQueryResponse, IGetOrderQueryVariables>(
    GetOrderQuery,
    queryParams
  );
  const { called, stopPolling, data, error: userOrderStatusError } = lazyQueryResult;
  const {
    stopPolling: stopPollingFulfillment,
    data: dataFulfillment,
    called: calledFulfillment,
    error: getOrderStatusError,
  } = lazyQueryResultFulfillment;

  if (!skipQuery) {
    if (!called) {
      getOrder();
    }
  } else if (!skipFulfillmentQuery) {
    if (!calledFulfillment) {
      getOrderFulfillment();
    }
  }

  const serverOrder = useMemo<any | null>(() => {
    if (called) {
      return data?.order ?? null;
    }
    if (calledFulfillment) {
      return dataFulfillment?.order ?? null;
    }
    return null;
  }, [data, dataFulfillment, called, calledFulfillment]);

  const orderStatus = serverOrder && serverOrder.status;
  const orderDeliveryStatus: DeliveryStatus | null =
    serverOrder && serverOrder.delivery && serverOrder.delivery.status;

  const prevOrderStatus = usePrevious<OrderStatus | null>(orderStatus);
  const prevOrderDeliveryStatus = usePrevious<DeliveryStatus | null>(orderDeliveryStatus);

  const failure =
    !!serverOrder &&
    serverOrder.rbiOrderId === rbiOrderId &&
    orderPollFailure({
      cateringFailureStatus: failureStatuses.catering,
      deliveryFailureStatus: failureStatuses.delivery,
      order: serverOrder,
      orderFailureStatus: failureStatuses.pos,
    });

  const orderErrors = userOrderStatusError || getOrderStatusError;

  // update with catering
  const success =
    !!serverOrder &&
    serverOrder.rbiOrderId === rbiOrderId &&
    orderPollSuccessful({
      cateringSuccessStatus: successStatuses.catering,
      deliverySuccessStatus: successStatuses.delivery,
      order: serverOrder,
      orderSuccessStatus: successStatuses.pos,
    });

  useEffect(() => {
    if (skipQuery || success || failure) {
      if (called) {
        stopPolling();
      }
    }
    if (skipFulfillmentQuery || success || failure) {
      if (calledFulfillment) {
        stopPollingFulfillment();
      }
    }
  }, [
    skipQuery,
    skipFulfillmentQuery,
    success,
    failure,
    stopPolling,
    stopPollingFulfillment,
    called,
    calledFulfillment,
  ]);

  useEffectOnUpdates(() => {
    if (!serverOrder) {
      return;
    }

    handleOrderStatusChange({
      failure,
      logOrderLatency,
      logger,
      onSuccess,
      orderStatus,
      orderDeliveryStatus,
      prevOrderStatus,
      prevOrderDeliveryStatus,
      serverOrder,
      success,
    });
  }, [
    failure,
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
    success,
  ]);

  useEffectOnUnmount(() => {
    if (called) {
      stopPolling();
    }
    if (calledFulfillment) {
      stopPollingFulfillment();
    }
  });

  return {
    ...(skipFulfillmentQuery ? lazyQueryResult : lazyQueryResultFulfillment),
    failure,
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
    success,
    orderErrors,
  };
};
