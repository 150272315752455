import styled from 'styled-components';

import ActionButton from 'components/action-button';

export const ActionButtonStyled = styled(ActionButton)<{ reversed: boolean }>`
  color: ${p => p.reversed && p.theme.token('text-reversed')};
  border-color: ${p => p.reversed && p.theme.token('icon-reversed')};
  background: ${p => p.reversed && 'transparent'};
  svg {
    path {
      fill: ${p => p.reversed && p.theme.token('icon-reversed')};
    }
  }
`;
