import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconCircleCheck = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isActive,
  ...props
}) => (
  <svg viewBox="0 0 20 20" {...props}>
    <title>Circle Check Icon</title>
    <path d="M9.52 0h1A9.77 9.77 0 0 1 20 10a9.77 9.77 0 0 1-9.52 10h-1A9.77 9.77 0 0 1 0 10 9.77 9.77 0 0 1 9.52 0z" />
    <path
      fill={Styles.color.white}
      d="M8.91 14a1 1 0 0 1-.49-.13 1.46 1.46 0 0 1-.21-.16l-2.55-2.48a1 1 0 0 1-.3-.71 1 1 0 0 1 .29-.7 1 1 0 0 1 1.41 0l1.85 1.82 4.39-4.35A1 1 0 0 1 15 8a1 1 0 0 1-.3.71l-5.09 5-.15.11a1.06 1.06 0 0 1-.55.18z"
    />
  </svg>
);

export default IconCircleCheck;
