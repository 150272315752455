import * as React from 'react';
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { IStore } from '@rbi-ctg/store';
import { ModalItemUnavailable } from 'components/modal-item-unavailable/modal-item-unavailable';
import { ItemAvailabilityStatus } from 'enums/menu';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { getPosVendorFromStore } from 'hooks/menu/use-pos-vendor';
import useDialogModal, { DialogCb } from 'hooks/use-dialog-modal';
import {
  getRewardIdFromSearch,
  makeLoyaltyRewardDetailsPath,
} from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useMenuContext } from 'state/menu';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { routes } from 'utils/routing';

export interface IStaticMenuContext {
  setSelectedStaticMenuItem: Dispatch<
    SetStateAction<{ itemId: string; itemName: string; search: string }>
  >;
  selectedStaticMenuItemName: string;
  selectedStaticMenuItemId: string;
  selectedStaticMenuItemSearch: string;
  OpenItemUnavailableModal: DialogCb<object>;
  checkStaticMenuItemAvailability(
    store: StoreProxy | IRestaurantNode,
    serviceMode?: ServiceMode | null
  ): Promise<void>;
}

export const StaticMenuContext = createContext({} as IStaticMenuContext);

export const useStaticMenuContext = () => useContext(StaticMenuContext);

/**
 *
 * Controls showing of static menu and redirect flow for static menu ordering.
 *
 * If a user selects a menu item in the static menu with no store selected
 * the item pathname they selected is stored in "selectedStaticMenuItemId" and they are
 * navigated to store selector.
 *
 * After selection of a store, the user is routed back to the "selectedStaticMenuItemId".
 *
 **/

const DEFAULT_STATE = {
  itemId: '',
  itemName: '',
  search: '',
};

export const StaticMenuProvider: FC = ({ children }) => {
  const { checkItemAvailability } = useMenuContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [{ itemId, itemName, search }, setSelectedStaticMenuItem] = useState(DEFAULT_STATE);
  const [ItemUnavailableModal, OpenItemUnavailableModal] = useDialogModal({
    Component: ModalItemUnavailable,
  });
  const { isInRestaurantRedemptionEnabledAtStore } = useInRestaurantRedemptionContext();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (itemId) {
      const notInStaticMenuRedirectFlow =
        !pathname.includes(itemId) && !pathname.includes(routes.storeLocator);
      if (notInStaticMenuRedirectFlow) {
        setSelectedStaticMenuItem(DEFAULT_STATE);
      }
    }
  }, [itemId, pathname]);

  const checkStaticMenuItemAvailability = useCallback<
    IStaticMenuContext['checkStaticMenuItemAvailability']
  >(
    async (restaurant, serviceMode) => {
      const vendor = getPosVendorFromStore(restaurant, serviceMode);

      const { availabilityStatus } = await checkItemAvailability({
        vendor,
        storeNumber: restaurant.number ?? '',
        restaurantPosDataId: restaurant?.restaurantPosData?._id ?? '',
        itemId,
      });

      if (availabilityStatus !== ItemAvailabilityStatus.AVAILABLE) {
        OpenItemUnavailableModal();
        return;
      }

      const rewardId = getRewardIdFromSearch(search);
      const shouldNavigateToRewardDetails =
        rewardId &&
        (await isInRestaurantRedemptionEnabledAtStore(restaurant as IStore, serviceMode));

      if (rewardId && shouldNavigateToRewardDetails) {
        navigate(makeLoyaltyRewardDetailsPath({ rewardId, search, formatMessage }));
      } else {
        navigate(`${routes.menu}/${itemId}${search}`);
      }
    },
    [
      checkItemAvailability,
      itemId,
      search,
      isInRestaurantRedemptionEnabledAtStore,
      navigate,
      formatMessage,
      OpenItemUnavailableModal,
    ]
  );

  const value = useMemo(
    () => ({
      setSelectedStaticMenuItem,
      selectedStaticMenuItemId: itemId,
      selectedStaticMenuItemName: itemName,
      selectedStaticMenuItemSearch: search,
      OpenItemUnavailableModal,
      checkStaticMenuItemAvailability,
    }),
    [OpenItemUnavailableModal, checkStaticMenuItemAvailability, itemId, itemName, search]
  );

  return (
    <StaticMenuContext.Provider value={value}>
      {children}
      <ItemUnavailableModal />
    </StaticMenuContext.Provider>
  );
};
