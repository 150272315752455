import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { routes } from 'utils/routing';

const CommPreference = () => {
  const mParticle = useMParticleContext();
  const { formatMessage } = useIntl();

  const onCommPreferenceClick = useCallback(() => {
    mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_COMMUNICATION_PREFERENCES);
  }, [mParticle]);

  return (
    <ArrowLink
      data-testid="communication-preferences"
      to={routes.communication}
      onClick={onCommPreferenceClick}
      prefixIcon="communicationPref"
    >
      {formatMessage({ id: 'communicationPreferences' })}
    </ArrowLink>
  );
};

export default CommPreference;
