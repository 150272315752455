import { gql } from '@apollo/client';

import ImageFragment from './fragments/image';
import ImagesFragment from './fragments/images';

export default gql`
  query rollUpTheRim {
    allRollUpTheRims(limit: 1) {
      gamePlayHeader {
        localeRaw: _LANG_Raw
      }
      gamePlayTextBlock {
        localeRaw: _LANG_Raw
      }
      gamePlayImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      desktopGamePlayImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      gamePlayButtonText {
        localeRaw: _LANG_Raw
      }
      rollsRemainingText {
        localeRaw: _LANG_Raw
      }

      timelineHeader {
        localeRaw: _LANG_Raw
      }
      timelineTextBlock {
        localeRaw: _LANG_Raw
      }
      timelineComponents {
        key
        state
        timelineEventType
        headerColor
        timelineHeader {
          locale: _LANG_
        }
        description {
          locale: _LANG_
        }
        buttonText {
          locale: _LANG_
        }
        image {
          locale: _LANG_ {
            ...ImagesFragment
          }
        }
      }
      timelineButtonText {
        localeRaw: _LANG_Raw
      }
      emptyTimelineTextBlock {
        localeRaw: _LANG_Raw
      }
      emptyTimelineButtonBlock {
        localeRaw: _LANG_Raw
      }
      emptyTimelineImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }

      shareHeader {
        localeRaw: _LANG_Raw
      }
      shareTextBlock {
        localeRaw: _LANG_Raw
      }
      shareButtonText {
        localeRaw: _LANG_Raw
      }
      shareImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      desktopShareImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }

      prizesHeader {
        localeRaw: _LANG_Raw
      }
      prizesSeparator {
        localeRaw: _LANG_Raw
      }
      prizesTextBlock {
        localeRaw: _LANG_Raw
      }
      prizesButtonText {
        localeRaw: _LANG_Raw
      }
      prizesImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      planeImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      sponsorTitle {
        locale: _LANG_
      }
      sponsorsImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      desktopPrizesImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }

      winnersHeader {
        localeRaw: _LANG_Raw
      }
      winnersTextBlock {
        localeRaw: _LANG_Raw
      }
      winnersButtonText {
        localeRaw: _LANG_Raw
      }

      reusablesHeader {
        localeRaw: _LANG_Raw
      }
      reusablesTextBlock {
        localeRaw: _LANG_Raw
      }
      reusablesButtonText {
        localeRaw: _LANG_Raw
      }
      reusablesImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      desktopReusablesImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }

      modalTimelineHeader {
        localeRaw: _LANG_Raw
      }
      endText {
        localeRaw: _LANG_Raw
      }

      countdownText {
        localeRaw: _LANG_Raw
      }
      entriesText {
        localeRaw: _LANG_Raw
      }
      nextDrawText {
        localeRaw: _LANG_Raw
      }
      firstDrawing
      secondDrawing
      thirdDrawing
      fourthDrawing
      moneyStackImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }

      prizes {
        numberAwarded {
          localeRaw: _LANG_Raw
        }
        header {
          localeRaw: _LANG_Raw
        }
        text {
          localeRaw: _LANG_Raw
        }
        image {
          locale: _LANG_ {
            ...ImagesFragment
          }
        }
        excludeInAlberta
        buttonText {
          localeRaw: _LANG_Raw
        }
      }

      rulesLinkText {
        locale: _LANG_
      }

      rulesLinkPath {
        locale: _LANG_
      }

      footerLinks {
        _key
        footerLinkLocalizedPath {
          locale: _LANG_
        }
        footerLinkText {
          locale: _LANG_
        }
        footerText {
          locale: _LANG_
        }
        footerTitle {
          locale: _LANG_
        }
      }

      modalWinnersHeader {
        localeRaw: _LANG_Raw
      }
      modalWinnersTextBlock {
        localeRaw: _LANG_Raw
      }

      gameHeader {
        localeRaw: _LANG_Raw
      }
      gameTextBlock {
        localeRaw: _LANG_Raw
      }
      cupImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      gameBackgroundImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      gameButtonText {
        localeRaw: _LANG_Raw
      }

      rewardsHeaderText {
        locale: en
      }
      rewardsHeaderSubtitleText {
        locale: en
      }
      rewards {
        key
        rewardName {
          locale: _LANG_
        }
        image {
          locale: _LANG_ {
            ...ImagesFragment
          }
        }
        requireSkillsQuestion
        rewardYouWonMessageBlock {
          localeRaw: _LANG_Raw
        }
      }

      homepageHeader {
        localeRaw: _LANG_Raw
      }
      homepageCupImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      homepageBackgroundImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      enterButtonText {
        localeRaw: _LANG_Raw
      }
      shareRollHeader {
        localeRaw: _LANG_Raw
      }
      shareRollTextBlock {
        localeRaw: _LANG_Raw
      }
      shareRollsImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      shareRollsBackgroundImage {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
      howManyTextBlock {
        localeRaw: _LANG_Raw
      }
      shareRolluttonText {
        localeRaw: _LANG_Raw
      }
      rollupAnimationFrames {
        locale: _LANG_ {
          ...ImagesFragment
        }
      }
    }
  }
  ${ImageFragment}
  ${ImagesFragment}
`;
