import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getConfigValue } from 'utils/environment';

/**
 *
 * useGoogleAds is a util for understanding whether or not
 *  google ads are enabled.
 * Google Ads should only be enabled when:
 *  - the LD flag is TRUE
 *  AND
 *  - a campaign id existing in the FE config
 *
 * also provides the campaign id so consumers don't have to
 *  also pull in the config util
 */
export const useGoogleAds = () => {
  const googleAdsEnabled = useFlag(LaunchDarklyFlag.ENABLE_TH_MEDIA_SERVICES);
  const googleAdManagerCampaignId = getConfigValue('googleAdManager')?.campaignId;
  return {
    isEnabled: googleAdsEnabled && !!googleAdManagerCampaignId,
    campaignId: googleAdManagerCampaignId,
  };
};
