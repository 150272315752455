import dlv from 'dlv';

import { AnyArgs, VariadicFn } from '@rbi-ctg/frontend';

import { RBIEnv, env } from './environment';
import logger from './logger';

// eslint-disable-next-line import/no-cycle
export { EMAIL_REGEX, IPV4_REGEX, FormValidationState, isEmailValid } from './form';

export const debounce = <A extends any[]>(fn: VariadicFn<A, void>, wait = 0) => {
  let timeout: NodeJS.Timeout;

  return (...args: A) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = global.setTimeout(() => fn(...args), wait);
  };
};

export function centsToDollars(price = 0) {
  return price / 100;
}

/**
 * Convert dollars to cents, and round to the nearest cent
 * @param price - an amount in dollars
 * @returns the dollar value in cents
 */
export function dollarsToCents(price = 0) {
  return Math.round(price * 100);
}

export function centsToDollarString(cents: number = 0, precision = 2) {
  return centsToDollars(cents).toFixed(precision);
}

export interface IFormatCurrencyProps {
  amount: number | undefined;
  language: string | undefined;
  currency: string | undefined;
  currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name' | undefined;
}
export const defaultCurrencyFormat: IFormatCurrencyProps = {
  amount: 0,
  language: 'en',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
};

export const formatCurrency = (input: IFormatCurrencyProps | number | undefined): string => {
  const { language, currency, currencyDisplay, amount } =
    typeof input === 'number'
      ? { ...defaultCurrencyFormat, amount: input }
      : { ...defaultCurrencyFormat, ...input };

  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    currencyDisplay,
  }).format(centsToDollars(amount));
};

export function centsToDollarsAndCentsArray(cents: number = 0) {
  const balance = centsToDollarString(cents);
  return balance.split('.');
}

export const formatDataQaIds = (itemFormatDataQaIds: string = '') =>
  (itemFormatDataQaIds || '').replace(/\s/g, '').toLowerCase();

// simple logger for availability
// TODO: batch these logs to clean up the console
export const availabilityLogger = <
  I extends { _type?: string; name?: { locale: string } | string }
>(
  item: I | undefined | null,
  reason: string
) => {
  if (env() === RBIEnv.PROD || window.location.hostname === 'localhost') {
    return;
  }
  const name =
    !!item?.name && typeof item?.name === 'object'
      ? item.name?.locale
      : item?.name || 'Unknown name';
  logger.groupCollapsed(
    `Unavailable ${item?._type ? item._type : 'item of unknown type'}: ${name}`
  );
  logger.info({ item, message: reason });
  logger.groupEnd();
};

const dividedBy = (denominator: number) => (numerator: number) => numerator / denominator;
const mult = (x: number) => (y: number) => x * y;

const comp2 =
  <A extends any[], B, C>(left: (b: B) => C, right: VariadicFn<A, B>): VariadicFn<A, C> =>
  (...args: A) =>
    left(right(...args));

export const identity = <A>(x: A) => x;

export function compose<A extends any[], B, C, D, E, F>(
  fn1: (e: E) => F,
  fn2: (d: D) => E,
  fn3: (c: C) => D,
  fn4: (b: B) => C,
  fn5: VariadicFn<A, B>
): VariadicFn<A, F>;
export function compose<A extends any[], B, C, D, E>(
  fn1: (d: D) => E,
  fn2: (c: C) => D,
  fn3: (b: B) => C,
  fn4: VariadicFn<A, B>
): VariadicFn<A, E>;
export function compose<A extends any[], B, C, D>(
  fn1: (c: C) => D,
  fn2: (b: B) => C,
  fn3: VariadicFn<A, B>
): VariadicFn<A, D>;
export function compose<A extends any[], B, C>(
  fn1: (b: B) => C,
  fn2: VariadicFn<A, B>
): VariadicFn<A, C>;
export function compose<A extends any[]>(...fns: A) {
  return fns.reduce(comp2, identity);
}

export const tap =
  <X>(sideEffect: (x: X) => void) =>
  (x: X): X => {
    sideEffect(x);
    return x;
  };

type VariadicPredicate<A extends any[]> = VariadicFn<A, boolean>;

export function and<V extends any[]>(
  x: VariadicPredicate<V>,
  y: VariadicPredicate<V>
): VariadicPredicate<V> {
  return (...args) => x(...args) && y(...args);
}

export const or =
  <V extends AnyArgs>(x: VariadicPredicate<V>, y: VariadicPredicate<V>): VariadicPredicate<V> =>
  (...args) =>
    x(...args) || y(...args);

export const not =
  <V extends AnyArgs>(x: VariadicPredicate<V>): VariadicPredicate<V> =>
  (...args) =>
    !x(...args);

export const every =
  <V>(predicate: (element: V) => boolean) =>
  (input: V[]): boolean =>
    input.every(predicate);

export const isLengthWithDefaultValue =
  <V extends { length: number }>(length: number, defaultValue: V) =>
  (value: V = defaultValue) =>
    value.length === length;

export const maxLengthWithDefaultValue =
  <V extends { length: number }>(length: number, defaultValue: V) =>
  (value: V = defaultValue) =>
    value.length <= length;

export const minLengthWithDefaultValue =
  <V extends { length: number }>(length: number, defaultValue: V) =>
  (value: V = defaultValue) =>
    value.length >= length;

export function propEq<O extends object, P extends keyof O>(prop: P) {
  return (obj: O, otherObj: O): boolean => obj[prop] === otherObj[prop];
}

export const propIs =
  <O extends object, P extends keyof O = keyof O, V extends O[P] = O[P]>(prop: P, value: V) =>
  (obj: O | null): boolean =>
    !!obj && obj[prop] === value;

export const get =
  <O extends object, V>(path: string, defaultValue?: V) =>
  (obj: O): V =>
    dlv(obj, path) || defaultValue;

export const some =
  <V>(predicate: (element: V) => boolean) =>
  (input: V[]): boolean =>
    input.some(predicate);

export const getRandomId = compose(btoa, String, mult(99999), Math.random);

export const catchPromise =
  <E, R, R2>(onCatch: (error: E) => R2) =>
  (lazyP: () => Promise<R>) =>
  (): Promise<R | R2> =>
    lazyP().catch(onCatch);

export const millisecondsToSeconds = dividedBy(1000);
export const secondsToMilliseconds = mult(1000);

export const replace =
  (replacePattern: RegExp | string, replacement: string) => (searchString: string) =>
    searchString.replace(replacePattern, replacement);

export const remove = (pattern: RegExp | string) => replace(pattern, '');

export const prepend = (prefix: string) => (value: string) => prefix.concat(value);

export const isFalse = (bool: any): bool is false => bool === false;
