import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconTwitter = props => (
  <svg viewBox="0 0 15 12" {...props}>
    <title lang="en">Follow us on Twitter</title>
    <path
      d="M7.434 3.128l.03.508-.506-.062c-1.84-.239-3.45-1.048-4.815-2.408L1.476.492 1.304.99c-.365 1.11-.132 2.283.627 3.072.404.436.313.498-.385.238-.242-.083-.455-.145-.475-.114-.07.073.172 1.017.364 1.39.263.52.8 1.028 1.386 1.33l.496.238-.587.01c-.566 0-.587.01-.526.229.202.674 1.001 1.39 1.892 1.702l.627.218-.546.332c-.81.477-1.76.747-2.711.768-.456.01-.83.052-.83.083 0 .104 1.234.685 1.952.913 2.155.675 4.714.384 6.636-.768 1.366-.82 2.732-2.45 3.369-4.027.344-.84.688-2.376.688-3.113 0-.478.03-.54.597-1.11.333-.333.647-.696.708-.8.1-.197.09-.197-.425-.02-.86.31-.981.27-.557-.198.314-.332.688-.934.688-1.11 0-.031-.151.02-.323.114-.182.104-.587.26-.89.353l-.547.176-.496-.342c-.273-.187-.657-.395-.86-.457-.515-.145-1.304-.124-1.77.042-1.264.467-2.063 1.67-1.972 2.989z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconTwitter;
