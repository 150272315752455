import React from 'react';

import styled from 'styled-components';

import {
  ILinkWidgetFragment,
  IVerticalLinkListWithHeaderWidgetFragment,
} from 'generated/sanity-graphql';

import { Links } from './links';
import { Header } from './styled';

const Container = styled.div`
  display: none;

  ${Styles.desktop`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

type IVerticalLinkListWithHeaderProps = IVerticalLinkListWithHeaderWidgetFragment;

export const VerticalLinkListWithHeader: React.FC<IVerticalLinkListWithHeaderProps> = props => {
  return (
    <Container>
      <Header>{props.header?.locale}</Header>
      <Links links={props.linkWidgets as ILinkWidgetFragment[]} />
    </Container>
  );
};
