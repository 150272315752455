import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useFeatureLoyaltyNotAvailableAtDeliveryModeQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { useUIContext } from 'state/ui';
import { isMobile } from 'utils/environment';

const NotAvailableContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: ${Styles.layout.sectionMaxWidth};
  padding-bottom: 0;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: ${Styles.color.black};

  img {
    margin-top: 4rem;
    height: 216px;
    ${Styles.desktop`
      width: 25%;
    `}
    ${Styles.mobile`
      width: 80%;
    `}
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 0.7rem;
    font-family: ${Styles.fontFamily.base};
    font-size: 1.5rem;
    line-height: 1.7rem;
    text-transform: capitalize;
    text-align: center;
    width: 343px;
  }

  h2 {
    font-family: ${Styles.fontFamily.base};
    font-size: 1rem;
    margin-top: 3.5rem;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.1rem;
    margin-bottom: 0;
    a {
      color: ${Styles.color.red};
    }
  }

  button {
    width: 16rem;
    height: 2.5rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 1.5rem;
  }
`;

const LoyaltyCtaBar = React.lazy(() =>
  import('pages/loyalty/loyalty-cta-bar').then(module => ({
    default: module.LoyaltyCtaBar,
  }))
);

const LoyaltyNotAvailableAtDeliveryMode: React.FC = () => {
  const { formatMessage } = useIntl();
  const { buildImageUrl } = useUIContext();
  const { featureLoyaltyUIId = '' } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyNotAvailableAtDeliveryModeQuery({
    variables: { id: featureLoyaltyUIId },
  });

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation fillColor={Styles.color.primaryHover} />
      </LoadingContainer>
    );
  }
  const loyaltyNotAvailableData = data?.LoyaltyUI?.loyaltyNotAvailableAtDeliveryMode;
  const imageUrl = buildImageUrl(loyaltyNotAvailableData?.image?.locale?.app?.asset?._id || '');
  const imageAlt = loyaltyNotAvailableData?.image?.locale?.imageDescription || '';
  const title = loyaltyNotAvailableData?.title?.locale;
  const description = loyaltyNotAvailableData?.description?.locale || '';
  const firstRedirectPath = loyaltyNotAvailableData?.firstRedirectPath?.locale || '';
  const firstButtonText = loyaltyNotAvailableData?.firstButtonText?.locale || '';

  return (
    <div data-testid="loyalty-not-available-at-delivery-mode">
      <Helmet title={formatMessage({ id: 'loyaltyNotAvailableAtDeliveryMode' })} />
      {isMobile() && <LoyaltyCtaBar />}
      <NotAvailableContainer>
        <img alt={imageAlt} src={imageUrl} />
        <h1>{title}</h1>
        {description && <p>{description}</p>}
        <ActionButton variant={ActionButtonVariants.OUTLINE} to={firstRedirectPath}>
          {firstButtonText}
        </ActionButton>
      </NotAvailableContainer>
    </div>
  );
};

export default LoyaltyNotAvailableAtDeliveryMode;
