import React from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import ReactDOM from 'react-dom';

import { LaunchDarklyFlagsObject } from 'utils/launchdarkly';

import App from './app';
import bootstrap from './bootstrap';

// Our automation tests when we're running in integration mode mocks all external requests
// A few APIs require a little special logic to mock properly. This lets us patch the requests
// to get our mocks correctly when we're in test mode and not an E2E suite
if (process.env.REACT_APP_RBI_ENV === 'test' && process.env.CYPRESS_IS_E2E !== 'true') {
  require('./patch-fetch-for-mocks');
}

const searchParams = new URLSearchParams(window.location.search);
const enableWhyDidYouRender = searchParams.get('enableWhyDidYouRender');
if (process.env.NODE_ENV !== 'production' && !window.Cypress && enableWhyDidYouRender === 'true') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

export const renderApp = ({
  flags: flattenedFlags,
  userHasSignedIn,
  userSession,
}: {
  flags: LaunchDarklyFlagsObject;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
}) => {
  const rootTag = document.getElementById('root');
  rootTag!.innerHTML = '';

  //TO-DO: ReactDOM is deprecated in React18. We should refactor this when a new version of react native (with react 18) to use createRoot
  ReactDOM.render(
    <App ldFlags={flattenedFlags} userHasSignedIn={userHasSignedIn} userSession={userSession} />,
    rootTag
  );
};

bootstrap(renderApp);
