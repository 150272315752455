import styled, { StyledProps } from 'styled-components';

import ActionButton from 'components/action-button';
import { brandFont } from 'components/layout/brand-font';
import { DrawerContainer } from 'components/navigation-drawer/navigation-drawer.styled';
import { primitive } from 'styles/constants/primitives';

import { OverviewContainer } from './product-overview/product-overview.styled';

export const LayoutContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${primitive.$white};
`;

export const LayoutContent = styled.div<{ $windowHeight: number }>`
  display: flex;
  flex-direction: column;
  min-height: calc(${p => p.$windowHeight}px - ${Styles.layout.navHeight.mobile});

  ${Styles.desktopLarge`
    display: block;
    height: 100%;
    min-height: auto;
    position: relative;
    margin-top: ${primitive.$spacing2}
  `}
`;

export const LayoutContainer = styled.div<{ $isStatic?: boolean }>`
  display: flex;
  flex-direction: column;

  ${Styles.desktopLarge`
    position: relative;
    width: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? '100%' : '1136px')};
    margin: 0 auto;
    padding-bottom: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? '2rem' : '0')};
    margin-top: ${(p: StyledProps<{}>) => p.theme.token('margin-top-product-wizard-layout')};
    ${OverviewContainer} {
      width: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? 'inherit' : '60%')};
    }
    ${LayoutContainerWrapper} {
      width: 40%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      ${DrawerContainer}{
        height: 100%;
      }
    }
  `}
`;

export const ProductNoteText = styled.p`
  font: ${brandFont.copyTwo};
  margin: 0;
`;

export const StyledActionButton = styled(ActionButton)`
  font: ${brandFont.headerThree};
  padding: 0.75rem 3rem;
  font-size: 1rem;

  ${Styles.mobileSmall`
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  `}

  ${Styles.mobileTiny`
    padding: 0.75rem;
    font-size: 0.9rem;
  `}
`;

export const StyledNumberInputWrapper = styled.div`
  input[type='number'] {
    width: 3rem;
    margin: 0 0.5rem;
  }

  ${Styles.mobileTiny`
    input[type='number'] {
      width: 1rem;
      margin: 0 0.1rem;
    }
  `}

  ${Styles.mobileSmall`
    input[type='number'] {
      width: 1.5rem;
      margin: 0 0.2rem;
    }
  `}
`;

export const StyledOverviewText = styled.span`
  margin: 0 ${primitive.$spacing2};
`;

export const DesktopMainViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const CheckAvailabilityButton = styled.span`
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ScrollableArea = styled.div`
  overflow: auto;
`;
