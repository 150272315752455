import React, { FC } from 'react';

import NavLink from 'components/app-header/nav-link';
import NavLinkExternal from 'components/app-header/nav-link-external';
import { checkFlagEnabled } from 'components/mobile-web-navigation-bar/utils-sanity';
import SkipToContent from 'components/skip-content';
import UniversalLink from 'components/universal-link';
import { INavigationLinkFragment } from 'generated/sanity-graphql';
import { useLDContext } from 'state/launchdarkly';

import {
  AlternateLDNavigationLink,
  IDynamicLeftNavContainerProps,
  IValidDesktopLink,
  IValidNavLink,
} from './types';

export const DynamicLeftNavContainer: FC<IDynamicLeftNavContainerProps> = ({ navLinks }) => {
  const { flags } = useLDContext();

  const buildNavLink = (data: IValidDesktopLink) => ({
    key: data._key,
    link: data.navLink?.link?.locale,
    text: data.navLink?.text?.locale,
  });

  const isValidLink = (
    link: INavigationLinkFragment | AlternateLDNavigationLink | null
  ): link is IValidDesktopLink => {
    const enabledIfAltTab = link?.__typename !== 'LaunchDarklyNavigationLink' || link?.enabled;
    return !!(link?.navLink?.link?.locale && link?.navLink?.text?.locale && enabledIfAltTab);
  };

  const validNavLinks = navLinks.reduce<IValidNavLink[]>((acc, val) => {
    const hideNavLinkLdFlag = val?.hideLinkBasedOnLdFlag?.launchDarklyFlag;
    const shouldHideNavLink = checkFlagEnabled(flags, hideNavLinkLdFlag);

    if (isValidLink(val) && !shouldHideNavLink) {
      const altLdFlagNavLink = val?.launchDarklyAlternateNavigationLink;
      const LDFlagDependency = altLdFlagNavLink?.launchDarklyFlagDependency?.launchDarklyFlag;
      const shouldShowAlternateLdNavLink = checkFlagEnabled(flags, LDFlagDependency);

      acc.push(
        buildNavLink(
          shouldShowAlternateLdNavLink && isValidLink(altLdFlagNavLink) ? altLdFlagNavLink : val
        )
      );
    }
    return acc;
  }, []);

  return (
    <>
      <SkipToContent />
      {validNavLinks.map(navLink => (
        <UniversalLink
          to={navLink.link}
          key={navLink.key}
          data-testid={navLink.text}
          internal={{ component: NavLink, props: { matchRoute: navLink.link } }}
          external={{ component: NavLinkExternal }}
        >
          {navLink.text}
        </UniversalLink>
      ))}
    </>
  );
};
