import { IOffer } from '@rbi-ctg/menu';
import { ICartProperty, Rule } from '@rbi-ctg/offers';
import { ServiceMode } from 'state/service-mode/types';
import {
  CATERING_SERVICE_MODES,
  DELIVERY_SERVICE_MODES,
  NON_CATERING_SERVICE_MODES,
  NON_PICKUP_SERVICE_MODES,
  PICKUP_SERVICE_MODES,
  SERVICE_MODES,
} from 'utils/service-mode';

import { findAllRulesOfTypeThatApply } from './rule-of-type-will-apply';
import { RuleType } from './types';
import { valueSatisfiesCartPropertyRule } from './value-satisfies-cart-property-rule';

/**
 * @name getAllowedServiceModes
 * @description
 *  determines allowed service modes for a given offer ruleset
 *  because cart property rules have an option to be
 *  negated, there are two different sets of rules:
 *    1. Required service mode (ex. must be of type x)
 *    2. Disallowed service mode (ex. cannot be of type y)
 *  first finds all CartProperty rules which is wheere serviceMode
 *  rules are configured
 *    we currently have two separate data formats for offers rulesets:
 *    1. when we query offers in Sanity directly from the frontend
 *       where serviceMode rules are directly on the ruleSet object
 *    2. when we query offers from the backend coupon engine
 *       where serviceMode rules are nested on a 'params' object
 *       within a given ruleSet
 *  then, we filter down all possible service modes by reducing
 *  over each service mode rule and ensureing the service modes
 *  available satisfy the given service mode rules
 * @returns {array} of allowed service modes for the given offer
 *
 * @TODO: remove iffy logic for different data formats
 *        once backend offers is fully rolled out
 */
export function getAllowedServiceModes(ruleSet: Rule[] | null): string[] {
  return findAllRulesOfTypeThatApply<ICartProperty>(RuleType.CartProperty, ruleSet)
    .reduce((acc, curRuleSet) => {
      if (curRuleSet.params && curRuleSet.params.key === 'serviceMode') {
        // case: when offers are queried from backend
        // NOTE: need to type cast here because valueSatisfiesCartPropertyRule
        //       expects the passed in value to match ICartProperty
        acc.push(curRuleSet.params as ICartProperty);
      } else if (curRuleSet.key === 'serviceMode') {
        // case: when offers are queried from frontend
        acc.push(curRuleSet);
      }
      return acc;
    }, [] as ICartProperty[])
    .reduce<string[]>(
      (acc, rule) => acc.filter(valueSatisfiesCartPropertyRule(rule)),
      SERVICE_MODES
    );
}

/**
 * the following offerIsAvailableFor<SM> functions
 *  return a boolean for whether or not the serviceMode
 *  is one of potentially many allowed SMs for the ruleset
 */
export const offerIsAvailableForPickup = (offer: IOffer, allowedServiceModes?: string[]) => {
  return (allowedServiceModes ?? getAllowedServiceModes(offer.ruleSet)).some((sm: ServiceMode) =>
    PICKUP_SERVICE_MODES.includes(sm)
  );
};

export const offerIsAvailableForDelivery = (offer: IOffer, allowedServiceModes?: string[]) => {
  return (allowedServiceModes ?? getAllowedServiceModes(offer.ruleSet)).some((sm: ServiceMode) =>
    DELIVERY_SERVICE_MODES.includes(sm)
  );
};

export const offerIsAvailableForCatering = (offer: IOffer, allowedServiceModes?: string[]) => {
  return (allowedServiceModes ?? getAllowedServiceModes(offer.ruleSet)).some((sm: ServiceMode) =>
    CATERING_SERVICE_MODES.includes(sm)
  );
};

export const offerIsAvailable = (offer: IOffer, serviceMode: ServiceMode) => {
  return getAllowedServiceModes(offer.ruleSet).some((sm: ServiceMode) => sm === serviceMode);
};

/**
 * the following offerIs<SM>Only functions
 *  return a boolean for whether or not the serviceMode
 *  is the ONLY allowed SM for the given offer.
 */
export const offerIsDeliveryOnly = (offer: IOffer, allowedServiceModes?: string[]) => {
  // NOTE: CATERING_DELIVERY does not count as an available delivery only option
  const serviceModesForOffer = allowedServiceModes ?? getAllowedServiceModes(offer.ruleSet);
  return serviceModesForOffer.length === 1 && serviceModesForOffer[0] === ServiceMode.DELIVERY;
};

export const offerIsPickupOnly = (offer: IOffer, allowedServiceModes?: string[]) => {
  // since there are multiple pickup options, we
  // go through every allowedServiceMode and make
  // sure none of them are non-pickup modes
  return (allowedServiceModes ?? getAllowedServiceModes(offer.ruleSet)).every(
    (sm: ServiceMode) => !NON_PICKUP_SERVICE_MODES.includes(sm)
  );
};

export const offerIsCateringOnly = (offer: IOffer, allowedServiceModes?: string[]) => {
  // since there are multiple pickup options, we
  // go through every allowedServiceMode and make
  // sure none of them are non-pickup modes
  return (allowedServiceModes ?? getAllowedServiceModes(offer.ruleSet)).every(
    (sm: ServiceMode) => !NON_CATERING_SERVICE_MODES.includes(sm)
  );
};
