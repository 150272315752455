import styled from 'styled-components';

type LayoutProps = {
  position?: 'absolute' | 'relative';
};

export const Layout = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  ${Styles.desktop`
    justify-content: flex-start;
    padding: 0 40px 0 55px;
  `}
  height: 32px;
  width: 100%;
  background-color: ${Styles.color.black};
`;

export const Message = styled.p`
  color: ${Styles.color.white};
  margin-right: 8px;
`;

export const Action = styled.a`
  color: ${Styles.color.white};
  cursor: pointer;
  text-decoration: underline;
`;
