import React from 'react';

import { LoadingMessage, LoadingWrapper, LoadingWrapperInner } from '../styled';

import { Animation } from './animation';
import { Background } from './background';

export const Loader = ({ copy }: { copy: string }) => (
  <LoadingWrapper>
    <Background />
    <LoadingWrapperInner>
      <Animation />
      <LoadingMessage>{copy}</LoadingMessage>
    </LoadingWrapperInner>
  </LoadingWrapper>
);
