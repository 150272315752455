import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Currency from 'components/currency';
import Picture from 'components/picture';
import { useComposeDescription } from 'hooks/use-compose-description';
import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOffersContext } from 'state/offers';
import { priceForCartEntry } from 'utils/menu/price';

import { PriceAndQuantity } from '../cart-item/price-and-quantity';
import { RedeemReward } from '../cart-item/redeem-reward';

import CartPreviewActions, {
  CartPreviewEditButton,
  CartPreviewIncrementor,
  CartPreviewRemoveButton,
  CartPreviewTitle,
  CartPreviewVisuallyHidden,
} from './cart-preview-actions';
import { CartEntryQuantityText } from './styled/cart-entry-quantity-text';
import { CartPreviewEditContainer } from './styled/cart-preview-edit-container';
import { CartPreviewEntryContainer } from './styled/cart-preview-entry-container';
import { CartPreviewItem } from './styled/cart-preview-item';
import { ICartPreviewEntry } from './types';
import { getIsOffer } from './utils';

const PictureWrap = styled.div`
  align-items: center;
  border: 1px solid ${Styles.color.grey.four};
  border-radius: ${Styles.borderRadius};
  display: flex;
  overflow: hidden;
  padding: 0 !important;
  width: 85px;
`;

const PriceWrapper = styled.div<{ $isOffer: boolean }>`
  font-family: ${Styles.fontFamily.base};
  font-size: 1rem;
  font-weight: ${Styles.fontWeight.heavy};
  margin: 0;
  text-align: right;
  grid-column: 3;

  & span {
    margin: 0;
  }

  ${p => p.$isOffer && `color: ${Styles.color.black}`}
`;

const DetailsContainer = styled.div`
  & span {
    align-self: flex-start;
  }
`;

const Description = styled.div`
  color: ${Styles.color.black};
  font-size: 0.75rem;
  font-weight: ${Styles.fontWeight.normal};
  line-height: 16px;
  margin: 0 0 0.5rem 0;
`;

const RedeemRewardWrapper = styled.div`
  grid-column-end: span 3;

  > div {
    margin: 1.25rem 0 0;
  }
`;

const CartPreviewEntry = ({
  closeModalCallback,
  item,
  rewardApplied = false,
}: ICartPreviewEntry) => {
  const { formatMessage } = useIntl();
  const description = useComposeDescription(item, {
    returnTypeArray: true,
  });
  const { selectedOfferPrice, selectedOfferCartEntry } = useOffersContext();
  const isOffer = selectedOfferCartEntry ? getIsOffer({ selectedOfferCartEntry, item }) : false;
  const isExtra = item?.isExtra;
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const loyaltyRewardAvailable = getAvailableRewardFromCartEntry(item);
  const displayLoyaltyComponents = !isOffer && loyaltyEnabled;
  const isLoyaltyOffer = appliedOffers.some(lylOffer => lylOffer.cartId === item.cartId);
  const price = isOffer ? selectedOfferPrice : priceForCartEntry(item);

  return (
    <CartPreviewEntryContainer>
      <CartPreviewItem>
        {item.image && (
          <PictureWrap>
            <Picture image={item.image} alt="" />
          </PictureWrap>
        )}
        <DetailsContainer>
          <CartPreviewTitle item={item} />
          <Description>
            {description.map((descriptionItem, index) => (
              <span key={`descriptionItem-${index}`}>
                {descriptionItem}
                <br />
              </span>
            ))}
          </Description>
        </DetailsContainer>
        <PriceWrapper $isOffer={isOffer}>
          {displayLoyaltyComponents ? (
            <PriceAndQuantity item={item} rewardApplied={rewardApplied} />
          ) : (
            <Currency amount={price} />
          )}
        </PriceWrapper>
        {displayLoyaltyComponents && loyaltyRewardAvailable && !isExtra && !item.isDonation && (
          <RedeemRewardWrapper>
            <RedeemReward
              isRewardApplied={rewardApplied}
              item={item}
              reward={loyaltyRewardAvailable}
            />
          </RedeemRewardWrapper>
        )}
        {!item.isDonation && (
          <CartPreviewActions isOffer={isOffer} isReward={rewardApplied} item={item}>
            <CartPreviewEditContainer>
              {!isLoyaltyOffer && <CartPreviewEditButton closeModalCallback={closeModalCallback} />}
              <CartPreviewRemoveButton closeModalCallback={closeModalCallback} />
            </CartPreviewEditContainer>
            {isLoyaltyOffer ? null : isExtra ? (
              <CartEntryQuantityText>{`${item.quantity}x`}</CartEntryQuantityText>
            ) : (
              <CartPreviewIncrementor />
            )}
            <CartPreviewVisuallyHidden />
          </CartPreviewActions>
        )}
      </CartPreviewItem>
      <VisuallyHidden role="alert">
        {formatMessage(
          { id: 'quantityUpdatedAlert' },
          {
            quantity: item.quantity,
            itemName: item.name,
          }
        )}
      </VisuallyHidden>
    </CartPreviewEntryContainer>
  );
};

export default CartPreviewEntry;
