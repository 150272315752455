import { ILoyaltyCategory } from 'state/loyalty/types';

import { IGetSelectedTierName } from '../../types';

export const getSelectedTierName = ({
  selectedCategory,
  tiers,
  selectedRewardTierId,
}: IGetSelectedTierName) => {
  if (!selectedCategory) {
    return undefined;
  }

  const tier = tiers?.find(
    ({ _id: tierId, name: tierName }: { _id: string; name: any }) =>
      tierId === selectedRewardTierId && tierName
  );

  return tier?.name?.locale;
};

export const getRewardCategory = (categoryId: string, loyaltyCategories: ILoyaltyCategory[]) =>
  loyaltyCategories.find(category => category._id === categoryId);
