import styled from 'styled-components';

import { BUTTON_CONTAINER_TOP_BORDER } from '../constants';

export const ButtonContainer = styled.div`
  background-color: ${Styles.color.white};
  bottom: 0;
  position: relative;
  width: 100%;
  border-top: ${BUTTON_CONTAINER_TOP_BORDER};
`;
