import { gql } from '@apollo/client';

export default gql`
  fragment NutritionFragment on Nutrition {
    calories
    fat
    saturatedFat
    transFat
    cholesterol
    sodium
    salt
    carbohydrates
    fiber
    sugar
    proteins
    weight
  }
`;
