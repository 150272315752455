import styled from 'styled-components';

import ActionButton from 'components/action-button';
import { brandFont } from 'components/layout/brand-font';
import Modal from 'components/modal';
import Picture from 'components/picture';

export const StyledModal = styled(Modal)`
  &[data-reach-dialog-content] {
    width: 90%;
    height: 75vh;
    margin: 5em auto;
    position: relative;
    border-radius: ${Styles.borderRadius};

    > button {
      position: absolute;
      top: 0;
      left: 0;
      margin: 10px 0 0 10px;
    }

    ${Styles.desktop`
      margin: 0;
    `}
  }
`;

export const ModalInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;

  h2 {
    text-align: center;
    margin: 0;
    text-transform: ${Styles.textTransform.headlines};
    font: ${brandFont.headerOne};
    font-size: 2rem;
    line-height: 50px;
  }
`;

export const Description = styled.div`
  padding: 1.5em;
  text-align: center;
  font-size: 1.15em;
`;

export const ModalHead = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;

  ${Styles.desktop`
    margin-bottom: 30px;
  `}
`;

export const ModalPicture = styled(Picture)`
  width: 100%;
  max-width: 350px;
  margin-top: 4vh;

  ${Styles.desktop`
    width: 50%;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 1em;

  ${Styles.desktop`
    margin-top: 0;
  `}
`;

export const DismissButton = styled(ActionButton)`
  width: 100%;
  margin-bottom: 0.6em;

  ${Styles.desktop`
    width: 50%;
    margin: 0 auto 0.6em;
  `}
`;
