import {
  ApolloLink,
  gql as gqlFunc /* do not change this `as` as it makes it so the babel-plugin-graphql-tag transform works properly */,
} from '@apollo/client';
import { Language, getDefaultLanguage } from '@rbilabs/intl';
import { print } from 'graphql';

import { brand, getCountry } from 'utils/environment';

const DEPRECATED_LANGUAGE_INTERPOLATION_REGEX = /_LANG_/g;
const LANGUAGE_INTERPOLATION_REGEX = /locale(Raw)?: en(Raw)?/g;

const defaultLanguage = (): Language => getDefaultLanguage(brand(), getCountry());

export const withLocalizedQueries = () =>
  new ApolloLink((operation, next) => {
    const context = operation.getContext();
    const { language = defaultLanguage() } = context;

    if (operation.query) {
      const rawQuery = print(operation.query);

      const replacedRawQuery = rawQuery
        .replace(DEPRECATED_LANGUAGE_INTERPOLATION_REGEX, language) // TODO(ICFE-359) migrate queries to the new regex below
        .replace(LANGUAGE_INTERPOLATION_REGEX, `locale$1: ${language}$2`);

      if (rawQuery === replacedRawQuery) {
        // skip recreating gql DocumentNode if not needed since it's CPU expensive
        return next(operation);
      }

      // It's important to use gqlFunc and not the gql export because otherwise the babel-plugin-graphql-tag will process this
      const interpolated = gqlFunc(replacedRawQuery);
      operation.query = interpolated;

      return next(operation);
    }

    return next(operation);
  });
