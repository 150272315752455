import React, { useEffect } from 'react';

import { PYPESTREAM_INTERFACE_ID, usePypestreamContext } from 'state/pypestream';

import { Loader } from './loader';
import { Container, Screen } from './styled';

export const PypestreamInterface = () => {
  const { visible, declareContainerReady, loading, loadingMessage } = usePypestreamContext();

  useEffect(() => {
    declareContainerReady();
  }, [declareContainerReady]);

  return (
    <Screen visible={visible || loading}>
      {loading && <Loader copy={loadingMessage} />}
      <Container
        visible={visible && !loading}
        id={PYPESTREAM_INTERFACE_ID}
        data-testid={PYPESTREAM_INTERFACE_ID}
      />
    </Screen>
  );
};
