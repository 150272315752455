import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { LinkNoDecoration } from 'components/app-header/mobile-header/styled';
import { LoyaltyPointsButton } from 'components/loyalty-points-button';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

const MobileHeaderCta = () => {
  const { formatMessage } = useIntl();

  const enableSignUp = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableAccountInFooter = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_IN_FOOTER);
  const isHamburgerMenuEnabled = useFlag(LaunchDarklyFlag.ENABLE_HAMBURGER_MENU_ON_MOBILE);
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated()) {
    return (
      <>
        <LoyaltyPointsButton />
        {!enableAccountInFooter && !isHamburgerMenuEnabled && (
          <LinkNoDecoration data-testid="mobile-nav-account-link" to={routes.account}>
            <Icon icon="profile" color="primary" height="1.5rem" width="2rem" aria-hidden />
            <VisuallyHidden>{formatMessage({ id: 'account' })}</VisuallyHidden>
          </LinkNoDecoration>
        )}
      </>
    );
  }

  if (!enableSignUp) {
    return null;
  }

  return (
    <Box>
      <ActionButton
        variant={
          enableLoyaltyPointsInHeader ? ActionButtonVariants.OUTLINE : ActionButtonVariants.PRIMARY
        }
        size={ActionButtonSizes.SMALL}
        to={routes.signUp}
        data-testid="mobile-nav-signup-link"
      >
        {formatMessage({ id: 'signUp' })}
      </ActionButton>
    </Box>
  );
};

export default MobileHeaderCta;
