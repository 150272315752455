import { gql } from '@apollo/client';

import {
  IAdditionalItemInformation,
  IAllergens,
  ISanityCombo,
  ISanityImage,
  ISanityItem,
  ISanityPicker,
  ISanitySection,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';

import ImageFragment from './image';
import NutritionFragment from './nutrition';

/**
 * This fragment represents the smallest amount of data necessary
 * in order to populate the nutrition explorer widget.
 */

export const NutritionExplorerItemFragment = gql`
  fragment NutritionExplorerItemFragment on Item {
    _id
    _type
    name {
      locale: _LANG_
    }
    image {
      ...ImageFragment
    }
    options {
      options {
        default
        nutrition {
          ...NutritionFragment
        }
      }
    }
    nutrition {
      ...NutritionFragment
    }
    additionalItemInformation {
      ingredients {
        locale: en
      }
      additives {
        locale: en
      }
      producerDetails {
        locale: en
      }
    }
    nutritionWithModifiers {
      ...NutritionFragment
    }
    allergens {
      milk
      eggs
      fish
      peanuts
      shellfish
      treeNuts
      soy
      wheat
      mustard
      sesame
      celery
      lupin
      gluten
      sulphurDioxide
    }
  }
  ${ImageFragment}
  ${NutritionFragment}
`;

export type INutritionExplorerItem = Pick<
  ISanityItem,
  '_id' | '_type' | 'name' | 'image' | 'options' | 'nutrition' | 'nutritionWithModifiers'
> & {
  allergens?: IAllergens;
  additionalItemInformation: IAdditionalItemInformation;
};

export const NutritionExplorerComboFragment = gql`
  fragment NutritionExplorerComboFragment on Combo {
    _id
    _type
    name {
      locale: _LANG_
    }
    image {
      ...ImageFragment
    }
    mainItem {
      ...NutritionExplorerItemFragment
    }
  }
  ${NutritionExplorerItemFragment}
`;

export type INutritionExplorerCombo = Pick<ISanityCombo, '_id' | '_type' | 'name' | 'image'> & {
  mainItem: INutritionExplorerItem;
};

const NutritionExplorerPickerFragment = gql`
  fragment NutritionExplorerPickerFragment on Picker {
    _id
    _type
    name {
      locale: _LANG_
    }
    image {
      ...ImageFragment
    }
    pickerDefaults {
      pickerAspect {
        _id
      }
      pickerAspectValueIdentifier
    }
    pickerAspects {
      _id
      _type
      pickerAspectOptions {
        identifier
      }
    }
    pickerAspectItemOptionMappings {
      pickerAspect {
        _id
      }
      options {
        value
      }
    }
    options {
      pickerItemMappings {
        pickerAspectValueIdentifier
        pickerAspect {
          _id
        }
      }
      option {
        ...NutritionExplorerItemFragment
        ...NutritionExplorerComboFragment
      }
    }
  }
  ${NutritionExplorerComboFragment}
  ${NutritionExplorerItemFragment}
`;

export type INutritionExplorerPicker = Pick<ISanityPicker, '_id' | '_type' | 'name' | 'image'> & {
  pickerDefaults: null | Array<{
    pickerAspect: {
      _id: string;
    };
    pickerAspectValueIdentifier: string;
  }>;
  pickerAspects: Array<{
    _id: string;
    _type: MenuObjectTypes.PICKER_ASPECT;
    pickerAspectOptions: {
      identifier: string;
    };
  }>;
  pickerAspectItemOptionMappings: Array<{
    pickerAspect: {
      _id: string;
    };
    options: {
      value: string;
    };
  }>;
  options: Array<{
    pickerItemMappings: Array<{
      pickerAspectValueIdentifier: string;
    }>;
    option: NutritionExplorerMenuObject;
  }>;
};

const NutritionExplorerSectionFragment = gql`
  fragment NutritionExplorerSectionFragment on Section {
    _id
    _type
    name {
      locale: _LANG_
    }
    carouselImage {
      ...ImageFragment
    }
    image {
      ...ImageFragment
    }
    options {
      ...NutritionExplorerPickerFragment
      ...NutritionExplorerItemFragment
      ...NutritionExplorerComboFragment
      ... on Section {
        _id
        _type
        name {
          locale: _LANG_
        }
        options {
          ...NutritionExplorerPickerFragment
          ...NutritionExplorerItemFragment
          ... on Section {
            _type
            _id
            options {
              ...NutritionExplorerPickerFragment
              ...NutritionExplorerItemFragment
            }
          }
        }
      }
    }
  }
  ${ImageFragment}
  ${NutritionExplorerItemFragment}
  ${NutritionExplorerPickerFragment}
  ${NutritionExplorerComboFragment}
`;

export type INutritionExplorerSection = Pick<
  ISanitySection,
  '_id' | '_type' | 'name' | 'image' | 'carouselImage'
> & {
  options: Array<NutritionExplorerMenuObject>;
};

export default gql`
  fragment NutritionExplorerMenuFragment on Menu {
    _id
    _type
    options {
      ...NutritionExplorerPickerFragment
      ...NutritionExplorerSectionFragment
      ...NutritionExplorerItemFragment
      ...NutritionExplorerComboFragment
    }
    pickerBackgroundImage {
      ...ImageFragment
    }
  }
  ${NutritionExplorerSectionFragment}
  ${NutritionExplorerItemFragment}
  ${NutritionExplorerPickerFragment}
  ${NutritionExplorerComboFragment}
`;

export interface INutritionExplorerMenu {
  options?: NutritionExplorerMenuObject[];
  pickerBackgroundImage?: ISanityImage;
}

export type NutritionExplorerMenuObject =
  | INutritionExplorerItem
  | INutritionExplorerCombo
  | INutritionExplorerPicker
  | INutritionExplorerSection;
