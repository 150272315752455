export type IAllowListPath = string | RegExp;

const matchesRoute = (path: string) => (routePath: IAllowListPath) => {
  if (typeof routePath === 'string') {
    return path === routePath;
  }

  return routePath.test(path);
};

export const pathMatchesAllowList = (allowlist: Array<IAllowListPath>) => (pathname: string) => {
  return allowlist.some(matchesRoute(pathname));
};
