import React from 'react';

import { useIntl } from 'react-intl';

import useRoutes from 'hooks/use-routes';

import { DONATIONS } from './constants';
import { StyledLink } from './styled';
import { DonationsContent, IUseDonationsProps } from './types';

export const useDonations = ({ donationType, donations }: IUseDonationsProps): DonationsContent => {
  const defaultReturn = {};

  const donation = donations?.find(({ name }) => name === donationType);

  const { formatMessage } = useIntl();
  const { getLocalizedRouteForPath } = useRoutes();

  const getRoundUpContent = (totalCents: number) => {
    const message = formatMessage({ id: 'timsCampDonation' });
    const iconAriaLabel = formatMessage({ id: 'moreInfo' });

    const linkContent = (
      <StyledLink
        data-testid="tims-rewards-faq-link"
        to={getLocalizedRouteForPath('tims-rewards-faq')}
      >
        {formatMessage({ id: 'timsRewardsFaqLinkText' })}
      </StyledLink>
    );

    const donationRowModalContent = {
      title: formatMessage({ id: 'timsCampDonation' }),
      modalAppearanceEventMessage: 'round up donations modal',
      body: formatMessage({ id: 'roundUpDescription' }, { link: linkContent }),
    };

    return {
      donationRowContent: {
        totalCents,
        message,
        iconAriaLabel,
      },
      donationRowModalContent,
    };
  };

  if (!donations || !donation) {
    return defaultReturn;
  }

  if (donationType === DONATIONS.ROUND_UP) {
    return getRoundUpContent(donation.totalCents);
  }

  return defaultReturn;
};
