import React, { FC, useState } from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { ProgressDots } from 'components/progress-dots';
import { ImageWithHeaderAndText } from 'components/widgets/image-with-header-and-text';

import {
  ContentContainer,
  LeftButtonContainer,
  NavigationContainer,
  RightButtonContainer,
  SkipContainer,
  Stepper,
} from './styles';
import { IOnboardingScreensModal } from './types';

export const OnboardingScreensModal: FC<IOnboardingScreensModal> = ({
  featureOnboardingScreens,
  onModalDismiss,
}) => {
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);

  const { formatMessage } = useIntl();
  const numberOfScreens = featureOnboardingScreens.length;
  const isLastScreen = currentScreenIndex >= numberOfScreens - 1;

  const navigateForward = () => {
    setCurrentScreenIndex(current => current + 1);
  };

  const navigateBackward = () => {
    setCurrentScreenIndex(current => current - 1);
  };

  return (
    <ContentContainer>
      <SkipContainer>
        {!isLastScreen && (
          <ActionButton
            variant={ActionButtonVariants.TEXT_ONLY}
            size={ActionButtonSizes.SMALL}
            onClick={onModalDismiss}
          >
            {formatMessage({ id: 'skip' })}
          </ActionButton>
        )}
      </SkipContainer>
      <ImageWithHeaderAndText
        image={featureOnboardingScreens[currentScreenIndex]?.image ?? null}
        imageDescription={featureOnboardingScreens[currentScreenIndex]?.imageDescription ?? ''}
        textContent={featureOnboardingScreens[currentScreenIndex]?.textContent ?? []}
        buttonText={featureOnboardingScreens[currentScreenIndex]?.buttonText ?? ''}
        buttonUrl={featureOnboardingScreens[currentScreenIndex]?.buttonUrl ?? ''}
      />
      <NavigationContainer>
        {currentScreenIndex !== 0 && (
          <LeftButtonContainer>
            <ActionButton
              variant={ActionButtonVariants.TEXT_ONLY}
              size={ActionButtonSizes.SMALL}
              onClick={navigateBackward}
            >
              {formatMessage({ id: 'previous' })}
            </ActionButton>
          </LeftButtonContainer>
        )}

        {numberOfScreens > 1 && (
          <Stepper data-testid={'stepper'}>
            <ProgressDots dotCount={numberOfScreens} activeDotIndex={currentScreenIndex} />
          </Stepper>
        )}

        {!isLastScreen && (
          <RightButtonContainer>
            <ActionButton
              variant={ActionButtonVariants.TEXT_ONLY}
              size={ActionButtonSizes.SMALL}
              onClick={navigateForward}
            >
              {formatMessage({ id: 'next' })}
            </ActionButton>
          </RightButtonContainer>
        )}
      </NavigationContainer>
    </ContentContainer>
  );
};
