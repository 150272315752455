import React from 'react';

import { RawPicture } from 'components/raw-picture';
import { IImageWidgetFragment } from 'generated/sanity-graphql';

interface IBrandLogoProps extends IImageWidgetFragment {
  className?: string;
}

export const BrandLogo: React.FC<IBrandLogoProps> = ({ className, image, imageDescription }) => {
  return image?.locale ? (
    <RawPicture image={image?.locale} alt={imageDescription?.locale ?? ''} className={className} />
  ) : null;
};
