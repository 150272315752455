import React, { useCallback, useContext } from 'react';

import { useOrderHref } from 'hooks/use-order-href';
import { CustomEventNames, MParticleContext } from 'state/mParticle';

import { StaticLeftNav } from './static-left-nav';

export const StaticLeftNavContainer = () => {
  const mParticle = useContext(MParticleContext);
  const orderHref = useOrderHref();

  // onReFocus Sets the focus of the clicked element to itself
  //
  // This is necessary due to the way that Reach Router changes the focus.
  // After the initial page load, reach router will move the focus to the outer most
  // component that is wrapped by the router. This component is outside of router,
  // so when clicking in the nav the focus is being pulled into the page.
  // For accessibility reasons, this is not the desired behavior.

  const onReFocus = useCallback(e => {
    e.persist();
    window.setTimeout(() => {
      if (e?.target?.focus) {
        e.target.focus();
      }
    }, 200);
  }, []);

  const curriedHandleClick = useCallback(
    (event: CustomEventNames) => (e: MouseEvent) => {
      mParticle.logNavigationClick(event);
      onReFocus(e);
    },
    [mParticle, onReFocus]
  );

  return <StaticLeftNav orderHref={orderHref} curriedHandleClick={curriedHandleClick} />;
};
