import React, { ReactNode, forwardRef, useMemo } from 'react';

import {
  BoxShadowButton,
  Button,
  IButtonProps,
  InverseButton,
  OnClick,
  OutlineButton,
  TextOnlyButton,
} from '@rbilabs/components-library/build/components/button';
import { DesignTokens } from '@rbilabs/components-library/build/designTokens';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import LoadingAnimation, { ILoadingAnimationProps } from 'components/loading-animation';
import { checkForOSPrompts } from 'state/location/routes';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { HapticsImpactStyle, hapticImpact } from 'utils/haptic';

export enum ActionButtonVariants {
  PRIMARY,
  OUTLINE,
  INVERSE,
  BOX_SHADOW_INVERSE,
  BOX_SHADOW_PRIMARY,
  TEXT_ONLY,
}

export { ButtonSizes as ActionButtonSizes } from '@rbilabs/components-library/build/components/button/types';

type AttributesMap = { [key: string]: string | number | null };
export interface IActionButtonProps extends IButtonProps {
  eventAttributes?: AttributesMap;
  eventName?: CustomEventNames;
  eventType?: EventTypes;
  onNonVisualClick?: OnClick;
  skipLogEvent?: boolean;
  isLoading?: boolean;
  loadingColor?: string;
  'data-testid'?: string;
  to?: string;
  perceptible?: boolean;
  hapticFeedbackStyle?: HapticsImpactStyle;
  color?:
    | React.ComponentProps<typeof TextOnlyButton>['color']
    | React.ComponentProps<typeof InverseButton>['color']
    | keyof DesignTokens;
  variant?: ActionButtonVariants;
  className?: any;
  maintainWidth?: boolean;
  css?: any;
  style?: any;
}

export interface IActionLinkProps extends IActionButtonProps {
  to: string;
}

const Container = styled.div`
  position: relative;
`;

const LoadingAnimationWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HiddenText = styled.span`
  visibility: hidden;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Loading: React.FC<ILoadingAnimationProps & { maintainWidth?: boolean }> = ({
  children,
  maintainWidth = true,
  ...props
}) => {
  if (maintainWidth) {
    return (
      <Container>
        <LoadingAnimationWrapper>
          <LoadingAnimation {...props} />
        </LoadingAnimationWrapper>
        <HiddenText aria-hidden>{children}</HiddenText>
      </Container>
    );
  }

  return <LoadingAnimation {...props} />;
};

const ActionButton: React.FC<IActionButtonProps> = forwardRef(
  (
    {
      children,
      disabled,
      eventAttributes = {},
      eventName,
      eventType,
      skipLogEvent = false,
      isLoading = false,
      loadingColor,
      onClick,
      onNonVisualClick,
      to,
      perceptible,
      hapticFeedbackStyle,
      variant,
      color,
      maintainWidth,
      tabIndex = 0,
      ...rest
    },
    ref
  ) => {
    const mParticle = useMParticleContext();
    const isImperceptible = !perceptible && disabled;
    const navigate = useNavigate();
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isLoading) {
        e.preventDefault();
        return;
      }
      if (isImperceptible) {
        e.preventDefault();
        return;
      } else if (perceptible && disabled) {
        e.preventDefault();
        void onNonVisualClick?.(e);
        return;
      }

      if (!skipLogEvent) {
        try {
          mParticle.trackEvent({
            name: eventName || CustomEventNames.BUTTON_CLICK,
            type: eventType || EventTypes.Navigation,
            attributes: {
              Name: eventName || getTextFromChildren(children),
              ...eventAttributes,
            },
          });
        } catch (_) {
          // Nothing to do here
        }
      }

      if (hapticFeedbackStyle) {
        hapticImpact({ style: hapticFeedbackStyle });
      }

      if (onClick) {
        onClick(e);
      }

      if (to) {
        navigate(to);
      }
    };

    const loadingComponent = useMemo(
      () =>
        isLoading ? (
          <Loading fillColor={loadingColor} maintainWidth={maintainWidth}>
            {children}
          </Loading>
        ) : (
          children
        ),
      [children, isLoading, loadingColor, maintainWidth]
    );

    const buttonTabIndex = isLoading || isImperceptible ? -1 : tabIndex;

    switch (variant) {
      case ActionButtonVariants.BOX_SHADOW_PRIMARY:
        return (
          <BoxShadowButton
            {...rest}
            tabIndex={buttonTabIndex}
            aria-disabled={disabled || isLoading}
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
          >
            {loadingComponent}
          </BoxShadowButton>
        );
      case ActionButtonVariants.BOX_SHADOW_INVERSE:
        return (
          <BoxShadowButton
            {...rest}
            inverse
            tabIndex={buttonTabIndex}
            aria-disabled={disabled || isLoading}
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
          >
            {loadingComponent}
          </BoxShadowButton>
        );
      case ActionButtonVariants.TEXT_ONLY:
        return (
          <TextOnlyButton
            {...rest}
            color={color}
            tabIndex={buttonTabIndex}
            aria-disabled={disabled || isLoading}
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
          >
            {loadingComponent}
          </TextOnlyButton>
        );
      case ActionButtonVariants.INVERSE:
        return (
          <InverseButton
            {...rest}
            color={color}
            tabIndex={buttonTabIndex}
            aria-disabled={disabled || isLoading}
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
          >
            {loadingComponent}
          </InverseButton>
        );
      case ActionButtonVariants.OUTLINE:
        return (
          <OutlineButton
            {...rest}
            tabIndex={buttonTabIndex}
            aria-disabled={disabled || isLoading}
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
          >
            {loadingComponent}
          </OutlineButton>
        );
      case ActionButtonVariants.PRIMARY:
      default:
        return (
          <ButtonContainer>
            <Button
              {...rest}
              tabIndex={buttonTabIndex}
              aria-disabled={disabled || isLoading}
              disabled={disabled}
              ref={ref}
              onClick={handleClick}
            >
              {loadingComponent}
            </Button>
          </ButtonContainer>
        );
    }
  }
);

const UnStyledLink = styled(Link)<{ fullWidth: boolean }>`
  width: ${p => (p.fullWidth ? '100%' : 'auto')};
  text-decoration: none;
`;

// TODO: This wraps the button with an anchor tag. We should instead style the link as a button.
export const ActionLink: React.FC<IActionButtonProps & { to: string }> = ({
  children,
  to,
  fullWidth = false,
  onClick,
  'data-testid': dataTestId,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    checkForOSPrompts(to);
    if (onClick) {
      onClick(e);
    }
  };
  if (to.startsWith('/OS/')) {
    return (
      <ActionButton onClick={handleClick} fullWidth={fullWidth} {...props}>
        {children}
      </ActionButton>
    );
  }
  return (
    <UnStyledLink
      to={to}
      fullWidth={fullWidth}
      onClick={onClick}
      {...props}
      data-testid={dataTestId}
    >
      <ActionButton tabIndex={-1} fullWidth={fullWidth} {...props}>
        {children}
      </ActionButton>
    </UnStyledLink>
  );
};

export default ActionButton;

// Return the first non-false string value
export function getTextFromChildren(children?: ReactNode): string {
  if (!children) {
    return '';
  }

  return React.Children.map(children || '', child => {
    if (typeof child === 'string') {
      return child;
    } else if (child && React.isValidElement(child)) {
      return getTextFromChildren((child.props as { children?: ReactNode }).children);
    } else {
      return '';
    }
  }).reduce((name, curr) => name || curr, '');
}
