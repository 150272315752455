import React, { createContext, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useLogger } from 'state/logger';
import { useNetworkContext } from 'state/network';

import { useDeprecatedLoyalty } from './hooks/use-deprecated-loyalty';
import { ILoyaltyCardBarcodeDetails, ILoyaltyCategory, ILoyaltyTier } from './types';

export interface IThLoyaltyContext {
  getBankMyRewardTierId: (tiers: Array<ILoyaltyTier>) => string;
  getLoyaltyCategory: () => Promise<ILoyaltyCategory[]>;
  getLoyaltyTier: () => Promise<ILoyaltyTier>;
  getLoyaltyOnboardingQuickSelectTier: () => Promise<ILoyaltyTier[]>;
  loyaltyBarcodeDetails: ILoyaltyCardBarcodeDetails;
  setLoyaltyBarcodeDetails: (details: ILoyaltyCardBarcodeDetails) => void;
  hasLoyaltyDetailsError?: boolean;
}

// To be deprecated for generic useLoyaltyContext when TH loyalty api is migrated to new loyalty api
export const ThLoyaltyContext = createContext<IThLoyaltyContext>({} as IThLoyaltyContext);
export const useThLoyaltyContext = () => useContext(ThLoyaltyContext);

export const ThLoyaltyProvider = (props: IBaseProps) => {
  const logger = useLogger();
  const { query } = useNetworkContext();

  // useDeprecatedLoyalty is currently solely for TH and will be replaced with brand agnostic useLoyalty hook after api migration is complete
  const {
    getBankMyRewardTierId,
    getLoyaltyCategory,
    getLoyaltyTier,
    getLoyaltyOnboardingQuickSelectTier,
    loyaltyBarcodeDetails,
    setLoyaltyBarcodeDetails,
  } = useDeprecatedLoyalty({
    query,
    logger,
  });

  return (
    <ThLoyaltyContext.Provider
      value={{
        loyaltyBarcodeDetails,
        setLoyaltyBarcodeDetails,
        getBankMyRewardTierId,
        getLoyaltyCategory,
        getLoyaltyTier,
        getLoyaltyOnboardingQuickSelectTier,
      }}
    >
      {props.children}
    </ThLoyaltyContext.Provider>
  );
};
