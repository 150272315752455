import React, { FC, useState } from 'react';

import { useIntl } from 'react-intl';

import { RewardCategory } from 'components/app-header/loyalty/rewards-management/reward-tier-selection-modal/components/reward-category';
import {
  Fieldset,
  HiddenLegend,
} from 'pages/account/tims-rewards/reward-categories/components/styled';
import { ILoyaltyCategory } from 'state/loyalty/types';

import {
  SectionDescription,
  SectionHeader,
  SectionHeaderWrapper,
} from './reward-categories.styled';
import { IRewardCategoriesProps } from './types';

const BANK_MY_REWARDS_CATEGORY_INDEX = -1;
const sectionHeaderId = 'reward-categories-section-header';
const sectionDescriptionId = 'reward-categories-section-description';

export const RewardCategories: FC<IRewardCategoriesProps> = ({
  radioButtonName,
  setCurrentRewardTierId,
  selectedRewardCategoryId,
  selectedRewardTierId,
  loyaltyCategories,
}) => {
  const { formatMessage } = useIntl();

  const [openCategoryAccordionIndex, setOpenCategoryAccordionIndex] = useState(-1);

  return (
    <>
      <SectionHeaderWrapper>
        <SectionHeader id={sectionHeaderId}>
          {formatMessage({ id: 'chooseYourRewardLevel' })}
        </SectionHeader>
        <SectionDescription id={sectionDescriptionId}>
          {formatMessage({ id: 'selectBestRewardLevel' })}
        </SectionDescription>
      </SectionHeaderWrapper>

      <Fieldset>
        <HiddenLegend aria-labelledby={sectionHeaderId} aria-describedby={sectionDescriptionId} />
        {loyaltyCategories.map((cat: ILoyaltyCategory, index: number) => (
          <RewardCategory
            key={index}
            category={cat}
            onChange={(loyaltyTierId: string | null) =>
              setCurrentRewardTierId({
                updatedRewardCategoryId: cat._id,
                updatedRewardTierId: loyaltyTierId || '',
              })
            }
            optionsVisible={index === openCategoryAccordionIndex}
            radioButtonName={radioButtonName}
            selectedRewardTierId={selectedRewardTierId}
            selectedCategory={cat._id === selectedRewardCategoryId}
            toggleOptionsVisible={() =>
              index === openCategoryAccordionIndex
                ? setOpenCategoryAccordionIndex(BANK_MY_REWARDS_CATEGORY_INDEX)
                : setOpenCategoryAccordionIndex(index)
            }
          />
        ))}
      </Fieldset>
    </>
  );
};
