import { CurrencyCode } from '@rbilabs/intl';

import {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
  DEFAULT_PAYMENT_CURRENCY_NUMBER,
} from './default-codes/default-payment-codes';
import { BILLING_COUNTRIES, ISOs, PaymentISOs } from './generated/constants';

export * from './generated/constants';

export {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
} from './default-codes/default-payment-codes';

export const getCountryAndCurrencyCodes = (
  billingCountry: ISOs
): { countryCode: PaymentISOs; currencyCode: CurrencyCode; currencyNumber: number } => {
  const defaultCodes = {
    countryCode: DEFAULT_PAYMENT_COUNTRY,
    currencyCode: DEFAULT_PAYMENT_CURRENCY,
    currencyNumber: DEFAULT_PAYMENT_CURRENCY_NUMBER,
  };
  return BILLING_COUNTRIES[billingCountry] || defaultCodes;
};

export const defaultLocaleKeys = {
  postalCode: 'postalCode',
  region: 'province',
};

export const postalCodeStringLocaleKey = {
  [ISOs.USA]: 'zipCode',
};

export const regionStringLocaleKey = {
  [ISOs.USA]: 'state',
  [ISOs.CHE]: 'canton',
};

export const defaultInputProperties = {
  postalCode: { maxLength: '5', pattern: '[0-9]*' },
  region: { maxLength: '2' },
};

export const postalCodeInputProperties = {
  [ISOs.CAN]: { maxLength: '7' },
  [ISOs.GBR]: { maxLength: '10' },
  [ISOs.CHE]: { maxLength: '4', pattern: '[0-9]*' },
};

export const regionInputProperties = {};
