import { IsoCountryCode2 } from '@rbilabs/intl';
import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import { isValid } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import { merge } from 'lodash';
import { IntlShape } from 'react-intl';
import { superstruct } from 'superstruct';

import { SupportedRegions } from '@rbi-ctg/frontend';
import { ParseError } from 'utils/libphonenumber';

// eslint-disable-next-line import/no-cycle
import { isLengthWithDefaultValue, maxLengthWithDefaultValue, minLengthWithDefaultValue } from '..';

import { ISOs, ISOsToRegions } from './constants';
import { IDateOfBirth } from './types';

export enum FormValidationState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PRISTINE = 'PRISTINE',
}

export enum PhoneNumberValidationError {
  EMPTY = 'EMPTY',
  UNKNOWN = 'UNKNOWN',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  NOT_A_NUMBER = 'NOT_A_NUMBER',
  TOO_LONG = 'TOO_LONG',
  TOO_SHORT = 'TOO_SHORT',
}

// To get message keys based on field names
export enum MapInputNameWithTranslationKey {
  agreeToTermsOfService = 'agreesToTermsOfService',
  emailAddress = 'email',
  creditCardNumber = 'cardNumber',
  cardExpiration = 'expiry',
  billingStreetAddress = 'streetAddress',
  billingApt = 'apartment',
  billingCity = 'city',
  billingState = 'state',
  billingZip = 'zipCode',
}

// eslint-disable-next-line
export const VALID_NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõőøùúûüųūűÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕŐØÙÚÛÜŲŪŰŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;

// eslint-disable-next-line
export const EMAIL_REGEX =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/i;

/**
 * Validate that a string is valid.
 *
 * @param {string} name
 * The string to test.
 *
 * @returns {boolean}
 * True if the string provided is a valid string.
 */
export const isNameInputValid = (name: string): boolean => {
  if (!name) {
    return false;
  }
  const isValidName = VALID_NAME_REGEX.test(name);
  if (!isValidName) {
    return false;
  }
  return name.includes('.') ? name.includes(' ') : true;
};

/**
 * Validate that a string is an email address.
 *
 * The same function exists on the backend.
 * Changes here should be duplicated in common/src/utils/index.ts
 *
 * @param {string} email
 * The string to test.
 *
 * @returns {boolean}
 * True if the string provided is a valid email address.
 */
export const isEmailValid = (email = '') => {
  return EMAIL_REGEX.test(email) && email[0] !== '.' && !email.includes('..');
};

/**
 * Validate that a string is a phone number.
 *
 *
 * @param {string} phoneNumber
 * The string to test.
 *
 * @returns {Object} validation
 * @returns {boolean} validation.valid True if the string provided is a valid phone number
 * @returns {PhoneNumberValidationError} validation.error Error description if the string provided is not a valid phone number
 *
 */

export const isPhoneNumberValid = async ({
  phoneNumber,
  country = IsoCountryCode2.US,
  simpleValidation,
}: {
  phoneNumber: string;
  country?: SupportedRegions;
  simpleValidation?: boolean;
}): Promise<{ valid: boolean; error?: PhoneNumberValidationError }> => {
  try {
    if (!phoneNumber.trim()) {
      return {
        valid: false,
        error: PhoneNumberValidationError.EMPTY,
      };
    }
    if (simpleValidation) {
      // This is the regex currently used on ctg-user-service
      // We want to ensure it can be updated on the backend
      const userServiceValidation = new RegExp(/^\+?[0-9]{5,15}$/);
      return { valid: userServiceValidation.test(phoneNumber) };
    } else {
      const parsedPhoneNumber = await parsePhoneNumber(
        phoneNumber,
        country as Exclude<IsoCountryCode2Char, 'AQ'>
      );
      return { valid: parsedPhoneNumber.isValid() };
    }
  } catch (error) {
    if (error instanceof ParseError) {
      return {
        valid: false,
        error: error.message as PhoneNumberValidationError,
      };
    } else {
      return {
        valid: false,
        error: PhoneNumberValidationError.UNKNOWN,
      };
    }
  }
};

export const getPhoneNumberErrorMessage = (
  error: PhoneNumberValidationError | undefined,
  formatMessage: IntlShape['formatMessage']
): string => {
  switch (error) {
    case PhoneNumberValidationError.EMPTY:
      return formatMessage({ id: 'phoneNumberRequiredError' });
    case PhoneNumberValidationError.TOO_LONG:
      return formatMessage({ id: 'phoneNumberTooLongError' });
    case PhoneNumberValidationError.TOO_SHORT:
      return formatMessage({ id: 'phoneNumberTooShortError' });
    default:
      return formatMessage({ id: 'notValidPhoneNumberError' });
  }
};

/**
 * Validate that a string is an invitation code.
 *
 * The same function exists on the backend.
 * Changes here should be duplicated in common/src/utils/index.ts
 *
 * @param {string} invitationCode
 * The string to test.
 *
 * @returns {boolean}
 * True if the string provided is a valid invitationCode.
 */
export const isInvitationCodeValid = (invitationCode = '') => {
  if (invitationCode === '') {
    return true;
  }
  return invitationCode.toLocaleLowerCase().match(/^[0-9a-z]+$/) && invitationCode.length === 10;
};

export const IPV4_REGEX =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const requiredString = (s = '') => s.length > 0;

export const requiredBoolean = (b = false) => b === true;

export const requiredValidDateObject = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}) => {
  if (!day || !month || !year) {
    return false;
  }
  const date = new Date(formatDateObject({ day, month, year }));
  return isValid(date);
};

export const structWithRequiredString = (opts: superstruct.Config = {}) =>
  superstruct(
    merge(
      {
        types: {
          requiredString,
          requiredBoolean,
        },
      },
      opts
    )
  );

export const sanitizeNumber = (number = '') => number.replace(/[^\d]/g, '');

export const sanitizePhoneNumber = async (rawNumber = '', country: ISOs | string = ISOs.US) => {
  const region = ISOsToRegions[country];
  try {
    const { number, nationalNumber, countryCallingCode } = await parsePhoneNumber(
      rawNumber,
      region
    );
    // return either the valid number (which already includes area code) or add it based on passed in region
    return `${number}` || `+${countryCallingCode}${nationalNumber}`;
  } catch {
    // Replace all non-numbers and non-pluses with empty string and
    // replaces pluses only if they are not at the start of the string
    return rawNumber.replace(/[^\d+]/g, '').replace(/(?!^)\+/g, '');
  }
};

export const sanitizeAlphanumeric = (v = '') => v.replace(/[^A-Za-z0-9]/g, '');

/*
 * This value was previously set to users DOB as a side effect of this PR:
 * - https://github.com/rbilabs/EngineeringMonoRepo/pull/8426
 *
 * It is added to the possible list of values that will allow
 * users to edit their DOB in account info
 */
const LEGACY_DOB_OBJECT_BLANK_FORMATTED_DOB = '0000-00-00';

// List all current and previous default/empty dob values
// NOTE: We send the `SignUp` mutation an empty string "" when
// users leave DOB empty, but the `GetMe` query returns `null`
export const POSSIBLE_DEFAULT_OR_EMPTY_DOB_VALUES = [
  undefined,
  null,
  '',
  LEGACY_DOB_OBJECT_BLANK_FORMATTED_DOB,
];

export const formatDateObject = (date = { month: '', day: '', year: '' }): string | '' => {
  const { month, day, year } = date;

  if (!month || !day || !year) {
    return '';
  }

  const yyyy = year.padStart(4, '0');
  const dd = day.padStart(2, '0');
  const mm = month.padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

export const dobStringToObj = (dateString: string = ''): IDateOfBirth => {
  const dobObj = { month: '', day: '', year: '' };
  if (dateString) {
    const split = dateString.split('-');
    dobObj.year = split[0];
    dobObj.month = split[1];
    dobObj.day = split[2];
  }
  return dobObj;
};

export const isLength = (len: number) => isLengthWithDefaultValue(len, '');

export const minLength = (len: number) => minLengthWithDefaultValue(len, '');
export const maxLength = (len: number) => maxLengthWithDefaultValue(len, '');

/**
 * Validates form data against struct and returns an object
 * of error messages mapped to the field name
 *
 * @param Struct The Struct to validate against.
 * @param extraMessages Messages used to swap out Struct errors.
 * @param formState The form data. Should match shape of Struct.
 */
export const getFormErrorsForStruct =
  <FormDataType extends object, extraMessagesType extends object>(
    Struct: superstruct.Struct,
    extraMessages: extraMessagesType,
    formatMessage?: IntlShape['formatMessage']
  ) =>
  (
    formState: FormDataType
  ): { [key in keyof FormDataType]?: (typeof extraMessages)[keyof extraMessagesType] } => {
    const [structErrors] = Struct.validate(formState);
    if (structErrors) {
      return structErrors.errors.reduce(
        (errors, { path: [fieldName], type }) => ({
          ...errors,
          [fieldName]: formatMessage
            ? formatMessage({ id: extraMessages[type] })
            : extraMessages[type],
        }),
        {}
      );
    }

    return {};
  };
