import useRoutes from 'hooks/use-routes';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

export const useOrderHref = ({ forceStoreChange }: { forceStoreChange?: boolean } = {}) => {
  const { isStoreOpenAndAvailable } = useStoreContext();
  const { getLocalizedRouteForPath } = useRoutes();
  const route = getLocalizedRouteForPath('store-locator');
  const enableStaticMenu = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU);

  if (forceStoreChange) {
    return route;
  }

  const orderHref =
    isStoreOpenAndAvailable || enableStaticMenu
      ? routes.menu
      : getLocalizedRouteForPath('store-locator');

  return orderHref;
};
