import React, { useEffect, useMemo, useState } from 'react';

import { Icon } from '@rbilabs/components-library';

import { ISanityBlockContent } from '@rbi-ctg/menu';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { ILocaleBlockTextContent, IStaticPageRoute, IWidget } from 'remote/queries/static-page';

import { ContentDescription, ContentHeader, TitleModal } from '../styled';

import { ButtonBack, Container, HeaderContainer, ModalContainerDetail } from './styled';

interface ImodalPolicies {
  termsObject: IStaticPageRoute;
  onBack: () => void;
}

const ModalAgreementDetail = ({ termsObject, onBack }: ImodalPolicies) => {
  const [sanityContent, setSanityContent] = useState([] as ISanityBlockContent[]);

  const [sanityContentHtml, setSanityContentHtml] = useState('');

  const hasExternalContent = useMemo(() => !!sanityContentHtml, [sanityContentHtml]);

  useEffect(() => {
    if (termsObject.pageHtml?.code) {
      setSanityContentHtml(termsObject.pageHtml?.code);
    } else {
      const widgets = termsObject.widgets || [];

      const localeBlockTextContent = widgets
        .map((w: IWidget): ILocaleBlockTextContent => {
          if (w.localeBlockTextContent?.localeRaw?.length) {
            return w.localeBlockTextContent;
          }
          return {} as ILocaleBlockTextContent;
        })
        .find((lbtc: ILocaleBlockTextContent) => lbtc.localeRaw);

      const content = localeBlockTextContent?.localeRaw as ISanityBlockContent[];

      setSanityContent(content);
    }
  }, [termsObject]);

  return (
    <ModalContainerDetail hasExternalContent={hasExternalContent}>
      <ButtonBack data-testid="details-back-button" onClick={onBack}>
        <Icon icon="back" color="black" aria-hidden width="20px"></Icon>
      </ButtonBack>

      <Container>
        <HeaderContainer hasExternalContent={hasExternalContent}>
          {!hasExternalContent && (
            <ContentHeader>
              <TitleModal>{termsObject.localeTitle?.locale || termsObject.title}</TitleModal>
            </ContentHeader>
          )}
        </HeaderContainer>
        <ContentDescription data-testid="details-content-description">
          {sanityContentHtml ? (
            <div dangerouslySetInnerHTML={{ __html: sanityContentHtml }} />
          ) : (
            sanityContent?.length && <SanityBlockRenderer withMargins content={sanityContent} />
          )}
        </ContentDescription>
      </Container>
    </ModalContainerDetail>
  );
};

export default ModalAgreementDetail;
