import { Locale } from '@rbilabs/intl';
import { IntlShape } from 'react-intl';

import { IAdditionalItemInformation, IAllergens, IItem, INutritionInfo } from '@rbi-ctg/menu';
import { AllergenIncludes } from 'enums/menu';
import { AllergenIdentifier } from 'remote/queries/fragments/nutrition-explorer-widget';
import { TLocalizationKey } from 'types/i18n';

import { AllergenGroup } from './types';

export const getAllergenNamesForInclude = (
  allergens: IAllergens,
  allergensInclude: AllergenIncludes,
  sourcesOfGluten: string | undefined,
  formatMessage: IntlShape['formatMessage']
): string[] =>
  (Object.keys(allergens) as Array<keyof typeof allergens>)
    .filter(allergenKey => allergens[allergenKey] === allergensInclude)
    .map(key => {
      const formattedAllergenText = formatMessage({ id: key });

      if (key === AllergenIdentifier.Gluten && sourcesOfGluten) {
        return `${formattedAllergenText} (${sourcesOfGluten})`;
      }

      return formattedAllergenText;
    });

export const getAllergenGroupForInclude = (
  allergens: IAllergens,
  allergenIncludes: AllergenIncludes,
  sourcesOfGluten: string | undefined,
  formatMessage: IntlShape['formatMessage']
): AllergenGroup => ({
  allergenIncludes,
  allergenNames: getAllergenNamesForInclude(
    allergens,
    allergenIncludes,
    sourcesOfGluten,
    formatMessage
  ),
});

export const getAllergenGroups = (
  allergens: IAllergens,
  formatMessage: IntlShape['formatMessage'],
  additionalItemInformation?: IAdditionalItemInformation
): AllergenGroup[] => {
  const groups: AllergenGroup[] = [];
  const sourcesOfGluten = additionalItemInformation?.sourcesOfGluten;

  const contains = getAllergenGroupForInclude(
    allergens,
    AllergenIncludes.Contains,
    sourcesOfGluten,
    formatMessage
  );
  if (contains.allergenNames.length > 0) {
    groups.push(contains);
  }

  const mayContain = getAllergenGroupForInclude(
    allergens,
    AllergenIncludes.MayContain,
    sourcesOfGluten,
    formatMessage
  );
  if (mayContain.allergenNames.length > 0) {
    groups.push(mayContain);
  }

  const sameEquipment = getAllergenGroupForInclude(
    allergens,
    AllergenIncludes.CookedInSameEquipment,
    sourcesOfGluten,
    formatMessage
  );
  if (sameEquipment.allergenNames.length > 0) {
    groups.push(sameEquipment);
  }

  return groups;
};

export const allergenIncludesToIntlId = (
  allergenIncludes: AllergenIncludes
): TLocalizationKey | null => {
  switch (allergenIncludes) {
    case AllergenIncludes.Contains:
      return 'containsAllergens';
    case AllergenIncludes.MayContain:
      return 'mayContainAllergens';
    case AllergenIncludes.CookedInSameEquipment:
      return 'mayContainAllergensViaSharedEquipment';
    default:
      return null;
  }
};

export const roundToOneDecimalIfNecessaryLocale = (
  value: number,
  locale: Locale | string = Locale.en_us
): string => {
  return value
    ? value.toLocaleString(locale, {
        style: 'decimal',
        maximumFractionDigits: 1,
      })
    : `${~~value}`;
};

export const roundToOneDecimalIfNecessary = (value: number | string): number =>
  Math.round((+value + Number.EPSILON) * 10) / 10;

export const emptyNutritionFacts: INutritionInfo = {
  calories: 0,
  energyKJ: 0,
  fat: 0,
  saturatedFat: 0,
  transFat: 0,
  cholesterol: 0,
  sodium: 0,
  salt: 0,
  carbohydrates: 0,
  fiber: 0,
  sugar: 0,
  proteins: 0,
  weight: 0,
  caloriesPer100: 0,
  energyKJPer100: 0,
  carbohydratesPer100: 0,
  sugarPer100: 0,
  fatPer100: 0,
  saturatedFatPer100: 0,
  proteinsPer100: 0,
  saltPer100: 0,
};

const nutritionKeys = Object.keys(emptyNutritionFacts);

/**
 * Sum the nutrition info of an items default modifier options with it's own nutrition.
 *
 * @param item {IItem}
 * The item to calcuate nutrition info for
 *
 * @returns INutritionInfo
 */
export function sumModifierNutrition(
  item: IItem,
  displayNutritionWithModifiersFromSanity?: boolean
): INutritionInfo {
  if (displayNutritionWithModifiersFromSanity) {
    const map = { ...emptyNutritionFacts };
    nutritionKeys.forEach(
      nutritionKey => (map[nutritionKey] += item?.nutritionWithModifiers?.[nutritionKey] ?? 0)
    );
    return map;
  }
  return (item.options || []).reduce(
    (map, next) => {
      const defaultOpt = next.options.find(o => o.default);
      nutritionKeys.forEach(
        nutritionKey => (map[nutritionKey] += defaultOpt?.nutrition?.[nutritionKey] ?? 0)
      );
      return map;
    },
    { ...item.nutrition }
  );
}
