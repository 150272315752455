import { useCallback } from 'react';

import { cloneDeep } from 'lodash';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';

import {
  GenericsImages,
  GenericsImagesAsset,
  GenericsItemsObject,
  IImagesByChannelResult,
} from './types';

/**
 *  This function changes the Image asset to the selected delivery, kiosk or restaurant image.
 *  In Sanity, we can set an Item, Combo or Picker image when Delivery, Kiosk or Restaurant is selected
 *  Here we will check which ServiceMode is working and replace image default with configured one
 *
 *  https://rbictg.atlassian.net/wiki/spaces/IBC/pages/4242047141/Tech+refinement+-+Images+per+channel
 */

export const useImagesByChannels = (): IImagesByChannelResult => {
  const { serviceMode } = useServiceModeContext();
  const enableImagesByChannels = useFlag(LaunchDarklyFlag.ENABLE_IMAGES_BY_CHANNELS);

  const getImagesByChannel = useCallback(
    (images?: GenericsImages | null): GenericsImagesAsset | null => {
      if (!serviceMode || !images) {
        return null;
      }

      return serviceMode === ServiceMode.DELIVERY
        ? images.imageDelivery?.asset ?? null
        : images.imageRestaurant?.asset ?? images?.default?.asset ?? null;
    },
    [serviceMode]
  );

  const processSingleMenu = useCallback(
    <T extends GenericsItemsObject>(menuOptions: T) => {
      const newMenuOptions = cloneDeep(menuOptions);

      const imagesByChannels = newMenuOptions?.imagesByChannels;

      if (imagesByChannels && !imagesByChannels.default) {
        const updatedImagesByChannel = { ...imagesByChannels, default: { ...menuOptions?.image } };
        newMenuOptions.imagesByChannels = updatedImagesByChannel;
      }

      const assetImage = getImagesByChannel(newMenuOptions?.imagesByChannels);
      if (assetImage && newMenuOptions.image?.asset) {
        const updatedAssetImage = { asset: assetImage };
        newMenuOptions.image = updatedAssetImage;
      }

      if (newMenuOptions.options) {
        const menuOptionsMap = newMenuOptions.options.map(processSingleMenu);
        newMenuOptions.options = menuOptionsMap;
      }

      if (newMenuOptions.option) {
        newMenuOptions.option = processSingleMenu(newMenuOptions.option);
      }

      if (newMenuOptions.mainItem) {
        newMenuOptions.mainItem = processSingleMenu(newMenuOptions.mainItem);
      }

      return newMenuOptions;
    },
    [getImagesByChannel]
  );

  const processMultiMenu = useCallback(
    <T extends GenericsItemsObject[]>(data: T): T => data.map(processSingleMenu) as T,
    [processSingleMenu]
  );

  const changeImageByChannel = useCallback(
    <T>(data: T): T => {
      if (!enableImagesByChannels || !data) {
        return data;
      }

      if (Array.isArray(data)) {
        return processMultiMenu(data);
      }
      return processSingleMenu(data);
    },
    [enableImagesByChannels, processSingleMenu, processMultiMenu]
  );

  return { changeImageByChannel };
};
