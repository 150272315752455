import { IBrandRoutes } from './types';
import { LazyRoute, lazyWithFallback } from './util';

const LoveThatGame: LazyRoute = lazyWithFallback(
  () => import('pages/vendor-redirect/lovethatgame')
);
const RoadTrip: LazyRoute = lazyWithFallback(() => import('pages/vendor-redirect/roadtrip'));
const Catering: LazyRoute = lazyWithFallback(() => import('components/catering'));
const WllLoyaltyVendor: LazyRoute = lazyWithFallback(() => import('pages/wll-loyalty-vendor'));

export const brandRoutes: IBrandRoutes = {
  'routes.catering': Catering,
  'routes.lovethatgame': LoveThatGame,
  'routes.roadtrip': RoadTrip,
  'routes.wllLoyaltyVendor': WllLoyaltyVendor,
};

export const fullPageBrandRoutes: IBrandRoutes = {};

export default brandRoutes;
