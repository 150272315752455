import noop from 'utils/noop';

import { APP_STATE_CHANGE, AppflowStorageKeys, IUseAppflow, IUseAppflowProps } from './types';

const defaultAppflowState: IUseAppflow = {
  error: null,
  extractingUpdate: false,
  needsBlockingUpdate: false,
  progress: 0,
  permissionToUpdate: false,
  updateConnection: noop,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useAppflow = (props: IUseAppflowProps) => defaultAppflowState;

export default useAppflow;
export { APP_STATE_CHANGE, AppflowStorageKeys };
