import { useCallback } from 'react';

import { useLocale } from 'state/intl';
import { usePageManagerContext } from 'state/static-page-manager';
import { getInCodeLocalizedRouteForPath, getStaticPagesLocalizedRouteForPath } from 'utils/routing';

export default function useRoutes() {
  const { language, region } = useLocale();
  const { routes: staticRoutes, loadRoutes } = usePageManagerContext();

  const getLocalizedRouteForPath = useCallback(
    (path: string): string => {
      const relativePath = path[0] === '/' ? path : `/${path}`;

      const localizedInCodePath = getInCodeLocalizedRouteForPath(relativePath, language, region);
      if (localizedInCodePath) {
        return localizedInCodePath;
      }

      let localizedRelativePath = relativePath;

      const localizedStaticPagesPath = getStaticPagesLocalizedRouteForPath(
        path,
        staticRoutes,
        language
      );

      return localizedStaticPagesPath || localizedRelativePath || '';
    },
    [language, region, staticRoutes]
  );

  const doesPageExist = useCallback(
    (path: string): boolean => {
      loadRoutes();
      return !!staticRoutes.find(
        route =>
          route.path.current === path ||
          route.localePath?.[language]?.current === getLocalizedRouteForPath(path)
      );
    },
    [getLocalizedRouteForPath, language, loadRoutes, staticRoutes]
  );

  return {
    getLocalizedRouteForPath,
    doesPageExist,
  };
}
