/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { TLocalizationKey } from 'types/i18n';
import { ISOs } from 'utils/form/constants';

export const messageIdMap = {
  [ISOs.ATA]: 'antarctica',
  [ISOs.AUT]: 'austria',
  [ISOs.CAN]: 'canada',
  [ISOs.CHE]: 'switzerland',
  [ISOs.CZE]: 'czechRepublic',
  [ISOs.DEU]: 'germany',
  [ISOs.ESP]: 'spain',
  [ISOs.FRA]: 'france',
  [ISOs.GBR]: 'unitedKingdom',
  [ISOs.KOR]: 'southKorea',
  [ISOs.NLD]: 'netherlands',
  [ISOs.NZL]: 'newZealand',
  [ISOs.POL]: 'poland',
  [ISOs.PRT]: 'portugal',
  [ISOs.ROU]: 'romania',
  [ISOs.SAU]: 'saudiArabia',
  [ISOs.SGP]: 'singapore',
  [ISOs.TWN]: 'taiwan',
  [ISOs.USA]: 'unitedStates',
  [ISOs.ZAF]: 'southAfrica',
} as const satisfies Record<ISOs, TLocalizationKey>;

export const countriesList: ISOs[] = [
  ISOs.ATA,
  ISOs.AUT,
  ISOs.CAN,
  ISOs.CHE,
  ISOs.CZE,
  ISOs.DEU,
  ISOs.ESP,
  ISOs.FRA,
  ISOs.GBR,
  ISOs.KOR,
  ISOs.NLD,
  ISOs.NZL,
  ISOs.POL,
  ISOs.PRT,
  ISOs.ROU,
  ISOs.SAU,
  ISOs.SGP,
  ISOs.TWN,
  ISOs.USA,
  ISOs.ZAF,
];
