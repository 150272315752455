import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import { CognitoUser, CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { isNil, omitBy } from 'lodash';
import uuidv4 from 'uuid/v4';

import { Cognito } from 'utils/cognito';
import { RBIBrand, brand, getCountry } from 'utils/environment';

import { CapsuleChannels, CapsuleEvents, CustomCognitoAttribute } from '../../types/aws-amplify';

import normalizeString from './util';

interface ISignUpTermAcceptance {
  name: string;
  accepted: boolean;
}

interface IRequiredAcceptanceAgreementInfoInput {
  id: string;
  updatedAt: string;
}

interface ISignUp {
  username: string;
  name: string;
  phoneNumber: string;
  wantsPromotionalEmails: boolean;
  country: string;
  dob?: string;
  invitationCode?: string;
  // Korea specific fields
  gender?: string;
  ageFourteen?: boolean;
  additionalSignUpTerms?: ISignUpTermAcceptance[];
  requiredAcceptanceAgreementInfo?: IRequiredAcceptanceAgreementInfoInput[];
}

interface IValidateLogin {
  username: string;
  code: string;
  sessionId: string;
}

interface ISignUpAttributes {
  nickname: string;
  name: string;
  locale: string;
  phone_number?: string;
  birthdate?: string;
  gender?: string;
  [CustomCognitoAttribute.ReferralCode]?: string;
  // Korea specific fields
  ageFourteen?: boolean;
  additionalSignUpTerms?: ISignUpTermAcceptance[];
}

interface IUpdateUserAttributes {
  name?: string;
  phoneNumber?: string;
  dob?: string;
}

////////////////////////////////////////////////////////////
// Sign In Moved to GQL queries
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
// Sign Out
////////////////////////////////////////////////////////////

export const signOut = async (shouldSignOutGlobally: boolean = false): Promise<void> => {
  await Auth.signOut({ global: shouldSignOutGlobally });

  // dispatch an event so those listening to the sign out can trigger their callbacks
  Hub.dispatch(CapsuleChannels.AUTH, {
    event: CapsuleEvents.SIGN_OUT,
  });
};

////////////////////////////////////////////////////////////
// Sign Up
////////////////////////////////////////////////////////////

export const signUp = ({
  username,
  wantsPromotionalEmails,
  name,
  phoneNumber,
  country,
  dob,
  gender,
  ageFourteen,
  additionalSignUpTerms,
  requiredAcceptanceAgreementInfo,
  invitationCode,
}: ISignUp): Promise<ISignUpResult> => {
  // TODO: customAttributes must be predefined using nickname as a hack
  const normalizedUsername = normalizeString(username);
  const password = uuidv4();
  const emailConsent = wantsPromotionalEmails ? 'true' : 'false';
  const attributes: ISignUpAttributes = {
    nickname: emailConsent,
    name,
    locale: country,
  };

  if (phoneNumber) {
    attributes.phone_number = phoneNumber;
  }

  if (dob) {
    attributes.birthdate = dob;
  }

  if (gender) {
    attributes.gender = gender;
  }

  if (invitationCode) {
    attributes[CustomCognitoAttribute.ReferralCode] = invitationCode;
  }

  if (brand() === RBIBrand.PLK && getCountry()?.toUpperCase() === IsoCountryCode2Char.KR) {
    attributes.ageFourteen = ageFourteen;
    attributes.additionalSignUpTerms = additionalSignUpTerms?.map(term => {
      return { name: term.name, accepted: term.accepted };
    });
  }

  return Auth.signUp({
    username: normalizedUsername,
    password,
    attributes,
    clientMetadata: {
      requiredAcceptanceAgreementInfo: JSON.stringify(requiredAcceptanceAgreementInfo),
    },
  });
};

////////////////////////////////////////////////////////////
// Confirm Login
////////////////////////////////////////////////////////////

export const validateLogin = ({
  username,
  code,
  sessionId,
}: IValidateLogin): Promise<CognitoUserSession | null> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserForUsername(username);
    // @ts-ignore
    cognitoUser.Session = sessionId;
    return Auth.sendCustomChallengeAnswer(cognitoUser, code)
      .then((_cognitoUser: CognitoUser) => {
        const signInUserSession = _cognitoUser.getSignInUserSession();
        resolve(signInUserSession);
      })
      .catch(err => {
        reject(err);
      });
  });
};

////////////////////////////////////////////////////////////
// Update User Attributes
////////////////////////////////////////////////////////////

export const updateUserAttributes = async ({ name, phoneNumber, dob }: IUpdateUserAttributes) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(
    user,
    omitBy(
      {
        name,
        phone_number: phoneNumber,
        birthdate: dob,
      },
      isNil
    )
  );
};

////////////////////////////////////////////////////////////
// Helper
////////////////////////////////////////////////////////////

const getCognitoUserForUsername = (username: string): CognitoUser => {
  const normalizedUsername = normalizeString(username);
  return new CognitoUser({
    Username: normalizedUsername,
    Pool: Cognito.userPool(),
    Storage: Cognito.storage,
  });
};
