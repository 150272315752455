/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { CurrencyCode } from '@rbilabs/intl';

export enum ISOs {
  ATA = 'ATA',
  AUT = 'AUT',
  CAN = 'CAN',
  CHE = 'CHE',
  CZE = 'CZE',
  DEU = 'DEU',
  ESP = 'ESP',
  FRA = 'FRA',
  GBR = 'GBR',
  KOR = 'KOR',
  NLD = 'NLD',
  NZL = 'NZL',
  POL = 'POL',
  PRT = 'PRT',
  ROU = 'ROU',
  SAU = 'SAU',
  SGP = 'SGP',
  TWN = 'TWN',
  USA = 'USA',
  ZAF = 'ZAF',
  AQ = 'ATA',
  AT = 'AUT',
  CA = 'CAN',
  CH = 'CHE',
  CZ = 'CZE',
  DE = 'DEU',
  ES = 'ESP',
  FR = 'FRA',
  GB = 'GBR',
  KR = 'KOR',
  NL = 'NLD',
  NZ = 'NZL',
  PL = 'POL',
  PT = 'PRT',
  RO = 'ROU',
  SA = 'SAU',
  SG = 'SGP',
  TW = 'TWN',
  US = 'USA',
  ZA = 'ZAF',
}

export enum RegionToISOs {
  AQ = ISOs.ATA,
  AT = ISOs.AUT,
  CA = ISOs.CAN,
  CH = ISOs.CHE,
  CZ = ISOs.CZE,
  DE = ISOs.DEU,
  ES = ISOs.ESP,
  FR = ISOs.FRA,
  GB = ISOs.GBR,
  KR = ISOs.KOR,
  NL = ISOs.NLD,
  NZ = ISOs.NZL,
  PL = ISOs.POL,
  PT = ISOs.PRT,
  RO = ISOs.ROU,
  SA = ISOs.SAU,
  SG = ISOs.SGP,
  TW = ISOs.TWN,
  US = ISOs.USA,
  ZA = ISOs.ZAF,
}

export enum ISOsToRegions {
  ATA = 'AQ',
  AUT = 'AT',
  CAN = 'CA',
  CHE = 'CH',
  CZE = 'CZ',
  DEU = 'DE',
  ESP = 'ES',
  FRA = 'FR',
  GBR = 'GB',
  KOR = 'KR',
  NLD = 'NL',
  NZL = 'NZ',
  POL = 'PL',
  PRT = 'PT',
  ROU = 'RO',
  SAU = 'SA',
  SGP = 'SG',
  TWN = 'TW',
  USA = 'US',
  ZAF = 'ZA',
}

export enum PaymentISOs {
  AQ = 'AQ',
  AT = 'AT',
  CA = 'CA',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  KR = 'KR',
  NL = 'NL',
  NZ = 'NZ',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SA = 'SA',
  SG = 'SG',
  TW = 'TW',
  US = 'US',
  ZA = 'ZA',
}

export enum Currencies {
  CAD = 'CAD',
  CHF = 'CHF',
  CZK = 'CZK',
  EUR = 'EUR',
  GBP = 'GBP',
  KRW = 'KRW',
  NZD = 'NZD',
  PLN = 'PLN',
  RON = 'RON',
  SGD = 'SGD',
  TWD = 'TWD',
  USD = 'USD',
  ZAR = 'ZAR',
}

export const BILLING_COUNTRIES = {
  [ISOs.ATA]: { countryCode: PaymentISOs.AQ, currencyCode: CurrencyCode.USD, currencyNumber: 840 },
  [ISOs.AUT]: { countryCode: PaymentISOs.AT, currencyCode: CurrencyCode.EUR, currencyNumber: 978 },
  [ISOs.CAN]: { countryCode: PaymentISOs.CA, currencyCode: CurrencyCode.CAD, currencyNumber: 124 },
  [ISOs.CHE]: { countryCode: PaymentISOs.CH, currencyCode: CurrencyCode.CHF, currencyNumber: 756 },
  [ISOs.CZE]: { countryCode: PaymentISOs.CZ, currencyCode: CurrencyCode.CZK, currencyNumber: 203 },
  [ISOs.DEU]: { countryCode: PaymentISOs.DE, currencyCode: CurrencyCode.EUR, currencyNumber: 978 },
  [ISOs.ESP]: { countryCode: PaymentISOs.ES, currencyCode: CurrencyCode.EUR, currencyNumber: 978 },
  [ISOs.FRA]: { countryCode: PaymentISOs.FR, currencyCode: CurrencyCode.EUR, currencyNumber: 978 },
  [ISOs.KOR]: { countryCode: PaymentISOs.KR, currencyCode: CurrencyCode.KRW, currencyNumber: 410 },
  [ISOs.GBR]: { countryCode: PaymentISOs.GB, currencyCode: CurrencyCode.GBP, currencyNumber: 826 },
  [ISOs.NLD]: { countryCode: PaymentISOs.NL, currencyCode: CurrencyCode.EUR, currencyNumber: 978 },
  [ISOs.NZL]: { countryCode: PaymentISOs.NZ, currencyCode: CurrencyCode.NZD, currencyNumber: 554 },
  [ISOs.POL]: { countryCode: PaymentISOs.PL, currencyCode: CurrencyCode.PLN, currencyNumber: 985 },
  [ISOs.PRT]: { countryCode: PaymentISOs.PT, currencyCode: CurrencyCode.EUR, currencyNumber: 978 },
  [ISOs.ROU]: { countryCode: PaymentISOs.RO, currencyCode: CurrencyCode.RON, currencyNumber: 946 },
  [ISOs.SAU]: { countryCode: PaymentISOs.SA, currencyCode: CurrencyCode.SAR, currencyNumber: 682 },
  [ISOs.SGP]: { countryCode: PaymentISOs.SG, currencyCode: CurrencyCode.SGD, currencyNumber: 702 },
  [ISOs.TWN]: { countryCode: PaymentISOs.TW, currencyCode: CurrencyCode.TWD, currencyNumber: 901 },
  [ISOs.USA]: { countryCode: PaymentISOs.US, currencyCode: CurrencyCode.USD, currencyNumber: 840 },
  [ISOs.ZAF]: { countryCode: PaymentISOs.ZA, currencyCode: CurrencyCode.ZAR, currencyNumber: 710 },
};
