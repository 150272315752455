import { useCallback, useEffect, useState } from 'react';

import isEqual from 'react-fast-compare';

import { Mutable } from '@rbi-ctg/frontend';
import { IOffer } from '@rbi-ctg/menu';
import { useListOffersByUserIdQuery } from 'generated/rbi-graphql';
import { IOffersFragment } from 'generated/sanity-graphql';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';
import { useActivateOfferMutation } from 'state/graphql/hooks/use-activate-offer-mutation';
import { useLocationContext } from 'state/location';
import { IMParticleCtx } from 'state/mParticle';
import { Query } from 'state/network/hook';
import { ServiceMode } from 'state/service-mode';
import { ILogger } from 'utils/logger';
import noop from 'utils/noop';

import {
  combineSortedOffersWithAvailableOfferCounts,
  countAvailableOffersByCouponId,
} from '../util';
import sortOffersToDisplay from '../util/sortOffersToDisplay';

import { shouldSkipOffersQuery } from './constants';
import useCartPromoCodeOffers from './use-cart-promo-code-offers';
import useLockedOffers from './use-locked-offers';
import useOffersList from './use-offer-list';
import useOfferRedemption from './use-offer-redemption';
import useOfferUnavailableModal from './use-offer-unavailable-modal';
import usePromoCodeOffers from './use-promo-code-offers';
import useSelectedOffer from './use-selected-offer';

interface IUseOffersProps {
  activeDayParts: IDayPartBoundary[];
  decorateLogger<O extends object>(object: O): void;
  logger: ILogger;
  mParticle: IMParticleCtx;
  query: Query;
  serviceMode: ServiceMode | null;
  user: UserDetails | null;
}

export default function useOffers({
  decorateLogger,
  logger,
  mParticle,
  query,
  serviceMode,
  user,
}: IUseOffersProps) {
  const [filteredOffers, setFilteredOffers] = useState<IOffer[]>([]);
  const [offersToDisplay, setOffersToDisplay] = useState<IOffer[]>([]);
  const [activateOfferMutation] = useActivateOfferMutation();
  const [numberAvailableByCouponId, setNumberAvailableByCouponId] = useState<{
    [key: string]: number;
  }>({});
  const { navigate } = useLocationContext();
  const [redemptionInProgress, setRedemptionInProgress] = useState(false);
  const [upsellOfferId, setUpsellOfferId] = useState<string | null>(null);
  const {
    data: offersByUserId,
    refetch: refetchOffersByUserIdOriginal,
    loading: offersByUserIdLoading,
  } = useListOffersByUserIdQuery({
    fetchPolicy: 'cache-and-network',
    skip: !user || shouldSkipOffersQuery,
  });

  const refetchOffersByUserId = user ? refetchOffersByUserIdOriginal : noop;

  useEffect(() => {
    if (!offersByUserId) {
      return;
    }

    const counts = offersByUserId && countAvailableOffersByCouponId(offersByUserId);
    setNumberAvailableByCouponId(counts || {});
  }, [offersByUserId]);

  const {
    evaluateUserOffersLoading,
    offers,
    offersFeedback,
    queryRedeemableOffers,
    redeemUnauthenticatedOffer,
    refetch: refetchOffersList,
    standardOffers,
  } = useOffersList({
    serviceMode,
    user,
    skip: !user && shouldSkipOffersQuery,
  });

  /**
   * combine sortedOffers with numberAvailableByCouponId for all offer lists to consume
   */
  useEffect(() => {
    if (!offers || !numberAvailableByCouponId) {
      return;
    }
    const sortedOffersWithCounts = combineSortedOffersWithAvailableOfferCounts(
      offers as IOffersFragment[],
      numberAvailableByCouponId
    );
    setFilteredOffers(sortedOffersWithCounts as Mutable<IOffersFragment[]> as IOffer[]);
  }, [offers, numberAvailableByCouponId]);

  const refreshOffers = useCallback(
    /**
     * Refetch sorted offers, offersByUserId, and evaluateUserOffers in parallel
     */
    async () => {
      try {
        if (user?.cognitoId) {
          // queryRedeemableOffers will trigger refetchOffersList but only for auth user
          await Promise.all([refetchOffersByUserId() as any, queryRedeemableOffers()]);
        } else {
          await Promise.all([refetchOffersList(), refetchOffersByUserId() as any]);
        }
      } catch (error) {
        // TODO: retry or error state
        logger.error({ error, message: 'Refresh Offers Error' });
      }
      return;
    },
    [logger, queryRedeemableOffers, refetchOffersByUserId, refetchOffersList, user]
  );

  const activateOffer = useCallback(
    /**
     * Activates an offer
     *
     * @param tokenId pulled from offerFeedback
     */
    async (tokenId: string) => {
      try {
        const resp = await activateOfferMutation({ variables: { tokenId } });
        const isOfferActivated = resp?.data?.activateLoyaltyOffer || false;
        return { isOfferActivated };
      } catch (error) {
        logger.error({ error, message: 'Activate Offer Error' });
        throw error;
      } finally {
        refreshOffers();
      }
    },
    [activateOfferMutation, refreshOffers, logger]
  );

  const { lockedOffers } = useLockedOffers({
    sortedOffers: filteredOffers,
    offersFeedback,
  });

  /**
   * promo code offers are separated out because we want to be able to
   *  update their redeemable state without refetching all the offers
   */
  const { promoCodeOffers, togglePromoCodeOfferRedeemable } = usePromoCodeOffers({
    sortedOffers: filteredOffers,
    offersFeedback,
  });

  /**
   * cart promo code offers are separated out because they are not
   *  displayed on the offers page and have a completely separate UX
   */
  const { cartPromoCodeOffers } = useCartPromoCodeOffers({
    sortedOffers: filteredOffers,
    offersFeedback,
  });

  const {
    clearSelectedOffer,
    isSelectedOfferCartEntry,
    selectOfferById,
    selectedOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    setSelectedOfferCartEntry,
    setSelectedOfferPrice,
  } = useSelectedOffer({
    decorateLogger,
    mParticle,
    offers: offersToDisplay,
    lockedOffers,
    serviceMode,
    cartPromoCodeOffers,
  });

  /**
   * offersToDisplay combines the base sorted offers (either from FE or BE)
   *  as well as any promo code offers. This effect listens for any changes
   *  to either the main offers or promo code offers and updates the offersToDisplay
   * example of change: a user unlocks a promo code offer. instead of re-fetching all
   *  offers to get the updated unlocked state, we just update the state on the FE
   *  and update this setOffersToDisplay state.
   */
  useEffect(() => {
    const newOffersToDisplayList = sortOffersToDisplay(
      filteredOffers,
      standardOffers,
      promoCodeOffers
    );
    setOffersToDisplay(previousOffers =>
      isEqual(previousOffers, newOffersToDisplayList) ? previousOffers : newOffersToDisplayList
    );
  }, [filteredOffers, promoCodeOffers, selectedOffer, setOffersToDisplay, standardOffers]);

  const {
    offerValidationErrors,
    onCancelRedemption,
    onConfirmRedemption,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    redemptionMethod,
    redeemedOffer,
    setRedeemedOffer,
    setOfferValidationErrors,
    setRedemptionMethod,
    validateOfferRedeemable,
  } = useOfferRedemption({
    clearSelectedOffer,
    decorateLogger,
    logger,
    mParticle,
    query,
    queryRedeemableOffers,
    redeemUnauthenticatedOffer,
    user,
    upsellOfferId,
  });

  const [OfferUnavailableDialog, openOfferUnavailableDialog] = useOfferUnavailableModal({
    navigate,
    onCancelRedemption,
  });

  return {
    OfferUnavailableDialog,
    clearSelectedOffer,
    isSelectedOfferCartEntry,
    loading: evaluateUserOffersLoading || offersByUserIdLoading,
    offers: offersToDisplay,
    offersFeedback,
    offerValidationErrors,
    lockedOffers,
    promoCodeOffers,
    togglePromoCodeOfferRedeemable,
    cartPromoCodeOffers,
    onCancelRedemption,
    onConfirmRedemption,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    openOfferUnavailableDialog,
    redemptionMethod,
    redeemedOffer,
    setRedeemedOffer,
    refetchOffersList,
    selectOfferById,
    selectedOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    setOfferValidationErrors,
    setRedemptionMethod,
    setSelectedOfferCartEntry,
    setSelectedOfferPrice,
    queryRedeemableOffers,
    validateOfferRedeemable,
    refreshOffers,
    activateOffer,
    redemptionInProgress,
    setRedemptionInProgress,
    upsellOfferId,
    setUpsellOfferId,
  };
}
