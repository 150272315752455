import { gql } from '@apollo/client';

export default gql`
  fragment ImageFragment on Image {
    hotspot {
      x
      y
      height
      width
    }
    crop {
      top
      bottom
      left
      right
    }
    asset {
      metadata {
        lqip
        palette {
          dominant {
            background
            foreground
          }
        }
      }
      _id
    }
  }
`;
