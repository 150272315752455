import React from 'react';

import styled, { css } from 'styled-components';

import { IOffer } from '@rbi-ctg/menu';
import ExternalLink from 'components/external-link';
import SanityBlockRenderer, {
  IBlockContentOverrides,
  ISerializers,
  SanityBlockContentLinkProps,
} from 'components/sanity-block-renderer';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyOffer } from 'state/loyalty/types';
import { isMoreInfoDisclaimerAvailable } from 'utils/offers';

import { shouldUseCustomDisclaimer } from './should-use-custom-disclaimer';
import theme from './theme';

export const DisclaimerContainer = styled.div`
  max-width: 36rem;
  margin: 3rem auto;
  text-align: center;

  ${Styles.desktop`
    margin: 2rem auto 1.5rem auto;
  `}
`;

const disclaimerTextCss = css`
  font-size: 0.75rem;
  font-family: ${Styles.fontFamily.body};
  font-style: normal;
  line-height: 1.33;
  color: ${theme.disclaimerTextCssColor};
`;

const DisclaimerText = styled.p`
  ${disclaimerTextCss}
  margin: 0;
  word-break: break-word;
`;

const DisclaimerLink = styled(ExternalLink)`
  ${disclaimerTextCss}
  text-decoration:underline;
`;

const disclaimerBlockContentOverrides: IBlockContentOverrides = {
  normal: DisclaimerText,
};

const disclaimerBlockSerializers: ISerializers = {
  marks: {
    link: ({ children = [], mark = {} }: SanityBlockContentLinkProps) => {
      const [linkText] = children;
      const { href } = mark;
      return href && linkText ? <DisclaimerLink href={href}>{linkText}</DisclaimerLink> : null;
    },
  },
};

export const CustomDisclaimer = ({
  content,
}: {
  content: React.ComponentProps<typeof SanityBlockRenderer>['content'];
}) => {
  return (
    <SanityBlockRenderer
      blockContentOverrides={disclaimerBlockContentOverrides}
      serializers={disclaimerBlockSerializers}
      content={content}
    />
  );
};

interface IDisclaimerProps {
  offer: IOffer | LoyaltyOffer | null;
}

export const OfferDisclaimer: React.FC<IDisclaimerProps> = ({ offer }) => {
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { locale: generalOfferDisclaimer } = featureDisclaimers?.offers || {};

  const enableMoreInfoDisclaimer = useFlag(
    LaunchDarklyFlag.ENABLE_MORE_INFO_OPTIONAL_OFFER_DISCLAIMER
  );

  if (!offer) {
    return null;
  }

  const isMoreInfoDisclaimer = isMoreInfoDisclaimerAvailable(offer);
  const shouldUseMoreInfoDisclaimer = shouldUseCustomDisclaimer(
    isMoreInfoDisclaimer,
    enableMoreInfoDisclaimer
  );

  return (
    <DisclaimerContainer>
      {shouldUseMoreInfoDisclaimer ? (
        <CustomDisclaimer content={offer?.moreInfo?.localeRaw} />
      ) : (
        <DisclaimerText>{generalOfferDisclaimer}</DisclaimerText>
      )}
    </DisclaimerContainer>
  );
};
