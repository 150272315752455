import styled, { keyframes } from 'styled-components';

import TextButton from 'components/text-button';

const fadeIn = keyframes`
  from {
    transform: translateY(3rem)
  } to {
    opacity: 1;
    transform: translateX(0)
  }
`;

export const Container = styled.div`
  opacity: 0;
  background: ${Styles.color.validated};
  display: flex;
  padding: 1rem 1.5rem;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  animation: ${fadeIn} forwards 0.5s 0.75s;
  z-index: ${Styles.zIndex.overlay + 300};
  ${Styles.desktop`
    flex-direction: row;
    padding: 1.3125rem 3.5rem;
    bottom: 0;
    justify-content: space-between;
  `}
`;

export const InfoText = styled.p`
  color: ${Styles.color.white};
  margin: 0 0 1rem;
  ${Styles.desktop`
    margin: unset;
    max-width: 75%;
    padding-right: 2rem;
  `}
`;

export const CloseButton = styled(TextButton)`
  padding-right: 1.5rem;
  color: ${Styles.color.white};
  font-weight: ${Styles.fontWeight.heavy};
`;

export const CookieNoticeLink = styled.a`
  color: ${Styles.color.white};
  font-weight: ${Styles.fontWeight.heavy};
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  button {
    white-space: break-spaces;
  }
  ${Styles.desktop`
    flex-direction: row;
  `}
`;
