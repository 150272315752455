import styled from 'styled-components';

export const LinkButton = styled.button`
  border: none;
  background-color: initial;
  color: inherit;
  font-size: 1rem;
  text-decoration: underline;
  font-family: ${Styles.fontFamily.base};

  &:hover {
    color: ${Styles.color.tertiary};
    cursor: pointer;
  }
`;
