import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { ICartEntry } from '@rbi-ctg/menu';
import { IRewardFragment, LoyaltyTierKey } from 'generated/graphql-gateway';
import { useFeatureEarningCalculationQuery } from 'generated/sanity-graphql';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { updateAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { IUseLoyaltyRewards, UseLoyaltyRewards } from 'state/loyalty/hooks/types';
import { getParentIdFromUrl } from 'utils/cart';

export const useLoyaltyRewards: UseLoyaltyRewards = (loyaltyUser): IUseLoyaltyRewards => {
  const { refetchRewards, engineRewardsMap } = useLoyaltyRewardsList();
  const [shouldApplyStorageRewards, setShouldApplyStorageRewards] = useState(false);
  const dispatch = useAppDispatch();
  const availableLoyaltyRewardsMap = useAppSelector(
    selectors.loyalty.selectAvailableLoyaltyRewardsMap
  );
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );

  const stagedCartPoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);

  const loyaltyUserId = loyaltyUser?.id;
  const previousLoyaltyUserId = usePrevious(loyaltyUserId);

  useEffect(() => {
    // It means the user already has points in the cart, avoid rerun the sideEffet to rehydrateAppliedReward
    if (stagedCartPoints) {
      return;
    }

    // loyalty user id will populate upon logging in / initial page load
    if (!previousLoyaltyUserId && loyaltyUserId) {
      setShouldApplyStorageRewards(true);
    }
    // occurs when a user logs out
    else if (previousLoyaltyUserId && !loyaltyUserId) {
      updateAppliedRewardsInStorage({});
    }
  }, [previousLoyaltyUserId, loyaltyUserId, stagedCartPoints]);

  useEffect(() => {
    if (!isEmpty(availableLoyaltyRewardsMap) && shouldApplyStorageRewards) {
      dispatch(actions.loyalty.rehydrateAppliedReward());
      setShouldApplyStorageRewards(false);
    }
  }, [availableLoyaltyRewardsMap, dispatch, shouldApplyStorageRewards]);

  const getAvailableRewardFromCartEntry = useCallback(
    (cartEntry: ICartEntry) => {
      if (!cartEntry) {
        return;
      }

      const availableReward = availableLoyaltyRewardsMap[cartEntry._id];

      if (availableReward) {
        return availableReward;
      }

      // there may not always be a 1-1 correlation with the index of `availableLoyaltyRewardsMap` and `cartEntry._id`
      // this is the case with pickers because the cartEntry._id is the item that the picker resolved to
      // since the reward map holds the parent picker, we have to parse the parent picker id from `cartEntry.url`
      // example url - /menu/picker-$pickerId?cartId=$cartId&_id=$itemId
      const parentId = getParentIdFromUrl(cartEntry.url);
      return availableLoyaltyRewardsMap[parentId];
    },
    [availableLoyaltyRewardsMap]
  );

  const { data: earningCalculationData } = useFeatureEarningCalculationQuery({
    variables: { id: 'earningCalculation' },
  });

  const enableLoyaltyTiers = Boolean(
    earningCalculationData?.EarningCalculation?.enableLoyaltyTiers
  );

  const isRewardTierLocked = useCallback(
    (engineReward: IRewardFragment, currentUserTier: LoyaltyTierKey) => {
      return Boolean(
        currentUserTier &&
          engineReward?.requiredLoyaltyTier &&
          engineReward?.requiredLoyaltyTier !== currentUserTier
      );
    },
    []
  );

  const rewardLimitePerOrder =
    earningCalculationData?.EarningCalculation?.rewardsRedemptionConfig?.standardRewardsLimit;

  const isLimitRewardRedemptionPerOrderReached = useMemo(() => {
    const totalAppliedLoyaltyRewards = appliedLoyaltyRewardsArray?.length;

    const isTimesAppliedReachedLimit = appliedLoyaltyRewardsArray?.some(
      item => item.timesApplied === rewardLimitePerOrder
    );

    // 0 means unlimited rewards per order, so do not shown the replace dialog
    const maxRewardsPerOrder = rewardLimitePerOrder || 0;

    // If no max is defined, rewards can be stacked until default maximum (9)
    if (totalAppliedLoyaltyRewards === maxRewardsPerOrder || isTimesAppliedReachedLimit) {
      return true;
    }
    return false;
  }, [rewardLimitePerOrder, appliedLoyaltyRewardsArray]);

  return {
    getAvailableRewardFromCartEntry,
    refetchRewards,
    engineRewardsMap,
    isLimitRewardRedemptionPerOrderReached,
    rewardLimitePerOrder,
    enableLoyaltyTiers,
    isRewardTierLocked,
  };
};
