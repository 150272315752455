import { ReactNode } from 'react';

import { ISanityImage, ISanityTypographyBlock } from '@rbi-ctg/menu';
import { IImageFragment } from 'generated/sanity-graphql';

export interface IOnboardingContext {
  onboardingEnabled: boolean;
  allFeatureOnboarding: IFeatureOnboarding[];
  featureOnboardingLoading: boolean;
  showOnboardingContent: boolean;
  firstAppLoadOnboarding: IFeatureOnboardingScreen[];
}

export interface IOnboardingProps {
  children: ReactNode;
}

export type IFeatureOnboardingScreen =
  | {
      image: ISanityImage | IImageFragment | null;
      textContent: ISanityTypographyBlock[];
      buttonText: string;
      buttonUrl: string;
      imageDescription: string;
    }
  | null
  | undefined;

export interface IFeatureOnboarding {
  featureOnboardingChannel: string | null;
  featureOnboardingTriggerRule: string | null;
  featureOnboardingScreens: IFeatureOnboardingScreen[] | undefined;
}

export interface IOnboardingContent {
  featureOnboardingScreens: IFeatureOnboardingScreen[];
}

export enum Channel {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export enum TriggerRule {
  ON_FIRST_APP_LOAD = 'firstAppLoad',
}
