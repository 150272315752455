import { CustomEventNames, EventTypes, IMParticleCtx } from 'state/mParticle';

export const checkLimitReachedEvent = (overLimitTotal: string, mParticle: IMParticleCtx) => {
  mParticle.logEvent(CustomEventNames.CHECKOUT_LIMIT_REACHED, EventTypes.Other, {
    total: overLimitTotal,
  });
};

export const checkMinimumNotReachedEvent = (underLimitTotal: string, mParticle: IMParticleCtx) => {
  mParticle.logEvent(CustomEventNames.CHECKOUT_DELIVERY_MINIMUM_NOT_REACHED, EventTypes.Other, {
    total: underLimitTotal,
  });
};
