/* eslint-disable no-console */
import { Loggable } from '@rbi-ctg/frontend';
import LocalStorage from 'utils/cognito/storage';
import { StatusType, dataDogLogger } from 'utils/datadog';
import {
  RBIBrand,
  RBIEnv,
  RBIPlatform,
  appVersionCode,
  brand,
  env,
  platform,
} from 'utils/environment';
import { StorageKeys } from 'utils/local-storage';

import {
  ErrorCategory,
  ILoggerMiddleware,
  errorCategoryMiddleware,
  uuidScrubberMiddleware,
} from './middleware';

export enum LogLevel {
  trace = 10,
  debug = 20,
  info = 30,
  warn = 40,
  error = 50,
  fatal = 60,
}

interface ILoggerBaseFields {
  /**
   * The Appflow build id, if applicable
   */
  appflowBuildId?: number;
  /**
   * The binary version code, if available, or commit sha1 otherwise
   */
  appVersionCode?: number | string;
  /**
   * The binary version name, if available
   */
  brand: RBIBrand;
  platform: RBIPlatform;
  stage: RBIEnv;
  userId?: string;
}

type LogFunction = <O extends Loggable | Array<Loggable>>(message: O) => void;

export interface ILoggerOptions {
  middlewares?: ILoggerMiddleware[];
  currentEnv?: RBIEnv;
}

export interface ILogger<O extends object = ILoggerBaseFields> {
  DEBUG: LogLevel.debug;
  ERROR: LogLevel.error;
  FATAL: LogLevel.fatal;
  INFO: LogLevel.info;
  TRACE: LogLevel.trace;
  WARN: LogLevel.warn;
  child<P extends object>(fields: P, loggerOptions?: ILoggerOptions): ILogger<O & P>;
  debug: LogFunction;
  error: LogFunction;
  fatal: LogFunction;
  groupCollapsed(...name: string[]): void;
  groupEnd(): void;
  info: LogFunction;
  trace: LogFunction;
  warn: LogFunction;
}

type Method = keyof typeof LogLevel;

const methods: Method[] = ['debug', 'error', 'fatal', 'info', 'trace', 'warn'];

const prepareMessage = <O extends Loggable>(
  message: O,
  level: LogLevel,
  loggerAttributes: ILoggerBaseFields
) => {
  if (message instanceof Error) {
    return Object.assign({}, { error: message }, loggerAttributes, { level });
  }

  if (typeof message === 'object') {
    return Object.assign({}, message, loggerAttributes, { level });
  }

  return Object.assign({}, { message }, loggerAttributes, {
    level,
  });
};

/**
 * This function is only exported for testing.
 * The default export for this file is a logger
 * instantiated with fields we want to send
 * on _every_ message to logger.
 * We should only use loggers that are
 * a child of the default logger in production.
 */
export function Logger<T extends object, U extends object>(
  fields: T,
  parentFields?: U,
  loggerOptions: ILoggerOptions = {}
): ILogger<T & U> {
  const { middlewares = [], currentEnv = env() } = loggerOptions;
  // merge child into parent attributes, then clear undefined attributes
  const loggerAttributes = Object.entries(Object.assign({}, parentFields, fields)).reduce(
    (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }),
    {}
  );

  const logFunction =
    (method: Method): LogFunction =>
    message => {
      const level = LogLevel[method];

      type middlewareAttributes = { [key: string]: Loggable };

      // NOTE: If `error` is present uuid-scrubber middleware
      // will replace `message` with `error.message` and
      // append the original `message` property
      // as `originalDeveloperMessage` in attributes
      const attributes = middlewares.reduce<middlewareAttributes>(
        (attr, middleware) => middleware(attr),
        prepareMessage(
          message,
          level,
          loggerAttributes as ILoggerBaseFields
        ) as middlewareAttributes
      );

      const { category } = attributes;

      if (category === ErrorCategory.DoNotLog) {
        return;
      }

      /**
       * Sends events to dataDog
       */
      if (level >= LogLevel.error && currentEnv !== RBIEnv.TEST) {
        dataDogLogger({
          message: (attributes.error as Error) || attributes.message,
          context: attributes,
          status: StatusType.error,
        });
      }

      if (currentEnv === RBIEnv.DEV) {
        /**
         * Note: If you use logger.debug the chrome web
         * console will not show the messages by default.
         * There is a dropdown that says "Default Levels",
         * by clicking it and turning on "Verbose" you will
         * be able to see debug logs.
         */
        const f = (console[method] || console.error).bind(console);

        f(message);

        console.table({ ...loggerAttributes, level });
      }
    };

  const loggerPublicInterface = methods.reduce<ILogger>((logger, method) => {
    return { ...logger, [method]: logFunction(method), [method.toUpperCase()]: LogLevel[method] };
  }, {} as ILogger);

  ['group', 'groupCollapsed', 'groupEnd'].forEach(method => {
    loggerPublicInterface[method] = console[method].bind(console);
  });

  /**
   * create a logger with the attributes of its parent and new attributes, merged
   */
  loggerPublicInterface.child = <V extends object>(
    attributes: V,
    { middlewares: childMiddlewares = [] }: { middlewares?: ILoggerMiddleware[] } = {}
  ) =>
    Logger(attributes, loggerAttributes, {
      middlewares: middlewares.concat(childMiddlewares),
    });

  return loggerPublicInterface;
}

const baseFields: ILoggerBaseFields = {
  appVersionCode: appVersionCode(),
  brand: brand(),
  platform: platform(),
  stage: env(),
  userId: LocalStorage?.getItem(StorageKeys.USER)?.cognitoId,
};

const DEFAULT_MIDDLEWARES = [errorCategoryMiddleware, uuidScrubberMiddleware];

/**
 * The preferred used export is a configured logger
 * with appVersion, brand, env and platform attributes.
 * Using the `.child` method will allow you to create loggers
 * that include these fields as well as the additional ones
 * specified in .child(). this logger or a child of it should
 * always be used. do not use the `Logger` constructor directly.
 */
export const defaultLogger = Logger(baseFields, {}, { middlewares: DEFAULT_MIDDLEWARES });

export default defaultLogger;
