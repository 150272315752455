import styled from 'styled-components';

import {
  Points as DefaultPoints,
  StyledArrowLink as DefaultStyledArrowLink,
  StyledPointsIcon as DefaultStyledPointsIcon,
} from './rewards-link.styled.default';

export * from './rewards-link.styled.default';

export const StyledPointsIcon = styled(DefaultStyledPointsIcon)`
  path {
    fill: ${p => p.theme.token('text-loyalty-highlight')};
  }
`;

export const StyledArrowLink = styled(DefaultStyledArrowLink)`
  &:hover ${StyledPointsIcon} {
    path {
      fill: ${p => p.theme.token('text-loyalty-highlight')};
    }
  }
`;

export const Points = styled(DefaultPoints)`
  color: ${p => p.theme.token('text-loyalty-highlight')};
`;
