import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { RewardTierBaseProps } from '../reward-tier/types';

import {
  Container,
  InformationContainer,
  RewardsWrapper,
  TierLabel,
  Title,
  TitleWrapper,
} from './reward-tier-no-input.styled';

export const RewardTierNoInput: FC<RewardTierBaseProps> = ({
  children,
  name = '',
  tierLabel,
  titleId,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <InformationContainer>
        <TitleWrapper>
          <Title id={titleId}>{name}</Title>
        </TitleWrapper>
        <RewardsWrapper>
          <TierLabel>
            {formatMessage({ id: 'loyaltyDropdownPoints' }, { points: tierLabel })}
          </TierLabel>
        </RewardsWrapper>
      </InformationContainer>
      {children}
    </Container>
  );
};
