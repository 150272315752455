import React, { createContext, useCallback, useContext, useRef } from 'react';

import { ScrollView } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';

export interface IScrollContext {
  scrollRef: React.MutableRefObject<ScrollView | null>;
  scrollTo: ScrollView['scrollTo'];
}

export const ScrollContext = createContext<IScrollContext>({} as IScrollContext);
export const useScrollContext = () => useContext(ScrollContext);

export default ScrollContext.Consumer;

export const ScrollProvider = ({ children }: IBaseProps) => {
  const scrollRef = useRef<ScrollView | null>(null);

  // If no coordinates given, default scroll to top
  const scrollTo = useCallback(
    ({ x, y, animated } = { x: 0, y: 0, animated: true }) =>
      scrollRef.current?.scrollTo({ x, y, animated }),
    []
  );

  return (
    <ScrollContext.Provider
      value={{
        scrollRef,
        scrollTo,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
