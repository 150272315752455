import React, { createContext, useContext } from 'react';

import { useSelectedReward } from 'components/app-header/loyalty/rewards-management/hooks';
import { ILoyaltyCategoryOption } from 'state/loyalty/types';

export interface IThRewardsManagementContext {
  dismissUpdateError(): void;
  errorUpdatingRewardTier: boolean;
  selectedRewardCategoryId?: string;
  selectedRewardTier?: Omit<ILoyaltyCategoryOption, 'description'>;
  selectedRewardTierId: string | null;
  onChangeRewardTier(newLoyaltyTierId: string | null): Promise<void>;
  updatingRewardTier: boolean;
}

export const ThRewardsManagementContext = createContext<IThRewardsManagementContext>(
  {} as IThRewardsManagementContext
);

export const useThRewardsManagementContext = () => useContext(ThRewardsManagementContext);

export const ThRewardsManagementProvider: React.FC = ({ children }) => {
  const {
    dismissUpdateError,
    errorUpdatingRewardTier,
    selectedRewardCategoryId,
    selectedRewardTier,
    selectedRewardTierId,
    onChangeRewardTier,
    updatingRewardTier,
  } = useSelectedReward();

  return (
    <ThRewardsManagementContext.Provider
      value={{
        dismissUpdateError,
        errorUpdatingRewardTier,
        selectedRewardCategoryId,
        selectedRewardTier,
        selectedRewardTierId,
        onChangeRewardTier,
        updatingRewardTier,
      }}
    >
      {children}
    </ThRewardsManagementContext.Provider>
  );
};
