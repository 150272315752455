export enum FeatureHomePageComponentTypes {
  Alert = 'Alert',
  AppDownload = 'AppDownload',
  BrazeContentCards = 'BrazeContentCards',
  Hero = 'Hero',
  FanFavorites = 'FanFavorites',
  LockedOffers = 'LockedOffers',
  MainHero = 'MainHero',
  MarketingTileCarousel = 'MarketingTileCarousel',
  MarketingTileGroup = 'MarketingTileGroup',
  MarketingCardGroup = 'MarketingCardGroup',
  OffersSection = 'OffersSection',
  OurServices = 'OurServices',
  RecentItems = 'RecentItems',
  RewardsSection = 'RewardsSection',
  RollUpTheRimSection = 'RollUpTheRimSection',
  ScanForRewards = 'ScanForRewards',
  Trending = 'Trending',
  GoogleAdSlot = 'GoogleAdSlot',
  TimsRewardsPointsSection = 'TimsRewardsPointsSection',
}

export type FeatureHomePageComponents = {
  [key in FeatureHomePageComponentTypes]?: any;
};

export interface IRawFeatureHomePageComponent {
  _id: string;
  __typename: FeatureHomePageComponentTypes;
}
