import React, { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Picture from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { RBIBrand, brand } from 'utils/environment';

import {
  LinkWrapper,
  PointsNumber,
  StyledPointsIcon,
  StyledRewardsLogo,
} from './loyalty-app-header.styled';
import { ILoyaltyAppHeaderProps } from './types';

const LoyaltyRewardsLogoPLK = styled.div`
  ${Styles.desktop`
  width: 5.125rem;
  height: 4rem;
  display: inline-block;
  position: relative;
`}
`;

export const LoyaltyAppHeaderView: FC<ILoyaltyAppHeaderProps> = ({ points, to }) => {
  const { formatMessage } = useIntl();
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  if (featureLoyaltyUiLoading) {
    return null;
  }
  return (
    <LinkWrapper to={to}>
      <VisuallyHidden>{formatMessage({ id: 'rewardsPointsAmount' }, { points })}</VisuallyHidden>
      {brand() === RBIBrand.PLK && loyaltyEnabled ? (
        <LoyaltyRewardsLogoPLK data-testid="auth-reward-logo">
          <Picture
            image={featureLoyaltyUi?.rewardsLogo?.locale}
            alt={formatMessage({ id: 'rewards' })}
            objectFitContain
          />
        </LoyaltyRewardsLogoPLK>
      ) : (
        <StyledRewardsLogo />
      )}

      <StyledPointsIcon />
      <PointsNumber aria-hidden>{points}</PointsNumber>
    </LinkWrapper>
  );
};
