import * as React from 'react';

import { useIntl } from 'react-intl';

import { useFeaturesLoyaltyTiersContent } from 'state/loyalty/hooks/use-feature-loyalty-tiers-content';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyRewards } from 'state/loyalty/hooks/use-loyalty-rewards';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';

import {
  LinkContainer,
  Points,
  PointsContainer,
  StyledArrowLink,
  StyledPointsIcon,
  StyledRewardsLogo,
} from './rewards-link.styled';

interface IRewardsLinkProps {
  onClickEventName: CustomEventNames;
  showPointsLabel?: boolean;
  textLabel?: string;
}

const RewardsLink = ({ onClickEventName, showPointsLabel, textLabel }: IRewardsLinkProps) => {
  const mParticle = useMParticleContext();
  const { formatMessage } = useIntl();
  const handleClick = () => mParticle.logNavigationClick(onClickEventName);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { enableLoyaltyTiers } = useLoyaltyRewards(loyaltyUser);
  const { data } = useFeaturesLoyaltyTiersContent();

  const route = enableLoyaltyTiers
    ? data?.loyaltyTiersHowItWorksButtonLink?.locale
    : formatMessage({ id: 'routes.rewardsHistory' });

  return loyaltyEnabled && loyaltyUser ? (
    <StyledArrowLink data-testid="loyalty-link" to={route} onClick={handleClick}>
      <LinkContainer>
        {textLabel || <StyledRewardsLogo />}
        <PointsContainer $hasLabel={showPointsLabel}>
          {showPointsLabel && `${formatMessage({ id: 'points' })}`}
          <StyledPointsIcon />
          <Points>{loyaltyUser.points}</Points>
        </PointsContainer>
      </LinkContainer>
    </StyledArrowLink>
  ) : null;
};

export default RewardsLink;
