import React, { FC, InputHTMLAttributes } from 'react';

import { RadioButton } from '@rbilabs/components-library/build/components/radio-button';

import { RewardTierNoInput } from '../reward-tier-no-input';

import { CheckmarkWrapper } from './reward-tier-input.styled';
import { RewardTierBaseProps } from './types';

export type RewardTierInputProps = RewardTierBaseProps & {
  allowTabNavigation?: boolean;
  ariaDescribedBy?: string;
  radioButtonName: string;
  selected: boolean;
  value: InputHTMLAttributes<HTMLInputElement>['value'];
};
export const RewardTierInput: FC<RewardTierInputProps> = ({
  radioButtonName,
  selected,
  value,
  ...props
}) => {
  const titleId = `reward-tier-title-${value}`;

  return (
    <RewardTierNoInput {...props} titleId={titleId}>
      <CheckmarkWrapper>
        <RadioButton
          label={''}
          name={radioButtonName}
          value={value?.toString()}
          checked={selected}
        />
      </CheckmarkWrapper>
    </RewardTierNoInput>
  );
};
