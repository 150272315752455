import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IItem } from '@rbi-ctg/menu';
import { getUserModifications } from 'components/product-detail/product-customization/utils';
import {
  IProductWizardContext,
  IProductWizardProvider,
  IUserManualModifications,
  IUserSelection,
  Product,
  ProductMenuObject,
  ProductWizardStep,
  SubmitCustomizationsFn,
  UserSelectionComboSlot,
} from 'state/product-wizard/types';
import { ProductWizardUtils } from 'state/product-wizard/utils';
import { MIN_CART_QUANTITY } from 'utils/cart';

import { useEditingCartEntry } from './hooks/use-editing-cart-entry';

const ProductWizardContext = createContext<IProductWizardContext>({
  currentStep: ProductWizardStep.ProductHome,
  goToCustomizeProduct: () => null,
  goToHome: () => null,
  itemToCustomize: null,
  menuObject: {} as ProductMenuObject,
  productQuantity: MIN_CART_QUANTITY,
  selectedProduct: {} as Product,
  setProductQuantity: () => {},
  slotKeyToCustomize: '',
  submitCustomizations: () => null,
  updatePickerAspectSelections: () => null,
  userSelections: {} as IUserSelection,
  manualModifications: {} as IUserManualModifications,
  isDirty: false,
});

export const ProductWizardProvider: React.FC<IProductWizardProvider> = ({
  product: menuObject,
  children,
}) => {
  const editingCartEntry = useEditingCartEntry();
  const [currentStep, setCurrentStep] = useState<ProductWizardStep>(ProductWizardStep.ProductHome);
  const [slotKeyToCustomize, setSlotKeyToCustomize] = useState('');
  const [manualModifications, setManualModifications] = useState<IUserManualModifications>({
    pickerAspects: false,
    comboSlot: false,
    modifiers: false,
  });
  const [userSelections, setUserSelections] = useState<IUserSelection>(() =>
    ProductWizardUtils.SetDefaultSelections({ menuObject, cartEntryOverrides: editingCartEntry })
  );
  const [selectedProduct, setSelectedProduct] = useState<Product>(() =>
    ProductWizardUtils.ComputeSelectedOption(menuObject, userSelections.pickerAspects)
  );
  const defaultComboSlotSelections = useMemo<UserSelectionComboSlot>(
    () =>
      ProductWizardUtils.SetDefaultSelections({
        menuObject,
        pickerSelectionOverrides: userSelections.pickerAspects,
      }).comboSlot || new Map(),
    [menuObject, userSelections.pickerAspects]
  );

  useEffect(() => {
    if (!userSelections.comboSlot) {
      return;
    }
    const modifications = getUserModifications(
      userSelections.comboSlot,
      userSelections.modifiers,
      defaultComboSlotSelections
    );
    setManualModifications(prev => ({
      ...prev,
      comboSlot: prev.comboSlot || modifications.comboSlot,
      modifiers: prev.modifiers || modifications.modifiers,
    }));
  }, [defaultComboSlotSelections, userSelections.comboSlot, userSelections.modifiers]);

  const isDirty = useMemo<boolean>(() => {
    if (!userSelections.comboSlot) {
      return false;
    }
    const modifications = getUserModifications(
      userSelections.comboSlot,
      userSelections.modifiers,
      defaultComboSlotSelections
    );
    return modifications.comboSlot || modifications.modifiers;
  }, [defaultComboSlotSelections, userSelections.comboSlot, userSelections.modifiers]);

  const [productQuantity, setProductQuantity] = useState(
    editingCartEntry?.quantity || MIN_CART_QUANTITY
  );

  const itemToCustomize = useMemo<IItem | null>(
    () => userSelections.comboSlot?.get(slotKeyToCustomize)?.selectedItem || null,
    [slotKeyToCustomize, userSelections.comboSlot]
  );

  const updatePickerAspectSelections = useCallback(
    args => {
      setManualModifications(prev => ({ ...prev, pickerAspects: true }));
      setUserSelections(current => {
        current.pickerAspects = args;
        return ProductWizardUtils.SetDefaultSelections({
          menuObject,
          pickerSelectionOverrides: current.pickerAspects,
        });
      });
    },
    [menuObject]
  );

  const goToHome = useCallback(() => {
    setCurrentStep(ProductWizardStep.ProductHome);
  }, []);

  const goToCustomizeProduct = useCallback((slotKey: string) => {
    setSlotKeyToCustomize(slotKey);
    setCurrentStep(ProductWizardStep.ProductCustomization);
  }, []);

  const submitCustomizations = useCallback<SubmitCustomizationsFn>(
    ({ modifiers, selectedItem, slotKey }) => {
      setUserSelections(current => {
        const comboSlot = current.comboSlot ? new Map(current.comboSlot) : new Map();
        comboSlot.set(slotKey, {
          comboSlot: current.comboSlot?.get(slotKey)?.comboSlot,
          selectedItem,
        });

        return {
          ...current,
          comboSlot,
          modifiers: {
            ...current.modifiers,
            [slotKey]: modifiers,
          },
        };
      });
    },
    []
  );

  useEffect(() => {
    setSelectedProduct(
      ProductWizardUtils.ComputeSelectedOption(menuObject, userSelections.pickerAspects)
    );
  }, [menuObject, userSelections.pickerAspects]);

  if (!selectedProduct) {
    return null;
  }

  return (
    <ProductWizardContext.Provider
      value={{
        currentStep,
        goToCustomizeProduct,
        goToHome,
        itemToCustomize,
        menuObject,
        productQuantity,
        selectedProduct,
        setProductQuantity,
        slotKeyToCustomize,
        submitCustomizations,
        updatePickerAspectSelections,
        userSelections,
        manualModifications,
        isDirty,
      }}
    >
      {children}
    </ProductWizardContext.Provider>
  );
};

export const useProductWizardContext = () => useContext(ProductWizardContext);
