import { ServiceMode } from '@rbi-ctg/menu';
import { ILoyaltyGenerateOtpMutationVariables } from 'generated/graphql-gateway';
import { LoyaltyAppliedOffer } from 'state/loyalty/types';
import { StoreProxy } from 'state/store';

export enum ShortCodeState {
  None = 'NONE',
  Pending = 'PENDING',
  Expired = 'EXPIRED',
  RequestError = 'REQUEST_ERROR',
  Claimed = 'CLAIMED',
  // Cart Offer Validation Error
  OfferValidationError = 'OFFER_VALIDATION_ERROR',
}

export interface IShortCodeData {
  shortCode?: string;
  expiry?: string;
}

export interface IUserShortCode {
  shortCode?: string;
  shortCodeState: ShortCodeState;
  shortCodeLoading: boolean;
  getNewShortCode: (params: IGetNewShortCodeParams) => void;
  setShortCodeClaimed: () => void;
  setShortCodeExpired: () => void;
  resetShortCode: () => void;
}

export interface IGetNewShortCodeParams extends ILoyaltyGenerateOtpMutationVariables {
  appliedOffers: LoyaltyAppliedOffer[];
  serviceMode: ServiceMode | null;
  store: StoreProxy | null;
}
