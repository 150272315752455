import React from 'react';

import styled from 'styled-components/native';

import { IBaseProps } from '@rbi-ctg/frontend';
import useMediaQuery from 'hooks/use-media-query';
import { useLocationContext } from 'state/location';
import { useMobileFooterComponent } from 'state/mobile-footer';
import { UUID_REGEX_STRING } from 'utils/constants';
import { routes } from 'utils/routing';

export interface LayoutBottomContainerProps extends IBaseProps {}

const Container = styled.View`
  width: 100%;
`;

const LayoutBottomContainer: React.FC = ({ children, ...props }) => {
  const {
    location: { pathname },
  } = useLocationContext();
  const isDesktop = useMediaQuery('desktopLarge');
  const FooterComponent = useMobileFooterComponent();

  const mobileRoutesWithoutBottomNavbar = [
    routes.cart,
    routes.rewardsOnboarding,
    routes.signUp,
    new RegExp(`${routes.orderConfirmation}/${UUID_REGEX_STRING}`),
  ];

  if (FooterComponent && !isDesktop) {
    return <FooterComponent />;
  } else if (mobileRoutesWithoutBottomNavbar.some(route => !!pathname.match(route))) {
    return null;
  }

  return (
    <Container testID="container-layout-bottom" {...props}>
      {children}
    </Container>
  );
};

export default LayoutBottomContainer;
