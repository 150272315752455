import { useCallback } from 'react';

import { useLocationContext } from 'state/location';

const useGoBack = () => {
  const { navigate } = useLocationContext();
  // Need to cast as `any` here to be able to pass -1 as a parameter,
  // for the 'goBack' behavior
  return useCallback(() => navigate(-1 as any), [navigate]);
};

export default useGoBack;
