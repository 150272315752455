import * as React from 'react';
import { FC } from 'react';

import { DFPSlotsProvider } from 'react-dfp';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useGoogleAds } from 'hooks/use-google-ads';

/**
 *
 * GoogleAdManager wraps the react-dfp DFPSlotsProvider
 *  to enable conditional rendering based on LD flag
 *
 * When the flag is not enabled, just render children
 *
 */
const GoogleAdManagerProvider: FC<IBaseProps> = ({ children }) => {
  const { isEnabled, campaignId } = useGoogleAds();
  if (!isEnabled || !campaignId) {
    return children ? <>{children}</> : null;
  }
  return (
    <DFPSlotsProvider
      data-testid="google-ad-manager-provider"
      dfpNetworkId={campaignId}
      lazyLoad={true}
      personalizedAds={false}
      collapseEmptyDivs={true}
    >
      {children}
    </DFPSlotsProvider>
  );
};

export default GoogleAdManagerProvider;
