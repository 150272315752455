import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { primitive } from 'styles/constants/primitives';

export const SectionDescription = styled.div`
  text-align: center;
  margin: ${primitive.$spacing2} 0 ${primitive.$spacing6};

  ${Styles.mobileTiny`
    font-size: 0.875rem;
  `}
`;

export const SectionHeader = styled.h2`
  margin: 0;
  text-align: center;
  font: ${brandFont.headerTwo};
`;

export const SectionHeaderWrapper = styled.div`
  padding: 0 ${primitive.$spacing4};
  ${Styles.desktop`
    margin-bottom: ${primitive.$spacing10};
  `}
`;
