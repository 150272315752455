import { useCallback, useMemo } from 'react';

import { useFlag } from 'state/launchdarkly';
import { isNative } from 'utils/environment';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

interface ISalesForceResult {
  handleLogPageView: () => void;
  handleDispatchEvent: (eventName: string) => void;
}

export const useSalesForceInApp = (): ISalesForceResult => {
  const enableSalesForceInAppNotifications = useFlag(
    LaunchDarklyFlag.ENABLE_SALESFORCE_IN_APP_NOTIFICATIONS
  );

  const isInAppEnabled = useMemo(
    () => enableSalesForceInAppNotifications && isNative,
    [enableSalesForceInAppNotifications]
  );
  const handleLogPageView = useCallback(() => {
    if (!isInAppEnabled) {
      return;
    }
  }, [isInAppEnabled]);

  const handleDispatchEvent = useCallback(
    (eventName: string) => {
      if (!isInAppEnabled) {
        return;
      }
      // eslint-disable-next-line no-console
      console.debug(
        `SalesForce In-App ${eventName} handleDispatchEvent triggered - but only works for natives`
      );
    },
    [isInAppEnabled]
  );

  return {
    handleLogPageView,
    handleDispatchEvent,
  };
};
