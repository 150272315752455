import noop from 'utils/noop';

import { IUseWelcomeProps, IWelcomeState } from './types';

const defaultState: IWelcomeState = {
  isLoading: false,
  legacyEmail: null,
  checkLegacy: noop,
  setEmailRead: noop,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useWelcome = (props: IUseWelcomeProps) => defaultState;
