import React, { FC } from 'react';

import Logo from 'components/logo';
import { routes } from 'utils/routing';

import { StyledLogo } from '../styled/mobile-header';

import { LinkNoDecoration } from './styled';

export const HeaderLogo: FC = () => (
  <LinkNoDecoration to={routes.base}>
    <StyledLogo data-testid="mobile-header-logo">
      <Logo />
    </StyledLogo>
  </LinkNoDecoration>
);
