import { gql } from '@apollo/client';

import ImageFragment from './image';

export default gql`
  fragment ImagesFragment on Images {
    app {
      ...ImageFragment
    }
    kiosk {
      ...ImageFragment
    }
    imageDescription
  }
  ${ImageFragment}
`;
