import { QUERY_TYPE } from 'remote/constants';
import { MainMenu } from 'state/main-menu';

import { MenuDataError } from '../exceptions';
import { menu } from '../queries/menus';

export const getMenuDataById = (id: string) => {
  return {
    queryType: QUERY_TYPE.SanityGraphqlQuery,
    maxRetries: 3,
    query: menu,
    variables: { id },
    then: ({ Menu }: { Menu: MainMenu }): MainMenu => Menu,
    catch: (e: Error) => {
      throw new MenuDataError('An error occurred loading menu data', e);
    },
  };
};
