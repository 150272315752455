import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconTiktok = props => (
  <svg viewBox="0 0 40 40" {...props}>
    <title lang="en">Follow us on TikTok</title>
    <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM14.1691 28.3269C14.7632 28.6404 15.4248 28.804 16.0964 28.8037C18.3336 28.8037 20.1599 27.0242 20.2424 24.8063L20.2506 5H25.1923C25.1927 5.42068 25.2318 5.84043 25.3091 6.25395H21.6908V6.25464H26.6331C26.6324 7.91227 27.2332 9.51385 28.3241 10.762L28.3254 10.7636C29.4382 11.4905 30.7388 11.877 32.068 11.8757V12.9764C32.5332 13.076 33.0142 13.1291 33.5089 13.1291V18.072C31.0417 18.0749 28.6362 17.3013 26.6331 15.8609V25.9043C26.6331 30.9193 22.5524 35 17.5366 35C16.2876 35.0003 15.0519 34.7426 13.907 34.2431C12.7629 33.7439 11.7343 33.014 10.8853 32.099L10.8835 32.0977C8.53772 30.4508 7 27.7271 7 24.6502C7 19.6344 11.0807 15.5531 16.0964 15.5531C16.5066 15.5551 16.9161 15.5849 17.3223 15.6424V16.8126C17.3508 16.812 17.3792 16.811 17.4077 16.8101C17.4504 16.8086 17.4933 16.8071 17.5366 16.8071C17.9468 16.809 18.3563 16.8389 18.7624 16.8964V21.9418C18.3749 21.8201 17.9647 21.7493 17.5366 21.7493C16.4351 21.7506 15.3791 22.1888 14.6003 22.9677C13.8215 23.7467 13.3835 24.8028 13.3824 25.9042C13.3825 26.7742 13.6574 27.6219 14.1681 28.3264L14.1691 28.3269ZM9.04018 29.1498C9.46234 30.2498 10.0924 31.2476 10.8815 32.0956C10.0763 31.2339 9.45459 30.2319 9.04018 29.1498Z" />
  </svg>
);

export default IconTiktok;
