import React from 'react';

import { useIntl } from 'react-intl';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';

import { StyledLink } from './styled.default';

const PHONE_NUMBER_REGEX = /\+\d+[\d\s]+/;
const extractPhoneNumber = (message: string) => message.match(PHONE_NUMBER_REGEX)?.[0];

export const SupportLink: React.FC = () => {
  const { formatMessage } = useIntl();
  const { serviceMode } = useServiceModeContext();
  const enableCallSupportLink = useFlag(LaunchDarklyFlag.ENABLE_CALL_SUPPORT_LINK_FOR_DELIVERY);
  const showCallSupport = enableCallSupportLink && serviceMode === ServiceMode.DELIVERY;
  const callSupportLabel = formatMessage({ id: 'callSupport' });
  const phoneNumber = extractPhoneNumber(callSupportLabel);

  return showCallSupport ? (
    <StyledLink to={`tel:${phoneNumber}`}>{callSupportLabel}</StyledLink>
  ) : (
    <StyledLink to={routes.support}>{formatMessage({ id: 'visitSupport' })}</StyledLink>
  );
};
