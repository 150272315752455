import React, { SVGProps } from 'react';

interface LoyaltyLogoPLK {
  title?: string;
}

const LoyaltyLogoPLK = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="200"
    height="auto"
    viewBox="0 0 81 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.06669 3.66498C14.0393 3.90984 17.1828 6.68601 17.1828 9.9771C17.1828 12.7785 15.1844 15.1694 12.6026 16.0444L12.653 16.1416C13.8485 18.5289 14.385 20.8226 16.7723 22.893C17.6257 23.6239 18.1118 23.7932 18.1118 24.0884C18.0866 24.2577 17.7697 24.3801 17.6005 24.4521C16.9667 24.6213 16.5995 25.4999 15.8217 25.4999C14.0897 25.4999 11.9221 21.7227 10.1181 16.3864C8.92266 16.3144 7.85323 15.7059 7.85323 14.5573C7.85323 13.7759 8.58418 13.3402 9.26833 13.3402C9.92726 13.3402 10.8527 13.6067 11.778 14.7553C13.3624 14.0495 14.1653 12.6345 14.1653 10.8305C14.1653 8.17312 12.2389 5.98027 8.73182 5.90825C8.38974 7.5646 7.83163 10.0275 6.97825 15.3386C6.29771 19.6271 6.41653 21.8956 6.19689 23.3071C6.04926 24.2073 5.80801 24.5745 5.51635 24.5745C5.03025 24.5745 5.14907 23.8184 4.54054 23.2819C3.97883 22.7958 3.15066 23.5735 3.15066 22.1836C3.15066 20.9162 5.00144 11.5578 6.68299 6.09909C3.61155 6.80484 2.51692 9.21734 2.68616 11.3886C2.73657 12.1195 2.80858 12.584 2.3945 12.584C1.83278 12.584 1.71036 11.6586 1.10183 11.3166C0.388881 10.9745 0 11.583 0 9.87988C0 7.31975 2.94901 4.02866 7.50756 3.66498C7.89644 2.71439 8.26372 2.15267 8.55538 2.20308C8.99467 2.2751 9.11349 2.7864 9.06669 3.66498Z"
      fill="white"
    />
    <path
      d="M17.2296 16.5558C17.2296 13.5095 19.2028 11.6335 21.9574 11.6335C23.8586 11.6335 25.5401 12.5589 25.5401 14.0208C25.5401 15.4827 24.2475 16.4081 22.7604 16.4081C21.8098 16.4081 20.6899 16.0409 19.8618 15.4827C19.8366 15.652 19.8366 15.8248 19.8366 16.0193C19.8366 18.2121 22.0042 19.6488 24.5175 19.6488C25.9542 19.6488 26.7356 19.2347 27.4413 18.6514C28.3199 17.9457 28.8312 17.1643 29.4145 17.1643C29.6342 17.1643 29.7314 17.4092 29.7314 17.9205C29.7314 18.2121 29.537 19.1375 28.4892 20.2357C27.4665 21.2836 25.929 22.2594 24.053 22.2594C20.8628 22.2594 17.2296 20.4086 17.2296 16.5558ZM19.959 14.6798C20.5675 14.8022 21.0788 14.849 21.5181 14.849C22.7604 14.849 23.2501 14.3125 23.2501 13.776C23.2501 13.2143 22.6163 12.7786 21.9826 12.7786C21.104 12.7786 20.2758 13.2431 19.959 14.6798Z"
      fill="white"
    />
    <path
      d="M30.7072 22.4536C29.2453 22.4536 27.8302 21.0889 27.8302 18.2876C27.8302 16.1667 28.3415 13.7542 28.7556 13.1457C28.9248 12.8792 29.0725 12.7568 29.2417 12.7568C29.8502 12.7568 30.754 13.9271 30.754 14.9497C30.754 15.7778 30.4371 16.9481 30.4371 18.4352C30.4371 19.6054 30.7792 20.3112 31.6326 20.3112C32.7308 20.3112 33.6814 18.7269 33.9983 17.2145C34.1927 16.3144 34.2431 15.6806 34.704 15.6806C35.1433 15.6806 35.435 16.0695 35.5826 16.8257C35.6798 17.1929 35.705 18.338 36.0219 19.7027C36.3136 20.9953 36.8249 21.5786 37.509 21.5786C38.6793 21.5786 39.1185 19.0689 39.1185 17.0453C39.1185 16.1199 38.8989 15.6806 38.3372 15.2413C37.8727 14.8741 37.5558 14.3411 37.5558 13.729C37.5558 13.2645 37.8979 12.512 38.7009 12.512C39.9935 12.512 40.8217 14.4132 40.8217 16.7032C40.8217 19.3354 39.8711 23.3574 37.311 23.3574C34.9705 23.3574 34.9453 20.3832 34.2899 20.3832C33.9011 20.3832 33.0477 22.4536 30.7072 22.4536Z"
      fill="white"
    />
    <path
      d="M51.786 13.0234C51.786 13.1206 51.7356 13.2178 51.5915 13.2178C51.4691 13.2178 51.3467 13.1926 51.2495 13.1926C51.055 13.1926 50.9074 13.4591 50.6877 13.4591C50.5185 13.4591 50.2485 13.2646 49.8596 13.0702C49.91 13.3871 49.9568 13.7543 50.054 14.1684C50.5905 16.4837 51.1522 18.9934 52.3441 18.9934C53.4423 18.9934 53.9032 17.8484 54.6594 17.8484C55.0266 17.8484 55.1239 17.996 55.1239 18.3129C55.1239 18.9214 54.1733 21.0927 52.4917 21.0927C49.8596 21.0927 49.5679 17.6323 49.1034 17.6323C48.8586 17.6323 48.4949 18.3885 47.9836 19.0474C47.3246 19.8756 46.23 20.5345 44.9842 20.5345C43.425 20.5345 41.9127 19.4615 41.9127 17.0994C41.9127 14.0784 44.5701 11.2986 47.98 11.2986C50.1044 11.2914 51.786 12.3644 51.786 13.0234ZM45.5747 17.7007C46.4749 17.7007 47.5011 16.5053 47.7675 13.7039C47.8179 13.2898 48.1348 12.9226 48.4985 12.6309C48.3509 12.6057 48.1816 12.6057 48.0124 12.6057C46.1112 12.6057 44.4548 14.4349 44.4548 16.3109C44.4512 17.1174 44.7681 17.7007 45.5747 17.7007Z"
      fill="white"
    />
    <path
      d="M55.8332 22.6015C55.2967 22.6015 54.3965 22.2846 54.2741 21.7229C53.86 19.9693 53.5899 17.8953 53.4207 16.0193C53.2263 14.1685 53.1542 12.4366 53.1542 11.5832C53.1542 10.6074 53.3739 10.2905 53.7628 10.2905C54.4217 10.2905 55.736 11.1691 55.736 12.3862C55.736 14.1901 55.6856 15.238 55.6856 15.8969C55.6856 16.383 55.7576 16.6531 55.9772 16.6531C56.3661 16.6531 56.5605 15.58 57.7812 13.704C58.5374 12.5338 59.4628 11.8533 60.1937 11.8533C61.3135 11.8533 61.706 12.6814 61.706 13.3872C61.706 14.9715 60.0965 15.1659 59.5132 15.238C58.7102 15.31 57.7092 16.0661 57.0755 17.6757C56.6614 18.7487 56.4165 20.063 56.4165 21.6725C56.4165 22.2594 56.2221 22.6015 55.8332 22.6015Z"
      fill="white"
    />
    <path
      d="M70.6035 22.0397C69.6781 22.0397 68.9471 20.7002 68.5078 18.8458C67.3628 20.0161 66.1925 20.747 65.0727 20.747C63.2687 20.747 61.9041 19.4795 61.9041 17.2363C61.9041 13.1674 65.4868 11.0214 67.9245 10.7045C68.1441 10.6793 68.2918 10.6541 68.4106 10.6325C68.8751 7.2694 69.9229 3.80908 72.1626 3.80908C73.5993 3.80908 74.1106 5.07655 74.1106 6.70769C74.1106 10.0204 72.5263 13.3871 70.6971 16.0912C71.0139 18.1149 71.6477 19.9188 72.7207 20.4301C72.8683 20.5022 72.9151 20.5994 72.9151 20.7218C72.9151 20.9414 72.7675 21.1107 72.5263 21.1107C72.3066 21.1107 71.9429 21.0855 71.6477 21.2079C71.0644 21.4564 71.4064 22.0397 70.6035 22.0397ZM68.1874 13.0486C66.4554 13.2178 63.9241 14.6581 63.9241 17.5099C63.9241 18.3129 64.4354 19.0943 65.2636 19.0943C66.1169 19.0943 67.19 18.2913 68.2126 17.0238C68.1406 16.4405 68.1153 15.8284 68.1153 15.2199C68.1153 14.6113 68.1405 13.8516 68.1874 13.0486ZM70.481 13.9488C71.4064 12.0728 72.8935 9.56308 72.8935 6.58886C72.8935 5.68867 72.5767 5.41862 72.2094 5.41862C71.0391 5.41862 70.4558 10.2184 70.4558 12.8038C70.4558 13.1674 70.4558 13.5599 70.481 13.9488Z"
      fill="white"
    />
    <path
      d="M80.1779 11.9755C80.1779 12.44 80.1059 12.5588 79.3497 12.6596C77.985 12.782 76.6204 13.8047 76.6204 14.9497C76.6204 15.8283 77.9598 16.0947 78.9608 16.8257C79.5442 17.2398 80.0087 17.7763 80.0087 18.7989C80.0087 20.8225 77.0849 23.0154 74.7444 23.0154C74.2331 23.0154 74.4779 22.6985 73.9882 22.6985C73.5021 22.6985 72.5011 22.6733 72.5011 22.234C72.5011 21.8199 73.1852 21.8199 73.6713 21.6723C74.7912 21.3302 77.0596 20.1384 77.0596 18.554C77.0596 17.6286 75.695 17.3118 74.7696 16.606C74.3555 16.2892 74.0134 15.8031 74.0134 15.1189C74.0134 13.8011 74.964 12.656 76.1595 11.8278C77.1605 11.1221 78.2803 10.708 79.1805 10.708C79.7638 10.708 80.1779 11.4858 80.1779 11.9755Z"
      fill="white"
    />
    <path
      d="M48.7038 7.60431H50.911V5.73912L53.4928 0.845703H51.1487L49.82 3.7083L48.5489 0.845703H46.248L48.7038 5.73552V7.60431Z"
      fill="white"
    />
    <path
      d="M22.4651 0.510863C21.6729 0.474855 20.3262 0.54687 19.7501 0.590079L19.8005 2.30044L20.3514 2.26443V7.59714H22.4723V6.26486C25.5689 6.11723 26.2927 4.46449 26.2927 3.30144C26.2891 1.6487 24.9424 0.626087 22.4651 0.510863ZM22.4687 4.60492V2.27523C23.819 2.34004 24.143 2.91616 24.143 3.38786C24.143 4.054 23.6965 4.57971 22.4687 4.60492Z"
      fill="white"
    />
    <path
      d="M56.1069 0.593628C54.0616 0.593628 52.6429 2.07714 52.6429 4.12236C52.6429 6.16758 54.0076 7.6907 56.3049 7.6907C57.2627 7.6907 58.0909 7.48186 58.7894 7.01016L58.0261 5.44023C57.5256 5.7607 57.0035 5.93714 56.3949 5.93714C55.5487 5.93714 55.0194 5.53385 54.8142 4.83171H59.3223C59.3619 4.63007 59.3943 4.23398 59.3943 3.88111C59.3799 2.43001 58.613 0.593628 56.1069 0.593628ZM54.7926 3.40221C54.9582 2.72167 55.3903 2.25357 56.1141 2.25357C56.8018 2.25357 57.3059 2.66406 57.3563 3.40221H54.7926Z"
      fill="white"
    />
    <path
      d="M36.2236 0.579222C35.4386 0.543215 34.1063 0.61523 33.5374 0.658439L33.5842 2.35439L34.1279 2.32198V7.60068H36.2272V6.28281C39.295 6.13518 40.0115 4.50044 40.0115 3.3482C40.0079 1.70626 38.6757 0.694446 36.2236 0.579222ZM36.2236 4.63366V2.32919C37.5594 2.3904 37.8835 2.96292 37.8835 3.43102C37.8835 4.08995 37.4406 4.60846 36.2236 4.63366Z"
      fill="white"
    />
    <path
      d="M63.0923 3.58225C62.7143 3.43102 62.2606 3.26178 61.9617 3.12495C61.67 2.99172 61.5692 2.8621 61.5692 2.66046C61.5692 2.394 61.8429 2.24997 62.1921 2.24997C62.7251 2.24997 63.1427 2.49482 63.5172 2.89811L64.6911 1.67745C64.187 1.00051 63.3228 0.572021 62.2173 0.572021C60.723 0.572021 59.5528 1.29217 59.5528 2.71807C59.5528 3.8415 60.0965 4.38161 61.1479 4.83891C61.4468 4.96854 61.8465 5.08376 62.1453 5.20979C62.4082 5.32141 62.5702 5.43663 62.5702 5.65268C62.5702 5.85072 62.3974 6.01275 61.8897 6.01275C61.4252 6.01275 60.7518 5.82551 60.2657 5.50865L59.5528 6.97055C60.2333 7.46385 61.0543 7.6655 61.8933 7.6655C63.1968 7.6655 64.5938 7.10018 64.5938 5.58426C64.5974 4.43562 63.8665 3.89191 63.0923 3.58225Z"
      fill="white"
    />
    <path
      d="M30.0411 0.636841C28.0931 0.636841 26.4763 2.08074 26.4763 4.13317C26.4763 6.18199 28.0967 7.6295 30.0411 7.6295C31.9891 7.6295 33.6058 6.18559 33.6058 4.13317C33.6094 2.08434 31.9891 0.636841 30.0411 0.636841ZM30.0591 5.7535C29.1121 5.7535 28.5143 5.02615 28.5143 4.11876C28.5143 3.21138 29.1157 2.48403 30.0591 2.48403C31.0061 2.48403 31.6038 3.21138 31.6038 4.11876C31.6038 5.02615 31.0025 5.7535 30.0591 5.7535Z"
      fill="white"
    />
    <path
      d="M43.6411 0.593628C41.5959 0.593628 40.1772 2.07714 40.1772 4.12236C40.1772 6.16758 41.5131 7.6907 43.8571 7.6907C44.905 7.6907 45.8232 7.43505 46.5577 6.86613L45.7295 5.35382C45.1894 5.72829 44.6097 5.94074 43.9436 5.94074C43.0614 5.94074 42.5393 5.53745 42.3412 4.83531H46.853C46.8926 4.63367 46.925 4.23758 46.9214 3.88471C46.9142 2.43001 46.1472 0.593628 43.6411 0.593628ZM42.3268 3.40221C42.4925 2.72167 42.9246 2.25357 43.6483 2.25357C44.336 2.25357 44.8329 2.65686 44.8906 3.40221H42.3268Z"
      fill="white"
    />
  </svg>
);

export default LoyaltyLogoPLK;
