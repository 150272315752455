import { useEffect, useState } from 'react';

import { useThLoyaltyContext } from 'state/loyalty';
import { ILoyaltyCategory } from 'state/loyalty/types';

export const useLoyaltyCategories = () => {
  const { getLoyaltyCategory } = useThLoyaltyContext();

  const [loyaltyCategories, setLoyaltyCategories] = useState<ILoyaltyCategory[]>([]);

  useEffect(() => {
    getLoyaltyCategory().then((availableLoyaltyCategories: Array<ILoyaltyCategory> | null) =>
      setLoyaltyCategories(availableLoyaltyCategories || [])
    );
  }, [getLoyaltyCategory, setLoyaltyCategories]);

  return loyaltyCategories;
};
