import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
`;

export const plopDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  } 50% {
    transform: translateY(5px);
  } 100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const plopUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  } 50% {
    transform: translateY(5px);
  } 100% {
    transform: translateY(-10px);
    opacity: 0;
  }
`;

export const popIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95)
  } 50% {
    opacity: 1;
    transform: scale(1.10)
  } 100% {
    opacity: 1;
    transform: scale(1)
  }
`;

export const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }`;

export const slideIn = keyframes`
  from {
    transform: translateY(-12px);
    opacity: 0;
  } to {
    transform: translateY(0);
    opacity: 1;
  }
`;
