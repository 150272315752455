import { IPriceOrderParams } from 'hooks/price-order/types';
import { IPriceOrderDeliveryInput } from 'remote/queries/order';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { ServiceMode } from 'state/order';

export const buildPriceDeliveryInput = (
  order: Pick<
    IPriceOrderParams,
    'deliveryAddress' | 'orderPhoneNumber' | 'serviceMode' | 'tipCents' | 'deliveryInstructions'
  >,
  user: UserDetails,
  quoteId: string
): IPriceOrderDeliveryInput | null => {
  if (order.serviceMode !== ServiceMode.DELIVERY || !order.deliveryAddress) {
    return null;
  }

  const usersPhoneNumber = order.orderPhoneNumber || user.details.phoneNumber || '';
  const phoneNumber = usersPhoneNumber.length === 10 ? `+1${usersPhoneNumber}` : usersPhoneNumber;

  return {
    dropoff: {
      ...order.deliveryAddress,
      phoneNumber,
    },
    instructions: order.deliveryInstructions,
    quoteId,
    tipCents: order.tipCents,
  };
};
