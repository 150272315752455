import useDialogModal from 'hooks/use-dialog-modal';
import { routes } from 'utils/routing';

interface IUseOfferUnavailableModalProps {
  onCancelRedemption(): void;
  navigate: (to: string) => void;
}

export default function useOfferUnavailableModal({
  onCancelRedemption,
  navigate,
}: IUseOfferUnavailableModalProps) {
  return useDialogModal({
    showCancel: true,
    allowDismiss: false,
    onConfirm: () => {
      onCancelRedemption();
    },
    onCancel: () => {
      onCancelRedemption();
      navigate(routes.base);
    },
    modalAppearanceEventMessage: 'Cancel Redemption: Offer unavailable',
  });
}
