import { capitalize } from 'lodash';

import {
  ICartEntry,
  ICombo,
  IComboSlot,
  IItemOption,
  IPickerOption,
  MenuObject,
} from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { EnablePremiumComboSlotsVariations } from 'state/launchdarkly/variations';
import { useMenuContext } from 'state/menu';
import { useOffersContext } from 'state/offers';
import { useUIContext } from 'state/ui';
import { CartEntryType, ComboSlotUiPattern } from 'utils/cart/types';
import { maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';

/**
 * This hook takes a cart entry item and returns description
 * composed of comboSlot & itemOption selections.
 *
 * Return type can be either in the form of selection names,
 * or a single string containing comma separated selection names
 * depending on options specified.
 *
 * Note: includeModifiers also controls if we show comboSlot items.
 */
export function useComposeDescription(
  item: ICartEntry,
  options: {
    includeModifiers?: boolean;
    returnTypeArray: true;
    includeQuantity?: boolean;
  }
): string[];
export function useComposeDescription(
  item: ICartEntry,
  options?: {
    includeModifiers?: boolean;
    returnTypeArray?: false;
    includeQuantity?: boolean;
  }
): string;
export function useComposeDescription(
  item: ICartEntry,
  options: {
    includeModifiers?: boolean;
    returnTypeArray?: boolean;
    includeQuantity?: boolean;
  } = {}
): string | string[] {
  const enablePremiumComboSlotsVariation = useFlag<EnablePremiumComboSlotsVariations>(
    LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  );
  const includeAllPrices =
    enablePremiumComboSlotsVariation !== EnablePremiumComboSlotsVariations.NONE;

  const { includeModifiers = false, returnTypeArray = false, includeQuantity = false } = options;
  const menuCtx = useMenuContext();
  const { formatCurrencyForLocale } = useUIContext();
  const { isSelectedOfferCartEntry } = useOffersContext();
  const menuIdentifier = !isSelectedOfferCartEntry(item)
    ? maybeMapCartEntryToMenuObjectIdentifier(item)
    : null;

  const { data }: { data?: MenuObject | null; loading?: boolean } = menuIdentifier
    ? menuCtx?.getMenuObject?.(menuIdentifier) || {}
    : {};

  const itemPricesToIgnore = new Set<string>();
  ((data as ICombo)?.mainItem?.options || []).forEach((option: IItemOption) => {
    if (option.allowMultipleSelections) {
      option.options.forEach(opt => itemPricesToIgnore.add(opt._key));
    }
  });
  (data?.options || []).forEach((option: IComboSlot | IItemOption | MenuObject | IPickerOption) => {
    if ((option as IItemOption).allowMultipleSelections) {
      (option as IItemOption).options.forEach(opt => itemPricesToIgnore.add(opt._key));
    }
  });
  const description = (function recursiveDesc(currentItem): string[] {
    return (currentItem.children || []).reduce<string[]>((desc, child) => {
      if (
        (child.type === CartEntryType.comboSlot && child.uiPattern === ComboSlotUiPattern.HIDDEN) ||
        child.uiPattern === ComboSlotUiPattern.COLLAPSED
      ) {
        return desc;
      }
      if (
        child.type === CartEntryType.item ||
        (includeModifiers && child.type === CartEntryType.itemOptionModifier)
      ) {
        const itemName = (child.name || '').split(' ').map(capitalize).join(' ');
        const displayName = includeQuantity ? `${child.quantity || ''} ${itemName}` : itemName;

        const shouldIgnorePrice = itemPricesToIgnore?.has(child._id);
        const price = shouldIgnorePrice ? 0 : (child.price || 0) * item.quantity;
        desc.push(
          (child.type === CartEntryType.itemOptionModifier || includeAllPrices) && price > 0
            ? displayName.concat(` + ${formatCurrencyForLocale(price)}`)
            : displayName
        );
      }

      return desc.concat(recursiveDesc(child));
    }, []);
  })(item);

  return returnTypeArray ? description : description.join(', ');
}
