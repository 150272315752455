import React, { FC } from 'react';

import styled, { css } from 'styled-components';

/** Basic inline wrapper to contain a list of items */
const Wrapper = styled.div`
  position: absolute;
  bottom: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

type ColorType = 'primary' | 'secondary' | 'inverted';

interface IDotProps {
  /** Changes the dot color to mark that it's active */
  isActive: boolean;
  /** Changes the dot color to mark that it's active */
  color: ColorType;
}

const colors = {
  primary: Styles.color.primary,
  seconary: Styles.color.secondary,
  inverted: Styles.color.white,
};

const getColor = (color: ColorType) => {
  return colors[color];
};

const DOT_SIZE = `0.7rem`;
/** Circles used to note an active or inactive state. */
const Dot = styled.div<IDotProps>`
  display: inline-block;
  width: ${DOT_SIZE};
  height: ${DOT_SIZE};
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.45);

  &:not(:last-child) {
    margin-right: ${DOT_SIZE};
  }

  ${({ isActive, color }) =>
    isActive &&
    css`
      background-color: ${getColor(color)};
    `}
`;

interface IProgressDotsProps {
  /** How many dots shot up */
  dotCount: number;
  /** The color of the active dot
   *
   *  default 'primary'
   */
  color?: ColorType;
  /** in index of the currently active dot */
  activeDotIndex: number;
}

/**
 * Displays a dot(circle) based stepped progress indicator
 */
export const ProgressDots: FC<IProgressDotsProps> = ({
  dotCount = 4,
  activeDotIndex = 0,
  color = 'primary',
}) => {
  const dots = Array.from(Array(dotCount).keys());

  return (
    <Wrapper>
      {dots.map(dotIndex => (
        <Dot isActive={dotIndex === activeDotIndex} key={dotIndex} color={color} />
      ))}
    </Wrapper>
  );
};

export default ProgressDots;
