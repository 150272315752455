import { IEncryptCreditArgs } from './types';

const ALGORITHM_PKCS = 'RSA/NONE/PKCS1Padding';

const encrypt = (publicKey: string, algorithm?: string | null) => {
  /**
   * Currently the encryption PKCS is used by FD and THDigital.
   * If the algorithm is undefined or equals to ${algorithmType.PKCS}
   * the current solution will be used, otherwise the new encryption
   * will be defined as the encryption function.
   */
  if (!algorithm || algorithm === ALGORITHM_PKCS) {
    return import('node-rsa').then(({ default: NodeRSA }) => {
      const key = new NodeRSA(`-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`);
      key.setOptions({ encryptionScheme: 'pkcs1' });
      return (field: string) => key.encrypt(field, 'base64');
    });
  }

  // New encrypt key generator
  // @ts-ignore
  return import('node-forge').then(({ default: NodeForge }) => {
    const pem = `-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`;
    const forgePublicKey = NodeForge.pki.publicKeyFromPem(pem);
    return (field: string) => {
      const encryptedValue = NodeForge.util.encode64(
        forgePublicKey.encrypt(field, 'RSA-OAEP', {
          md: NodeForge.md.sha512.create(),
        })
      );
      return 'ENC_[' + encryptedValue + ']';
    };
  });
};

export const encryptCredit = ({
  credit,
  fdPublicKey,
  isUsingTokenEx = false,
  algorithm = undefined,
}: IEncryptCreditArgs) =>
  encrypt(fdPublicKey, algorithm).then(encryptWithKey => {
    const expiryMonth = credit.expiryDate?.month ?? '';
    const expiryYear = credit.expiryDate?.year ?? '';

    return {
      ...credit,
      cardNumber: encryptWithKey(credit.cardNumber),
      expiryDate: {
        month: isUsingTokenEx ? expiryMonth : encryptWithKey(expiryMonth),
        year: isUsingTokenEx ? expiryYear : encryptWithKey(expiryYear),
      },
      securityCode: encryptWithKey(credit.securityCode ?? ''),
    };
  });

export { getStoredPrepaidCard, storePrepaidCard } from './store-prepaid-card';
