import React, { FC, useCallback, useContext, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IMainMenuView, MenuObject } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import {
  CaloriesFragmentDoc,
  Channel,
  ICaloriesFragment,
  IPricingAndAvailabilityFragment,
  IStoreMenuQuery,
  PosDataServiceMode,
  PricingAndAvailabilityFragmentDoc,
  ServiceMode,
  useStoreMenuLazyQuery,
} from 'generated/graphql-gateway';
import {
  ComboFragmentDoc,
  IGetComboWithVendorConfigQuery,
  IGetItemWithVendorConfigQuery,
  IGetMenuSectionsQuery,
  IGetPickerWithVendorConfigQuery,
  ItemFragmentDoc,
  PickerWithVendorConfigFragmentDoc,
  ProductListSectionFragmentDoc,
  useGetComboWithVendorConfigLazyQuery,
  useGetItemWithVendorConfigLazyQuery,
  useGetMenuSectionsLazyQuery,
  useGetPickerWithVendorConfigLazyQuery,
} from 'generated/sanity-graphql';
import { useServiceModeContext } from 'state/service-mode';

import { IUseDayPart } from './types';
import { useDayPartSelector } from './use-day-part-selector';

export type MainMenu = IMainMenuView | IGetMenuSectionsQuery;
export interface IMainMenuContext extends IUseDayPart {
  getStoreMenu: (region: string, storeId: string) => void;
  getMenuObject(id: string): IMenuObjectState;
  getMainMenu(menuId?: string, region?: string, storeId?: string | null): IState;
  checkIfUserIsOnMainMenuPath: () => boolean;
  getPricingAndAvailability: (id: string) => IPricingAndAvailabilityFragment | null;
  getCalories: (item: MenuObject) => ICaloriesFragment['calories'] | null;
  isStaticMenu: boolean;
  data: IGetMenuSectionsQuery | undefined;
  loading: boolean;
  hasError: boolean;
  getSection: (id: string) => Promise<void>;
  getPickerDetail: (id: string) => Promise<void>;
  getItemDetail: (id: string) => Promise<void>;
  getComboDetail: (id: string) => Promise<void>;
  pickerLoading: boolean;
  pickerData: IGetPickerWithVendorConfigQuery | undefined;
  comboLoading: boolean;
  comboData: IGetComboWithVendorConfigQuery | undefined;
  itemLoading: boolean;
  itemData: IGetItemWithVendorConfigQuery | undefined;
  storeMenuLoading: boolean;
  storeMenuCalled: boolean;
  storeMenuData: IStoreMenuQuery | undefined;
  setIsOffer: (isOffer: boolean) => void;
  isOffer: boolean;
}

interface IMenuObjectState {
  data: MenuObject | null;
  loading: boolean;
}
interface IState {
  data: IGetMenuSectionsQuery['Menu'] | undefined;
  loading: boolean;
}

export const MainMenuContext = React.createContext<IMainMenuContext>({
  data: undefined,
  loading: false,
  getStoreMenu: () => null,
  getMenuObject: () => ({ data: {} as MenuObject, loading: false }),
  getMainMenu: () => ({ data: null, loading: false }),
  getDayPart: () => ({ currentDayPart: null, dayParts: [] }),
  setDayPart: () => null,
  breakfastInterval: undefined,
  mainMenuInterval: undefined,
  checkIfUserIsOnMainMenuPath: () => false,
  getPricingAndAvailability: () => null,
  getCalories: () => null,
  isStaticMenu: false,
  hasError: false,
  getSection: () => Promise.resolve(),
  getPickerDetail: () => Promise.resolve(),
  getItemDetail: () => Promise.resolve(),
  getComboDetail: () => Promise.resolve(),
  pickerLoading: false,
  pickerData: undefined,
  comboLoading: false,
  comboData: undefined,
  itemLoading: false,
  itemData: undefined,
  storeMenuLoading: false,
  storeMenuCalled: false,
  storeMenuData: undefined,
  setIsOffer: isOffer => isOffer,
  isOffer: false,
});

export const useMainMenuContext = () => useContext(MainMenuContext);

const matchSanityIdFromPath = (id: string) => {
  const [, sanityId] = id.split(/-(.*)/);

  if (!sanityId) {
    return '';
  }

  return sanityId;
};

// @TODO:
//
// Do we throw this into one giant reducer?
// - reducer just for loading state?
//   - we need to possibly trigger loading from 2 places
//     - maybe I can refactor menu-options > InnerContent to do what I want?

export const MainMenuProvider: FC<IBaseProps> = ({ children }) => {
  const client = useApolloClient();
  const { setDayPart, getDayPart, breakfastInterval, mainMenuInterval } = useDayPartSelector();
  const [getMenu, { loading, error, data }] = useGetMenuSectionsLazyQuery();
  const { serviceMode } = useServiceModeContext();
  const [getPicker, { loading: pickerLoading, error: pickerError, data: pickerData }] =
    useGetPickerWithVendorConfigLazyQuery();
  const [getCombo, { loading: comboLoading, error: comboError, data: comboData }] =
    useGetComboWithVendorConfigLazyQuery();
  const [getItem, { loading: itemLoading, error: itemError, data: itemData }] =
    useGetItemWithVendorConfigLazyQuery();
  const [
    getStoreMenuLazy,
    {
      loading: storeMenuLoading,
      error: storeMenuError,
      called: storeMenuCalled,
      data: storeMenuData,
    },
  ] = useStoreMenuLazyQuery();
  const getStoreMenu = useCallback(
    (region: string, storeId: string) =>
      getStoreMenuLazy({
        variables: {
          channel: Channel.WHITELABEL,
          region,
          storeId,
          serviceMode:
            ServiceMode.DELIVERY === serviceMode
              ? PosDataServiceMode.DELIVERY
              : PosDataServiceMode.PICKUP,
        },
      }),
    [getStoreMenuLazy, serviceMode]
  );
  const [isOffer, setIsOffer] = useState(false);

  const getMainMenu = useCallback(
    (menuId?: string, region?: string, storeId?: string | null) => {
      if (menuId && !storeId) {
        getMenu({ variables: { id: menuId } });
      }

      if (menuId && storeId && region) {
        // this should be awaited but it really screws up the types and it works anyways
        Promise.all([getStoreMenu(region, storeId), getMenu({ variables: { id: menuId } })]);
      }

      return {
        loading,
        data: data?.Menu,
      };
    },
    [data, getMenu, getStoreMenu, loading]
  );
  const getPickerDetail = useCallback(
    async (id: string) => {
      const sanityId = matchSanityIdFromPath(id);

      await getPicker({ variables: { id: sanityId } });
    },
    [getPicker]
  );
  const getComboDetail = useCallback(
    async (id: string) => {
      const sanityId = matchSanityIdFromPath(id);

      await getCombo({ variables: { id: sanityId } });
    },
    [getCombo]
  );
  const getItemDetail = useCallback(
    async (id: string) => {
      const sanityId = matchSanityIdFromPath(id);

      await getItem({ variables: { id: sanityId } });
    },
    [getItem]
  );

  const getCachedSection = (id: string) =>
    client.readFragment({
      id,
      fragmentName: 'ProductListSectionFragment',
      fragment: ProductListSectionFragmentDoc,
    });

  const getCachedPicker = (id: string) =>
    client.readFragment({
      id,
      fragmentName: 'PickerWithVendorConfigFragment',
      fragment: PickerWithVendorConfigFragmentDoc,
    });

  const getCachedCombo = (id: string) =>
    client.readFragment({
      id,
      fragmentName: 'ComboFragment',
      fragment: ComboFragmentDoc,
    });

  const getCachedItem = (id: string) =>
    client.readFragment({
      id,
      fragmentName: 'ItemFragment',
      fragment: ItemFragmentDoc,
    });

  const getPricingAndAvailability = (id: string) => {
    if (storeMenuLoading || !storeMenuData) {
      return null;
    }

    const pricingAndAvail = client.readFragment({
      id: `Entity:${id}`,
      fragmentName: 'PricingAndAvailability',
      fragment: PricingAndAvailabilityFragmentDoc,
    });

    return pricingAndAvail;
  };

  const getCalories = (item: MenuObject) => {
    if (!item || !item._id) {
      return null;
    }

    const calorieFragment = client.readFragment({
      id: `Entity:${item._id}`,
      fragmentName: 'Calories',
      fragment: CaloriesFragmentDoc,
    });

    return calorieFragment?.calories || null;
  };

  const getMenuObject = (id: string) => {
    const [type, sanityId] = id.split(/-(.*)/);

    switch (type) {
      case MenuObjectTypes.SECTION:
        return { loading: false, data: getCachedSection(`Section:${sanityId}`) as MenuObject };
      case MenuObjectTypes.PICKER:
        return {
          loading: pickerLoading,
          data: ((pickerData &&
            pickerData?.Picker?._id === sanityId &&
            getCachedPicker(`Picker:${sanityId}`)) ||
            pickerData?.Picker) as MenuObject,
        };
      case MenuObjectTypes.COMBO:
        return {
          loading: comboLoading,
          data: ((comboData && getCachedCombo(`Combo:${sanityId}`)) ||
            comboData?.Combo) as MenuObject,
        };
      case MenuObjectTypes.ITEM:
        return {
          loading: itemLoading,
          data: ((itemData && getCachedItem(`Item:${sanityId}`)) || itemData?.Item) as MenuObject,
        };
      default:
        return { loading: false, data: {} as MenuObject };
    }
  };

  const hasError = Boolean(error || pickerError || comboError || itemError || storeMenuError);

  return (
    <MainMenuContext.Provider
      value={{
        data,
        getStoreMenu,
        getMainMenu,
        getMenuObject,
        getPickerDetail,
        getComboDetail,
        getItemDetail,
        getSection: getCachedSection,
        hasError,
        loading,
        pickerLoading,
        pickerData,
        comboLoading,
        comboData,
        itemLoading,
        itemData,
        storeMenuCalled,
        storeMenuLoading,
        storeMenuData,
        setDayPart,
        getDayPart,
        breakfastInterval,
        mainMenuInterval,
        getPricingAndAvailability,
        getCalories,
        isStaticMenu: !storeMenuData && !storeMenuLoading && !storeMenuCalled,
        checkIfUserIsOnMainMenuPath: () => false,
        isOffer,
        setIsOffer,
      }}
    >
      {children}
    </MainMenuContext.Provider>
  );
};

// MainMenuContext.Provider.whyDidYouRender = true;

export default MainMenuContext.Consumer;
