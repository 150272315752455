import { useEffect, useMemo, useState } from 'react';

import { useThLoyaltyContext } from 'state/loyalty';
import { ILoyaltyTier } from 'state/loyalty/types';

export const useBankMyRewardsTierId = () => {
  // NOTE: this was a poorly named function, renaming here to be more explicity
  const { getLoyaltyTier: getBankMyRewardsTierId } = useThLoyaltyContext();

  const [bankMyRewardTier, setBankMyRewardTier] = useState<ILoyaltyTier | null>(null);

  useEffect(() => {
    getBankMyRewardsTierId().then((loyaltyTier: ILoyaltyTier) => {
      if (!loyaltyTier) {
        setBankMyRewardTier(null);
        return;
      }

      setBankMyRewardTier(loyaltyTier ?? null);
    });
  }, [getBankMyRewardsTierId, setBankMyRewardTier]);

  const bankMyRewardTierId = useMemo(() => bankMyRewardTier?._id ?? null, [bankMyRewardTier]);

  return { bankMyRewardTier, bankMyRewardTierId };
};
