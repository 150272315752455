import { ServiceMode } from 'state/service-mode';
import { IRoutesRelevantToServiceModeHeaderDisplay } from 'utils/routing/types';

// should return true (to show service mode header) if there is a service mode selected
// and the user is not on the store locator view
export const showServiceModeHeader = (
  pathname: string,
  serviceMode: ServiceMode | null,
  serviceModeHeaderAncillaryRoutes: IRoutesRelevantToServiceModeHeaderDisplay
) => Boolean(serviceMode) && !pathname.includes(serviceModeHeaderAncillaryRoutes.default);
