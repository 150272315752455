import React, { createContext, useContext, useEffect } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { IUseLoyaltyOffers, IUseLoyaltyRewards } from './hooks/types';
import { useGetOffersIncentivesIds } from './hooks/use-get-offers-incentives';
import { useIsLoyaltyEnabled } from './hooks/use-is-loyalty-enabled';
import { useLoyaltyOffers } from './hooks/use-loyalty-offers';
import { useLoyaltyRewards } from './hooks/use-loyalty-rewards';
import { useLoyaltyUserState } from './hooks/use-loyalty-user-state';
import { InRestaurantRedemptionProvider } from './in-restaurant-redemption';
import { ThLoyaltyProvider } from './th-loyalty-context';

// Leaving as an interface to be extended in future PR
export interface ILoyaltyContext extends IUseLoyaltyRewards, IUseLoyaltyOffers {
  claimPointsEnabled: boolean;
  refetchLoyaltyUser: () => void;
}

export const LoyaltyContext = createContext({} as ILoyaltyContext);
export const useLoyaltyContext = () => useContext(LoyaltyContext);

export const LoyaltyProvider = (props: IBaseProps) => {
  const claimPointsEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_CLAIM_LOYALTY_POINTS));
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const {
    loading: loadingLoyaltyUser,
    loyaltyUser,
    refetch: refetchLoyaltyUser,
  } = useLoyaltyUserState();
  const loyaltyUserId = loyaltyUser?.id;
  const prevLoyaltyUserId = usePrevious(loyaltyUserId);
  const loyaltyUserPoints = loyaltyUser?.points ?? 0;

  const loyaltyRewards = useLoyaltyRewards(loyaltyUser);
  const loyaltyOffers = useLoyaltyOffers();
  const { fetchIncentiveIds } = useGetOffersIncentivesIds();

  const dispatch = useAppDispatch();
  const shouldRefetch = useAppSelector(selectors.loyalty.selectShouldRefetchRewards);

  const { refetchRewards, engineRewardsMap } = loyaltyRewards;

  useEffectOnce(() => {
    if (loyaltyEnabled) {
      fetchIncentiveIds().then(ids => dispatch(actions.loyalty.setIncentivesIds(ids || [])));
    }
  });

  useEffectOnUpdates(() => {
    if (!loyaltyEnabled) {
      dispatch(actions.loyalty.resetLoyaltyRewardsState(loyaltyUserPoints));
    }
  }, [dispatch, loyaltyEnabled, loyaltyUserPoints]);

  // Reset rewards on loyalty user change
  useEffect(() => {
    if (prevLoyaltyUserId && !loadingLoyaltyUser && prevLoyaltyUserId !== loyaltyUserId) {
      dispatch(actions.loyalty.resetLoyaltyRewardsState(loyaltyUserPoints));
    }
    if (loyaltyUserId) {
      dispatch(actions.loyalty.setShouldRefetchRewards(true));
    }
  }, [dispatch, loadingLoyaltyUser, loyaltyUserId, loyaltyUserPoints, prevLoyaltyUserId]);

  useEffect(() => {
    if (shouldRefetch && loyaltyUserId) {
      refetchRewards(loyaltyUserId);
      dispatch(actions.loyalty.setShouldRefetchRewards(false));
    }
  }, [dispatch, loyaltyUserId, refetchRewards, shouldRefetch]);

  useEffect(() => {
    const engineRewardsList = Object.values(engineRewardsMap);
    if (engineRewardsList?.length) {
      dispatch(actions.loyalty.createAvailableLoyaltyRewardsMap(engineRewardsList));
    }
  }, [dispatch, engineRewardsMap, shouldRefetch]);

  return (
    <LoyaltyContext.Provider
      value={{
        claimPointsEnabled,
        refetchLoyaltyUser,
        // other loyalty hooks
        ...loyaltyOffers,
        ...loyaltyRewards,
      }}
    >
      <InRestaurantRedemptionProvider>
        {/* To be deprecated and replaced with generic LoyaltyContext after api migration is complete */}
        <ThLoyaltyProvider>{props.children}</ThLoyaltyProvider>
      </InRestaurantRedemptionProvider>
    </LoyaltyContext.Provider>
  );
};
