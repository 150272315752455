import React, { FC } from 'react';

import styled from 'styled-components';

import { RawPicture } from 'components/raw-picture';
import { INavigationTabFragment } from 'generated/sanity-graphql';
import { useAccessibility } from 'state/accessibility';
import { routes } from 'utils/routing';

import { NAV_BAR_ICON_SIZE } from './constants';
import { NavLink } from './helpers';
import { IconWrap, NavLinkText } from './styled';

export const IconWrapBase = styled(IconWrap)`
  position: relative;
  & img {
    max-width: 20px;
  }
`;

const IconWrapOverlay = styled(IconWrap)`
  position: absolute;
  top: 0;
  left: 0;
`;

const NavLinkWithPictureIcon = styled(NavLink)`
  ${IconWrapOverlay} {
    opacity: 0;
  }
  &.active {
    ${IconWrapBase} > img {
      opacity: 0;
    }
    ${IconWrapOverlay} {
      opacity: 1;
    }
  }
`;

type SanityNavMobileTabProps = (
  | INavigationTabFragment
  | INavigationTabFragment['launchDarklyAlternateMobileTab']
) & { forceExactMatch?: boolean };

export const SanityNavMobileTab: FC<SanityNavMobileTabProps> = ({
  _key,
  tabIcon,
  tabIconActive,
  tabLink,
  forceExactMatch = false,
}) => {
  const route = tabLink?.link?.locale || routes.base;
  const requiresExactMatch = forceExactMatch || route === routes.base || route === routes.account;

  const { isFullPageModalActive } = useAccessibility();

  return (
    <NavLinkWithPictureIcon
      to={route}
      requiresExactMatch={requiresExactMatch}
      hidden={isFullPageModalActive}
      data-testid="nav-with-picture-icon"
      navBarText={tabLink?.text?.locale ?? ''}
      componentKey={_key ?? ''}
    >
      <IconWrapBase>
        {tabIcon?.locale && (
          <RawPicture
            image={tabIcon?.locale}
            alt=""
            aria-hidden
            height={NAV_BAR_ICON_SIZE}
            width={NAV_BAR_ICON_SIZE}
          />
        )}
        <IconWrapOverlay>
          {tabIconActive?.locale && (
            <RawPicture
              image={tabIconActive?.locale}
              alt=""
              aria-hidden
              height={NAV_BAR_ICON_SIZE}
              width={NAV_BAR_ICON_SIZE}
            />
          )}
        </IconWrapOverlay>
      </IconWrapBase>
      <NavLinkText aria-hidden={isFullPageModalActive}>{tabLink?.text?.locale}</NavLinkText>
    </NavLinkWithPictureIcon>
  );
};
