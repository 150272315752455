import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import LocalStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';

// return the current user session
export const getCurrentSession = (): Promise<CognitoUserSession | null> => {
  return Auth.currentSession().catch(() => {
    // if cognito failed to get a valid session
    // clear local storage to prevent a user with
    // no session from being "logged in" in app state
    LocalStorage.setItem(StorageKeys.USER, null);
    // remove USER_SIGNED_IN_SUCCESSFULLY to avoid mis-trigged unexpected sign outs events
    LocalStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);
    return null;
  });
};
