import { ApolloLink, Operation, createHttpLink, from, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { sha256 } from 'js-sha256';
import { isNil } from 'lodash';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { getGraphqlApiUrl, getGraphqlGatewayApiUrl } from 'remote/constants';

import {
  AUTH_REQUIRED_DIRECTIVE,
  isRequestAuthProtected,
  isUserLoggedIn,
  stripAuthDirective,
} from './auth-required-directive';
import { cancelRequestLink } from './cancel-request-link';
import { sanityFetch } from './sanity-fetch';
import { isGatewayRequest, stripGatewayDirective } from './strip-gateway-directive';
import { removeNestedTypenameFromMenuQueriesLink, stripTypenameLink } from './strip-typename';
import { isCacheRequest, stripUseCacheDirective } from './strip-useCache-directive';
import { withAuthToken } from './with-auth-token';
import { withClientInfo } from './with-client-info-headers';
import { withErrorLogger } from './with-error-logger';
import { withErrorMParticle } from './with-error-mparticle';
import { withForterHeaders } from './with-forter-headers';
import { withHeaders } from './with-headers';
import { withI18nContext } from './with-i18n-context';
import { withI18nHeaders } from './with-i18n-headers';
import { withLocalizedQueries } from './with-localized-queries';
import { withLogger } from './with-logger';
import { withMParticle } from './with-mparticle';
import { withSessionId } from './with-session-id';
import { withDateTimeHeaders } from './with-user-datetime-headers';

const isSanityRequest = (context?: Record<string, any>) => !isNil(context && context.uri);
const operationIsGatewayRequest = (operation: Operation) => isGatewayRequest(operation.query);
const operationIsSanityRequest = (operation: Operation) => isSanityRequest(operation.getContext());
const operationIsCacheRequest = (operation: Operation) => isCacheRequest(operation.query);

const fetchOptions = {
  referrerPolicy: 'no-referrer',
};

const sanityHttpLink = from([
  withLocalizedQueries(),
  new RetryLink({ attempts: { max: 3 } }),
  // sanity requests have the uri set in context
  from([
    removeNestedTypenameFromMenuQueriesLink,
    createHttpLink({ credentials: 'omit', fetch: sanityFetch, fetchOptions }),
  ]),
]);

const withRBIGraphQLLinks = from([
  withAuthToken,
  withI18nHeaders,
  withDateTimeHeaders,
  withForterHeaders,
  withSessionId,
  withClientInfo,
]);

const gatewayHttpLink = createHttpLink({
  uri: getGraphqlGatewayApiUrl(),
  credentials: 'omit',
});

const gatewaySplit = (alternativeGqlApiUrl: string = '') => {
  return split(
    operationIsGatewayRequest,
    split(
      operationIsCacheRequest,
      from([
        stripGatewayDirective,
        stripUseCacheDirective,
        createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
        gatewayHttpLink,
      ]),
      from([stripGatewayDirective, gatewayHttpLink])
    ),
    from([
      stripGatewayDirective,
      split(
        /**
         * We avoid batching requests via BatchHttpLink when:
         * - context.shouldNotBatch = true
         * - app is run within Cypress. we want deterministic tests.
         */
        operation => operation.getContext().shouldNotBatch || !!window?.Cypress,
        createHttpLink({
          uri: getGraphqlApiUrl(alternativeGqlApiUrl),
          credentials: 'omit',
        }),
        new BatchHttpLink({
          uri: getGraphqlApiUrl(alternativeGqlApiUrl),
          credentials: 'omit',
        }) as ApolloLink
      ),
    ])
  );
};

/**
 * The lambdaHttpLink composes the link chain for requests going out to our
 * lambda backend. It can be difficult to visualize the chain with all the
 * splits, so here's a diagram:
 *
 *                         ...withRBIGraphQLLinks
 *                      is auth required on this request?
 *                         /                 \
 *                       yes                  no
 *                      /                      \
 *                 is user logged in?       gateway split
 *                  /           \
 *                yes           no
 *               /                \
 *          gateway split     invalid request link
 *
 * we split out the gatewaySplit into a variable so that it can be re-used,
 * because there are now two different places where we might want to use it.
 * after composing the "base" links (`withRBIGraphQLLinks`), we first ask if
 * we're dealing with a query that required auth. if not, we send the request
 * down the pre-existing chain, which just has the gateway split as the next
 * step. if the query does require auth, we send it to this new step, which
 * then asks if the user is logged in. if not, we cancel the request and
 * return an error. if they are logged in, then we treat it as a "normal"
 * request and send it down the rest of the link chain, where the first
 * step is the gateway split.
 */
const lambdaHttpLink = (alternativeGqlApiUrl: string = '') => {
  return from([
    withRBIGraphQLLinks,
    split(
      isRequestAuthProtected,
      split(
        isUserLoggedIn,
        from([stripAuthDirective, gatewaySplit(alternativeGqlApiUrl)]),
        cancelRequestLink(
          `@${AUTH_REQUIRED_DIRECTIVE} directive present on query but user is not authorized.`
        )
      ),
      gatewaySplit(alternativeGqlApiUrl)
    ),
  ]);
};

const httpSplit = (alternativeGqlApiUrl: string = '') => {
  return split(operationIsSanityRequest, sanityHttpLink, lambdaHttpLink(alternativeGqlApiUrl));
};

export const link = (alternativeGqlApiUrl: string = '') => {
  return from([
    withLogger,
    withMParticle,
    withErrorLogger,
    withErrorMParticle,
    withI18nContext,
    withHeaders({ 'content-type': 'application/json' }),
    stripTypenameLink,
    httpSplit(alternativeGqlApiUrl),
  ]);
};

// ensure language and region are set before any queries are fired
export const getConfiguredLink = (
  language: SupportedLanguages,
  region: SupportedRegions,
  alternativeGqlApiUrl: string
) => {
  withI18nContext.setLocale(language, region);

  return link(alternativeGqlApiUrl);
};
